import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

// styled
import GridStyled from '../styled/GridStyled';
import RowStyled from '../styled/RowStyled';
// common
import UploadFile from '../commons/UploadFile';
import TextField from '../commons/TextField';
import TextFieldPhone from '../commons/TextFieldPhone';

const ProfileEdit = (props) => {
  const { user, onHandleFormSubmit, buttonFormSubmitRef } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      avatar: '',
      given_name: '',
      family_name: '',
      email: '',
      phone_number: '',
      display_currency: '',
    },
  });

  function onSubmit(values) {
    onHandleFormSubmit(values);
  }

  useEffect(() => {
    if (!user || Object.keys(user).length === 0) return;
    setValueForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const setValueForm = () => {
    setValue('avatar', user?.picture?.url);
    setValue('given_name', user?.given_name);
    setValue('family_name', user?.family_name);
    setValue('email', user?.email);
    setValue('phone_number', user?.phone_number);
    setValue('display_currency', user?.profile?.display_currency);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CampaignContainer>
        <CustomGridStyled>
          <RowStyled>
            <UploadFile {...register('avatar')} width="101px" height="101px" error={errors?.avatar?.message} />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <TextField
              {...register('given_name', {
                required: {
                  value: true,
                  message: 'First Name is required',
                },
              })}
              name="given_name"
              label="First Name"
              error={errors?.given_name?.message}
            />
          </RowStyled>
          <RowStyled>
            <TextField
              {...register('family_name', {
                required: {
                  value: true,
                  message: 'Last Name is required',
                },
              })}
              name="family_name"
              label="Last Name"
              error={errors?.family_name?.message}
            />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <TextField
              {...register('email', {
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Email is invalid',
                },
              })}
              label="Email Address"
              name="email"
              placeholder="Enter your email address"
              error={errors?.email?.message}
              readonly
            />
          </RowStyled>
          <RowStyled>
            <TextFieldPhone
              {...register('phone_number', {
                required: {
                  value: true,
                  message: 'Phone number is required',
                },
                validate: (value: any) => {
                  return !value.phoneNumber ? 'Phone number is required' : value;
                },
              })}
              placeholder="Enter your phone number"
              error={errors?.phone_number?.message}
              name="phone_number"
              label="Phone"
            />
          </RowStyled>
        </CustomGridStyled>
      </CampaignContainer>
      <HideButtonStyled ref={buttonFormSubmitRef} type="submit"></HideButtonStyled>
    </Form>
  );
};

export default ProfileEdit;

const Form = styled.form`
  width: 100%;
`;

const CampaignContainer = styled.div<any>`
  max-width: 700px;
  padding-top: 34.5px;
  padding-bottom: 80px;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const CustomGridStyled = styled<any>(GridStyled)`
  margin-bottom: 9px;
  &:first-child {
    margin-bottom: 28px;
  }
`;
const HideButtonStyled = styled.button<any>`
  display: none;
`;
