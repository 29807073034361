import styled from 'styled-components';
import { ReactComponent as CheckCirCle } from '../../../assets/svg/Icon-feather-check-circle.svg';
import { ReactComponent as AccountUnAvailableIcon } from '../../../assets/svg/account_unavailable.svg';
import HeaderAuth from '../HeaderAuth';
import { Button, Typography } from '@mui/material';
import BackSvg from '../../../assets/svg/BackSvg';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../utils/constants';
import { useContext } from 'react';
import ClientProfileContext from '../../../containers/ClientProfileProvider';

const PendingRegister = (props: { type: 'pendingReview' | 'unAvailable' | 'tokenExpired' | undefined | null | '' }) => {
  const history = useHistory();
  const { saveCurrentProfile } = useContext(ClientProfileContext);

  const backToMerchantList = () => {
    saveCurrentProfile(undefined);
    history.push(ROUTES.selectMerchant);
  };

  if (!props.type || props.type === 'pendingReview') {
    return (
      <>
        <HeaderAuth />
        <Container>
          <Content>
            <CheckCirCle />
            <Title>Your account is pending for review.</Title>
            <Message>You will be informed via email as soon as the account is approved.</Message>
            <Button onClick={backToMerchantList} startIcon={<BackSvg />} sx={{ marginTop: '32px', color: '#000000' }}>
              <Typography variant="body1">Back</Typography>
            </Button>
          </Content>
        </Container>
      </>
    );
  }

  if (props.type === 'unAvailable') {
    return (
      <>
        <HeaderAuth />
        <Container>
          <Content>
            <AccountUnAvailableIcon />
            <Title>Your account is Not Available</Title>
            <Message>If you have any questions relating to the account, please reach us at hello@kols.asia</Message>
            <Button onClick={backToMerchantList} startIcon={<BackSvg />} sx={{ marginTop: '32px', color: '#000000' }}>
              <Typography variant="body1">Back</Typography>
            </Button>
          </Content>
        </Container>
      </>
    );
  }

  if (props.type === 'tokenExpired') {
    return (
      <>
        <HeaderAuth />
        <Container>
          <Content>
            <AccountUnAvailableIcon />
            <Title>Unable to sign in</Title>
            <Message>You do not have permission to access this merchant</Message>
            <Button onClick={backToMerchantList} startIcon={<BackSvg />} sx={{ marginTop: '32px', color: '#000000' }}>
              <Typography variant="body1">Back</Typography>
            </Button>
          </Content>
        </Container>
      </>
    );
  }

  return null;
};

export default PendingRegister;

const Container = styled.div<any>`
  height: calc(100vh - 80px);
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    width: 55px;
    height: 55px;
  }
`;
const Content = styled.div<any>`
  margin: -160px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h2`
  color: #000000;
  font: normal normal bold 34px/41px 'Helvetica Neue';
  margin: 20px 0 0;
`;
const Message = styled.p<any>`
  font: normal normal normal 22px/27px Helvetica Neue;
  margin: 10px 0 0;
  opacity: 0.6;
  color: #000000;

  & a {
    color: #7b73f4;
    text-decoration: none;
  }
`;
