import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../../commons/Button';

const PublistNavbar = ({ handleEditCampaign, handlePublishCampaign, isLoading }) => {
  return (
    <PublistNavbarWrapepr>
      <RowStyled style={{ width: 'fit-content' }}>
        <Button type="button" width="110px" variant="secondary" label="Edit" onClick={handleEditCampaign} />
        <Button type="button" width="149px" label={isLoading ? 'WAITING' : 'Publish'} onClick={handlePublishCampaign} />
      </RowStyled>
    </PublistNavbarWrapepr>
  );
};

export default memo(PublistNavbar);

PublistNavbar.propTypes = {
  handleEditCampaign: PropTypes.func,
  handlePublishCampaign: PropTypes.func,
  isLoading: PropTypes.bool,
};

const PublistNavbarWrapepr = styled.div<any>`
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0px -1px 10px #00000029;
`;

const RowStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 0 auto;
`;
