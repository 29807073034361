import { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PRIVATE_ROUTE } from '../../../../utils/constants';
import { formatCurrencyInput } from '../../../../utils/formatCurrency';

import { ReactComponent as CloseSVG } from '../../../../assets/svg/close_black_24dp.svg';

import PopUpLayout from '../../popUpLayout/PopUpLayout';
import Button from '../../../commons/Button';

const CampaignPublishSuccessPopUp = ({ balance, balanceUSD, onRefresh, currency }) => {
  const history = useHistory();

  const goToCampaign = useCallback(() => {
    onRefresh();
    history.push(PRIVATE_ROUTE.campaigns);
  }, [history, onRefresh]);

  return (
    <PopUpLayout width="376px">
      <Header>
        <HeaderTitle>Confirm</HeaderTitle>
        <CloseSVG onClick={goToCampaign} />
      </Header>
      <Content>
        <DescribeStyled>Your campaign has been published.</DescribeStyled>
        <BalanceLabel>NEW BALANCE</BalanceLabel>
        <MoneyContainer>
          <MoneyStyled>
            {formatCurrencyInput(balance)} {currency}
          </MoneyStyled>
          {currency !== 'USD' && <MoneyStyledSmall>~{formatCurrencyInput(balanceUSD)} USD</MoneyStyledSmall>}
        </MoneyContainer>
        <Button label="CLOSE" onClick={goToCampaign} />
      </Content>
    </PopUpLayout>
  );
};

export default memo(CampaignPublishSuccessPopUp);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 20px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  color: #000000;
`;

const Content = styled.div<any>`
  padding: 18px 20px 33px;
  max-width: initial;
  width: 100%;
`;

const MoneyStyled = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #000000;
  margin: 5px 0 0px;
`;

const MoneyStyledSmall = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #717171;
  margin: 5px 0 0px;
`;

const MoneyContainer = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #000000;
  margin: 5px 0 40px;
`;

const DescribeStyled = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #5770c6;
  margin-bottom: 28px;
`;

const BalanceLabel = styled.div<any>`
  color: #949494;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  font-family: GilRoy-Bold;
`;
