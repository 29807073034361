import { gql } from '@apollo/client';
export default gql`
  mutation CreateMerchantSurvey(
    $client_profile_id: uuid = ""
    $display_currency: String = ""
    $is_currently_running_campaigns: Boolean = false
    $number_of_campaigns_annually: Int = 10
    $avg_kols_per_campaign: Int = 10
    $avg_budget_per_campaign_usd_cents: bigint = ""
    $additional_info: String = ""
  ) {
    insert_client_survey_one(
      object: {
        client_profile_id: $client_profile_id
        display_currency: $display_currency
        is_currently_running_campaigns: $is_currently_running_campaigns
        number_of_campaigns_annually: $number_of_campaigns_annually
        avg_kols_per_campaign: $avg_kols_per_campaign
        avg_budget_per_campaign_usd_cents: $avg_budget_per_campaign_usd_cents
        additional_info: $additional_info
      }
    ) {
      id
      currency {
        code
        name
        symbol
      }
      is_currently_running_campaigns
      number_of_campaigns_annually
      avg_kols_per_campaign
      avg_budget_per_campaign_usd_cents
      additional_info
      updated_at
      created_at
      client_profile_id
    }
  }
`;
