import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../../assets/svg/logo_white.svg';
import { ReactComponent as LogoMobileSVG } from '../../assets/svg/logo_svg.svg';
import { mobileQueryString } from '../../utils/responsive';
import useMobile from '../../hooks/useMobile';

const LogoContainer = styled.div<any>`
  padding-top: 17px;

  svg {
    width: 150px;
    height: 22px;
  }

  ${mobileQueryString} {
    padding-top: 0;
  }
`;

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 45px;

  ${mobileQueryString} {
    background-color: #fff;
    padding: 10px 20px;
  }
`;

const Container = styled.div<any>`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: transparent linear-gradient(254deg, #51adf0 0%, #666bfa 49%, #b64dc8 100%) 0% 0% no-repeat padding-box;
`;

const LoginLink = styled.div<any>`
  font: normal normal normal 14px/17px Helvetica Neue;
  font-weight: 500;
  color: white;
  cursor: pointer;

  ${mobileQueryString} {
    font-size: 16px;
    line-height: 19px;
    color: #3e3e3e;
  }
`;

const Authorization = () => {
  const location = useLocation();
  const { addToast } = useToasts();
  const { loginWithRedirect } = useAuth0();
  const isMobile = useMobile();

  const error = useMemo(() => {
    return new URLSearchParams(location.search).get('error');
  }, [location]);

  useEffect(() => {
    if (error === 'email') {
      addToast('This email has been used, please try another email.', { appearance: 'error', autoDismiss: true });
    }
  }, [error, addToast]);

  return (
    <Container>
      <Header>
        <LogoContainer>{isMobile ? <LogoMobileSVG /> : <LogoSVG />}</LogoContainer>

        <LoginLink onClick={loginWithRedirect} role="button">
          SIGN IN / REGISTER
        </LoginLink>
      </Header>
    </Container>
  );
};

export default Authorization;
