import { gql } from '@apollo/client';

export default gql`
  mutation topUp($stripeToken: String!, $amount: numeric!, $walletId: uuid!) {
    topUp(stripeToken: $stripeToken, amount: $amount, walletId: $walletId) {
      message
      success
    }
  }
`;
