import { useCallback, useEffect, useState } from 'react';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const onResize = useCallback(() => {
    if (window.innerWidth <= 576) {
      setIsMobile(true);
      return;
    }
    setIsMobile(false);
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return isMobile;
};

export default useMobile;
