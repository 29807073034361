import { memo, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PRIVATE_ROUTE } from '../../../../utils/constants';

// assets
import { ReactComponent as LogoutSVG } from '../../../../assets/svg/profile-logout.svg';
import { ReactComponent as BusinessSVG } from '../../../../assets/svg/profile_business.svg';
import { ReactComponent as WalletSVG } from '../../../../assets/svg/profile_wallet.svg';
import { ReactComponent as ProfileSVG } from '../../../../assets/svg/profile_personal.svg';

// components
import Typography from '../../../commons/Typography';
import ClientProfileContext from '../../../../containers/ClientProfileProvider';

const PopoverContent = ({ handleLogout }) => {
  const history = useHistory();
  const { currentProfile } = useContext(ClientProfileContext);

  return (
    <ContainerStyled>
      <ListStyled>
        <ItemStyled onClick={() => history.push(PRIVATE_ROUTE.wallet)}>
          <UserSVGStyled>
            <WalletSVG />
          </UserSVGStyled>
          <TypographyStyled hasBorderBottom>
            <Typography text="E-Wallet" color="#000000" fontSize="12px/15px" />
          </TypographyStyled>
        </ItemStyled>

        <ItemStyled onClick={() => history.push(PRIVATE_ROUTE.userProfile + '?mode=1')}>
          <UserSVGStyled>
            <ProfileSVG />
          </UserSVGStyled>
          <TypographyStyled hasBorderBottom>
            <Typography text="Edit User Profile" color="#000000" fontSize="12px/15px" />
          </TypographyStyled>
        </ItemStyled>

        <ItemStyled onClick={() => history.push(PRIVATE_ROUTE.companyProfile + '?mode=1')}>
          <UserSVGStyled>
            <BusinessSVG />
          </UserSVGStyled>
          <TypographyStyled hasBorderBottom>
            <Typography
              text={currentProfile?.isAdmin() ? 'Edit Company Profile' : 'View Company Profile'}
              color="#000000"
              fontSize="12px/15px"
            />
          </TypographyStyled>
        </ItemStyled>
        <ItemStyled onClick={handleLogout}>
          <UserSVGStyled>
            <LogoutSVG />
          </UserSVGStyled>
          <TypographyStyled>
            <Typography text="Log Out" color="#000000" fontSize="12px/15px" />
          </TypographyStyled>
        </ItemStyled>
      </ListStyled>
    </ContainerStyled>
  );
};

export default memo(PopoverContent);

// PopoverContent.propTypes = {
//   handleLogout: PropTypes.func,
// };

const ContainerStyled = styled.div<any>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 30px #00000029;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  z-index: 10;
  top: 53px;
  right: -15px;
  min-width: 206px;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    right: 42px;
    top: -6px;
  }
`;

const ListStyled = styled.ul`
  margin: 0;
  padding: 3px 0 0 0;
`;

const ItemStyled = styled.li<any>`
  list-style: none;
  display: flex;
  align-items: stretch;
  height: 54px;
  padding: 0px 10px 0 22px;
  cursor: pointer;
`;

const UserSVGStyled = styled.div<any>`
  margin-right: 18px;
  margin-top: 8px;
  align-self: center;
  svg {
    height: 20px;
  }
`;

const WalletSVGStyled = styled.div<any>`
  margin-right: 18px;
  align-self: center;
  svg {
    width: 20px;
    height: 15px;
  }
`;

const TypographyStyled = styled.div<any>`
  flex-grow: 1;

  div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.hasBorderBottom &&
    css`
      border-bottom: 1px solid #eee;
    `}
`;
