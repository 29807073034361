import { SVGProps } from 'react';

const BackSvg = ({ color, ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5818_6673)">
      <path d="M17.978 19.355L12.6347 14L17.978 8.645L16.333 7L9.33301 14L16.333 21L17.978 19.355Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_5818_6673">
        <rect width="28" height="28" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BackSvg;
