import { memo } from 'react';
import styled from 'styled-components';

import { formatCurrencyInput } from '../../../../utils/formatCurrency';

import { ReactComponent as CloseSVG } from '../../../../assets/svg/close_black_24dp.svg';

import PopUpLayout from '../../popUpLayout/PopUpLayout';
import Button from '../../../commons/Button';

const CampaignPublishPopUpFail = ({ wallet, walletUSD, currency, onClose, handleTopUp }) => {
  return (
    <PopUpLayout width="376px">
      <Header>
        <HeaderTitle>Failed</HeaderTitle>
        <CloseSVG onClick={onClose} />
      </Header>
      <ContentWrapper>
        <Paragraph>The top up has failed.</Paragraph>
        <BalanceLabel>CURRENT BALANCE</BalanceLabel>
        <BalanceContainer>
          <BalanceValue>
            {formatCurrencyInput(wallet)} {currency}
          </BalanceValue>
          {currency !== 'USD' && <BalanceValueSmall> {formatCurrencyInput(walletUSD)} USD</BalanceValueSmall>}
        </BalanceContainer>
        <ButtonStyled label="TRY AGAIN" onClick={handleTopUp} />
        <ButtonStyled label="CLOSE" variant="secondary" onClick={onClose} />
      </ContentWrapper>
    </PopUpLayout>
  );
};

export default memo(CampaignPublishPopUpFail);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 20px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  color: #eb4f3f;
`;

const ContentWrapper = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 20px;
`;

const Paragraph = styled.div<any>`
  font: normal normal bold 16px/22px Helvetica Neue;
  color: #0b0b0b;
`;

const BalanceLabel = styled.div<any>`
  color: #5770c6;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  font-family: GilRoy-Bold;
  margin: 27px 0 5px;
`;

const BalanceContainer = styled.div<any>`
  margin-bottom: 40px;
`;

const BalanceValue = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #5770c6;
  margin-bottom: 0px;
`;

const BalanceValueSmall = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #5770c6;
  margin-bottom: 0px;
`;

const ButtonStyled = styled<any>(Button)`
  height: 46px;
`;
