import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

// styled
import GridStyled from '../../styled/GridStyled';
import RowStyled from '../../styled/RowStyled';

// common
import TextField from '../../commons/TextField';
import TextFieldPhone from '../../commons/TextFieldPhone';
import StepperRegister from '../StepperRegister';
import StepperRegisterMobile from '../StepperRegisterMobile';
import Selectbox from '../../commons/Selectbox';
import Button from '../../commons/Button';
// query
import GET_LIST_CURRENCY from '../../../queries/common/list-currency';
import GET_LIST_COUNTRY from '../../../queries/common/list-country';
import { mobileQueryString } from '../../../utils/responsive';

function StepCompanyDetail(props) {
  const { submitCompanyDetailsForm, backToStep, isSubmitting, profileData = {} } = props;
  const [listCountry, setListCountry] = useState<any>([]);

  const { data: listCurrencyData } = useQuery(GET_LIST_CURRENCY);
  const { data: listCountryData } = useQuery(GET_LIST_COUNTRY);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: profileData.company_name || '',
      address: profileData.address || '',
      address2: profileData.address2 || '',
      state: profileData.region || '',
      city: profileData.region2 || '',
      postalCode: profileData?.postal_code || '',
      country: profileData?.country?.code,
      display_currency: profileData?.display_currency || profileData?.default_currency?.code || '',
      phone: profileData?.phone_number || '',
    },
  });

  function onSubmit(values) {
    const {
      companyName,
      address,
      address2,
      // eslint-disable-next-line no-unused-vars
      phone: { phoneCode, phoneNumber },
      country,
      postalCode,
      city,
      state,
      display_currency,
    } = values;
    submitCompanyDetailsForm({
      companyName,
      phone: phoneNumber,
      address,
      address2,
      country,
      postalCode,
      city,
      state,
      display_currency,
    });
  }

  const compareCurrency = useCallback((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }, []);

  const listCurrency = useMemo(() => {
    if (!listCurrencyData || !Array.isArray(listCurrencyData.currency_category)) return [];
    return listCurrencyData.currency_category
      ?.map((c) => ({
        ...c,
        label: c.name,
        name: `${c.symbol} - ${c.name}`,
        value: c.code,
      }))
      .sort(compareCurrency);
  }, [listCurrencyData, compareCurrency]);

  useEffect(() => {
    if (listCountryData && Array.isArray(listCountryData.country_category)) {
      setListCountry(listCountryData.country_category);
    }
  }, [listCountryData]);

  return (
    <>
      <StepperRegister active="1" backToStep={backToStep}></StepperRegister>
      <StepperRegisterMobile title="Company Details" order="1/2" active="1" backToStep={backToStep} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <GridStyled>
          <RowStyled>
            <TextField
              label="Company Name"
              {...register('companyName', {
                required: {
                  value: true,
                  message: 'Company name is required',
                },
              })}
              error={errors?.companyName?.message}
            />
          </RowStyled>
          <RowStyled>
            <TextFieldPhone
              {...register('phone', {
                validate: (value) => {
                  return !value.phoneNumber ? 'Phone number is required' : value;
                },
              })}
              phoneNumber={profileData?.phone_number}
              error={errors?.phone?.message}
            />
          </RowStyled>
        </GridStyled>
        <GridStyled>
          <RowStyled>
            <TextField
              label="Address"
              {...register('address', {
                required: {
                  value: true,
                  message: 'Address is required',
                },
              })}
              error={errors?.address?.message}
            />
          </RowStyled>
          <RowStyled>
            <TextField label="Address 2" {...register('address2')} />
          </RowStyled>
        </GridStyled>
        <GridStyled isRow>
          <RowStyled>
            <Selectbox
              label="Country"
              options={listCountry.map((c) => ({ ...c, value: c.code }))}
              {...register('country', {
                required: {
                  value: true,
                  message: 'Country is required',
                },
              })}
              defaultValue={profileData?.country?.code}
              error={errors?.country?.message}
              canInput
            />
          </RowStyled>
          <RowStyled>
            <TextField label="Postalcode" {...register('postalCode')} />
          </RowStyled>
        </GridStyled>
        <GridStyled isRow>
          <RowStyled>
            <TextField
              label="City"
              {...register('city', {
                required: {
                  value: true,
                  message: 'City is required',
                },
              })}
              error={errors?.city?.message}
            />
          </RowStyled>
          <RowStyled>
            <TextField
              label="State"
              {...register('state', {
                required: {
                  value: true,
                  message: 'State is required',
                },
              })}
              error={errors?.state?.message}
            />
          </RowStyled>
        </GridStyled>
        <GridStyled>
          <RowStyled>
            <Selectbox
              label="Display Currency"
              placeholder="$ - USD"
              canInput
              options={listCurrency}
              renderOption={(item) => item.label}
              {...register('display_currency', {
                required: {
                  value: true,
                  message: 'Display currency is required',
                },
              })}
              defaultValue={profileData?.display_currency || profileData?.default_currency?.code}
              error={errors?.display_currency?.message}
            />
          </RowStyled>
          <RowStyled />
        </GridStyled>
        <GridStyled>
          <RowStyled justifyContent="center">
            <Button disabled={isSubmitting} label={isSubmitting ? 'Please wait' : 'Next'} width="211px" />
          </RowStyled>
        </GridStyled>
      </Form>
    </>
  );
}
const Form = styled.form`
  max-width: 100%;
  width: 700px;
  margin: 60px auto 0;

  ${mobileQueryString} {
    padding: 0 20px;
    margin: 0 auto;
  }
`;
export default StepCompanyDetail;
