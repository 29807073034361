import React from 'react';
import styled, { css } from 'styled-components';

function Typography(props: any) {
  return (
    <ComponentStyled
      color={props.color}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      isCenter={props.isCenter}
      pdTop={props.pdTop}
    >
      <span>{props.text}</span>
    </ComponentStyled>
  );
}

export default Typography;

const ComponentStyled = styled.div<any>`
  span {
    padding-top: ${(props) => props.pdTop || '4px'};
    ${(props) =>
      props.isCenter &&
      css`
        padding-top: 0px;
      `}
    color: ${(props) => props.color};
    font: normal normal ${(props) => props.fontWeight} ${(props) => props.fontSize} Helvetica Neue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;

Typography.defaultProps = {
  text: 'Default',
  color: '#000',
  fontSize: '28px / 34px',
  fontWeight: '',
};
