export interface CommonData {
  campaign_status_category: CampaignStatusCategory[];
  age_category: AgeCategory[];
  country_category: CountryCategory[];
  currency_category: CurrencyCategory[];
  gender_category: GenderCategory[];
  product_category: ProductCategory[];
  social_platform_category: SocialPlatformCategory[];
  work_contract_status_category: WorkContractStatusCategory[];
  work_item_status_category: WorkItemStatusCategory[];
  content_type: ContentType[];
  language: Language[];
}

export interface CampaignStatusCategory {
  last_updated_at: string;
  is_active: boolean;
  created_at: string;
  description: string;
  code: string;
  __typename: string;
}

export interface AgeCategory {
  code: string;
  created_at: string;
  is_active: boolean;
  last_updated_at: string;
  max_value: number;
  min_value: number;
  name: string;
  __typename: string;
}

export interface CountryCategory {
  code: string;
  name: string;
  is_active: boolean;
  created_at: string;
  phone_code: string;
  updated_at: string;
  __typename: string;
}

export interface CurrencyCategory {
  symbol: string;
  name: string;
  code: string;
  fraction_size: number;
  template: string;
  __typename: string;
}

export interface GenderCategory {
  updated_at: string;
  is_active: boolean;
  description: string;
  created_at: string;
  code: string;
  __typename: string;
}

export interface ProductCategory {
  code: string;
  created_at: string;
  is_active: boolean;
  last_created_at: string;
  name: string;
  __typename: string;
}

export interface SocialPlatformCategory {
  name: string;
  last_updated_at: string;
  is_active: boolean;
  created_at: string;
  code: string;
  __typename: string;
}

export interface WorkContractStatusCategory {
  last_updated_at: string;
  is_active: boolean;
  description: string;
  created_at: string;
  code: string;
  __typename: string;
}

export interface WorkItemStatusCategory {
  last_updated_at: string;
  is_active: boolean;
  description: string;
  created_at: string;
  code: string;
  __typename: string;
}

export interface ContentType {
  code: string;
  name: string;
  __typename: string;
}

export interface Language {
  code: string;
  title: string;
  __typename: string;
}

export enum SocialPlatform {
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Tiktok = 'Tiktok',
}
