import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const KOLEngagedView = ({ totalKOLs, labels, values }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of KOL Engaged',
        data: values,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Container>
        <Header>
          <Title>KOL Engaged</Title>
          <Value>{totalKOLs}</Value>
        </Header>
        <Line />
        <Content>
          <ChartContent>
            <Pie
              data={data}
              width={20}
              height={20}
              options={{
                interaction: {
                  mode: 'index',
                },
                layout: {
                  padding: 10,
                },
                plugins: {
                  legend: {
                    position: 'bottom',
                    rtl: true,
                    fullSize: true,
                  },
                },
              }}
            />
          </ChartContent>
        </Content>
      </Container>
    </>
  );
};

const ContentCreatedView = () => {
  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [15, 16, 2, 3, 1, 2],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Container>
        <Header>
          <Title>Content Created</Title>
          <Value>20</Value>
        </Header>
        <Line />
        <Content>
          <ChartContent>
            <Pie
              data={data}
              width={20}
              height={20}
              options={{
                interaction: {
                  mode: 'index',
                },
                layout: {
                  padding: 10,
                },
                plugins: {
                  legend: {
                    position: 'bottom',
                    rtl: true,
                    fullSize: true,
                  },
                },
              }}
            />
          </ChartContent>
        </Content>
      </Container>
    </>
  );
};

export default KOLEngagedView;

const Container = styled.div<any>`
  width: 100%;
  overflow: hidden;
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  border-color: red;
  outline: 1px #d9d9d9 solid;
  > svg {
    width: 55px;
    height: 55px;
  }
`;
const Content = styled.div<any>`
  margin: 24;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ChartContent = styled.div<any>`
  margin: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Header = styled.div<any>`
  margin: auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 48px;
  width: 100%;
  padding: 16px;
`;

const Title = styled.div<any>`
  color: #000000;
  font: normal normal bold 24px/24px Inter;
  margin: 0 0;
`;
const Value = styled.div<any>`
  font: normal normal normal 36px/36px Inter;
  margin: 0 0;
  color: #5770c6;
`;

const Line = styled.div<any>`
  margin: auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
  padding: 0px;
`;
