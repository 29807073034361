import React from 'react';
import ImageShowProvider from '../../components/commons/ImageShowProvider';
import Chat from '../../components/commons/Chat';

const CampaignChat = ({ conversation, owner = {}, height, isLoading, onSendMessage }) => {
  return (
    <ImageShowProvider>
      <Chat conversation={conversation} owner={owner} height={height} loading={isLoading} onSendMessage={onSendMessage} />
    </ImageShowProvider>
  );
};

export default CampaignChat;
