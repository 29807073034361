import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import Typography from '../../components/commons/Typography';
import MainLayout from '../../components/layouts/MainLayout/MainLayout';
import MainLayoutGoBack from '../../components/layouts/MainLayout/MainLayoutGoBack';
import AsideLeftCampaignDetail from '../../components/campaigns/AsideLeftCampaignDetail';
import useExport from '../../hooks/useExport';
import { PRIVATE_ROUTE, ROUTES } from '../../utils/constants';
import qs from 'query-string';
import * as R from 'ramda';
import { formatDistanceToNow } from 'date-fns';

import useCampaign from '../../hooks/useCampaign';
import useWorkContract from '../../hooks/useWorkContract';
import useMobile from '../../hooks/useMobile';
import ContentMetricsUI from '../../components/campaigns/ContentMetrics/ContentMetricsUI';

function ContentMetrics() {
  let { id } = useParams() as any;
  let { kol_profile_id } = useParams() as any;
  const { campaignDetail, onGetCampaignById } = useCampaign() as any;
  const isMobile = useMobile();
  const { onApproveWorkContract, onRejectWorkContract } = useWorkContract();
  const tableRef = useRef<any>();
  const { onExportPdf } = useExport();
  const [data, setData] = useState<any>([]);
  const [listWorkContract, setListWorkContract] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    if (!campaignDetail) {
      onGetCampaignById(id);
      return;
    }
    const listWorkContract = campaignDetail?.tranches.map((tranche) => tranche.work_contracts).flat();
    setListWorkContract(listWorkContract);
    const listContractFilter = handleGetListWorkItem(listWorkContract);
    setData(listContractFilter);
    handleAutomateOpenChat(listWorkContract);
    handleAutomateOpenDeliverable(listWorkContract);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignDetail]);

  const owner = useMemo(() => {
    if (!campaignDetail || !campaignDetail?.client_profile) return {};
    return {
      name: campaignDetail?.client_profile?.company_name, // string or nullable
      avatar: campaignDetail?.client_profile?.logo_file?.url, // string or nullable
      lastActivityTime: formatDistanceToNow(new Date(campaignDetail?.created_at)), // instance of Date or string
      status: campaignDetail?.status.toLowerCase(), // string or nullable
    };
  }, [campaignDetail]);

  const gotoListCampaign = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };
  const renderHeaderCenter = (
    <MenuItemStyled onClick={gotoListCampaign}>
      <Typography text="Campaigns" color="#5770C6" fontSize="16px / 19px" fontWeight="500" />
    </MenuItemStyled>
  );

  const backToCampaign = () => {
    var path = generatePath(ROUTES.campaignsReport, { id: id });
    history.push(path);
  };

  const handleChangeTab = (tab) => {
    const newWorkContract = listWorkContract.filter((ls: any) => {
      if (tab === 'CLIENT_APPROVED') {
        return ls.status.code === tab;
      }
      return ls.status.code !== 'CLIENT_APPROVED';
    });
    setData(newWorkContract);
  };

  const handleAccept = async (item) => {
    await onApproveWorkContract(item.id);
    onGetCampaignById(id);
  };

  const handleReject = async (item) => {
    await onRejectWorkContract(item.id);
    onGetCampaignById(id);
  };

  const handleGotoChat = (item) => {
    const path = generatePath(ROUTES.campaignsChat, { id: id, contractID: item.id });
    history.push(path);
  };

  const handleGotoDeliverables = (item) => {
    const path = generatePath(ROUTES.campaignsDeliverables, { id: id, contractID: item.id });
    history.push(path);
  };

  const handleSearch = (text) => {
    const listContract = handleGetListWorkItem();
    if (!text) {
      setData(listContract);
    }
    const newData = listContract.filter((item: any) => R.toLower(item?.tranche?.name).includes(R.toLower(text)));
    setData(newData);
  };

  const handleDownloadPdf = () => {
    if (!tableRef.current) return;
    const el = tableRef.current;
    onExportPdf(el, 'yourfile.pdf');
  };

  const handleAutomateOpenChat = (listWs = listWorkContract) => {
    const query = qs.parse(history.location.search) as any;
    const senderthumbnailid = query.senderthumbnailid;
    if (senderthumbnailid) {
      const contract = listWs.filter((ls: any) => {
        return ls.kol_profile.user.picture.id === senderthumbnailid;
      }) as any;
      if (contract.length > 0) {
        const path = generatePath(ROUTES.campaignsChat, { id: id, contractID: contract[0].id });
        history.push(path);
      }
    }
  };

  const handleAutomateOpenDeliverable = (listWs = listWorkContract) => {
    const query = qs.parse(history.location.search) as any;
    const senderiddeliverable = query.senderiddeliverable;
    if (senderiddeliverable) {
      const contract = listWs.filter((ls: any) => {
        return ls.kol_profile.user.picture.id === senderiddeliverable;
      }) as any;
      if (contract.length > 0) {
        const path = generatePath(ROUTES.campaignsDeliverables, { id: id, contractID: contract[0].id });
        history.push(path);
      }
    }
  };

  const handleGetListWorkItem = (listWs = listWorkContract) => {
    const query = qs.parse(history.location.search) as any;
    const status = query.tab;
    const listContractFilter = listWs.filter((ls: any) => {
      if (status === 'CLIENT_APPROVED') {
        return ls.status.code === status;
      }
      return ls.status.code !== 'CLIENT_APPROVED';
    });
    return listContractFilter;
  };

  return (
    <>
      {isMobile ? (
        <MainLayoutGoBack
          onGoback={() => history.push(ROUTES.campaigns)}
          asideLeft={<AsideLeftCampaignDetail type={1} owner={owner} data={campaignDetail} id={id} backToStep={backToCampaign} />}
          asideRight={<ContentMetricsUI campaignId={id} kolProfileId={kol_profile_id} />}
        />
      ) : (
        <MainLayout
          hasButtonNew
          headerCenter={renderHeaderCenter}
          headerTeam={undefined}
          asideLeft={<AsideLeftCampaignDetail type={3} data={campaignDetail} id={id} backToStep={backToCampaign} />}
          asideRight={<ContentMetricsUI campaignId={id} kolProfileId={kol_profile_id} />}
        />
      )}
    </>
  );
}

export default ContentMetrics;

const MenuItemStyled = styled.div<any>``;
