import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../../../../assets/svg/logo_svg.svg';
import { ROUTES } from '../../../../utils/constants';

const CampaignPreviewHeader = ({ onGoBack, owner }) => {
  return (
    <Wrapper>
      <HeaderContentStyled>
        <CancelStyled onClick={() => onGoBack(1)}>Back</CancelStyled>
        <LinkStyled to={ROUTES.campaigns}>
          <LogoSVG />
        </LinkStyled>
        <div></div>
      </HeaderContentStyled>
      <ContainerStyled mt="40px">
        <MainStyled>
          <HeaderStyled>
            <AvatarStyled>
              <img src={owner.avatar} alt="Avatar" />
            </AvatarStyled>
            <div>
              <HeaderTitle>{owner?.title}</HeaderTitle>
              <HeaderName>{owner?.name}</HeaderName>
              <StatusStyled>{owner?.status}</StatusStyled>
            </div>
          </HeaderStyled>
        </MainStyled>
      </ContainerStyled>
      <TitleForm>Campaign Details</TitleForm>
    </Wrapper>
  );
};

export default memo(CampaignPreviewHeader);

CampaignPreviewHeader.propTypes = {
  onCancel: PropTypes.func,
};

const TextStyled = styled.div<any>`
  color: #5770c6;
  text-transform: uppercase;
`;

const CancelStyled = styled<any>(TextStyled)`
  font: normal normal 400 12px/14px Helvetica Neue;
`;

const LinkStyled = styled<any>(Link)`
  max-width: 150px;

  svg {
    max-width: 100%;
    height: 22px;
  }
`;

const HeaderContentStyled = styled.div<any>`
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const Wrapper = styled.div<any>`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  border-bottom: 1px solid #eeeeee;
`;

const ContainerStyled = styled.div<any>`
  max-width: 100%;
  margin: 71px auto 0;
  width: 100%;
  padding: 0 20px;
`;

const MainStyled = styled.div<any>`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const TitleForm = styled.div<any>`
  display: inline-block;
  font: normal normal 500 16px/19px Helvetica Neue;
  color: #5770c6;
  margin: 20px 20px 0 20px;
  padding-bottom: 16px;
  border-bottom: 2px solid #5770c6;
`;

const HeaderStyled = styled.div<any>`
  display: flex;
  column-gap: 10px;
`;

const AvatarStyled = styled.div<any>`
  img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #0b0b0b;
`;

const HeaderName = styled.div<any>`
  font: normal normal 500 14px/17px Helvetica Neue;
  color: #3e3e3e;
  margin: 2px 0 4px;
`;

const StatusStyled = styled.div<any>`
  display: inline-block;
  font: normal normal 400 12px/14px Helvetica Neue;
  color: #5770c6;
  background-color: rgba(219, 16, 142, 0.1);
  padding: 2px 5px;
  border-radius: 5px;
  text-transform: capitalize;
`;
