import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DEFAULT_AVATAR } from '../../../../utils/constants';
import { STATUS } from '../const/index';

const CampaignAcceptItem = ({ item, onGoToDeliverables, onGoToChat }) => {
  const [status, setStatus] = useState<any>({});
  const [part, setPart] = useState('');

  useEffect(() => {
    const listWorkItem = item?.work_items || [];
    const totalItem = listWorkItem.length;
    const countWorkItemApproved = listWorkItem.filter((w) => w?.status.code === STATUS.APPROVED.value).length;
    const isCompleted = totalItem === countWorkItemApproved && totalItem !== 0;
    setStatus(isCompleted ? STATUS.completed : STATUS.onGoing);
    setPart(`${countWorkItemApproved}/${totalItem}`);
  }, [item]);

  return (
    <ItemWrapper>
      <TopStyled>
        <LeftItemStyled>
          <AvatarStyled>
            <img src={item?.kol_profile?.user?.picture?.url || DEFAULT_AVATAR} alt="Logo" />
          </AvatarStyled>
          <div>
            <ContentStyled>
              {`${item?.kol_profile?.user?.given_name || ''} ${item?.kol_profile?.user?.family_name || ''}`}
            </ContentStyled>
            <Flex>
              <GroupStyled>{item?.tranche?.name}</GroupStyled>
            </Flex>
            <FlexStyled>
              <StatusTitle>Status:</StatusTitle>
              <StatusContent color={status.color}>{status.label}</StatusContent>
              <PartStyled>{part}</PartStyled>
            </FlexStyled>
          </div>
        </LeftItemStyled>
        <div className="action-navigation">
          <div onClick={() => onGoToDeliverables(item)}>Deliverables</div>
          <div className="chat-label" onClick={() => onGoToChat(item)}>
            Chat
          </div>
        </div>
      </TopStyled>
    </ItemWrapper>
  );
};

export default memo(CampaignAcceptItem);

CampaignAcceptItem.propTypes = {
  item: PropTypes.object,
  onGoToDeliverables: PropTypes.func,
  onGoToChat: PropTypes.func,
};

const ItemWrapper = styled.div<any>`
  margin-top: 20px;
  border-bottom: 1px solid #eeeeee;
`;

const TopStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  & .action-navigation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 7px;
    color: #5770c6;
    font: normal normal bold 12px/15px Helvetica Neue;
    & .chat-label {
      margin-top: 4px;
    }
    & > div {
      cursor: pointer;
    }
  }
`;

const AvatarStyled = styled.div<any>`
  margin-right: 12px;
  img {
    width: 59px;
    height: 59px;
    border-radius: 5px;
  }
`;

const Flex = styled.div<any>`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
`;

const FlexStyled = styled<any>(Flex)`
  margin-top: 3px;
`;

const GroupStyled = styled.div<any>`
  font: normal normal 400 13px/15px Helvetica Neue;
  color: #949494;
`;

const LeftItemStyled = styled.div<any>`
  display: flex;
`;

const ContentStyled = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #0b0b0b;
`;

const StatusTitle = styled.div<any>`
  font: normal normal normal 13px/16px Helvetica Neue;
  color: #949494;
`;

const StatusContent = styled.div<any>`
  font: normal normal bold 12px/15px Helvetica Neue;
  color: ${(props) => props.color || '#fff'};
  margin-top: 5px;
`;

const PartStyled = styled.div<any>`
  font: normal normal 400 13px/15px Helvetica Neue;
  color: #949494;
`;
