import { gql } from '@apollo/client';
export default gql`
  query CategoryValues {
    campaign_status_category(where: { is_active: { _eq: true } }) {
      last_updated_at
      is_active
      created_at
      description
      code
    }
    age_category(where: { is_active: { _eq: true } }) {
      code
      created_at
      is_active
      last_updated_at
      max_value
      min_value
      name
    }
    country_category(where: { is_active: { _eq: true } }) {
      code
      name
      is_active
      created_at
      phone_code
      updated_at
    }
    currency_category(where: {}) {
      symbol
      name
      code
      fraction_size
      template
    }
    gender_category(where: { is_active: { _eq: true } }) {
      updated_at
      is_active
      description
      created_at
      code
    }
    product_category(where: { is_active: { _eq: true } }) {
      code
      created_at
      is_active
      last_created_at
      name
    }
    social_platform_category(where: { is_active: { _eq: true } }) {
      name
      last_updated_at
      is_active
      created_at
      code
    }
    work_contract_status_category(where: { is_active: { _eq: true } }) {
      last_updated_at
      is_active
      description
      created_at
      code
    }
    work_item_status_category(where: { is_active: { _eq: true } }) {
      last_updated_at
      is_active
      description
      created_at
      code
    }
    content_type(where: { is_active: { _eq: true } }) {
      code
      name
    }
    language(where: {}) {
      code
      title
    }
  }
`;
