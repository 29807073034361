import { useQuery } from '@apollo/client';
import { fetchFxRatesAll } from '../queries/currencyConversion/convertCurrency';
import { keyBy } from 'lodash';

type RateRecord = {
  rate: number;
  currencyCode: string;
};

export const useFx = () => {
  const { data, loading } = useQuery<{ fetchAllRatesFromUSD: RateRecord[] }>(fetchFxRatesAll);

  if (loading) {
    return {
      data: [],
      mapCodeToRate: {},
      getRate: () => -1,
      loading,
    };
  }

  const list = data?.fetchAllRatesFromUSD;
  const mapCodeToRate = keyBy(list, 'currencyCode');

  return {
    list,
    mapCodeToRate,
    loading,
    getRate(target: string): number {
      if (target === 'USD') {
        return 1;
      }

      if (!Array.isArray(list) || list.length === 0) {
        throw new Error('Unable to get fx rate');
      }

      if (!mapCodeToRate[target]) {
        throw new Error(`Unable to get fx rate for ${target}`);
      }

      return mapCodeToRate[target]?.rate;
    },
  };
};
