import React, { useState, useEffect, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';
import ProfileEdit from '../components/profile/CompanyProfileEdit';
import MainLayout from '../components/layouts/MainLayout/MainLayout';
import MainLayoutMobile from '../components/layouts/MainLayout/MainLayoutMobile';
import Typography from '../components/commons/Typography';
import AsideLeftContent from '../components/commons/AsideLeftContent';
import Button from '../components/commons/Button';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import * as R from 'ramda';
import useGuard from '../hooks/useGuard';
import { useMutation } from '@apollo/client';
import UPDATE_PROFILE_USER from '../queries/onboarding/updateMerchantProfile';
import UPDATE_SURVEY from '../queries/onboarding/updateMerchantSurvey';
import { uploadFileHandler } from '../utils/heplers';
import SurveyForm from '../components/authentication/forms/SurveyForm';
import ContainerScroll from '../components/commons/ContainerScroll';
import { PRIVATE_ROUTE } from '../utils/constants';
import useMobile from '../hooks/useMobile';
import { mobileQueryString } from '../utils/responsive';
import useConvertCurrency from '../hooks/useConvertCurrency';
import useToastError from '../hooks/useToastError';
import ClientProfileContext from '../containers/ClientProfileProvider';
import { MerchantProfile } from '../types/merchantProfile';

const MODE = {
  INFO: '1',
  SURVEY: '2',
};

function CompanyProfile() {
  const isMobile = useMobile();
  const { user: currentUser, onUpdateProfile, onRefreshProfile, onRefreshUser } = useGuard();
  const { exchangeRate } = useConvertCurrency();
  const { toastError } = useToastError();
  const history = useHistory();
  const query = qs.parse(history.location.search) as any;
  const [mode, setMode] = useState(MODE.INFO);
  const [isLoading, setIsLoading] = useState(false);
  const [updateProfile] = useMutation(UPDATE_PROFILE_USER);
  const [updateSurvey] = useMutation(UPDATE_SURVEY);
  const buttonFormSubmitRef = useRef<any>();
  const { currentProfile, saveCurrentProfile, refetch } = useContext(ClientProfileContext);

  useEffect(() => {
    if (R.is(String, query.mode)) {
      setMode(query.mode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!query.mode && query.mode !== mode) {
      const queryParams = qs.parse(history.location.search) as any;
      const newQueries = { ...queryParams, mode };
      history.push({ search: qs.stringify(newQueries) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.mode, mode]);

  const handleFormSubmit = async (formData) => {
    let uploadAvatar;
    try {
      setIsLoading(true);
      if (formData.avatar && formData.avatar !== currentProfile?.avatar) {
        uploadAvatar = await uploadFileHandler(formData.avatar);
      }
      delete formData.avatar;
      const requestParams = {
        ...formData,
        logo_file_id: uploadAvatar?.id || currentProfile?.clientProfile?.logo_file?.id || '',
        phone_number: formData.phone_number.phoneNumber,
        id: currentProfile?.clientProfile?.id,
        display_currency: formData?.display_currency,
        country_code: formData?.country_code,
      };
      if (!requestParams.logo_file_id) {
        delete requestParams.logo_file_id;
      }
      const result = await updateProfile({
        variables: {
          ...requestParams,
        },
      });
      if (result && result?.data?.update_client_profile_by_pk && currentProfile) {
        saveCurrentProfile(
          new MerchantProfile(currentProfile.id, currentProfile.role, result?.data?.update_client_profile_by_pk),
        );
      }
      setIsLoading(false);
      history.push(PRIVATE_ROUTE.campaigns);
    } catch (error) {
      setIsLoading(false);
      toastError('Something wrongs, please try again');
    }
  };

  const handleSurveySubmit = async (formData: any) => {
    const client_survey = currentProfile?.clientProfile?.client_survey;
    if (!client_survey || !formData) return;

    const requestParams = {
      id: client_survey?.id,
      is_currently_running_campaigns: formData?.is_currently_running_campaigns === '1',
      number_of_campaigns_annually: parseInt(formData?.number_of_campaigns_annually),
      avg_kols_per_campaign: parseInt(formData?.avg_kols_per_campaign),
      avg_budget_per_campaign_usd_cents: Math.floor((formData?.avg_budget_per_campaign_usd_cents * 100) / exchangeRate),
      additional_info: formData?.additional_info,
    };
    setIsLoading(true);
    try {
      const result = await updateSurvey({
        variables: {
          ...requestParams,
        },
      });
      setIsLoading(false);
      if (result && result?.data?.update_client_survey_by_pk) {
        refetch();
      }
      history.push(PRIVATE_ROUTE.campaigns);
    } catch (error) {
      setIsLoading(false);
      toastError('Something wrongs, please try again');
    }
  };

  const triggerButtonSubmit = () => {
    if (buttonFormSubmitRef.current && !isLoading) {
      buttonFormSubmitRef.current.click();
    }
  };

  const handleDiscard = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const renderAsideLeft = (
    <AsideLeftContent
      asideTitle={
        <Typography
          fontSize={isMobile ? '34px/41px' : '28px/34px'}
          text={currentProfile?.isAdmin() ? 'Edit Profile' : 'View Profile'}
          fontWeight="bold"
        />
      }
    />
  );

  const renderUI = () => {
    switch (mode) {
      case MODE.INFO:
        return (
          <ProfileEdit
            onHandleFormSubmit={handleFormSubmit}
            clientProfile={currentProfile?.clientProfile}
            buttonFormSubmitRef={buttonFormSubmitRef}
          />
        );
      case MODE.SURVEY:
        return (
          <SurveyForm
            isSubmitting={false}
            profileData={currentProfile?.clientProfile}
            buttonFormSubmitRef={buttonFormSubmitRef}
            isUpdate={true}
            submitSurveyForm={handleSurveySubmit}
            exchangeRate={exchangeRate}
          />
        );
      default:
        return null;
    }
  };
  const mainLayout = () => {
    return (
      <>
        <ProfileHeaderStyled>
          <TabStyled>
            <TabItemStyled active={MODE.INFO === mode} onClick={() => setMode(MODE.INFO)}>
              Profile Information
            </TabItemStyled>
            <TabItemStyled active={MODE.SURVEY === mode} onClick={() => setMode(MODE.SURVEY)}>
              Survey
            </TabItemStyled>
          </TabStyled>
          {currentProfile?.isAdmin() && (
            <ButtonActionStyled>
              <Button
                disabled={isLoading}
                type="button"
                width="110px"
                variant="secondary"
                label="DISCARD"
                onClick={handleDiscard}
              />
              <Button
                disabled={isLoading}
                label={isLoading ? 'Please wait' : 'SAVE'}
                type="button"
                width="131px"
                variant="primary"
                onClick={triggerButtonSubmit}
              />
            </ButtonActionStyled>
          )}
        </ProfileHeaderStyled>
        <ContainerScroll>{renderUI()}</ContainerScroll>
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <MainLayoutMobile
          onCancel={handleDiscard}
          onSave={triggerButtonSubmit}
          asideLeft={renderAsideLeft}
          asideRight={mainLayout()}
        />
      ) : (
        <MainLayout hasButtonNew headerCenter={undefined} asideLeft={renderAsideLeft} asideRight={mainLayout()} />
      )}
    </>
  );
}

export default CompanyProfile;

const ProfileHeaderStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 46px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
  box-sizing: content-box;

  ${mobileQueryString} {
    height: auto;
    padding-bottom: 20px;
  }
`;

const ButtonActionStyled = styled.div<any>`
  display: flex;
  align-items: center;

  ${mobileQueryString} {
    display: none;
  }
`;

const TabStyled = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
  height: 100%;
`;

const TabItemStyled = styled.li<any>`
  position: relative;
  display: block;
  cursor: pointer;
  font: normal normal normal 16px/18px 'Helvetica Neue';
  color: #949494;
  padding-top: 7px;
  &:not(:last-child) {
    margin-right: 31.5px;
  }
  ${(props) =>
    !!props.active &&
    css`
      color: #5770c6;
      font-weight: 500;
      &::after {
        content: '';
        border-radius: 5px 5px 0px 0px;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: -31px;
        left: 0;
        display: block;
        background: #5770c6 0% 0% no-repeat padding-box;
      }
    `};

  ${mobileQueryString} {
    ${(props) =>
      props.active &&
      css`
        &::after {
          bottom: -21px;
        }
      `}
  }
`;
