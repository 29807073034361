import React, { memo } from 'react';
import styled from 'styled-components';
// assets
import { ReactComponent as ArrowDownSvg } from '../../assets/svg/arrow-down.svg';
import { mobileQueryString } from '../../utils/responsive';

const StepperRegisterMobile = (props) => {
  const { backToStep, title, order, active } = props;
  return (
    <StepperContainerStyled>
      <ButtonIcon onClick={() => backToStep(active)}>
        <ArrowDownSvg />
      </ButtonIcon>
      <StepGroupStyled>
        <StepItemStyled {...props} step="1">
          <LabelStyled>{title}</LabelStyled>
          <MarkButtonStyled>{order}</MarkButtonStyled>
        </StepItemStyled>
      </StepGroupStyled>
    </StepperContainerStyled>
  );
};

export default memo(StepperRegisterMobile);

const StepperContainerStyled = styled.div<any>`
  display: none;
  flex-direction: column;
  padding: 40px 20px;
  width: 100%;
  height: 100%;

  ${mobileQueryString} {
    display: flex;
  }
`;

const ButtonIcon = styled.button<any>`
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
  position: relative;
  svg {
    transform-origin: center;
    transform: rotate(90deg);
    width: 12px;
    height: 6px;
    stroke-width: 2px;
  }
`;

const StepGroupStyled = styled.div<any>`
  display: flex;
  width: fit-content;
  align-items: center;
  margin-top: 20px;
`;

const MarkButtonStyled = styled.div<any>`
  background-color: transparent;
  color: #db108e;
  font: normal normal 500 28px/33px Helvetica Neue;
  margin-top: -12px;
  margin-left: 10px;
`;

const StepItemStyled = styled.div<any>`
  display: flex;
  width: fit-content;
  align-items: center;
`;

const LabelStyled = styled.label<any>`
  display: block;
  width: 100%;
  color: #5770c6;
  font: normal normal bold 34px/41px 'Helvetica Neue';
  user-select: none;
  cursor: pointer;
`;
