import { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import useConvertCurrency from '../../../hooks/useConvertCurrency';
import useToastError from '../../../hooks/useToastError';
import delay from '../../../utils/delay';

import TOPUP_QUERY from '../../../queries/topUp/topUp';
import GET_LIST_COUNTRY from '../../../queries/common/list-country';

import Button from '../../commons/Button';
import Selectbox from '../../commons/Selectbox';
import { fortmatCurrencyTemplate } from '../../../utils/formatCurrency';

const StripeForm = (props: any) => {
  const { user, value, onClose, handlePublish, onRefreshWallet } = props;
  const { register, handleSubmit } = useForm();
  const stripe = useStripe() as any;
  const elements = useElements() as any;
  const { data: listCountryData } = useQuery(GET_LIST_COUNTRY);
  const [onTopUp] = useMutation(TOPUP_QUERY);
  const { toastError } = useToastError();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [listCountry, setListCountry] = useState<any>([]);

  const onSubmit = useCallback(
    async (params) => {
      setIsSubmitting(true);

      const billing_data = {
        email: params.email,
        name: params.name_on_card,
        address_country: params.country,
      };

      try {
        const card = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(card, billing_data);
        if (result.error) {
          setError(result.error.message);
        } else {
          if (user?.profile?.wallet_id && value > 0) {
            const { data } = await onTopUp({
              variables: {
                stripeToken: result.token.id,
                amount: value,
                walletId: user.profile.wallet_id,
              },
            });
            await delay(2000);
            onRefreshWallet();

            if (!data.topUp.success) {
              toastError(data.topUp.message);
            } else {
              handlePublish(true);
            }
          }
        }
      } catch (err: any) {
        setError(err.toString());
        toastError('Something wrongs, please try again');
      }
      setIsSubmitting(false);
    },
    [elements, stripe, user.profile.wallet_id, value, onTopUp, handlePublish, toastError, onRefreshWallet],
  );

  useEffect(() => {
    if (listCountryData && Array.isArray(listCountryData.country_category)) {
      setListCountry(listCountryData.country_category);
    }
  }, [listCountryData]);

  return (
    <>
      <StripeFormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormGroupLabel htmlFor="email">Email</FormGroupLabel>
            <InputStyled
              id="email"
              {...register('email', {
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'invalid email address',
                },
              })}
              placeholder="manyu.zhang@example.com"
            />
          </FormGroup>
          <FormGroup>
            <FormGroupLabel htmlFor="street_address">Card information</FormGroupLabel>
            <CardNumberEl className="card-el" />
            <RowGroup>
              <CardExpiryElementEl />
              <CardCvcElementEl />
            </RowGroup>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel htmlFor="name_on_card">Name on card</FormGroupLabel>
            <InputStyled id="name_on_card" {...register('name_on_card')} placeholder="Manyu Zhang" />
          </FormGroup>
          <Selectbox
            label="Country or region"
            options={listCountry.map((c: any) => ({ ...c, value: c.code }))}
            {...register('country')}
            defaultValue={user?.profile?.country?.code}
            canInput
          />
          <Button
            label={
              !isSubmitting
                ? `PAY ${fortmatCurrencyTemplate(value, user?.profile?.default_currency?.template, '$', 2)}`
                : 'WAITING'
            }
            type="submit"
            variant="payment"
          />
        </form>
      </StripeFormWrapper>
      {error}
      <Overlay onClick={onClose} />
    </>
  );
};

export default memo(StripeForm);

// StripeForm.propTypes = {
//   user: PropTypes.object,
//   value: PropTypes.number,
//   onClose: PropTypes.func,
// };

const StripeFormWrapper = styled.div<any>`
  width: 558px;
  padding: 40px 80px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px -1px 30px #00000029;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
`;

const FormGroup = styled.div<any>`
  margin-bottom: 16px;
  width: 100%;
  display: block;
`;

const FormGroupLabel = styled.label<any>`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  mix-blend-mode: normal;
  opacity: 0.9;
  display: block;
  margin-bottom: 10px;
  text-transform: capitalize;
`;

const CardNumberEl = styled<any>(CardNumberElement)`
  padding: 14px 10px;
  background: #fff;
  border: 1px solid #eee;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000;
  box-sizing: border-box;
`;

const CardExpiryElementEl = styled<any>(CardExpiryElement)`
  padding: 14px 10px;
  background: #fff;
  border: 1px solid #eee;
  border-right: none;
  border-radius: 0 0 0 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000;
  width: 100%;
  box-sizing: border-box;
`;

const CardCvcElementEl = styled<any>(CardCvcElement)`
  padding: 14px 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0 0 10px 0;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000;
  width: 100%;
  box-sizing: border-box;
`;

const RowGroup = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputStyled = styled.input<any>`
  width: 100%;
  color: #000;
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 14px 10px;
  outline: none;
`;

const Overlay = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.65);
`;
