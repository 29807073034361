import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import Modal from '../commons/Modal';
import Button from '../commons/Button';

const defaultFnc = () => {};
const ModalMarkApproved = React.forwardRef<any, any>((props, ref) => {
  const [isOpen, setOpen] = useState(false);
  const onOkRef = useRef<any>(defaultFnc);
  const onCancelRef = useRef<any>(defaultFnc);

  const handleOk = () => {
    onOkRef.current();
    setOpen(false);
  };
  const handleCancel = () => {
    onCancelRef.current();
    setOpen(false);
  };

  if (ref) {
    (ref as any).current = ({ onOk, onCancel }) => {
      setOpen(true);
      onOkRef.current = onOk || defaultFnc;
      onCancelRef.current = onCancel || defaultFnc;
    };
  }

  return (
    <Modal isOpen={isOpen}>
      <TitleStyled>Are you sure you want to mark this as Approved.</TitleStyled>
      <Button label="YES, MARK AS APPROVED" onClick={handleOk} />
      <ButtonCancelStyled>
        <Button variant="secondary" label="CANCEL" onClick={handleCancel} />
      </ButtonCancelStyled>
    </Modal>
  );
});

export default ModalMarkApproved;

const TitleStyled = styled.div<any>`
  text-align: center;
  font: normal normal 14px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #3e3e3e;
  opacity: 1;
  margin-bottom: 26px;
`;

const ButtonCancelStyled = styled.div<any>`
  margin-top: 20px;
`;
