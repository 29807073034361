import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useMobile from '../../../hooks/useMobile';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg';
import { ReactComponent as FilerIcon } from '../../../assets/svg/filer.svg';
import { ReactComponent as FileDownloadSVG } from '../../../assets/svg/file_download_black_24dp.svg';
import Button from '../../commons/Button';
import CampaignIconSocial from '../CampaignIconSocial';
import PropTypes from 'prop-types';
import { useDebounce } from 'react-use';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import * as R from 'ramda';

import useCommonApi from '../../../hooks/useCommon';
import { mobileQueryString } from '../../../utils/responsive';
import CampaignReportOverview, { MetricData, fetchMetricData } from './Overview/CampaignReportOverview';
import useGuard from '../../../hooks/useGuard';
import ContentPerformance from './ContentPerformance/ContentPerformance';
import KolPerformance, { aggregateMetricData, formatNumber } from './KolPerformance/KolPerformance';
import Selectbox from '../../commons/Selectbox';
import { useForm } from 'react-hook-form';
import GridStyled from '../../styled/GridStyled';
import RowStyled from '../../styled/RowStyled';
import { ApolloClient, useApolloClient } from '@apollo/client';
import GET_TRANCHES_IN_CAMPAIGN from './queries/queries';
import { format } from 'date-fns';
import download from 'downloadjs';

export const CAMPAIGN_REPORT_TAB = {
  overview: 'OVERVIEW',
  content: 'CONTENT_PERFORMANCE',
  kol: 'KOL_PERFORMANCE',
};

export const ALL_TRANCHES_ID = '0000-0000-0000-0000';

interface CampaignReportUIProps {
  id: string;
}

export interface TrancheData {
  id: string;
  name: string;
}

export const fetchTranches = async (client: ApolloClient<object>, campaignId: string): Promise<TrancheData[]> => {
  try {
    const data = await client.query({ query: GET_TRANCHES_IN_CAMPAIGN, variables: { id: campaignId } });
    const tranches = data?.data?.campaign_by_pk?.tranches.map((tranche) => ({
      id: tranche.id,
      name: tranche.name,
    }));
    console.log(tranches);
    return tranches;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const CampaignReportUI = React.forwardRef<CampaignReportUIProps, any>(
  ({ data = [], onTabChange, onDownloadPdf, onSearch, id }, ref) => {
    const [tab, setTab] = useState(CAMPAIGN_REPORT_TAB.overview);
    const [search, setSearch] = useState<any>(null);
    const history = useHistory();
    const isMobile = useMobile();
    const query = qs.parse(history.location.search) as any;
    const { listCategory, onRefreshListCategory } = useCommonApi();
    const { user } = useGuard();
    const { register, watch } = useForm();
    const [tranches, setTranches] = useState<TrancheData[]>([]);
    const client = useApolloClient();
    const selectTranche = watch('select_tranche');

    const [contentData, setContentData] = useState<MetricData[]>([]);
    const [filteredContentData, setFilteredContentData] = useState<MetricData[]>([]);
    const [filteredKolData, setFilteredKolData] = useState<MetricData[]>([]);

    useEffect(() => {
      fetchMetricData(client, id).then((data) => {
        setContentData(data);
      });
    }, [id]);

    useEffect(() => {
      if (selectTranche === ALL_TRANCHES_ID) {
        setFilteredContentData(contentData);
      } else {
        const filterData = contentData.filter((e) => e.trancheId == selectTranche);
        setFilteredContentData(filterData);
      }
    }, [contentData, selectTranche]);

    useEffect(() => {
      setFilteredKolData(aggregateMetricData(contentData, selectTranche));
    }, [contentData, selectTranche]);

    const handleDownloadPdf = () => {
      onDownloadPdf && onDownloadPdf();
    };

    useDebounce(
      () => {
        if (!search && typeof search === 'object') return;
        onSearch && onSearch(search);
      },
      400,
      [search],
    );

    useEffect(() => {
      if (Object.keys(listCategory).length === 0) {
        onRefreshListCategory();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      fetchTranches(client, id).then((data) => {
        var tranches: TrancheData[] = [
          {
            id: ALL_TRANCHES_ID,
            name: 'All Tranches',
          },
          ...data,
        ];
        setTranches(tranches);
      });
    }, []);

    const handleChangeTab = (tabIndex) => () => {
      setTab(tabIndex);
    };

    useEffect(() => {
      onTabChange && onTabChange(tab);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    useEffect(() => {
      if (
        R.is(String, query.tab) &&
        (query.tab === CAMPAIGN_REPORT_TAB.overview ||
          query.tab === CAMPAIGN_REPORT_TAB.kol ||
          query.tab === CAMPAIGN_REPORT_TAB.content)
      ) {
        setTab(query.tab);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (query.tab !== tab) {
        const queryParams = qs.parse(history.location.search) as any;
        const newQueries = { ...queryParams, tab };
        history.push({ search: qs.stringify(newQueries) });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.tab, tab]);

    const handleExport = () => {
      const headerData = [
        'No',
        'Platform',
        'Content Type',
        'Content Link',
        'KOL Name',
        'Fanbase',
        'Tier',
        'Post On',
        'Spend',
        'Reach',
        'Reach Rate',
        'Views',
        'Engagement',
        'Engagement Rate',
      ];
      const dataArray = filteredContentData.map((item, index) => {
        return [
          `"${index + 1}"`,
          `${item.platform}`,
          `"${item.contentType}"`,
          `"${item.contentLink}"`,
          `"${item.kolName}"`,
          `"${formatNumber(item.fanbase)}"`,
          `"${item.kolTier}"`,
          `"${item.postOn ? format(new Date(item.postOn), 'do MMM, yy') : '--'}"`,
          `"${formatNumber(item.spend)}"`,
          `"${formatNumber(item.reach)}"`,
          `"${item.reachRate} %"`,
          `"${formatNumber(item.views)}"`,
          `"${formatNumber(item.engagement)}"`,
          `"${item.engagementRate} %"`,
        ].join(',');
      });
      const csvString = [headerData.join(','), ...dataArray].join('\n');
      download(csvString, 'contentPerformance.csv', 'text/csv');

      const kolHeaderData = [
        'No',
        'KOL',
        'Fanbase',
        'Tier',
        'Platform',
        'Content',
        'Spend',
        'Reach',
        'Reach Rate',
        'Views',
        'Engagement',
        'Engagement Rate',
      ];
      const kolDataArray = filteredKolData.map((item, index) => {
        return [
          `"${index + 1}"`,
          `${item.kolName}`,
          `"${formatNumber(item.fanbase)}"`,
          `"${item.kolTier}"`,
          `"${item.platform}"`,
          `"${item.content}"`,
          `"${formatNumber(item.spend)}"`,
          `"${formatNumber(item.reach)}"`,
          `"${item.reachRate} %"`,
          `"${formatNumber(item.views)}"`,
          `"${formatNumber(item.engagement)}"`,
          `"${item.engagementRate} %"`,
        ].join(',');
      });
      const csvKolString = [kolHeaderData.join(','), ...kolDataArray].join('\n');
      download(csvKolString, 'kolPerformance.csv', 'text/csv');
    };

    return (
      <WrapperStyled>
        <LOKHeaderStyled>
          <GridStyled isRow>
            <RowStyled alignItems="center" justifyContent="space-between">
              <Selectbox
                style={{ width: '344px' }}
                options={tranches.map((c) => ({ ...c, value: c.id }))}
                renderOption={(item) => item.name}
                {...register('select_tranche')}
                defaultValue={ALL_TRANCHES_ID}
              />
              <Button label={'Export'} type="button" width="131px" variant="primary" onClick={handleExport} />
            </RowStyled>
          </GridStyled>
        </LOKHeaderStyled>
        <LOKHeaderStyled>
          <LOKHeaderTabStyled>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <LOKHeaderButtonStyled
                  active={tab === CAMPAIGN_REPORT_TAB.overview}
                  onClick={handleChangeTab(CAMPAIGN_REPORT_TAB.overview)}
                >
                  Overview
                </LOKHeaderButtonStyled>
              </div>
              <div>
                {
                  <div>
                    <LOKHeaderButtonStyled
                      active={tab === CAMPAIGN_REPORT_TAB.content}
                      onClick={handleChangeTab(CAMPAIGN_REPORT_TAB.content)}
                    >
                      Content Performance
                    </LOKHeaderButtonStyled>
                  </div>
                }
              </div>
              <div>
                {
                  <div>
                    <LOKHeaderButtonStyled
                      active={tab === CAMPAIGN_REPORT_TAB.kol}
                      onClick={handleChangeTab(CAMPAIGN_REPORT_TAB.kol)}
                    >
                      KOL Performance
                    </LOKHeaderButtonStyled>
                  </div>
                }
              </div>
            </div>

            <ButtonMobileWrapper>
              <ButtonMobile>
                <SearchIcon />
              </ButtonMobile>
              <ButtonMobile>
                <FilerIcon />
              </ButtonMobile>
              <ButtonMobile onClick={handleDownloadPdf}>
                <FileDownloadSVG />
                <div>PDF</div>
              </ButtonMobile>
            </ButtonMobileWrapper>
          </LOKHeaderTabStyled>
        </LOKHeaderStyled>
        {
          <>
            {tab === CAMPAIGN_REPORT_TAB.overview && (
              <CampaignReportOverview
                selectTranche={selectTranche}
                campaignId={id}
                data={data}
                symbol={user?.profile?.default_currency?.symbol}
              />
            )}
            {tab === CAMPAIGN_REPORT_TAB.content && <ContentPerformance data={filteredContentData} />}
            {tab === CAMPAIGN_REPORT_TAB.kol && <KolPerformance campaignId={id} data={filteredKolData} />}
          </>
        }
      </WrapperStyled>
    );
  },
);

CampaignReportUI.propTypes = {
  data: PropTypes.array,
  onGotoChat: PropTypes.func,
  onGotoDeliverables: PropTypes.func,
  onTabChange: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onDownloadPdf: PropTypes.func,
  onSearch: PropTypes.func,
};

export default CampaignReportUI;

const WrapperStyled = styled.div<any>`
  & .tools {
    display: inline-flex;
    & button {
      margin-left: 20px;
    }
  }

  ${mobileQueryString} {
    padding: 0 20px;

    & .tools {
      display: none;
    }
  }
`;

const LOKHeaderStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const LOKHeaderTabStyled = styled.div<any>`
  ${mobileQueryString} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid #eeeeee;
  }
`;

const LOKHeaderButtonStyled = styled.button<any>`
  font: normal normal normal 16px/18px Helvetica Neue;
  color: #949494;
  display: inline-flex;
  padding: 10px 20px 10px 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      color: #5770c6;
      border-bottom: 2px solid #5770c6;
      font-weight: 500;
    `}

  ${mobileQueryString} {
    padding: 0 0 20px 0;
    margin-right: 25px;
  }
`;

const LOKHeaderSearchStyled = styled.div<any>`
  height: 46px;
  width: 292px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  --padding-right: 22px;
  padding: 10px var(--padding-right);
  box-sizing: border-box;
  border-radius: 5px;
  & input {
    width: calc(100% - var(--padding-right));
    border: none;
    font: normal normal normal 14px/16px Helvetica Neue;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #949494;
    }
  }
  & .icon {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ButtonActionStyled = styled<any>(Button)`
  width: 87px;
  height: 38px;
  ${(props) =>
    props.variant === 'secondary' &&
    css`
      & {
        background-color: transparent;
        color: #949494;
      }
      &:hover {
        background-color: #949494;
        color: #fff;
      }
    `}
  ${(props) =>
    props.variant === 'primary' &&
    css`
      & {
        background: transparent;
        color: #5770c6;
      }
      &:hover {
        background: transparent linear-gradient(263deg, #51adf0 0%, #666bfa 49%, #b64dc8 100%) 0% 0% no-repeat padding-box;
        color: #fff;
      }
    `}
    ${(props) =>
    !!props.disabled &&
    css`
      &:hover {
        background-color: transparent;
        color: #949494;
      }
    `}
`;

const ActionCellStyled = styled.div<any>`
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const KolCard = (props: any) => {
  const {
    name = '',
    gender = '',
    avatar = '',
    platform = [],
    isAccepted = false,
    onGoToChat = () => {},
    onGoToDeliverables = () => {},
  } = props;
  return (
    <KolCardStyled>
      <div className="left">
        <img src={avatar} alt="" />
      </div>
      <div className="right">
        <div className="name">{name}</div>
        {isAccepted && (
          <div className="action-navigation">
            <div onClick={onGoToDeliverables}>Deliverables</div>
            <div className="chat-label" onClick={onGoToChat}>
              Chat
            </div>
          </div>
        )}
        <div className="bottom">
          <div className="gender">{gender}</div>
          <CampaignIconSocial platforms={platform} />
        </div>
      </div>
    </KolCardStyled>
  );
};

const KolCardStyled = styled.div<any>`
  display: flex;
  flex-direction: row;
  & .left {
    img {
      border-radius: 5px;
      width: 43px;
      height: 43px;
    }
  }
  & .right {
    margin-left: 28px;
  }
  & .name {
    color: #0b0b0b;
    text-align: left;
    font: normal normal bold 16px/19px Helvetica Neue;
  }
  & .action-navigation {
    display: flex;
    margin-bottom: 7px;
    color: #5770c6;
    font: normal normal bold 12px/15px Helvetica Neue;
    & .chat-label {
      margin-left: 13px;
    }
    & > div {
      cursor: pointer;
    }
  }
  & .bottom {
    display: flex;
    & .gender {
      font: normal normal normal 13px/15px Helvetica Neue;
      color: #949494;
      text-transform: capitalize;
      margin-right: 18px;
    }
  }
`;

const StatusStyled = styled.div<any>`
  & .status {
    font: normal normal normal 14px/16px Helvetica Neue;
    margin-bottom: 6px;
  }
  & .step {
    color: #949494;
    font: normal normal normal 14px/16px Helvetica Neue;
  }
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

const DownloadButtonStyled = styled.button<any>`
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  width: 160px;
  height: 46px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #5770c6;
  cursor: pointer;
  & span {
    margin-left: 12px;
  }
`;

const ButtonMobileWrapper = styled.div<any>`
  display: none;

  ${mobileQueryString} {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    column-gap: 10px;
  }
`;

const ButtonMobile = styled.div<any>`
  height: 30px;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  font-size: 12px;
  color: #5770c6;

  & svg {
    width: 13px;
    height: 13px;
    fill: #5770c6;
  }
`;
