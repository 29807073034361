import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';

// assets
import { ReactComponent as ArrowDropDownSVG } from '../../../assets/svg/drop_down_avatar.svg';

// components
import { DEFAULT_AVATAR, ROUTES, DEFAULT_AVATAR_MOBILE } from '../../../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import useGuard from '../../../hooks/useGuard';
import useMobile from '../../../hooks/useMobile';
import { mobileQueryString } from '../../../utils/responsive';
import PopoverContent from './PopoverContent';
import PopoverContentMobile from './PopoverContentMobile';

function ProfilePopover() {
  // States
  const { user } = useGuard();
  const [isShowProfile, setIsShowProfile] = useState(false);
  // Refs
  const profileRef = useRef<any>(null);
  const isMobile = useMobile();
  const { logout } = useAuth0();
  useClickAway(
    profileRef,
    () => {
      if (isShowProfile) {
        setIsShowProfile(false);
      }
    },
    ['mousedown'],
  );

  function toggleProfilePopover() {
    setIsShowProfile((prevState) => !prevState);
  }
  const handleLogout = (e) => {
    e.preventDefault();
    logout({
      returnTo: `${window.location.origin}${ROUTES.login}`,
    });
  };
  return (
    <AvatarStyled ref={profileRef}>
      <AvatarContentStyled onClick={toggleProfilePopover}>
        <img src={user?.picture?.url || (isMobile ? DEFAULT_AVATAR_MOBILE : DEFAULT_AVATAR)} alt="Avatar" />
        <IconArrowStyled>
          <ArrowDropDownSVG />
        </IconArrowStyled>
      </AvatarContentStyled>
      <PopoverStyled isShowPopover={isShowProfile}>
        {isMobile ? (
          <PopoverContentMobile handleLogout={handleLogout} handleClickOverlay={() => setIsShowProfile(false)} />
        ) : (
          <PopoverContent handleLogout={handleLogout} />
        )}
      </PopoverStyled>
    </AvatarStyled>
  );
}

export default ProfilePopover;

const AvatarStyled = styled.div<any>`
  position: relative;
`;

const AvatarContentStyled = styled.div<any>`
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
    width: 27px;
    height: 27px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
  }

  ${mobileQueryString} {
    img {
      width: 18px;
      height: 20px;
      margin-right: 0;
    }
  }
`;

const IconArrowStyled = styled.div<any>`
  svg {
    height: 10px;

    .a {
      stroke: #3e3e3e !important;
    }
  }

  ${mobileQueryString} {
    display: none;
  }
`;

const PopoverStyled = styled.div<any>`
  display: ${(props) => (props.isShowPopover ? 'block' : 'none')};
`;
