import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ChartSVG } from '../../../../assets/svg/icon-chart-reach.svg';
import { ReactComponent as StarSVG } from '../../../../assets/svg/icon-star-rate.svg';

const CampaignPerformanceView = ({ totalReach, totalReachRate }) => {
  return (
    <>
      <Container>
        <Header>
          <Title>Campaign Performance (Reach)</Title>
        </Header>
        <Line />
        <Content>
          <TotalReach>
            <ChartSVG />
            <SmallTitle>Total Reach</SmallTitle>
            <Value>{totalReach.toLocaleString()}</Value>
          </TotalReach>
          <TotalReach>
            <StarSVG />
            <SmallTitle>Reach rate</SmallTitle>
            <Value>{totalReachRate}</Value>
          </TotalReach>
        </Content>
      </Container>
    </>
  );
};

export default CampaignPerformanceView;

const Container = styled.div<any>`
  width: 100%;
  overflow: hidden;
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  border-color: red;
  outline: 1px #d9d9d9 solid;
  > svg {
    width: 55px;
    height: 55px;
  }
`;
const Content = styled.div<any>`
  margin: 24px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  gap: 24px;
`;

const Header = styled.div<any>`
  margin: auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 48px;
  width: 100%;
  padding: 16px;
`;

const Title = styled.div<any>`
  color: #000000;
  font: normal normal bold 24px/24px 'Inter';
  margin: 0 0;
`;

const Line = styled.div<any>`
  margin: auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
  padding: 0px;
`;

const TotalReach = styled.div<any>`
  overflow: hidden;
  display: block;
  justify-content: center;
  align-items: center;
  > svg {
    width: 24px;
    height: 24px;
  }
`;

const SmallTitle = styled.div<any>`
  color: #79747e;
  font: normal normal regular 12px/14px 'Inter';
  margin: 4px 0 8px 0;
`;

const Value = styled.div<any>`
  color: #000000;
  font: normal normal bold 26px/36px 'Inter';
  margin: 0 0;
`;
