import { gql } from '@apollo/client';
export default gql`
  mutation UpdateMyUser($id: uuid!, $given_name: String, $family_name: String, $picture_id: uuid, $phone_number: String) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        given_name: $given_name
        family_name: $family_name
        activated: true
        picture_id: $picture_id
        phone_number: $phone_number
      }
    ) {
      id
      phone_number
      email
      family_name
      given_name
      activated
      picture {
        url
        id
      }
    }
  }
`;
