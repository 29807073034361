import React, { useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import Collapsible from 'react-collapsible';
import { format, parseISO } from 'date-fns';
import * as R from 'ramda';

import messages from '../../utils/messages';
import { FORMAT_DATE_DEFAULT, MODE } from '../../utils/constants';
import { fortmatCurrencyTemplate } from '../../utils/formatCurrency';
import useConvertCurrency from '../../hooks/useConvertCurrency';
import useGuard from '../../hooks/useGuard';

import { ReactComponent as ArrowDownSVG } from '../../assets/svg/drop_down_bottom.svg';
import { ReactComponent as ErrorSVG } from '../../assets/svg/error_black_24dp.svg';

import GridStyled from '../styled/GridStyled';
import RowStyled from '../styled/RowStyled';
import LabelStyled from '../commons/Label';
import DatepickerRange from '../commons/DatepickerRange';
import DatePicker from '../commons/Datepicker';
import TextFieldArea from '../commons/TextFieldArea';
import CampaignIconSocial from './CampaignIconSocial';
import ErrorMessageStyled from '../commons/ErrorMessage';

const arrayFrom = R.defaultTo([]);

const CampaignDetailEdit = React.forwardRef<any, any>(({ data = {}, onSubmit, error, handleSetMode }, ref: any) => {
  const [indexGroup, setIndexGroup] = useState(0);
  const [totalKOLs, setTotalKOLs] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [totalPIK, setTotalPIK] = useState(0);
  const { register, handleSubmit, setValue, formState } = useForm();
  const { exchangeRate } = useConvertCurrency();
  const { user } = useGuard();
  const { errors } = formState;

  useEffect(() => {
    if (data?.status === 'DRAFT' || data?.status === 'PUBLISHED') return;
    handleSetMode(MODE.view);
  }, [data, handleSetMode]);

  useEffect(() => {
    if (R.isEmpty(data)) return;
    setValue('campaignDetails', data?.description);
    setValue('recruitmentDeadline', new Date(data?.recruitment_end_date));
    setValue('campaignPeriod', [new Date(data?.start_date), new Date(data?.end_date)]);

    const { cash_usd_cents, pik, number_kols } = data?.tranches?.reduce(
      (total, curr) => {
        return {
          cash_usd_cents: total.cash_usd_cents + parseInt(curr.cash_usd_cents),
          pik: total.pik + parseInt(curr.product_value_usd_cents),
          number_kols: total.number_kols + parseInt(curr.number_kols),
        };
      },
      {
        cash_usd_cents: 0,
        pik: 0,
        number_kols: 0,
      },
    );
    setTotalKOLs(number_kols);
    setTotalPIK(pik);
    setTotalCash(cash_usd_cents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const listImage = useMemo(() => {
    if (!data || !Array.isArray(data?.tranches)) return [];
    return data?.tranches.map((tranche) => tranche.images).flat();
  }, [data]);

  ref.current = handleSubmit;

  return (
    <Form>
      <CampaignContainer pt="20px" maxWidth="100%">
        <GridStyled>
          <RowStyled>
            <GalleryImage>
              {arrayFrom(listImage).map((image, idx) => {
                return <Image key={idx} src={image?.file_metadata?.url} />;
              })}
            </GalleryImage>
          </RowStyled>
        </GridStyled>
        <GridStyled>
          <RowCustomStyled mb="30px">
            <ListCampaignInfo mr="72px">
              <LabelLightColor>{messages['campaigns.product']}</LabelLightColor>
              <LabelBoldColor>{data?.product_name}</LabelBoldColor>
            </ListCampaignInfo>
            <ListCampaignInfo>
              <LabelLightColor>{messages['campaigns.qytOfKols']}</LabelLightColor>
              <LabelBoldColor>{totalKOLs}</LabelBoldColor>
            </ListCampaignInfo>
            <LineVertical />
            <ListCampaignInfo mr="38.79px">
              <LabelLightColor>
                <NoteBudgetIcon>
                  {messages['campaigns.totalBudget']}
                  <ErrorSVG />
                </NoteBudgetIcon>
              </LabelLightColor>
              <LabelBoldColor>
                {fortmatCurrencyTemplate(
                  (data?.budget_usd_cents / 100) * exchangeRate,
                  user?.profile?.default_currency?.template,
                  user?.profile?.default_currency?.symbol,
                  user?.profile?.default_currency?.fraction_size ?? 2,
                )}
              </LabelBoldColor>
            </ListCampaignInfo>
            <ListCampaignInfo mr="32px">
              <LabelLightColor>{messages['campaigns.cash']}</LabelLightColor>
              <LabelBoldColor>
                {fortmatCurrencyTemplate(
                  (totalCash / 100) * exchangeRate,
                  user?.profile?.default_currency?.template,
                  user?.profile?.default_currency?.symbol,
                  user?.profile?.default_currency?.fraction_size ?? 2,
                )}
              </LabelBoldColor>
            </ListCampaignInfo>
            <ListCampaignInfo>
              <LabelLightColor>{messages['campaigns.pik']}</LabelLightColor>
              <LabelBoldColor>
                {fortmatCurrencyTemplate(
                  (totalPIK / 100) * exchangeRate,
                  user?.profile?.default_currency?.template,
                  user?.profile?.default_currency?.symbol,
                  user?.profile?.default_currency?.fraction_size ?? 2,
                )}
              </LabelBoldColor>
            </ListCampaignInfo>
          </RowCustomStyled>
        </GridStyled>
      </CampaignContainer>
      <LineStyled />
      <CampaignContainer>
        <FormContainerStyled>
          <GridStyled>
            <RowStyled>
              <DatePicker
                label={messages['campaigns.recruitmentDeadline']}
                placeholder=""
                {...register('recruitmentDeadline', {
                  required: {
                    value: true,
                    message: 'Recruitment Deadline is required',
                  },
                })}
                error={errors?.recruitmentDeadline?.message}
              />
            </RowStyled>
            <RowStyled>
              <DatepickerRange
                label={messages['campaigns.campaignPeriod']}
                placeholder=""
                {...register('campaignPeriod', {
                  validate: (value) => {
                    return value.filter(Boolean).length === 0 ? 'Campaign Period is required' : value;
                  },
                })}
                error={errors?.campaignPeriod?.message}
              />
            </RowStyled>
          </GridStyled>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </FormContainerStyled>
        <FormContainerStyled>
          <GridStyled>
            <RowStyled>
              <TextFieldArea
                {...register('campaignDetails', {
                  required: {
                    value: true,
                    message: 'Campaign Name is required',
                  },
                })}
                label={messages['campaigns.campaignDetails']}
                error={errors?.campaignDetails?.message}
              />
            </RowStyled>
          </GridStyled>
        </FormContainerStyled>
      </CampaignContainer>
      <LineStyled />
      <TitleStyled mb="3px">{messages['campaigns.kolsRequirements']}</TitleStyled>
      <KolsContainer>
        {arrayFrom(data?.tranches).map((tranche, idx) => (
          <CollapsibleGroup
            open={idx === indexGroup}
            trigger={
              <CollapsibleTitle className="Collapsible__title">
                <GroupTitle>{tranche?.name}</GroupTitle>

                <ArrowDownSVG className="Collapsible__arrow" />
              </CollapsibleTitle>
            }
            idx={idx}
            length={arrayFrom(data?.tranches).length}
            key={idx}
            handleTriggerClick={(accordionPosition) => {
              setIndexGroup(idx === indexGroup ? null : idx);
            }}
          >
            <KolsRequirementGroup
              data={tranche}
              budget_currency={user?.profile?.default_currency}
              idx={idx}
              length={arrayFrom(data?.tranches).length}
              exchangeRate={exchangeRate}
            />
          </CollapsibleGroup>
        ))}
      </KolsContainer>
    </Form>
  );
});

const KolsRequirementGroup = (props: any) => {
  const { data = {}, exchangeRate, budget_currency } = props;
  return (
    <KolsRequirementItem>
      <TitleStyled mb="17px">{messages['campaigns.kolAffinity']}</TitleStyled>
      <GridStyled>
        <RowCustomStyled>
          <ListTag>
            {arrayFrom(data?.product_categories).map((tag, idx) => (
              <TagItem key={idx}>{tag?.product_category?.name}</TagItem>
            ))}
          </ListTag>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled>
          <ListCampaignInfo mr="34px">
            <LabelLightColor>{messages['campaigns.minFollowers']}</LabelLightColor>
            <LabelBoldColor>{data?.min_follower_size}</LabelBoldColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="5px">
            <LabelLightColor>{messages['campaigns.maxFollowers']}</LabelLightColor>
            <LabelBoldColor>{data?.max_follower_size}</LabelBoldColor>
          </ListCampaignInfo>
          <LineVertical />
          <ListCampaignInfo mr="0">
            <LabelLightColor>{messages['campaigns.dueDate']}</LabelLightColor>
            <LabelBoldColor>{!!data?.due_date && format(new Date(data?.due_date), FORMAT_DATE_DEFAULT)}</LabelBoldColor>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled>
          <TitleNote>
            If 2 or more platforms are selected, the follower size will refer to the total followers aggregated from the selected
            platforms.
          </TitleNote>
        </RowCustomStyled>
      </GridStyled>
      <TitleStyled mb="13px">{messages['campaigns.remunerationPerKol']}</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="30px">
          <ListCampaignInfo mr="76px">
            <LabelLightColor>{messages['campaigns.cash']}</LabelLightColor>
            <LabelBoldColor>
              {fortmatCurrencyTemplate(
                (data?.cash_usd_cents / 100) * exchangeRate,
                budget_currency?.template,
                budget_currency?.symbol,
                budget_currency?.fraction_size,
              )}
            </LabelBoldColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="0">
            <LabelLightColor>{messages['campaigns.pikValue']}</LabelLightColor>
            <LabelBoldColor>
              {fortmatCurrencyTemplate(
                (data?.product_value_usd_cents / 100) * exchangeRate,
                budget_currency?.template,
                budget_currency?.symbol,
                budget_currency?.fraction_size,
              )}
            </LabelBoldColor>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled>
          <ListCampaignInfo>
            <LabelLightColor>{messages['campaigns.pikDescription']}</LabelLightColor>
            <TextContent>{data?.product_description}</TextContent>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <LineStyled />
      <GridStyled>
        <RowCustomStyled mb="16px">
          <ListCampaignInfo mr="60px">
            <LabelLightColor>{messages['campaigns.noOfKoLs']}</LabelLightColor>
            <LabelMediumColor>{data?.number_kols}</LabelMediumColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="101px">
            <LabelLightColor>{messages['campaigns.platforms']}</LabelLightColor>
            <PlatformStyled>
              <CampaignIconSocial social_platforms={data?.social_platforms} />
            </PlatformStyled>
          </ListCampaignInfo>
          <ListCampaignInfo mr="70px">
            <LabelLightColor>{messages['campaigns.ageGroup']}</LabelLightColor>
            <AgeGroup>
              {arrayFrom(data?.age_groups).map((rage, idx) => (
                <AgeItem key={idx}>{rage?.age_category?.name}</AgeItem>
              ))}
            </AgeGroup>
          </ListCampaignInfo>
          <ListCampaignInfo mr="0px">
            <LabelLightColor>{messages['campaigns.gender']}</LabelLightColor>
            <LabelMediumColor>{data?.genders[0]?.gender?.description}</LabelMediumColor>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <LineStyled />
      <TitleStyled mb="13px">{messages['campaigns.deliverables']}</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="18px">
          <DeliverableContainer>
            {arrayFrom(data?.deliverable_specs).map((deliverable, idx) => (
              <DeliverableItem key={idx}>
                <span className="label">{deliverable?.requirement_description}</span>
                <div className="">
                  <span className="date-title">{messages['campaigns.dueDate']}</span>
                  <span className="date-time">
                    {!!deliverable?.due_date && format(parseISO(deliverable?.due_date), FORMAT_DATE_DEFAULT)}
                  </span>
                </div>
              </DeliverableItem>
            ))}
          </DeliverableContainer>
        </RowCustomStyled>
      </GridStyled>
      <TitleStyled mb="10px">{messages['campaigns.otherRequirements']}</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="30px">
          <TextContent>{data?.requirements}</TextContent>
        </RowCustomStyled>
      </GridStyled>
      {/* {idx !== length - 1 && <LineStyled mb="0" />} */}
    </KolsRequirementItem>
  );
};

export default CampaignDetailEdit;

const Form = styled.form`
  width: 100%;
`;

const CampaignContainer = styled.div<any>`
  ${(props) =>
    !!props.pt &&
    css`
      padding-top: ${props.pt || 0};
    `};
  ${(props) =>
    !!props.maxWidth &&
    css`
      max-width: ${props.maxWidth || '700px'};
    `};
`;
const KolsContainer = styled.div<any>`
  max-width: 100%;
`;
const GalleryImage = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-right: -11.7px;
`;
const Image = styled.img`
  max-width: 100%;
  width: 90px;
  object-fit: cover;
  padding-right: 11.6px;
  margin-bottom: 10px;
  display: block;
`;

const ListCampaignInfo = styled.div<any>`
  ${(props) =>
    !!props.mr &&
    css`
      margin-right: ${props.mr || 0};
    `};
`;

const LabelLightColor = styled<any>(LabelStyled)`
  color: #949494;
  font: normal normal 500 13px/16px 'Helvetica Neue';
  margin-bottom: 4px;
`;

const LabelBoldColor = styled<any>(LabelStyled)`
  color: #0b0b0b;
  font: normal normal bold 16px/21px 'Helvetica Neue';
  margin-bottom: 0px;
`;

const LabelMediumColor = styled<any>(LabelStyled)`
  color: #3e3e3e;
  font: normal normal 500 16px/19px 'Helvetica Neue';
  margin-bottom: 0px;
  svg {
    width: 14px;
    height: 14px;
  }
`;

const RowCustomStyled = styled<any>(RowStyled)`
  margin-bottom: ${(props) => props.mb || '18px'};
  width: ${(props) => props.width || '100%'};
`;
const TitleStyled = styled.p<any>`
  color: #5770c6;
  font: normal normal 500 16px/19px 'Helvetica Neue';
  margin: 0;
  margin-bottom: ${(props) => props.mb || '18px'};
`;
const LineStyled = styled.div<any>`
  width: 100%;
  position: relative;
  height: 1px;
  display: block;
  margin-bottom: ${(props) => props.mb || '18px'};
  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 0px;
    height: 1px;
    width: 100%;
    background: #eeeeee;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const CollapsibleTitle = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 19.5px;
  cursor: pointer;
  font-family: 'Helvetica Neue';
  background-color: rgba(238, 238, 238, 0.25);
`;

const PlatformStyled = styled<any>(LabelMediumColor)`
  svg {
    width: 14px;
    height: 14px;
  }
  & > svg + svg {
    margin-left: 14px;
  }
`;

const CollapsibleGroup = styled<any>(Collapsible)`
  & svg.Collapsible__arrow {
    transition: all 0.5s;
  }

  & .is-closed {
    span {
      color: #949494 !important;
      font: normal normal normal 14px/16px 'Helvetica Neue';
    }
    & svg.Collapsible__arrow {
      transform: rotate(-90deg);
    }
    & .Collapsible__title {
      background-color: transparent;
      ${(props) =>
        props.idx !== props.length - 1 &&
        css`
          border-bottom: 1px solid #eeeeee;
        `}
    }
  }
  & .is-open {
    color: #0b0b0b;
    font: normal normal 500 14px/17px 'Helvetica Neue';
    & svg.Collapsible__arrow {
      transform: rotate(360deg);
    }
    & .Collapsible__title {
      border-bottom: none;
    }
  }
`;
const KolsRequirementItem = styled.div<any>`
  padding-left: 20px;
  padding-right: 19.5px;
  background-color: rgba(238, 238, 238, 0.25);
`;
const GroupTitle = styled.span<any>`
  font: normal normal 500 14px/17px Helvetica Neue;
  color: #0b0b0b;
`;

const ListTag = styled.div<any>`
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
`;
const TagItem = styled.span<any>`
  display: block;
  font: normal normal normal 13px/15px Helvetica Neue;
  color: #3e3e3e;
  padding: 7px 5px 8px 9px;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  margin-right: 10px;
`;
const TitleNote = styled.p<any>`
  color: #949494;
  font: normal normal normal 12px/14px Helvetica Neue;
  margin: 0;
  padding: 0;
`;
const TextContent = styled.p<any>`
  margin: 0;
  font: normal normal normal 14px/16px Helvetica Neue;
  color: #3e3e3e;
`;
const AgeGroup = styled.div<any>`
  display: flex;
  margin-right: -20px;
`;
const AgeItem = styled.div<any>`
  padding-right: 20px;
  color: #3e3e3e;
  font: normal normal 500 16px/19px Helvetica Neue;
`;
const DeliverableContainer = styled.div<any>`
  width: 100%;
`;
const DeliverableItem = styled.div<any>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  &:not(:first-child) {
    margin-top: 11px;
  }
  & .label {
    font: normal normal normal 13px/15px Helvetica Neue;
    color: #3e3e3e;
  }
  & .date-title {
    font: normal normal 500 12px/15px Helvetica Neue;
    color: #5770c6;
    display: block;
    text-align: right;
  }
  & .date-time {
    font: normal normal bold 12px/15px Helvetica Neue;
    color: #0b0b0b;
    text-align: right;
  }
`;

const NoteBudgetIcon = styled.div<any>`
  position: relative;
  svg {
    width: 11px;
    height: 11px;
    fill: none;
    stroke: #5770c6;
    margin-left: 6px;
  }
  &:hover::after {
    display: block;
  }
  &::after {
    content: 'Cash + PIK \\A e.g. if current currency is MYR: USD1000 * 4.23 = MYR4230 ';
    position: absolute;
    display: none;
    background-color: #eeeeee;
    color: #3e3e3e;
    font: normal normal normal 10px/12px Helvetica Neue;
    width: 268px;
    left: 84%;
    top: -100%;
    transform: translate(0, -11.79px);
    padding: 2px 4px 3px;
    border-radius: 4px;
    white-space: pre;
  }
`;
const LineVertical = styled.div<any>`
  width: 1px;
  height: 25px;
  margin-left: 16px;
  margin-right: 22px;
  background-color: #eeeeee;
`;

const FormContainerStyled = styled.div<any>`
  max-width: 700px;
  width: 100%;
`;

const ErrorMessage = styled<any>(ErrorMessageStyled)`
  margin: -10px 0 19px 0;
`;
