import React, { useEffect, useState, useMemo, useContext } from 'react';
import styled, { css } from 'styled-components';
import * as Sentry from '@sentry/react';
import { useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as GridSVG } from '../../assets/svg/grid_view_black_24dp.svg';
import { ReactComponent as ListSVG } from '../../assets/svg/format_list_bulleted_black_24dp.svg';

import useMobile from '../../hooks/useMobile';
import useConvertCurrency from '../../hooks/useConvertCurrency';
import useCampaign from '../../hooks/useCampaign';
import useCommonApi from '../../hooks/useCommon';
import { mobileQueryString } from '../../utils/responsive';
import { PRIVATE_ROUTE, VIEW_MODE } from '../../utils/constants';

import MainLayout from '../../components/layouts/MainLayout/MainLayout';
import AsideLeftStatistic from '../../components/campaigns/AsideLeftStatistic';
import CampaignTable from '../../components/campaigns/CampaignTable';
import CampaignGrid from '../../components/campaigns/CampaignGrid';
import Typography from '../../components/commons/Typography';
import Pagination from '../../components/commons/Pagination';
import MainlayoutMobileSearch from '../../components/layouts/MainLayout/MainLayoutMobileSearch';
import FooterMobileCampaign from '../../components/footerMobileCampaign';

import generateQueryStatus from '../../queries/campaignCreation/getCountingCampaign';
import LIST_CAMPAIGN_WITH_STATUS from '../../queries/campaignCreation/listCampaignWithStatus';
import useGuard from '../../hooks/useGuard';
import ClientProfileContext from '../../containers/ClientProfileProvider';

const ALL_CAMPAIGN = 'ALL';
function Campaigns() {
  const history = useHistory();
  const isMobile = useMobile();
  const { listCategory, onRefreshListCategory } = useCommonApi();
  const { campaign_status_category: listCampaignStatus } = listCategory;
  const { user } = useGuard();
  const location = useLocation();
  const { currentProfile } = useContext(ClientProfileContext);
  const clientProfileIdToGet = currentProfile?.id ?? user.profile?.id;

  const { listCampaign, onGetListCampaign, campaignError, campaignLoading, campaignCalled } = useCampaign(clientProfileIdToGet);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mode, setMode] = useState(VIEW_MODE.list);
  const [statusCampaign, setStatusCampaign] = useState(ALL_CAMPAIGN);
  const { exchangeRate } = useConvertCurrency();

  const statusCode = useMemo(() => {
    return Array.isArray(listCampaignStatus) ? listCampaignStatus.filter((stt) => stt.is_active).map((stt) => stt.code) : [];
  }, [listCampaignStatus]);

  const [fetchCountingCampaign, { data: totalCountingCampaign, loading: totalCountingLoading }] = useLazyQuery(
    generateQueryStatus((statusCode || []) as any, clientProfileIdToGet),
    {},
  );
  const campaignCount = useMemo(() => {
    const draftCount = totalCountingCampaign?.['DRAFT']?.aggregate?.count || 0;
    const publishedCount = totalCountingCampaign?.['PUBLISHED']?.aggregate?.count || 0;
    const completedCount = totalCountingCampaign?.['COMPLETED']?.aggregate?.count || 0;
    const runningCount = totalCountingCampaign?.['RUNNING']?.aggregate?.count || 0;
    return [
      { key: 'ALL', total: draftCount + publishedCount + completedCount + runningCount },
      { key: 'DRAFT', total: draftCount },
      { key: 'PUBLISHED', total: publishedCount },
      { key: 'COMPLETED', total: completedCount },
      { key: 'RUNNING', total: runningCount },
    ];
  }, [totalCountingCampaign]);
  const sumAll = useMemo(() => {
    return campaignCount.find((c) => c.key === 'ALL')?.total || 0;
  }, [campaignCount]);

  const [fetchListCampaignWithStatus, { data: listCampaignWithStatus, loading, called, error }] =
    useLazyQuery(LIST_CAMPAIGN_WITH_STATUS);

  const isPickingAll = useMemo(() => statusCampaign === ALL_CAMPAIGN, [statusCampaign]);

  const totalRecord = useMemo(
    () => totalCountingCampaign?.[statusCampaign]?.aggregate?.count || 0,
    [statusCampaign, totalCountingCampaign],
  );

  const campaigns = useMemo(
    () => (isPickingAll ? listCampaign : listCampaignWithStatus?.campaign),
    [listCampaignWithStatus, isPickingAll, listCampaign],
  );

  const offset = useMemo(() => {
    return page * rowsPerPage + 1 - 1;
  }, [rowsPerPage, page]);

  const totalPage = React.useMemo(() => {
    return Math.ceil(totalRecord / rowsPerPage);
  }, [totalRecord, rowsPerPage]);

  const isGrid = useMemo(() => mode === VIEW_MODE.grid, [mode]);

  const listFiltered = useMemo(() => {
    return campaigns || [];
  }, [campaigns]);

  const handleChangePerPage = (newPerPage) => {
    setRowsPerPage(newPerPage);
    setPage(0);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeMode = (value) => () => {
    setMode(value);
  };

  const gotoListCampaign = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const gotoListTeam = () => {
    history.push(PRIVATE_ROUTE.teams);
  };

  const handleOnChangeStatus = (item) => {
    setStatusCampaign(item?.key);
    setPage(0);
  };

  const fetchListCampaign = isPickingAll ? onGetListCampaign : fetchListCampaignWithStatus;

  useEffect(() => {
    if (!statusCode.length) return;
    fetchCountingCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  useEffect(() => {
    if (totalCountingLoading) return;

    fetchListCampaign({
      variables: {
        limit: rowsPerPage,
        offset: offset,
        ...(!isPickingAll ? { status: statusCampaign, client_profile_id: clientProfileIdToGet } : {}),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRecord, offset, rowsPerPage, totalCountingLoading, isPickingAll, statusCampaign]);

  useEffect(() => {
    if (campaigns) {
      Sentry.captureMessage('Get campaigns successful', { level: 'info' });
    } else if ((called && !loading && error) || (campaignCalled && !campaignLoading && campaignError)) {
      Sentry.captureException(error || campaignError);
    }
  }, [called, campaignCalled, campaignError, campaignLoading, campaigns, error, loading]);

  useEffect(() => {
    if (Object.keys(listCategory).length === 0) {
      onRefreshListCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHeaderCenter = (
    <MenuItemStyled isActive onClick={gotoListCampaign}>
      <Typography text="Campaigns" color="#5770C6" fontSize="16px / 19px" fontWeight="600" />
    </MenuItemStyled>
  );

  const renderHeaderTeam = (
    <MenuItemStyled isActive={false} onClick={gotoListTeam}>
      <Typography text="Teams" color="#5770C6" fontSize="16px / 19px" fontWeight="400" />
    </MenuItemStyled>
  );

  const renderAsideRight = (
    <>
      <TopStyled isModeGrid={isGrid}>
        <ToggleStyled>
          <ActionStyled>
            <SvgStyled isActive={isGrid} onClick={handleChangeMode(VIEW_MODE.grid)}>
              <GridSVG fill="#5770C6" />
            </SvgStyled>
            <SvgStyled isActive={!isGrid} onClick={handleChangeMode(VIEW_MODE.list)}>
              <ListSVG fill="#5770C6" />
            </SvgStyled>
          </ActionStyled>
        </ToggleStyled>
      </TopStyled>

      {!isGrid && (
        <ListStyled>
          <CampaignTable
            data={listFiltered}
            user={user}
            statusCampaign={statusCampaign}
            rowsPerPage={rowsPerPage}
            onChangePerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={page}
            totalPage={totalPage}
            totalRecord={totalRecord}
            exchangeRate={exchangeRate}
            showAction={true}
          />
        </ListStyled>
      )}

      {(isGrid || isMobile) && <CampaignGrid data={listFiltered} user={user} />}

      {totalPage > 0 && (
        <Pagination
          rowsPerPage={rowsPerPage}
          onChangePerPage={handleChangePerPage}
          onChangePage={handleChangePage}
          currentPage={page}
          totalPage={totalPage}
          totalRecord={totalRecord}
        />
      )}
      {isMobile && <MbDiv />}
    </>
  );

  return (
    <>
      {!isMobile ? (
        <MainLayout
          hasButtonNew
          headerCenter={renderHeaderCenter}
          headerTeam={renderHeaderTeam}
          asideLeft={
            // <AsideLeftStatistic menu={menu} itemActive={statusCampaign} onChangeStatus={handleOnChangeStatus} total={sumAll} />
            <AsideLeftStatistic
              menu={campaignCount}
              itemActive={statusCampaign}
              onChangeStatus={handleOnChangeStatus}
              total={sumAll}
            />
          }
          asideRight={renderAsideRight}
        />
      ) : (
        <MainlayoutMobileSearch
          asideLeft={
            // <AsideLeftStatistic menu={menu} itemActive={statusCampaign} onChangeStatus={handleOnChangeStatus} total={sumAll} />
            <AsideLeftStatistic
              menu={campaignCount}
              itemActive={statusCampaign}
              onChangeStatus={handleOnChangeStatus}
              total={sumAll}
            />
          }
          asideRight={renderAsideRight}
        />
      )}
      <FooterMobileCampaign />
    </>
  );
}

export default Campaigns;

const MenuItemStyled = styled.div<any>`
  ${(props) =>
    props.isActive &&
    css`
      > div {
        font-weight: bold;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #5770c6 0% 0% no-repeat padding-box;
        border-radius: 5px 5px 0px 0px;
        opacity: 1;
      }
    `}
`;

const TopStyled = styled.div<any>`
  text-align: right;
  padding-bottom: 32px;

  ${(props) =>
    props.isModeGrid &&
    css`
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
    `}

  ${mobileQueryString} {
    display: none;
  }
`;

const ToggleStyled = styled.div<any>`
  display: inline-block;
`;

const ActionStyled = styled.div<any>`
  background: rgba(219, 16, 142, 0.1) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 3px;
  display: flex;
`;

const SvgStyled = styled.div<any>`
  padding: 8px;
  display: inline-block;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #fff;
      border-radius: 5px;
    `}

  svg {
    cursor: pointer;
    display: flex;
  }
`;

const ListStyled = styled.div<any>`
  table thead tr td {
  }

  ${mobileQueryString} {
    display: none;
  }
`;

const MbDiv = styled.div<any>`
  margin-bottom: 50px;
`;
