import React from 'react';
import Table from '../commons/Table';
import { format } from 'date-fns';
import CampaignBox from './CampaignBox';
import PlatformStyled from './Platform';
import { useHistory, generatePath } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';
import { fortmatCurrencyTemplate } from '../../utils/formatCurrency';
import Button from '../commons/Button';
const ROWS_PER_PAGE = [5, 10, 25, 45];
const DEFAULT_IMAGE = 'static/images/campaigns/placeholder.png';

const CampaignTable = ({
  data = [],
  user,
  page,
  rowsPerPage,
  totalRecord,
  onChangePerPage,
  onChangePage,
  totalPage,
  statusCampaign,
  exchangeRate,
  showAction,
}) => {
  const columns = React.useMemo(() => {
    var data = [
      {
        Header: 'Campaign',
        accessor: (d) => d,
        Cell: ({ cell }) => {
          const rowData = cell.value;
          return (
            <CampaignBox
              image={rowData.image_url || DEFAULT_IMAGE}
              title={rowData.name}
              startTime={new Date(rowData.start_date)}
              endTime={new Date(rowData.end_date)}
            />
          );
        },
      },
      {
        Header: 'Kols Applied:',
        accessor: (d) => d,
        Cell: ({ cell }) => {
          const value = cell.value;
          const listWorkContract = value?.tranches.map((tranche) => tranche.work_contracts).flat();
          if (!Array.isArray(listWorkContract)) return 0;
          const countWorkItemApproved = listWorkContract.filter((ls) => {
            return ls.status.code === 'KOL_APPLIED';
          });
          return countWorkItemApproved.length;
        },
      },
      {
        Header: 'Kols Accepted:',
        accessor: (d) => d,
        Cell: ({ cell }) => {
          const value = cell.value;
          const listWorkContract = value?.tranches.map((tranche) => tranche.work_contracts).flat();
          if (!Array.isArray(listWorkContract)) return 0;
          const countWorkItemApproved = listWorkContract.filter((ls) => {
            return ls.status.code === 'CLIENT_APPROVED';
          });
          return countWorkItemApproved.length;
        },
      },
      {
        Header: 'Platforms',
        accessor: (d) => d,
        Cell: ({ cell }) => {
          const rowData = cell.value || [];
          const listPlatform = rowData.tranches.map((tranche) => {
            return tranche.social_platforms.filter(Boolean).map((s) => s.category_code);
          });
          return <PlatformStyled platforms={Array.from(new Set(listPlatform.flat())) as any} />;
        },
      },
      {
        Header: 'End Date',
        accessor: 'recruitment_end_date',
        Cell: ({ cell }) => {
          const cellData = cell.value || '';
          return format(new Date(cellData), 'do MMMM, yy');
        },
      },
      {
        Header: 'Budget',
        accessor: (d) => d,
        Cell: ({ cell }) => {
          const cellData = cell.value || '';
          const budget = ((cellData?.budget_usd_cents || 0) / 100) * exchangeRate;
          const symbol = user?.profile?.default_currency?.symbol ?? cellData?.budget_currency?.symbol ?? '$';
          return `${fortmatCurrencyTemplate(
            budget,
            user?.profile?.default_currency?.template,
            symbol,
            user?.profile?.default_currency?.fraction_size ?? cellData?.budget_currency?.fraction_size,
          )}`;
        },
      },
      {
        Header: 'Status',
        accessor: 'campaign_status.code',
        Cell: ({ cell }) => {
          const cellData = cell.value || '';
          return cellData[0] + cellData.toLowerCase().slice(1);
        },
      },
    ];
    data.push({
      Header: 'Action',
      accessor: (d) => d,
      Cell: ({ cell }) => {
        const cellData = cell.value || '';
        if (cellData.status !== 'COMPLETED' && cellData.status !== 'RUNNING') {
          return <div />;
        }
        return (
          <Button
            type="button"
            width="110px"
            variant="simple"
            label="View Report"
            onClick={(e) => {
              const path = generatePath(ROUTES.campaignsReport, { id: cell?.value?.id });
              history.push(path + '?mode=0');
              e.stopPropagation();
            }}
          />
        );
      },
    });
    return data;
  }, [
    exchangeRate,
    user?.profile?.default_currency?.fraction_size,
    user?.profile?.default_currency?.symbol,
    user?.profile?.default_currency?.template,
  ]);
  const history = useHistory();

  return (
    <Table
      data={data}
      columns={columns}
      containerHeight="auto"
      containerMaxHeight="auto"
      rowsPerPageOptions={ROWS_PER_PAGE}
      rowsPerPage={rowsPerPage}
      onPerPageChange={onChangePerPage}
      onPageChange={onChangePage}
      page={page}
      statusCampaign={statusCampaign}
      totalPage={totalPage}
      totalRecord={totalRecord}
      stickyTop="0"
      isHiddenPagination
      getRowProps={(row) => {
        const { values } = row;
        return {
          onClick: () => {
            const path = generatePath(ROUTES.campaignsDetails, { id: values?.Campaign?.id });
            history.push(path + '?mode=0');
          },
        };
      }}
    />
  );
};

export default CampaignTable;
