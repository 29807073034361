import styled, { css } from 'styled-components';
import { mobileQueryString } from '../../utils/responsive';

const GridStyled = styled.div<any>`
  display: flex;
  align-items: ${(props) => props.alignItems || 'flex-start'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  margin: 0 -15px;
  width: calc(100% + 30px);
  gap: 20px;

  ${mobileQueryString} {
    flex-direction: column;
    max-width: 100%;
    margin: 0;

    ${(props) =>
      props.isRow &&
      css`
        flex-direction: row;
        column-gap: 20px;
      `}
  }
`;

export default GridStyled;
