import styled from 'styled-components';

const ContainerScroll = styled.div<any>`
  height: ${(props) => props.height || '70vh'};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
export default ContainerScroll;
