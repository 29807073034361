import { gql } from '@apollo/client';
export default gql`
  query MerchantCampaignList($limit: Int = 100, $offset: Int = 0, $client_profile_id: uuid) {
    campaign(limit: $limit, offset: $offset, where: { client_profile_id: { _eq: $client_profile_id } }) {
      brand_name
      client_profile_id
      created_at
      budget_usd_cents
      display_currency
      budget_currency {
        symbol
        fraction_size
        template
      }
      description
      end_date
      id
      image_url
      name
      product_name
      recruitment_end_date
      start_date
      status
      campaign_status {
        code
        created_at
        description
        is_active
        last_updated_at
      }
      tranches {
        name
        product_categories {
          product_category {
            name
          }
          category_code
        }
        min_follower_size
        max_follower_size
        social_platforms {
          category_code
          social_platform {
            name
            image_icon_path
          }
        }
        genders {
          category_code
          gender {
            code
            description
          }
        }
        age_groups {
          category_code
          age_category {
            name
            min_value
            max_value
          }
        }
        number_kols
        country_code
        cash_usd_cents
        product_value_usd_cents
        product_description
        deliverable_specs {
          id
          quantity
          content_type_code
          requirement_description
          due_date
          title
        }
        requirements
        work_contracts {
          id
          created_at
          kol_profile {
            id
            gender
            follower_size
            engagement_rate
            date_of_birth
            created_at
            cpe_rate
            country_code
            bio
            country_category {
              code
              name
            }
            is_active
            is_approved
            min_rate_photo_usd_cents
            min_rate_video_usd_cents
            past_brands_collaboration
            product_categories {
              product_category {
                code
                name
              }
            }
            social_platforms {
              social_platform_category {
                code
                name
                image_icon_path
              }
            }
            user {
              id
              given_name
              family_name
              picture {
                id
                url
                content_type
                size_bytes
              }
            }
            first_name
            last_name
          }
          status {
            code
            description
          }
          tranche {
            id
            name
          }
          work_items {
            id
            status {
              code
              description
            }
            deliverable_spec {
              id
              content_type {
                code
                name
              }
              title
              quantity
              requirement_description
              due_date
            }
          }
        }
      }
    }
  }
`;
