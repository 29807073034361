import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useClickAway } from 'react-use';
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';

// assets
import { ReactComponent as NotificationSVG } from '../../assets/svg/notifications.svg';
import { ReactComponent as NotificationActiveSVG } from '../../assets/svg/notifications-active.svg';

// components
import Typography from '../commons/Typography';
import GET_NOTIFICATIONS from '../../queries/mics/myNotifications';
import SUBSCRIBE_NOTIFICATIONS from '../../queries/mics/myNotificationsSubscription';
import MARK_NOTIFICATION_READ from '../../queries/mics/markNotificationRead';
import VIEW_TRANCHE_DETAIL from '../../queries/campaignCreation/trancheDetail';
import { formatDistanceToNow } from 'date-fns';
import { useHistory, generatePath } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';
import { mobileQueryString } from '../../utils/responsive';
import useToastError from '../../hooks/useToastError';
import useToastNotification from '../../hooks/useToastNotification';
import { useCallback } from 'react';

function NotificationPopover(props: any) {
  const { notificationInMobile, onClick } = props;
  const { data: result } = useQuery(GET_NOTIFICATIONS);
  const { data: resultSubscription } = useSubscription(SUBSCRIBE_NOTIFICATIONS);
  const [markNotificationRead] = useMutation(MARK_NOTIFICATION_READ);
  const [fetchTrancheDetail, { data: tranche }] = useLazyQuery(VIEW_TRANCHE_DETAIL);
  const [isShowNotify, setIsShowNotify] = useState(false);
  const notifyRef = useRef<any>(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { toastError } = useToastError();
  const { toastNotification } = useToastNotification();

  useEffect(() => {
    async function fetchLastNotification() {
      if (resultSubscription === undefined) {
        return;
      }
      if (resultSubscription.notification === undefined) {
        return;
      }
      if (resultSubscription.notification.length === 0) {
        return;
      }
      const notification = resultSubscription.notification[0];
      const date = new Date(notification.timestamp);
      const nowDate = new Date();
      const deltaSecond = (nowDate.getTime() - date.getTime()) / 1000;
      if (deltaSecond < 2) {
        toastNotification(notification);
      }
    }
    fetchLastNotification();
  }, [resultSubscription, toastNotification]);

  useClickAway(
    notifyRef,
    () => {
      if (isShowNotify) {
        setIsShowNotify(false);
      }
    },
    ['mousedown'],
  );

  function toggleNotificationPopover() {
    setIsShowNotify((prevState) => !prevState);
    onClick && onClick();
  }

  const handleMarkNotificationRead = useCallback(
    async (listId) => {
      if (isLoading || listId.length === 0) return;
      setLoading(true);
      try {
        await markNotificationRead({
          variables: {
            ids: listId,
          },
          fetchPolicy: 'network-only',
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toastError('Something wrongs, please try again');
      }
    },
    [isLoading, markNotificationRead, toastError],
  );

  const notifications = (listNotification) => {
    if (!Array.isArray(listNotification)) return [];
    const result = listNotification.reduce(
      (total, curr) => {
        if (curr.unread) total[0].push(curr);
        else total[1].push(curr);
        return total;
      },
      [[], []],
    );
    return [result[0], result[1].slice(0, 5)];
  };

  const handleMarkAllAsRead = async () => {
    const listUnread = notifications(result?.notification)[0];
    if (!Array.isArray(listUnread)) return;
    const listId = listUnread.map((n) => n.id);
    await handleMarkNotificationRead(listId);
  };

  const quantityNotificationUnread = () => {
    return Array.isArray(result?.notification) ? notifications(result?.notification)[0].length : 0;
  };

  const handleReadNotification = useCallback(
    async (notification) => {
      notification?.unread && handleMarkNotificationRead([notification.id]);

      if (notification?.category_code === 'CHAT_MESSAGE_RECEIVED') {
        const tranche = await fetchTrancheDetail({ variables: { tranch_id: notification?.subject_id } });
        const kolProfileId = notification?.extended_data?.kolProfileId;
        const tranches = tranche?.data?.tranche ?? [];
        const workContracts = tranche?.data?.tranche[0].work_contracts;
        for (let i = 0; i < tranches.length; i++) {
          const contract = tranches[i].work_contracts?.find((item) => item.kol_profile?.id === kolProfileId);
          if (contract === undefined || contract === null) {
            continue;
          }
          const pathName = `${ROUTES.campaignsChat}`;
          const path = generatePath(pathName, {
            id: tranche?.data?.tranche[0].campaign?.id,
            contractID: contract?.id ?? workContracts[0]?.id ?? '',
          });
          history.push(path);
          return;
        }
        return;
      }

      if (notification?.category_code === 'DELIVERABLE_SUBMITTED') {
        const tranche = await fetchTrancheDetail({ variables: { tranch_id: notification?.subject_id } });
        const kolProfileId = notification?.extended_data?.kolProfileId;
        const workContracts = tranche?.data?.tranche[0].work_contracts;
        const contract = workContracts?.find((item) => item.kol_profile?.id === kolProfileId);
        const pathName = `${ROUTES.campaignsDeliverables}`;
        const path = generatePath(pathName, {
          id: tranche?.data?.tranche[0].campaign?.id,
          contractID: contract?.id ?? workContracts[0]?.id ?? '',
        });
        history.push(path);
        return;
      }

      if (notification?.category_code === 'DRAFT_APPROVAL_REQUESTED') {
        const tranche = await fetchTrancheDetail({ variables: { tranch_id: notification?.subject_id } });
        const kolProfileId = notification?.extended_data?.kolProfileId;
        const workContracts = tranche?.data?.tranche[0].work_contracts;
        const contract = workContracts?.find((item) => item.kol_profile?.id === kolProfileId);
        const pathName = `${ROUTES.campaignsChat}`;
        const path = generatePath(pathName, {
          id: tranche?.data?.tranche[0].campaign?.id,
          contractID: contract?.id ?? workContracts[0]?.id ?? '',
        });
        history.push(path);
        history.push(path);
        return;
      }

      if (notification?.category_code === 'WORK_APPLICATION_RECEIVED') {
        const tranche = await fetchTrancheDetail({ variables: { tranch_id: notification?.subject_id } });
        const path = generatePath(`${ROUTES.campaignsListOfKols}?tab=KOL_APPLIED`, {
          id: tranche?.data?.tranche[0].campaign?.id,
        });
        history.push(path);
        return;
      }

      if (notification?.category_code === 'MERCHANT_PROFILE_APPROVED') {
        history.push(ROUTES.userProfile);
        return;
      }

      if (notification?.category_code === 'GENERIC') {
        history.push('/');
      }
    },
    [handleMarkNotificationRead, history],
  );

  return (
    <NotificationStyled ref={notifyRef}>
      <NotificationAlert onMouseUp={toggleNotificationPopover}>
        {quantityNotificationUnread() > 0 && <DisplayCountAlert>{quantityNotificationUnread()}</DisplayCountAlert>}
        {notificationInMobile ? <>{isShowNotify ? <NotificationActiveSVG /> : <NotificationSVG />}</> : <NotificationSVG />}
      </NotificationAlert>
      <PopoverStyled isShowPopover={isShowNotify}>
        <ContainerStyled>
          <TitleStyled>
            <Typography text="Notifications" fontSize="28px / 34px" fontWeight="bold" />
            <Typography text={`${notifications(result?.notification)[0]?.length || 0}`} fontSize="28px / 34px" />
          </TitleStyled>
          <TitleMarkAllRead isEmpty={notifications(result?.notification)[0]?.length === 0} onClick={handleMarkAllAsRead}>
            Mark all as read
          </TitleMarkAllRead>
          <ListStyled>
            {notifications(result?.notification)
              .flat(1)
              .map((notification, idx) => (
                <ItemStyled key={idx} onClick={() => handleReadNotification(notification)}>
                  {!!notification?.unread && <CircleBlue />}
                  <NameStyled>
                    <span className="lead line-clamp-3">
                      <span className="title">{notification.title}</span> {notification?.description}
                    </span>{' '}
                    <span className="time">{formatDistanceToNow(new Date(notification?.timestamp || ''))}</span>
                  </NameStyled>
                  {!!notification?.thumbnail && (
                    <ImageStyled>
                      <img src={notification?.thumbnail?.url} alt="Campaign" />
                    </ImageStyled>
                  )}
                </ItemStyled>
              ))}
          </ListStyled>
        </ContainerStyled>
      </PopoverStyled>
    </NotificationStyled>
  );
}

export default NotificationPopover;

const ContainerStyled = styled.div<any>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 30px #00000029;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  z-index: 10;
  top: 51px;
  right: -20px;
  min-width: 375px;
  padding-top: 28px;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    right: 42px;
    top: -6px;
  }

  ${mobileQueryString} {
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    z-index: -1;

    &::before {
      display: none;
    }
  }
`;

const TitleStyled = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0px 20px;
`;

const ListStyled = styled.ul`
  margin: 0;
  padding: 20px;
  max-height: 326px;
  overflow: auto;
`;

const ItemStyled = styled.li<any>`
  list-style: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  cursor: pointer;
  &:first-child {
    padding-top: 0;
  }
  position: relative;
`;

const NameStyled = styled.div<any>`
  flex-grow: 1;
  font: normal normal 400 14px/17px Helvetica Neue;
  padding-right: 32px;

  .title {
    color: #3e3e3e;
    font-weight: 500;
  }
  .lead {
    color: #949494;
  }
  .pointer {
    color: #5770c6;
    cursor: pointer;
  }
  .time {
    display: block;
    color: #949494;
    font: normal normal normal 12px/14px Helvetica Neue;
    margin-top: 5px;
  }

  ${mobileQueryString} {
    text-align: left;
  }
`;

const ImageStyled = styled.div<any>`
  width: 52px;
  height: 52px;
  border-radius: 5px;
  flex-shrink: 0;
  margin-right: 2px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PopoverStyled = styled.div<any>`
  display: ${(props) => (props.isShowPopover ? 'block' : 'none')};
`;

const NotificationStyled = styled.div<any>`
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  padding: 0 20px;
  margin: 0 20px;
  position: relative;

  svg {
    cursor: pointer;
  }

  ${mobileQueryString} {
    margin: 0;
    padding: 0;
    border: none;
  }
`;

const CircleBlue = styled.div<any>`
  background-color: #0160e1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 40%;
  right: -10px;
  transform: translate(0, -50%);
`;

const NotificationAlert = styled.div<any>`
  position: relative;
`;
const DisplayCountAlert = styled.span<any>`
  color: white;
  position: absolute;
  font: normal normal bold 12px/15px Helvetica Neue;
  background-color: #db493a;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  min-width: 8px;
  width: 13px;
  height: 16px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  padding-left: 4px;
  padding-right: 3px;
  box-sizing: content-box;
`;

const TitleMarkAllRead = styled.div<any>`
  font: normal normal 500 14px/17px Helvetica Neue;
  color: #5770c6;
  display: flex;
  justify-content: flex-end;
  padding: 10px 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  ${(props) =>
    props.isEmpty &&
    css`
      color: #949494;
      cursor: not-allowed;
    `}
`;
