import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import useGuard from './useGuard';
import useToastError from './useToastError';
import { useFx } from './useFx';

/**
 *
 */
const useConvertCurrency = (targetCurrency?: any) => {
  const [exchangeRate, setExchangeRate] = useState(1);
  const { user } = useGuard();
  const { toastError } = useToastError();
  const { getRate, loading } = useFx();

  const convert = useCallback(async () => {
    const currencyCode = targetCurrency || user?.profile?.default_currency?.code;
    if (currencyCode === 'USD' || currencyCode === undefined) {
      setExchangeRate(1);
      return 1;
    }

    if (loading) {
      return null;
    }

    try {
      const result = getRate(currencyCode);
      if (result) {
        setExchangeRate(result);
        return result;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error('ConvertCurrency: ', error);
      toastError('Failed to convert currency');
      return null;
    }
  }, [targetCurrency, loading, toastError, user?.profile?.default_currency?.code, user?.profile?.display_currency]);

  useEffect(() => {
    convert();
  }, [convert]);

  return {
    exchangeRate,
  };
};

export default useConvertCurrency;
