import { memo } from 'react';
import styled from 'styled-components';

import { formatCurrencyInput } from '../../../../utils/formatCurrency';

import { ReactComponent as CloseSVG } from '../../../../assets/svg/close_black_24dp.svg';

import PopUpLayout from '../../popUpLayout/PopUpLayout';
import Button from '../../../commons/Button';

const CampaignPublishPopUpFail = ({ balance, balanceUSD, onClose, onSubmit, currency, isLoadingPublish }) => {
  return (
    <PopUpLayout width="376px">
      <Header>
        <HeaderTitle>Failed</HeaderTitle>
        <CloseSVG onClick={onClose} />
      </Header>
      <ContentWrapper>
        <Paragraph>The top up has failed.</Paragraph>
        <Paragraph>The campaign cannot be published.</Paragraph>
        <BalanceLabel>CURRENT BALANCE</BalanceLabel>
        <BalanceValue>
          {formatCurrencyInput(balance)} ${currency}
        </BalanceValue>
        {currency !== 'USD' && <BalanceValueUSD>~{formatCurrencyInput(balanceUSD)} USD</BalanceValueUSD>}
        <ButtonStyled label={isLoadingPublish ? 'WAITING' : 'TRY AGAIN'} onClick={onSubmit} />
        <ButtonStyled label="CLOSE" variant="secondary" onClick={onClose} />
      </ContentWrapper>
    </PopUpLayout>
  );
};

export default memo(CampaignPublishPopUpFail);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 20px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  color: #eb4f3f;
`;

const ContentWrapper = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 20px;
`;

const Paragraph = styled.div<any>`
  font: normal normal bold 16px/22px Helvetica Neue;
  color: #0b0b0b;
`;

const BalanceLabel = styled.div<any>`
  color: #5770c6;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  font-family: GilRoy-Bold;
  margin: 27px 0 5px;
`;

const BalanceValue = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #5770c6;
  margin-bottom: 40px;
`;

const BalanceValueUSD = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #717171;
  margin-bottom: 40px;
`;

const ButtonStyled = styled<any>(Button)`
  height: 46px;
`;
