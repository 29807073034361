import { gql } from '@apollo/client';
export default gql`
  mutation PublishCampaign($campaignId: uuid!) {
    publishCampaign(campaignId: $campaignId) {
      campaignId
      campaign {
        id
        status
      }
    }
  }
`;
