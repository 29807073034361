import React from 'react';
import styled from 'styled-components';

// components
import Typography from '../commons/Typography';
import AsideLeftContent from '../commons/AsideLeftContent';
import { Avatar } from '@mui/material';

function AsideLeftCampaign({ step = 0, backToStep, avatar, campaignName }) {
  return (
    <AsideLeftContent
      onClick={backToStep}
      hasIconBack
      asideTitle={step <= 1 ? <Typography text={'New Campaign'} fontWeight="bold" /> : null}
    >
      <ContentStyled>
        {step <= 1 && (
          <ul>
            <ListStyled
              index="0"
              step={step}
              onClick={() => {
                if (step === 1) {
                  backToStep();
                }
              }}
            >
              <NumberStyled>1</NumberStyled>
              <LeadStyled>Campaign Details</LeadStyled>
            </ListStyled>
            <ListStyled index="1" step={step}>
              <NumberStyled>2</NumberStyled>
              <LeadStyled>Kols Requirements</LeadStyled>
            </ListStyled>
          </ul>
        )}
        {step > 1 && (
          <ul>
            <ListStyled index="0" step={step}>
              <CampaignAvatar>
                <Avatar src={avatar} sx={{ width: 59, height: 59, marginRight: '15px' }} variant="rounded" />
                <CampaignName>{campaignName}</CampaignName>
              </CampaignAvatar>
            </ListStyled>
          </ul>
        )}
      </ContentStyled>
    </AsideLeftContent>
  );
}

export default AsideLeftCampaign;

const ContentStyled = styled.div<any>`
  ul {
    margin: 0;
    padding: 0;
  }
`;
const ListStyled = styled.div<any>`
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${(props) => (props.step >= props.index ? 1 : 0.5)};

  & + & {
    margin-top: 25px;
  }
`;

const NumberStyled = styled.div<any>`
  background: #5770c6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const LeadStyled = styled.div<any>`
  text-align: left;
  font: normal normal medium 16px/19px Helvetica Neue;
  letter-spacing: 0px;
  color: #5770c6;
  opacity: 1;
`;

const AvatarStyled = styled.img`
  width: 59px;
  height: 59px;
  object-fit: cover;
  vertical-align: middle;
  display: inline-block;
  margin-right: 15px;
  border-radius: 5px;
`;
const CampaignAvatar = styled.span<any>`
  display: flex;
`;
const CampaignName = styled.span<any>`
  font: normal normal bold 22px/27px Helvetica Neue;
  display: inline-block;
  word-break: break-word;
`;
