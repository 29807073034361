import { gql } from '@apollo/client';
export default gql`
  query SearchMerchantCampaignList($name: String!, $client_profile_id: uuid!) {
    searchCampaigns(clientProfileId: $client_profile_id, name: $name) {
      id
      name
      image_url
    }
  }
`;
