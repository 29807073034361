import { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { formatCurrencyInput } from '../../../../utils/formatCurrency';

import { ReactComponent as CloseSVG } from '../../../../assets/svg/close_black_24dp.svg';
import { ReactComponent as CheckedSVG } from '../../../../assets/svg/checked.svg';

import PopUpLayout from '../../popUpLayout/PopUpLayout';
import Button from '../../../commons/Button';
import useConvertCurrency from '../../../../hooks/useConvertCurrency';

const RejectPayRequestPopUp = ({ onReject, onRejectPay, onClose, workContract }) => {
  const kol_profile = workContract?.kol_profile;
  const name = (kol_profile?.first_name || '') + ' ' + (kol_profile?.last_name || '');
  const [rejectOption, setRejectOption] = useState('');

  return (
    <PopUpLayout width="560px">
      <Header>
        <HeaderTitle>Reject KOL</HeaderTitle>
        <CloseSVG onClick={onClose} />
      </Header>
      <Content>
        <DescribeNormalStyled>You are going to reject KOL {name}. Please choose your reason as following</DescribeNormalStyled>

        <RadioStyled color={'#5770c6'} circle={true}>
          Reject for campaign
          <input name="reason" type="radio" onChange={() => setRejectOption('Campaign')} value={'Campaign'} />
          <span className="checkmark">
            <CheckedSVG fill="transparent" stroke="#ffff" />
          </span>
        </RadioStyled>
        <DescribeOptionStyled>
          KOL is not appropriate for the campaign criteria
          <br />
          KOL cannot re-apply for this campaign
        </DescribeOptionStyled>

        <RadioStyled color={'#5770c6'} circle={true}>
          Reject due to high additional pay
          <input type="radio" name="reason" onChange={() => setRejectOption('Pay')} value={'Pay'} />
          <span className="checkmark">
            <CheckedSVG fill="transparent" stroke="#ffff" />
          </span>
        </RadioStyled>
        <DescribeOptionStyled>KOL application is reset for KOL to re-apply</DescribeOptionStyled>

        <ButtonStyled disabled={rejectOption === ''} label="SUBMIT" onClick={rejectOption === 'Pay' ? onRejectPay : onReject} />
      </Content>
    </PopUpLayout>
  );
};

export default memo(RejectPayRequestPopUp);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 20px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 32px Inter;
  color: #000000;
`;

const Content = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 24px 16px 16px 24px;
  padding-right: 24px;
`;

const MoneyStyled = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #000000;
`;

const MoneyStyledSecondary = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #717171;
`;

const DescribeStyled = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #5770c6;
  margin: 8px 0 40px;
`;

const DescribeTitleStyled = styled.div<any>`
  font: normal normal bold 14px/14px Inter;
  color: #717171;
  margin-bottom: 8px;
`;

const DescribeNormalStyled = styled.div<any>`
  font: normal normal regular 16px Inter;
  color: #717171;
  margin-bottom: 24px;
`;

const DescribeOptionStyled = styled.div<any>`
  font: normal normal regular 16px Inter;
  color: #717171;
  margin-bottom: 24px;
  margin-left: 24px;
`;

const DescribeBoldStyled = styled.div<any>`
  font: normal normal bold 14px/14px Inter;
  color: #000000;
  margin-bottom: 24px;
`;

const RadioStyled = styled.label<any>`
  display: inline-block;
  position: relative;
  text-transform: none;
  cursor: pointer;
  padding-left: 21px;
  font: normal normal 400 16px Inter;
  color: #000000;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & > .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 2px solid #717171;
    border-radius: 50%;
    border-radius: ${(props) => (props.circle ? '50%' : '3px')};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
  &:hover .checkmark {
    background: #ccc;
  }
  & > .checkmark {
    & svg {
      display: none;
    }
  }
  & input:checked ~ .checkmark {
    background: ${(props) => props.color || '#a553d2'};
    border: none;
    & svg {
      display: block;
    }
  }
  & input:checked ~ .checkmark:after {
    display: block;
  }
`;

const ButtonStyled = styled<any>(Button)`
  height: 46px;
`;
