import { gql } from '@apollo/client';
export default gql`
  query CurrencyList {
    currency_category {
      name
      code
      symbol
      fraction_size
      template
    }
  }
`;
