import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useGuard from '../hooks/useGuard';
import HeaderAuth from '../components/authentication/HeaderAuth';
import { Box, Typography, Grid, Button, Stack, Avatar } from '@mui/material';
import AddMerchantSvg from '../assets/svg/AddMerchantSvg';
import CommonButton from '../components/commons/Button';
import { PRIVATE_ROUTE, ROUTES } from '../utils/constants';
import ClientProfileContext from '../containers/ClientProfileProvider';
import { MerchantProfile } from '../types/merchantProfile';

const SelectMerchant = () => {
  const history = useHistory();
  const [user, setUser] = useState<any>({});
  const [selectedMerchant, setSelectedMerchant] = useState<MerchantProfile | undefined>(undefined);
  const { saveCurrentProfile, clientProfiles } = useContext(ClientProfileContext);

  const { user: currentUser } = useGuard();

  const selectMerchant = (merchant: MerchantProfile | undefined) => {
    saveCurrentProfile(merchant);
    history.push(PRIVATE_ROUTE.campaigns);
  };

  useEffect(() => {
    if (!!currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  if (!currentUser) {
    return (
      <div className="auth0-callback">
        <p>Please wait</p>
      </div>
    );
  }

  return (
    <>
      <HeaderAuth />
      <Box sx={{ width: '100%', padding: '24px' }}>
        <Stack alignItems={'center'} spacing={'40px'}>
          <Typography variant="h4" gutterBottom color={'primary.main'}>
            Select Merchant
          </Typography>
          <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
            {clientProfiles.map((merchant, index) => (
              <Grid item key={merchant?.id || index}>
                <Stack
                  onClick={() => setSelectedMerchant(merchant)}
                  alignItems={'center'}
                  sx={{
                    width: '136px',
                    height: '136px',
                    borderRadius: '8px',
                    border:
                      selectedMerchant !== undefined && selectedMerchant?.id === merchant?.id
                        ? '1px solid var(--KOLs-gray, #5770c6)'
                        : '1px solid var(--KOLs-gray, #d9d9d9)',
                    background: '#f4f4f4',
                  }}
                  component={Button}
                  spacing={'10px'}
                >
                  <Avatar alt={merchant.name} src={merchant.avatar} sx={{ width: 56, height: 56 }} variant="rounded" />
                  <Typography variant="body2">{merchant.name}</Typography>
                </Stack>
              </Grid>
            ))}
            <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Stack
                onClick={() => history.push(ROUTES.clientRegister)}
                alignItems={'center'}
                component={Button}
                sx={{
                  width: '136px',
                  height: '136px',
                  borderRadius: '8px',
                  border: '1px solid var(--KOLs-gray, #d9d9d9)',
                  bgcolor: '#f4f4f4',
                }}
              >
                <AddMerchantSvg />
                <Typography variant="button" color={'#717171'}>
                  Create Merchant
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CommonButton
              variant={'primary'}
              width={'340px'}
              disabled={selectedMerchant === undefined}
              label={'Next'}
              type="submit"
              onClick={() => selectMerchant(selectedMerchant)}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default SelectMerchant;
