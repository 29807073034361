import { memo, useState, useCallback, useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CameraIcon } from '../../assets/svg/camera.svg';
import ImageRemovalIcon from '../../assets/png/remove-image.png';

import ErrorMessageStyled from './ErrorMessage';

import { uniqBy, uniq } from 'lodash';

const UploadFiles = forwardRef<any, any>(({ value, onChange, error, width, height, bgColor, oldFiles, step }, ref) => {
  const [images, setImages] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const valueRef = useRef<any>(false);

  const onChooseFile = useCallback(async (e) => {
    if (e.target.files.length <= 0) return;
    const files = [...e.target.files];
    files.forEach((file) => {
      const url = URL.createObjectURL(file);
      setImages((prevImages) => [...prevImages, url]);
      setFiles((prevFiles) => [...prevFiles, file]);
    });
  }, []);

  useEffect(() => {
    if (oldFiles) {
      oldFiles.forEach((file) => {
        if (!value.filter((item) => item.name === file.name)) {
          const url = URL.createObjectURL(file);
          setImages((prevImages) => {
            return uniq([...prevImages, url]);
          });
        }
        setFiles((prevFiles) => uniqBy([...prevFiles, file], 'name'));
      });
    }
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    if (value && value.length > 0 && !valueRef.current && typeof value?.[0].name !== 'string') {
      setImages(value);
      setFiles(value);
      valueRef.current = true;
    }
  }, [value]);

  useEffect(() => {
    if (files && files.length > 0) {
      onChange(files);
    }
  }, [files, onChange]);

  const handleRemoveImage = (idx: number) => {
    setImages((prevImages) => prevImages.filter((_, index) => index !== idx));
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== idx));
    value = value.filter((_, index) => index !== idx);
    onChange(value);
  };

  return (
    <ColumnStyled ref={ref}>
      <FlexBox>
        {images.length > 0 &&
          images.map((image, idx) => (
            <ImagesWrapper key={image?.id ?? idx} width={width} height={height}>
              <ImageStyled src={image?.file_metadata?.url ?? image} alt="File Kols" title="File Kols" />
              <PhotoRemoveIcon onClick={() => handleRemoveImage(idx)}>
                <img src={ImageRemovalIcon} alt="Removal" />
              </PhotoRemoveIcon>
            </ImagesWrapper>
          ))}

        <ContainerStyled width={width} height={height} bgColor={bgColor}>
          <LabelUploadStyled htmlFor="upload-file" />
          <InputStyled accept="image/*" multiple type="file" id="upload-file" onChange={onChooseFile} />

          <LabelStyled htmlFor="upload-file">
            <CameraIcon fill="#5770C6" />
          </LabelStyled>
        </ContainerStyled>
      </FlexBox>
      {error && <ErrorMessageStyled>{error}</ErrorMessageStyled>}
    </ColumnStyled>
  );
});

export default memo(UploadFiles);

UploadFiles.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
};

UploadFiles.defaultProps = {
  width: '168px',
  height: '167px',
  bgColor: '#fce6f3',
};

const LabelStyled = styled.label<any>`
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 17px;
  svg {
    width: 36px;
    height: 32px;
    position: relative;
    z-index: 3;
  }
  &::after {
    content: '';
    background: rgb(235, 242, 254) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    backdrop-filter: blur(7px);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 0;
    opacity: 0.68;
  }
`;

const ColumnStyled = styled.div<any>`
  display: flex;
  flex-direction: column;
`;

const ContainerStyled = styled.div<any>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.bgColor};
  border-radius: 5px;
  opacity: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;

const FlexBox = styled.div<any>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ImagesWrapper = styled.div<any>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 5px;
  margin-right: 25px;
  margin-bottom: 25px;
`;

const InputStyled = styled.input<any>`
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
`;

const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  object-fit: cover;
  border-radius: 5px;
`;

const LabelUploadStyled = styled.label<any>`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 4;
`;

const PhotoRemoveIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  cursor: pointer;
`;
