import React, { useEffect, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import useCommonApi from '../../hooks/useCommon';

// styled
import GridStyled from '../styled/GridStyled';
import RowStyled from '../styled/RowStyled';
import UploadFile from '../commons/UploadFile';
// common
import TextField from '../commons/TextField';
import TextFieldArea from '../commons/TextFieldArea';
import Selectbox from '../commons/Selectbox';
import { mobileQueryString } from '../../utils/responsive';
import ClientProfileContext from '../../containers/ClientProfileProvider';

const ProfileEdit = (props) => {
  const { clientProfile, onHandleFormSubmit, buttonFormSubmitRef } = props;
  const { listCategory, onRefreshListCategory } = useCommonApi();
  const { currentProfile, refetch } = useContext(ClientProfileContext);

  const { currency_category: listCurrencyData = [], country_category: listCountry = [] } = listCategory;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      avatar: '',
      company_name: '',
      description: '',
      phone_number: '',
      country_code: '',
      postal_code: '',
      region2: '',
      region: '',
      address: '',
      address2: '',
      display_currency: '',
    },
  });

  function onSubmit(values) {
    onHandleFormSubmit(values);
  }

  useEffect(() => {
    if (clientProfile === null || clientProfile === undefined) return;
    setValueFrom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientProfile]);

  useEffect(() => {
    if (Object.keys(listCategory).length === 0) {
      onRefreshListCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const compareCurrency = useCallback((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }, []);

  const listCurrency = useMemo(() => {
    if (!listCurrencyData || !Array.isArray(listCurrencyData)) return [];
    return listCurrencyData
      ?.map((c) => ({
        ...c,
        label: c.name,
        name: `${c.symbol} - ${c.name}`,
        value: c.code,
      }))
      .sort(compareCurrency);
  }, [listCurrencyData, compareCurrency]);

  const setValueFrom = () => {
    reset(clientProfile);
    setValue('avatar', clientProfile?.logo_file?.url);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CampaignContainer>
        <CustomGridStyled>
          <RowStyled>
            <UploadFile
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              {...register('avatar')}
              width="101px"
              height="101px"
              error={errors?.avatar?.message}
            />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <TextField
              {...register('company_name', {
                required: {
                  value: true,
                  message: 'Company Name is required',
                },
              })}
              name="company_name"
              label="Company Name"
              error={errors?.company_name?.message}
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
            />
          </RowStyled>
          <RowStyled>
            <TextField
              {...register('phone_number', {
                required: {
                  value: true,
                  message: 'Phone number is required',
                },
              })}
              placeholder="Enter your phone number"
              error={errors?.phone_number?.message}
              name="phone_number"
              label="Phone"
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
            />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <TextFieldArea
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              {...register('description')}
              name="description"
              label="Bio"
              error={errors?.description?.message}
            />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <Selectbox
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              label="Country"
              options={listCountry.map((c) => ({ ...c, value: c.code }))}
              {...register('country_code', {
                required: {
                  value: true,
                  message: 'Country is required',
                },
              })}
              defaultValue={'MY'}
              error={errors?.country_code?.message}
              canInput={currentProfile !== undefined && currentProfile.isAdmin()}
            />
          </RowStyled>
          <RowStyled>
            <TextField
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              label="Postalcode"
              {...register('postal_code', {
                required: {
                  value: true,
                  message: 'Postalcode is required',
                },
              })}
              error={errors?.postal_code?.message}
            />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <TextField
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              label="City"
              {...register('region2', {
                required: {
                  value: true,
                  message: 'City is required',
                },
              })}
              error={errors?.region2?.message}
            />
          </RowStyled>
          <RowStyled>
            <TextField
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              label="State"
              {...register('region', {
                required: {
                  value: true,
                  message: 'State is required',
                },
              })}
              error={errors?.region?.message}
            />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <TextField
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              label="Address"
              {...register('address', {
                required: {
                  value: true,
                  message: 'Address is required',
                },
              })}
              error={errors?.address?.message}
            />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <TextField
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              label="Address 2"
              {...register('address2')}
            />
          </RowStyled>
        </CustomGridStyled>
        <CustomGridStyled>
          <RowStyled>
            <CurrencyStyled
              disabled={currentProfile === undefined || !currentProfile.isAdmin()}
              label="Display Currency"
              placeholder="$ - USD"
              canInput={currentProfile !== undefined && currentProfile.isAdmin()}
              options={listCurrency}
              renderOption={(item) => item.label}
              {...register('display_currency', {
                required: {
                  value: true,
                  message: 'Display currency is required',
                },
              })}
              defaultValue={clientProfile?.display_currency || clientProfile?.default_currency?.code || 'USD'}
              error={errors?.display_currency?.message}
            />{' '}
          </RowStyled>
        </CustomGridStyled>
      </CampaignContainer>
      <HideButtonStyled ref={buttonFormSubmitRef} type="submit"></HideButtonStyled>
    </Form>
  );
};

export default ProfileEdit;

const Form = styled.form`
  width: 100%;
`;

const CampaignContainer = styled.div<any>`
  max-width: 700px;
  padding-top: 34.5px;
  padding-bottom: 80px;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const CustomGridStyled = styled<any>(GridStyled)`
  margin-bottom: 9px;
  &:first-child {
    margin-bottom: 28px;
  }
`;
const CurrencyStyled = styled<any>(Selectbox)`
  width: 50%;

  ${mobileQueryString} {
    width: 100%;
  }
`;

const HideButtonStyled = styled.button<any>`
  display: none;
`;
