import React from 'react';
import styled, { css } from 'styled-components';

import Typography from '../commons/Typography';
import AsideLeftContent from '../commons/AsideLeftContent';
import { mobileQueryString } from '../../utils/responsive';

function AsideLeftStatistic(props) {
  const { menu = [], onChangeStatus, itemActive, total = 0 } = props;

  return (
    <AsideLeftContent asideTitle={<Typography text="Teams" fontWeight="bold" />} total={total}>
      <BulletStyled>
        {menu.map((item, key) => {
          return (
            <ListStyled key={key} isActive={item.key === itemActive} onClick={() => onChangeStatus(item)}>
              <TextStyled>
                <span>{item?.key?.toLowerCase() || ''}</span>
                <TotalStyled>{item?.total}</TotalStyled>
              </TextStyled>
            </ListStyled>
          );
        })}
      </BulletStyled>
    </AsideLeftContent>
  );
}

export default AsideLeftStatistic;

const BulletStyled = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;

  ${mobileQueryString} {
    grid-auto-flow: column;
    column-gap: 20px;
    overflow-x: scroll;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const ListStyled = styled.li<any>`
  list-style: none;
  display: flex;
  align-items: center;
  font: normal normal normal 16px/18px Helvetica Neue;
  letter-spacing: 0px;
  color: #949494;
  opacity: 1;
  height: 45px;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 29px;
    background: #5770c6 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 0;
  }

  &:hover {
    color: #5770c6;
    &::before {
      opacity: 1;
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      color: #5770c6;
      &::before {
        opacity: 1;
      }

      span {
        font-weight: 600;
      }
    `}

  ${mobileQueryString} {
    font-size: 14px;
    line-height: 16px;
    &::before {
      display: none;
    }

    ${(props) =>
      props.isActive &&
      css`
        border-bottom: 2px solid #5770c6;
        border-radius: 5px 5px 0 0;

        span {
          font-weight: 400;
        }
      `}
  }
`;

const TextStyled = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  margin-left: 9px;
  text-transform: capitalize;

  ${mobileQueryString} {
    margin-left: 0;
  }
`;

const TotalStyled = styled.div<any>`
  ${mobileQueryString} {
    display: none;
  }
`;
