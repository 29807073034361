import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { format, differenceInDays } from 'date-fns';
import { useHistory, generatePath } from 'react-router-dom';
// assets
import { ReactComponent as BookmarkSVG } from '../../assets/svg/bookmark.svg';

import Typography from './Typography';
import Platform from '../campaigns/Platform';

import { FORMAT_DATE_DEFAULT, ROUTES } from '../../utils/constants';
import { fortmatCurrencyTemplate } from '../../utils/formatCurrency';
import { mobileQueryString } from '../../utils/responsive';
import useMobile from '../../hooks/useMobile';

function Card({ thumbnail, title, status, kols, budget, bookmark, platforms, budget_currency, id }) {
  const history = useHistory();
  const isMobile = useMobile();
  const totalDay = useMemo(() => {
    if (!status || Object.keys(status).length <= 0) return null;
    const days = Math.abs(differenceInDays(status?.start, status?.now));
    return `${days} ${days > 1 ? 'days left' : 'day left'}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remainingDays = useMemo(() => {
    if (!status || Object.keys(status).length <= 0) return null;
    const days = differenceInDays(status?.start, new Date());
    if (days > 0) {
      return `${days} ${days > 1 ? 'days left' : 'day left'}`;
    }
    return null;
  }, [status]);

  return (
    <ContainerStyled
      onClick={() => {
        const path = generatePath(ROUTES.campaignsDetails, { id });
        history.push(path + '?mode=0');
      }}
    >
      <TopStyled>
        <img src={thumbnail} alt="Thumbnail Campaign" />
        <SpacingStyled>
          <OverStyled>
            {platforms && platforms.length > 0 && <PlatfromStyled platforms={platforms} iconColor="#fff" />}
            {isMobile ? (
              <StatusStyled>{status?.label?.toLowerCase()}</StatusStyled>
            ) : (
              <Typography text={totalDay} fontSize="12px / 15px" color="#fff" pdTop="0px" />
            )}
          </OverStyled>
        </SpacingStyled>
      </TopStyled>
      <ContentStyled>
        <InfoStyled>
          <TitleStyled>
            <ComponentStyled>
              <span>{title}</span>
            </ComponentStyled>
          </TitleStyled>
          {kols && Object.keys(kols).length > 0 && (
            <KolStyled>
              <StatictisStyled>
                <Typography text="Kols Applied:" fontSize="14px / 17px" fontWeight="500" color="#3E3E3E" />
                <Typography text={`${'' + kols?.applied}`} fontSize="14px / 17px" fontWeight="500" color="#949494" />
              </StatictisStyled>
              <StatictisStyled>
                <Typography text="Accepted:" fontSize="14px / 17px" fontWeight="500" color="#3E3E3E" />
                <Typography text={`${'' + kols?.accepted}`} fontSize="14px / 17px" fontWeight="500" color="#949494" />
              </StatictisStyled>
            </KolStyled>
          )}

          {status && Object.keys(status).length > 0 && (
            <FlexBox>
              <Typography
                text={`${format(status?.start, FORMAT_DATE_DEFAULT)} - ${format(status?.now, FORMAT_DATE_DEFAULT)}`}
                fontSize="14px / 17px"
                fontWeight="500"
                color="#949494"
              />
              {isMobile && <TotalOfDateStyled>({Math.abs(differenceInDays(status?.start, status?.now))} Days)</TotalOfDateStyled>}
            </FlexBox>
          )}
        </InfoStyled>
        <FooterStyled>
          <Typography
            isCenter
            text={`${fortmatCurrencyTemplate(
              budget,
              budget_currency?.template,
              budget_currency?.symbol,
              budget_currency?.fraction_size,
            )}`}
            fontSize="18px / 23px"
            color="#5770C6"
            fontWeight={isMobile ? 'bold' : '500'}
          />
          <TotalOfDateStyled>{remainingDays}</TotalOfDateStyled>
          {bookmark ? (
            <BookMarkStyled>
              <BookmarkSVG />
            </BookMarkStyled>
          ) : null}
        </FooterStyled>
      </ContentStyled>
    </ContainerStyled>
  );
}

export default Card;

Card.propTypes = {
  thumbnail: PropTypes.node,
  title: PropTypes.string,
  status: PropTypes.object,
  kols: PropTypes.object,
  budget: PropTypes.string,
  bookmark: PropTypes.string,
  platforms: PropTypes.array,
};

Card.defaultProps = {};

const ContainerStyled = styled.div<any>`
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  img {
    width: 280px;
    height: 289px;
    object-fit: cover;
    border-radius: 5px;
  }

  ${mobileQueryString} {
    border: none;
    flex-direction: row;
    border-radius: 0;

    img {
      width: 90px;
      height: 90px;
    }
  }
`;

const InfoStyled = styled.div<any>`
  display: flex;
  flex-direction: column;
`;

const FooterStyled = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;

  ${mobileQueryString} {
    & > div {
      margin-top: 0;
    }
  }
`;

const KolStyled = styled.div<any>`
  display: flex;
  width: 100%;
  margin-bottom: 2px;

  ${mobileQueryString} {
    order: 1;
  }
`;

const StatictisStyled = styled.div<any>`
  flex-grow: 1;
  display: flex;

  div:first-child {
    margin-right: 5px;
  }
`;

const TitleStyled = styled.div<any>`
  margin-bottom: 0;
`;

const ContentStyled = styled.div<any>`
  padding: 4px 10px 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${mobileQueryString} {
    padding: 0 0 0 10px;
  }
`;

const BookMarkStyled = styled.div<any>`
  background: rgba(219, 16, 142, 0.1) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: inline-block;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
`;

const TopStyled = styled.div<any>`
  position: relative;
  height: 289px;

  ${mobileQueryString} {
    height: auto;
  }
`;

const OverStyled = styled.div<any>`
  display: flex;
  background: rgba(11, 11, 11, 0.2) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 15.6px 0px 10px;
  box-sizing: border-box;
  text-align: center;

  ${mobileQueryString} {
    flex-direction: column;
    padding: 5px;
  }
`;

const SpacingStyled = styled.div<any>`
  padding: 0 10px;
  height: 33px;
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 9.91px;
  box-sizing: border-box;

  ${mobileQueryString} {
    height: auto;
  }
`;

const StatusStyled = styled.div<any>`
  font: normal normal 500 10px/12px Helvetica Neue;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 5px;
  grid-column: span 2;
`;

const ComponentStyled = styled.div<any>`
  span {
    padding-top: 4px;
    color: #0b0b0b;
    font: normal normal 500 16px / 19px Helvetica Neue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  ${mobileQueryString} {
    span {
      padding-top: 2px;
      font-weight: bold;
    }
  }
`;

const TotalOfDateStyled = styled.div<any>`
  font: normal normal 400 12px/14px Helvetica Neue;
  color: #949494;
  margin: 5px 0 0 10px;
`;

const FlexBox = styled.div<any>`
  display: flex;
  align-items: center;
`;

const PlatfromStyled = styled<any>(Platform)`
  ${mobileQueryString} {
    justify-content: center;
    order: 2;
  }
`;
