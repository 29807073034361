import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../../../assets/svg/logo_svg.svg';
import { mobileQueryString } from '../../../utils/responsive';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../utils/constants';

const MainLayoutMobile = ({ onSave, onCancel, asideLeft, asideRight }) => {
  return (
    <Wrapper>
      <HeaderContentStyled>
        <HandleStyled onClick={onCancel}>Cancel</HandleStyled>
        <LinkStyled to={ROUTES.campaigns}>
          <LogoSVG />
        </LinkStyled>
        <HandleStyled onClick={onSave}>Save</HandleStyled>
      </HeaderContentStyled>
      <ContainerStyled mt="40px">
        <MainStyled>
          <AreaLeftStyled>{asideLeft}</AreaLeftStyled>
          <AreaRightStyled>{asideRight}</AreaRightStyled>
        </MainStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

export default memo(MainLayoutMobile);

MainLayoutMobile.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  asideLeft: PropTypes.node,
  asideRight: PropTypes.node,
};

const HandleStyled = styled.div<any>`
  font: normal normal 400 12px/14px Helvetica Neue;
  color: #5770c6;
  text-transform: uppercase;
`;

const HeaderContentStyled = styled.div<any>`
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const Wrapper = styled.div<any>`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
`;

const ContainerStyled = styled.div<any>`
  max-width: 100%;
  width: calc(100% - 170px);
  margin: 0 auto;
  margin-top: ${(props) => props.mt || 0};

  ${mobileQueryString} {
    width: 100%;
    padding: 0 20px;
    margin-top: 15px;
  }
`;

const MainStyled = styled.div<any>`
  display: flex;
  position: relative;

  ${mobileQueryString} {
    flex-direction: column;
  }
`;

const AreaLeftStyled = styled.div<any>`
  width: 100%;
  max-width: 232px;
  flex-shrink: 0;
  margin-right: 68px;
  box-sizing: border-box;
  height: fit-content;

  ${mobileQueryString} {
    max-width: none;
    margin-right: 0;
  }
`;

const AreaRightStyled = styled.div<any>`
  flex-grow: 1;
  margin-right: 50px;
  width: calc(100% - 232px - 68px);

  ${mobileQueryString} {
    width: 100%;
    margin-right: 0;
  }
`;

const LinkStyled = styled<any>(Link)`
  max-width: 150px;

  svg {
    max-width: 100%;
    height: 22px;
  }
`;
