import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PopupMobile = ({ children, handleClickOverlay }) => {
  return (
    <>
      <OverlayStyled onClick={handleClickOverlay} />
      <ContainerStyled>
        <BorderTopStyled />
        <ListStyled>{children}</ListStyled>
      </ContainerStyled>
    </>
  );
};

export default memo(PopupMobile);

PopupMobile.propTypes = {
  children: PropTypes.node,
  handleClickOverlay: PropTypes.func,
};

const ContainerStyled = styled.div<any>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 30px #00000029;
  opacity: 1;
  z-index: 10;
  min-width: 206px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  z-index: 10;
  border-radius: 10px 10px 0 0;
`;

const BorderTopStyled = styled.div<any>`
  width: 50px;
  height: 4px;
  border-radius: 3px;
  background-color: #d9d9d9;
  margin: 13px auto 0 auto;
`;

const ListStyled = styled.ul`
  margin: 0;
  padding: 3px 0 0 0;
`;

const OverlayStyled = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.65);
`;
