import { gql } from '@apollo/client';
export default gql`
  mutation MerchantCreateCampaign(
    $name: String!
    $brand_name: String!
    $product_name: String!
    $description: String!
    $start_date: timestamptz!
    $end_date: timestamptz!
    $recruitment_end_date: timestamptz!
    $image_url: String!
    $client_profile_id: uuid!
    $website: String
    $tranches: MerchantCreateCampaignTrancheArrRelInsertInput!
  ) {
    createCampaign(
      name: $name
      brand_name: $brand_name
      product_name: $product_name
      description: $description
      start_date: $start_date
      end_date: $end_date
      recruitment_end_date: $recruitment_end_date
      image_url: $image_url
      client_profile_id: $client_profile_id
      website: $website
      tranches: $tranches
    ) {
      campaign {
        brand_name
        client_profile_id
        created_at
        budget_usd_cents
        display_currency
        budget_currency {
          symbol
          fraction_size
        }
        description
        end_date
        id
        image_url
        name
        product_name
        recruitment_end_date
        start_date
        status
        updated_at
        tranches {
          id
          mapping_tranche_languages {
            language_code
          }
          name
          product_categories {
            product_category {
              name
            }
            category_code
          }
          min_follower_size
          max_follower_size
          social_platforms {
            category_code
            social_platform {
              name
              image_icon_path
            }
          }
          genders {
            category_code
            gender {
              code
              description
            }
          }
          age_groups {
            category_code
            age_category {
              name
              min_value
              max_value
            }
          }
          country {
            name
            code
          }
          number_kols
          country_code
          cash_usd_cents
          product_value_usd_cents
          product_description
          deliverable_specs {
            id
            quantity
            content_type_code
            requirement_description
            due_date
            title
          }
          requirements
        }
      }
    }
  }
`;
