import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Link, generatePath } from 'react-router-dom';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import NotificationPopover from '../NotificationPopover';
import SearchPopover from '../SearchPopover';
import { useHistory, useLocation } from 'react-router';
import { PRIVATE_ROUTE, ROUTES } from '../../../utils/constants';

import { ReactComponent as LogoSVG } from '../../../assets/svg/logo_svg.svg';
import { ReactComponent as AddSVG } from '../../../assets/svg/add.svg';
import useCampaign from '../../../hooks/useCampaign';
import { mobileQueryString, largeDesktopQueryString } from '../../../utils/responsive';
import { Stack } from '@mui/material';
import { TeamSelector } from '../MainLayout/TeamSelector';
import LIST_MEMBER, { LIST_MECHANT_INVITED_ME } from '../../../queries/teams/listMember';
import { ApolloClient, useApolloClient } from '@apollo/client';
import useGuard from '../../../hooks/useGuard';
import { useContext, useEffect, useState } from 'react';
import ClientProfileContext from '../../../containers/ClientProfileProvider';

function MainLayout({ asideLeft, asideRight, headerCenter, headerTeam, hasButtonNew, isWalletPage }) {
  const history = useHistory();
  const location = useLocation();
  const { onClearCampaignSelected } = useCampaign();
  const { clientProfiles: merchantProfiles, currentProfile, saveCurrentProfile } = useContext(ClientProfileContext);

  const gotoCreateCampaignPage = () => {
    onClearCampaignSelected();
    history.replace(PRIVATE_ROUTE.campaignsCreate);
    if (location?.search?.includes('draftId')) {
      window.location.reload();
    }
  };
  const client = useApolloClient();
  const { user } = useGuard();

  return (
    <Wrapper>
      <HeaderStyled>
        <ContainerStyled>
          <HeaderContentStyled>
            <LogoStyled>
              <A to={ROUTES.campaigns}>
                <LogoSVG />
              </A>
            </LogoStyled>

            <HeaderAreaStyled>
              <Stack direction={'row'} spacing={'42px'}>
                <HeaderCenterStyled>
                  <MenuItemStyled>{headerCenter}</MenuItemStyled>
                </HeaderCenterStyled>
                {headerTeam !== undefined && (
                  <HeaderCenterStyled>
                    <MenuItemStyled>{headerTeam}</MenuItemStyled>
                  </HeaderCenterStyled>
                )}
              </Stack>
              <HeaderRightStyled>
                {merchantProfiles.length > 0 && (
                  <TeamSelector
                    onSelected={(selectedProfile) => {
                      const path = generatePath(ROUTES.campaigns);
                      history.push({
                        pathname: path,
                        state: { key: Date.now() },
                      });
                      saveCurrentProfile(selectedProfile);
                    }}
                    merchantProfiles={merchantProfiles}
                    selectedProfile={currentProfile}
                  />
                )}
                {hasButtonNew && (
                  <ButtonStyled type="button" onClick={gotoCreateCampaignPage}>
                    <AddSVG />
                    <span>New</span>
                  </ButtonStyled>
                )}

                <SearchPopover />

                <NotificationPopover />

                <ProfilePopover />
              </HeaderRightStyled>
            </HeaderAreaStyled>
          </HeaderContentStyled>
        </ContainerStyled>
      </HeaderStyled>
      <ContainerStyled mt="40px">
        <MainStyled>
          <AreaLeftStyled isWalletPage={isWalletPage}>{asideLeft}</AreaLeftStyled>
          <AreaRightStyled>{asideRight}</AreaRightStyled>
        </MainStyled>
      </ContainerStyled>
    </Wrapper>
  );
}

export default MainLayout;

MainLayout.propTypes = {
  asideRight: PropTypes.node,
  asideLeft: PropTypes.node,
  headerCenter: PropTypes.node,
  headerTeam: PropTypes.node,
  hasButtonNew: PropTypes.bool,
  onClick: PropTypes.func,
  isWalletPage: PropTypes.bool,
};

MainLayout.defaultProps = {
  hasButtonNew: false,
  isWalletPage: false,
};

const HeaderStyled = styled.header`
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  background: #fff;
`;

const HeaderContentStyled = styled.div<any>`
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

const HeaderAreaStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
`;

const HeaderCenterStyled = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderRightStyled = styled.div<any>`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;

    .a {
      stroke: #5770c6;
    }
  }
`;

const A = styled<any>(Link)`
  cursor: pointer;
  text-decoration: none;
  display: block;
  min-width: 150px;
  height: 22px;
  margin-right: 147px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const LogoStyled = styled.div<any>`
  width: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonStyled = styled.button<any>`
  display: flex;
  align-items: center;
  padding: 9px 17px;
  border: 0;
  outline: 0;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  color: #5770c6;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
  margin-right: 22px;

  svg {
    margin-right: 9px;
  }

  span {
    font-size: 16px;
    line-height: 19px;
    font-family: system-ui;
    font-weight: bold;
  }
`;

const ContainerStyled = styled.div<any>`
  max-width: 1280px;
  width: calc(100% - 170px);
  margin: 0 auto;
  margin-top: ${(props) => props.mt || 0};

  ${mobileQueryString} {
    width: 100%;
    padding: 0 20px;
  }
`;

const MainStyled = styled.div<any>`
  display: flex;
`;

const AreaLeftStyled = styled.div<any>`
  width: 100%;
  max-width: 232px;
  flex-shrink: 0;
  margin-right: 68px;
  box-sizing: border-box;
  height: fit-content;

  ${(props) =>
    props.isWalletPage &&
    css`
      width: 20%;

      ${largeDesktopQueryString} {
        margin-right: 0;
      }
    `}
`;

const AreaRightStyled = styled.div<any>`
  flex-grow: 1;
  margin-right: 50px;
  width: calc(80% - 68px);
`;

const MenuItemStyled = styled.div<any>`
  position: relative;
  height: 100%;
  cursor: pointer;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Wrapper = styled.div<any>`
  width: 100%;
  margin: 0 auto;
`;
