import { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Avatar, ConversationHeader } from '@chatscope/chat-ui-kit-react';
import { ChatWrapperStyled, ConversationHeaderContent } from '../commons/Chat/Components';
import { DEFAULT_AVATAR, ROUTES, MODE } from '../../utils/constants';
import CampaignDetailEdit from './CampaignDetailEdit';
import CampaignDetailView from './CampaignDetailView';
import { useHistory, generatePath } from 'react-router-dom';
import useMobile from '../../hooks/useMobile';
import qs from 'query-string';
import * as R from 'ramda';
import Button from '../commons/Button';

import { ReactComponent as EditIcon } from '../../assets/svg/edit.svg';
import { mobileQueryString } from '../../utils/responsive';
import useGuard from '../../hooks/useGuard';

const CampaignDetail = (props: any) => {
  const { className, height, owner = {}, data, onSave } = props;
  const isMobile = useMobile();
  const [mode, setMode] = useState(MODE.view);
  const [errorRecruitment, setErrorRecruitment] = useState<any>(null);
  const history = useHistory();
  const query = qs.parse(history.location.search) as any;
  const formEditRef = useRef<any>();
  const { user } = useGuard();

  useEffect(() => {
    if (R.is(String, query.mode)) {
      setMode(query.mode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query.mode !== mode) {
      const queryParams = qs.parse(history.location.search) as any;
      const newQueries = { ...queryParams, mode };
      history.push({ search: qs.stringify(newQueries) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.mode, mode]);

  const handleEditMode = () => {
    if (!data || !(data?.status === 'DRAFT' || data?.status === 'PUBLISHED')) return;
    if (data?.status === 'DRAFT') {
      const path = generatePath(ROUTES.campaignsCreate);
      history.push(path + `?draftId=${data?.id}`);
      return;
    }
    setMode(MODE.edit);
  };

  const handleDiscard = () => {
    setMode(MODE.view);
  };

  const handleSubmit = (values) => {
    setErrorRecruitment(null);
    const campaignPeriod = values.campaignPeriod.map((t) => new Date(t).getTime()).sort();
    if (values.recruitmentDeadline.getTime() >= campaignPeriod[0]) {
      setErrorRecruitment('Recruitment end date is before campaign start date');
      return;
    }
    onSave && onSave(values);
    setMode(MODE.view);
  };

  const handleSave = () => {
    formEditRef.current(handleSubmit)();
  };

  const handleSetMode = useCallback((value) => {
    setMode(value);
  }, []);

  return (
    <ChatWrapperStyled height={height} className={className}>
      {isMobile && mode === MODE.view && (
        <EditIconStyled onClick={handleEditMode} enable_button={data?.status === 'DRAFT' || data?.status === 'PUBLISHED'} />
      )}
      <ConversationHeaderStyled>
        <Avatar className="chat-header-avatar" src={owner.avatar || DEFAULT_AVATAR} name={owner.name} />
        <ConversationHeader.Content>
          <ConversationHeaderContent owner={owner} />
        </ConversationHeader.Content>
        <ConversationHeader.Actions>
          {mode === MODE.view && (
            <EditIconStyled onClick={handleEditMode} enable_button={data?.status === 'DRAFT' || data?.status === 'PUBLISHED'} />
          )}
          {mode === MODE.edit && (
            <>
              <Button type="button" width="110px" variant="secondary" label="DISCARD" onClick={handleDiscard} />
              <Button type="button" width="131px" label="SAVE" onClick={handleSave} />
            </>
          )}
        </ConversationHeader.Actions>
      </ConversationHeaderStyled>
      <ListCampaignStyled>
        {mode === MODE.view && data && <CampaignDetailView data={data} symbol={user?.profile?.default_currency?.symbol} />}
        {mode === MODE.edit && data && (
          <CampaignDetailEdit handleSetMode={handleSetMode} data={data} ref={formEditRef} error={errorRecruitment} />
        )}
      </ListCampaignStyled>
    </ChatWrapperStyled>
  );
};

export default CampaignDetail;

export const EditIconStyled = styled<any>((props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <EditIcon stroke="#5770C6" width="20px" height="20px" />
      <span>Edit</span>
    </div>
  );
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgb(219, 16, 142, 0.1);
  cursor: pointer;
  border-radius: 5px;
  height: 46px;
  width: 91px;
  & span {
    font: normal normal 600 14px/17px Helvetica Neue;
    margin-left: 12px;
    margin-top: 9px;
    color: #5770c6;
  }

  ${(props) =>
    !props.enable_button &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}

  ${mobileQueryString} {
    position: absolute;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 8px;
    top: 107px;
    right: 20px;
    width: 65px;
    height: 30px;

    svg {
      width: 12px;
      height: 12px;
    }

    span {
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      margin-top: 0;
      margin-left: 0;
    }
  }
`;
const ListCampaignStyled = styled.div<any>`
  height: 66vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${mobileQueryString} {
    margin-top: 20px;
  }
`;

const ConversationHeaderStyled = styled<any>(ConversationHeader)`
  ${mobileQueryString} {
    display: none;
  }
`;
