import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { DEFAULT_AVATAR } from '../../../../utils/constants';
import CampaignIconSocial from '../../CampaignIconSocial';
import Button from '../../../commons/Button';
import { format } from 'date-fns';
import { STATUS } from '../const/index';
import { forwardRef } from 'react';

const TIME_FORMAT = 'do LLL, yy';

interface CampaignListOfKolMobileApplyProps {
  data: any[];
  handleAccept: (item: any) => void;
  handleReject: (item: any) => void;
}

const CampaignListOfKolMobileApply = forwardRef<HTMLDivElement, CampaignListOfKolMobileApplyProps>(
  ({ data, handleAccept, handleReject }, ref) => {
    const followers = useMemo(() => {
      const numberOfFollower = data?.[0]?.kol_profile?.follower_size;
      if (numberOfFollower <= 1) return `${numberOfFollower} follower`;
      else {
        return `${numberOfFollower} followers`;
      }
    }, [data]);

    const removeDuplicatePlatformMap = useCallback((platForm = []): any => {
      const map = new Map();
      platForm.forEach((f: any) => {
        map.set(f.social_platform_category.code, f);
      });

      const newPlatform = Array.from(map, ([_, value]) => ({ ...value }));
      return newPlatform;
    }, []);

    return (
      <div ref={ref}>
        {data.length > 0 &&
          data.map((item) => (
            <ItemWrapper key={item.id}>
              <TopStyled>
                <LeftItemStyled>
                  <AvatarStyled>
                    <img src={item?.kol_profile?.user?.picture?.url || DEFAULT_AVATAR} alt="Logo" />
                  </AvatarStyled>
                  <div>
                    <ContentStyled>
                      {`${item?.kol_profile?.user?.given_name || ''} ${item?.kol_profile?.user?.family_name || ''}`}
                    </ContentStyled>
                    <Flex>
                      <GenderStyled>{item?.kol_profile?.gender.toLowerCase()}</GenderStyled>
                      <GroupStyled>{item?.tranche?.name}</GroupStyled>
                    </Flex>
                    <FlexStyled>
                      <div>
                        <CampaignIconSocial social_platforms={removeDuplicatePlatformMap(item?.kol_profile?.social_platforms)} />
                      </div>
                      <FollowerStyled>{followers}</FollowerStyled>
                    </FlexStyled>
                  </div>
                </LeftItemStyled>
                {item?.status?.code === STATUS.clientReject.value ? (
                  <ActionCellStyled>
                    <ButtonActionStyled variant="secondary" label="REJECTED" disabled />
                  </ActionCellStyled>
                ) : (
                  <ActionCellStyled>
                    <ButtonActionStyled variant="secondary" label="REJECT" onClick={() => handleReject(item)} />
                    <ButtonActionStyled variant="primary" label="ACCEPT" onClick={() => handleAccept(item)} />
                  </ActionCellStyled>
                )}
              </TopStyled>
              <UnderStyled>
                <div>
                  <TitleStyled>Date</TitleStyled>
                  <ContentStyled>{item?.created_at && format(new Date(data[0].created_at), TIME_FORMAT)}</ContentStyled>
                </div>
                <div>
                  <TitleStyled>Engagement Rate:</TitleStyled>
                  <ContentStyled>{item?.kol_profile?.engagement_rate ?? 0}%</ContentStyled>
                </div>
              </UnderStyled>
            </ItemWrapper>
          ))}
      </div>
    );
  },
);
export default memo(CampaignListOfKolMobileApply);

// CampaignListOfKolMobileApply.propTypes = {
//   data: PropTypes.array,
//   handleAccept: PropTypes.func,
//   handleReject: PropTypes.func,
// };

const ItemWrapper = styled.div<any>`
  margin-top: 20px;
  border-bottom: 1px solid #eeeeee;
`;

const TopStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
`;

const AvatarStyled = styled.div<any>`
  margin-right: 12px;
  img {
    width: 59px;
    height: 59px;
    border-radius: 5px;
  }
`;

const Flex = styled.div<any>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 10px;
`;

const FlexStyled = styled<any>(Flex)`
  margin-top: 3px;
`;

const NormalFont = styled.div<any>`
  font: normal normal normal 13px/16px Helvetica Neue;
`;

const GenderStyled = styled<any>(NormalFont)`
  text-transform: capitalize;
  color: #949494;
`;

const FollowerStyled = styled<any>(NormalFont)`
  color: #3e3e3e;
  font-weight: 500;
`;

const GroupStyled = styled.div<any>`
  font: normal normal 400 13px/15px Helvetica Neue;
  color: #949494;
`;

const LeftItemStyled = styled.div<any>`
  display: flex;
`;

const ButtonActionStyled = styled<any>(Button)`
  height: 38px;
  ${(props) =>
    props.variant === 'secondary' &&
    css`
      & {
        background-color: transparent;
        color: #949494;
      }
      &:hover {
        background-color: #949494;
        color: #fff;
      }
    `}
  ${(props) =>
    props.variant === 'primary' &&
    css`
      & {
        background: transparent;
        color: #5770c6;
      }
      &:hover {
        background: transparent linear-gradient(263deg, #51adf0 0%, #666bfa 49%, #b64dc8 100%) 0% 0% no-repeat padding-box;
        color: #fff;
      }
    `}
    ${(props) =>
    !!props.disabled &&
    css`
      &:hover {
        background-color: transparent;
        color: #949494;
      }
    `}
`;

const ActionCellStyled = styled.div<any>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
`;

const TitleStyled = styled<any>(NormalFont)`
  color: #949494;
  margin-bottom: 5px;
`;

const ContentStyled = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #0b0b0b;
`;

const UnderStyled = styled.div<any>`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  column-gap: 35px;
  margin: 15px 0;
`;
