import React from 'react';
import styled from 'styled-components';

import Modal from '../commons/Modal';
import Button from '../commons/Button';

function ModalCampaignCancel(props: any) {
  const { onSaveDraft, onDiscard, onCancel, isOpen } = props;
  return (
    <Modal isOpen={isOpen} isPassiveCancel closeModal={onCancel}>
      <TitleStyled>If you go back now, your campaign will be discarded.</TitleStyled>
      <Button label="SAVE DRAFT" onClick={onSaveDraft} />
      <Button variant="tertiary" label="DISCARD" onClick={onDiscard} />
    </Modal>
  );
}

export default ModalCampaignCancel;

const TitleStyled = styled.div<any>`
  text-align: center;
  font: normal normal 600 14px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #3e3e3e;
  opacity: 1;
  margin-bottom: 25px;
  padding: 0 30px;
`;
