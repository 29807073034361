import React, { useState } from 'react';
import './DeliverableArea.css'; // Import the CSS file
import ContentMetricsField from './ContentMetricsField';
import IconInstagramNoColor from '../../../../assets/svg/ic_instagram_no_color.svg';
import { format } from 'date-fns';
import ic_calendar from '../../../../assets/svg/ic_calendar.svg';
import ic_dolar from '../../../../assets/svg/ic_dolar.svg';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MetricData } from '../../CampaignReport/Overview/CampaignReportOverview';

interface DeliverableAreaProps {
  data: MetricData;
  onDataChange: (data: MetricData) => void;
}

const DeliverableArea: React.FC<DeliverableAreaProps> = ({ data, onDataChange }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
    onDataChange({ ...data, postOn: date });
  };

  const handleButtonClick = () => {
    setShowDatePicker(true);
  };

  return (
    <div className="contentMetricsArea">
      <div className="areaTitle">{data.description}</div>
      <div style={{ paddingTop: '15px' }}>
        <a href={data.contentLink}>Deliverable link</a>
      </div>
      <div className="contentRow">
        <div>
          <div style={{ color: '#717171', fontSize: '14px' }}>Platform</div>
          <img src={IconInstagramNoColor} alt="platform" style={{ marginTop: '10px' }} />
        </div>
        <div style={{ width: '1px', height: '55px', backgroundColor: '#D9D9D9', marginLeft: '20px' }} />

        <div style={{ marginLeft: '20px' }}>
          <div style={{ color: '#717171', fontSize: '14px' }}>Type</div>
          <div style={{ color: 'black', fontSize: '14px', marginTop: '10px' }}>{data.contentType}</div>
        </div>
        <div style={{ width: '1px', height: '55px', backgroundColor: '#D9D9D9', marginLeft: '20px' }} />

        <div style={{ marginLeft: '20px' }}>
          <div style={{ color: '#717171', fontSize: '14px' }}>Quantity</div>
          <div style={{ color: 'black', fontSize: '14px', marginTop: '10px' }}>1</div>
        </div>
      </div>

      <div className="contentRow">
        <div className="contentField">
          <ContentMetricsField
            title="Spend Per Deliverable"
            contentType="number"
            content={data.spend.toString()}
            type="Spend"
            handleInputChange={(e, type) => {
              const newData = { ...data, spend: parseInt(e.target.value) };
              onDataChange(newData);
            }}
            iconPath={ic_dolar}
          />
        </div>
        <div className="contentField" style={{ marginLeft: '15px' }}>
          <ContentMetricsField
            title="Post On Date"
            iconPath={ic_calendar}
            contentType="text"
            onIconClick={() => {
              handleButtonClick();
            }}
            content={data.postOn ? format(data.postOn, 'MM/dd/yyyy') : '-'}
            type="PostOnDate"
            handleInputChange={(e, type) => {}}
          />
          {showDatePicker && (
            <div
              className="shadow-layer"
              onClick={() => {
                setShowDatePicker(false);
              }}
            ></div>
          )}
          {showDatePicker && (
            <div className="datepicker-popup">
              <DatePicker selected={selectedDate} onChange={handleDateChange} inline />
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          width: 'calc(100% - 20px)',
          height: '1px',
          backgroundColor: '#D9D9D9',
          marginLeft: '10px',
          marginRight: '10px',
          marginTop: '20px',
        }}
      />
      <div style={{ paddingTop: '15px', fontWeight: 'bold', color: '#5770C6' }}>Reach</div>

      <div className="contentRow">
        <div className="contentField">
          <ContentMetricsField
            title="Reach"
            contentType="number"
            iconPath={null}
            content={data.reach.toString()}
            type="Reach"
            handleInputChange={(e, type) => {
              const newData = { ...data, reach: parseInt(e.target.value) };
              onDataChange(newData);
            }}
          />
        </div>
        <div className="contentField" style={{ marginLeft: '15px' }}>
          <ContentMetricsField
            title="View"
            contentType="number"
            iconPath={null}
            content={data.views.toString()}
            type="View"
            handleInputChange={(e, type) => {
              const newData = { ...data, views: parseInt(e.target.value) };
              onDataChange(newData);
            }}
          />
        </div>
      </div>

      <div style={{ paddingTop: '15px', fontWeight: 'bold', color: '#5770C6' }}>Post engagement</div>

      <div className="contentRow">
        <div className="contentField">
          <ContentMetricsField
            title="Like"
            contentType="number"
            iconPath={null}
            content={data.engagement.toString()}
            type="Like"
            handleInputChange={(e, type) => {
              const newData = { ...data, engagement: parseInt(e.target.value) };
              onDataChange(newData);
            }}
          />
        </div>
        <div className="contentField" style={{ marginLeft: '15px' }}>
          <ContentMetricsField
            title="Comment"
            contentType="number"
            iconPath={null}
            content={data.ig_post_comments.toString()}
            type="Comment"
            handleInputChange={(e, type) => {
              const newData = { ...data, ig_post_comments: parseInt(e.target.value) };
              onDataChange(newData);
            }}
          />
        </div>
      </div>
      <div className="contentRow">
        <div className="contentField">
          <ContentMetricsField
            title="Share"
            contentType="number"
            iconPath={null}
            content={data.shares.toString()}
            type="Share"
            handleInputChange={(e, type) => {
              const newData = { ...data, shares: parseInt(e.target.value) };
              onDataChange(newData);
            }}
          />
        </div>
        <div className="contentField" style={{ marginLeft: '15px' }}>
          <ContentMetricsField
            title="Save"
            iconPath={null}
            content={data.ig_post_saves.toString()}
            type="Save"
            contentType="number"
            handleInputChange={(e, type) => {
              const newData = { ...data, ig_post_saves: parseInt(e.target.value) };
              onDataChange(newData);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DeliverableArea;
