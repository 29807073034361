import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

// styled
import GridStyled from '../../../styled/GridStyled';
import RowStyled from '../../../styled/RowStyled';
import FormGroupStyled from '../../../commons/FormGroup';
//css
import '../../../../style/main.css';

import useMobile from '../../../../hooks/useMobile';
import { mobileQueryString } from '../../../../utils/responsive';

// common
import DatepickerRange from '../../../commons/DatepickerRange';
import TextField from '../../../commons/TextField';
import Button from '../../../commons/Button';
import TextFieldArea from '../../../commons/TextFieldArea';
import ErrorMessageStyled from '../../../commons/ErrorMessage';
import CreateCampaignDetailHeader from './CreateCampaignDetailHeader';
import DatePicker from '../../../commons/Datepicker';
import UploadFiles from '../../../commons/UploadFiles';

const TIME_FORMAT = 'yyyy/MM/dd';

function CreateCampaignDetail(props: any) {
  const { submitCampaign, formData = {}, onCancelCreateCampaign, btnSubmitDetailRef, handleSaveDraft, step } = props;
  const isMobile = useMobile();
  const [errorRecruitment, setErrorRecruitment] = useState<any>(null);
  const [errorAvatar, setErrorAvatar] = useState<any>(null);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      avatar: formData?.avatar || '',
      name: formData?.name || '',
      brand_name: formData?.brand_name || '',
      product_name: formData?.product_name || '',
      description: formData?.description || '',
      website: formData?.website || '',
      campaignPeriod: [
        formData?.start_date ? new Date(formData?.start_date) : null,
        formData?.end_date ? new Date(formData?.end_date) : null,
      ],
      recruitment_end_date: formData?.recruitment_end_date ? new Date(formData?.recruitment_end_date) : null,
    },
  });

  const onSubmit = (value) => {
    setErrorRecruitment(null);
    setErrorAvatar(null);
    const campaignPeriod = value.campaignPeriod;
    const flipCampaignPeriod = campaignPeriod.map((t) => new Date(t).getTime()).sort();
    const [startDate, endDate] = flipCampaignPeriod;
    if (new Date(startDate).getTime() < Date.now()) {
      setErrorRecruitment('Start date cannot be in the past');
      return;
    }
    if (value.recruitment_end_date.getTime() >= startDate) {
      setErrorRecruitment('Recruitment end date is before campaign start date');
      return;
    }
    if (value.avatar.length === 0) {
      setErrorAvatar('Please upload at least 1 image');
      return;
    }

    submitCampaign({
      avatar: value.avatar,
      name: value.name,
      brand_name: value.brand_name,
      product_name: value.product_name,
      description: value.description,
      start_date: startDate && format(startDate, TIME_FORMAT),
      end_date: endDate && format(endDate, TIME_FORMAT),
      recruitment_end_date: value.recruitment_end_date && format(value.recruitment_end_date, TIME_FORMAT),
      website: value.website,
    });
  };

  useEffect(() => {
    setValue('avatar', formData.images || []);
    setValue('name', formData.name);
    setValue('brand_name', formData.brand_name);
    setValue('product_name', formData.product_name);
    setValue('description', formData.description);
    setValue('website', formData.website);
    setValue('campaignPeriod', [
      formData.start_date ? new Date(formData.start_date) : null,
      formData.end_date ? new Date(formData.end_date) : null,
    ]);
    setValue('recruitment_end_date', formData.recruitment_end_date ? new Date(formData.recruitment_end_date) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <>
      {isMobile && <CreateCampaignDetailHeader onNext={handleSubmit(onSubmit)} onCancel={onCancelCreateCampaign} />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CampaignHeader justifyContent="space-between">
          <RowStyled>
            <CampaignTitle>Enter Campaign Details</CampaignTitle>
          </RowStyled>
          <RowStyled style={{ width: 'fit-content' }}>
            <Button type="button" width="110px" variant="secondary" label="Cancel" onClick={onCancelCreateCampaign} />
            <Button width="149px" label="Next" onClick={() => handleSaveDraft && handleSaveDraft(false)} />
          </RowStyled>
        </CampaignHeader>
        <CampaignContainer>
          <GridStyled>
            <FormGroupStyled>
              <RowStyled>
                <Controller
                  control={control}
                  name="avatar"
                  render={({ field }) => (
                    <UploadFiles
                      {...field}
                      width={isMobile ? '101px' : '168px'}
                      height={isMobile ? '101px' : '168px'}
                      error={errorAvatar ?? errors?.avatar?.message}
                      oldFiles={formData?.avatar}
                      step={step}
                    />
                  )}
                />
              </RowStyled>
            </FormGroupStyled>
          </GridStyled>
          <GridStyled>
            <RowStyled>
              <TextField
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Campaign Name is required',
                  },
                })}
                name="name"
                label="Campaign Name"
                error={errors?.name?.message}
              />
            </RowStyled>
            <RowStyled>
              <TextField
                {...register('brand_name', {
                  required: {
                    value: true,
                    message: 'Brand Name is required',
                  },
                })}
                name="brand_name"
                label="Brand Name"
                error={errors?.brand_name?.message}
              />
            </RowStyled>
          </GridStyled>
          <GridStyled>
            <RowStyled>
              <TextField
                {...register('product_name', {
                  required: {
                    value: true,
                    message: 'Product Name is required',
                  },
                })}
                name="product_name"
                label="Product Name"
                error={errors?.product_name?.message}
              />
            </RowStyled>
          </GridStyled>
          <GridStyled>
            <RowStyled>
              <TextField
                {...register('website', {
                  required: {
                    value: true,
                    message: 'Website is required',
                  },
                })}
                name="website"
                label="Website"
                error={errors?.website?.message}
              />
            </RowStyled>
          </GridStyled>
          <GridStyled>
            <RowStyled>
              <TextFieldArea
                {...register('description', {
                  required: {
                    value: true,
                    message: 'Campaign Description is required',
                  },
                })}
                name="description"
                label="Campaign Description"
                error={errors?.description?.message}
              />
            </RowStyled>
          </GridStyled>
          <GridStyled>
            <RowStyled>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatepickerRange
                  // name="campaignPeriod"
                  label="Campaign Period"
                  placeholder=""
                  {...register('campaignPeriod', {
                    validate: ((value) => {
                      return value.filter(Boolean).length === 0 ? 'Campaign Period is required' : value;
                    }) as any,
                  })}
                  error={errors?.campaignPeriod?.message}
                />
              </MuiPickersUtilsProvider>
            </RowStyled>
            <RowStyled>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // name="recruitmentDeadline"
                  label="Recruitment Deadline"
                  placeholder=""
                  {...register('recruitment_end_date', {
                    required: {
                      value: true,
                      message: 'Recruitment Deadline is required',
                    },
                  })}
                  error={errors?.recruitment_end_date?.message}
                />
              </MuiPickersUtilsProvider>
            </RowStyled>
          </GridStyled>
          {errorRecruitment && <ErrorMessageStyled>{errorRecruitment}</ErrorMessageStyled>}
        </CampaignContainer>
        <HideButtonStyled ref={btnSubmitDetailRef} type="submit"></HideButtonStyled>
      </Form>
    </>
  );
}

export default CreateCampaignDetail;

const CampaignTitle = styled.h3`
  margin: 0;
  font: normal normal 500 16px/19px Helvetica Neue;
  letter-spacing: 0px;
  color: #5770c6;
`;
const Form = styled.form`
  width: 100%;
`;
const CampaignHeader = styled<any>(GridStyled)`
  margin-bottom: 31px;

  ${mobileQueryString} {
    display: none;
  }
`;

const CampaignContainer = styled.div<any>`
  max-width: 700px;
  padding-top: 32px;
  height: 70vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${mobileQueryString} {
    padding: 32px 20px;
  }
`;
const HideButtonStyled = styled.button<any>`
  display: none;
`;
