import React, { useState, useEffect } from 'react';
import './KolPerformance.css';
import InstagramIcon from '../../../../assets/svg/ic_instagram.svg';
import SortIcon from '../../../../assets/svg/ic_sort.svg';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../utils/constants';
import { roundToTwoDecimalPlaces } from '../ContentPerformance/ContentPerformance';
import { MetricData } from '../Overview/CampaignReportOverview';
import { ALL_TRANCHES_ID } from '../CampaignReportUI';

interface KolPerformanceProps {
  campaignId: string;
  data: MetricData[];
}

export const aggregateMetricData = (data: MetricData[], selectTranche: string): MetricData[] => {
  var inputData = Array.from(data);
  if (selectTranche !== ALL_TRANCHES_ID) {
    inputData = inputData.filter((item) => item.trancheId === selectTranche);
  }
  const grouped = inputData.reduce((acc, item) => {
    if (!acc[item.kolProfileId]) {
      acc[item.kolProfileId] = {
        ...item,
        content: '1',
      };
    } else {
      acc[item.kolProfileId].spend += item.spend;
      acc[item.kolProfileId].reach += item.reach;
      acc[item.kolProfileId].reachRate += item.reachRate;
      acc[item.kolProfileId].views += item.views;
      acc[item.kolProfileId].engagement += item.engagement;
      acc[item.kolProfileId].engagementRate += item.engagementRate;
      acc[item.kolProfileId].content = (parseInt(acc[item.kolProfileId].content) + 1).toString();
      acc[item.kolProfileId].reachRate = roundToTwoDecimalPlaces(acc[item.kolProfileId].reachRate);
      acc[item.kolProfileId].engagementRate = roundToTwoDecimalPlaces(acc[item.kolProfileId].engagementRate);
    }
    return acc;
  }, {} as Record<string, MetricData>);
  return Object.values(grouped);
};

export const formatNumber = (num: number) => {
  return num.toLocaleString('en-US');
};

const KolPerformance: React.FC<KolPerformanceProps> = ({ campaignId, data }) => {
  var [metricData, setMetricData] = useState<MetricData[]>([]);

  useEffect(() => {
    setMetricData(data);
  }, [data]);

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const sortFanbase = () => {
    const sortedData = [...metricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.fanbase - b.fanbase;
      } else {
        return b.fanbase - a.fanbase;
      }
    });
    setMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortSpend = () => {
    const sortedData = [...metricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.spend - b.spend;
      } else {
        return b.spend - a.spend;
      }
    });
    setMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortReach = () => {
    const sortedData = [...metricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.reach - b.reach;
      } else {
        return b.reach - a.reach;
      }
    });

    setMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortView = () => {
    const sortedData = [...metricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.views - b.views;
      } else {
        return b.views - a.views;
      }
    });

    setMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortEngagement = () => {
    const sortedData = [...metricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.engagement - b.engagement;
      } else {
        return b.engagement - a.engagement;
      }
    });

    setMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const history = useHistory();

  return (
    <div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="noImageSortableField">
                KOL <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortFanbase}>
                Fanbase <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                Tier <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">Platform</th>

              <th className="noImageSortableField">
                Content <img src={SortIcon} alt="sort" />
              </th>

              <th className="sortableField" onClick={sortSpend}>
                Spend <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortReach}>
                Reach <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                Reach Rate <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortView}>
                Views <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortEngagement}>
                Engagement <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                Engagement Rate <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                Action <img src={SortIcon} alt="sort" />
              </th>
            </tr>
          </thead>
          <tbody>
            {metricData.map((item, index) => (
              <tr key={index}>
                <td className="kol">
                  <img src={item.kolAvatar} alt="Instagram" /> {item.kolName}
                </td>
                <td>{formatNumber(item.fanbase)}</td>
                <td>{item.kolTier}</td>
                <td className="platform">
                  <img src={InstagramIcon} alt="Instagram" />
                </td>
                <td>{item.content}</td>

                <td>{formatNumber(item.spend)} US$</td>
                <td>{formatNumber(item.reach)}</td>
                <td>{item.reachRate} %</td>
                <td>{formatNumber(item.views)}</td>
                <td>{formatNumber(item.engagement)}</td>
                <td>{item.engagementRate} %</td>
                <td>
                  <button
                    className="editButton"
                    onClick={(e) => {
                      const path = generatePath(ROUTES.contentMetrics, { id: campaignId, kol_profile_id: item.kolProfileId });
                      history.push(path + '?mode=0');
                      e.stopPropagation();
                    }}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KolPerformance;
