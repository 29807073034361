import React, { useRef, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Message, MessageInput as LibMessageInput, Avatar } from '@chatscope/chat-ui-kit-react';
import * as R from 'ramda';
import { formatDistanceToNow } from 'date-fns';
import { useMessage } from './hooks';

import { ReactComponent as SaveIcon } from '../../../assets/svg/save.svg';
import { ReactComponent as AttachmentIcon } from '../../../assets/svg/attachment.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close_black_24dp.svg';
import VideoThumbnailUrl from '../../../assets/png/play-button.png';
import { useFileUpload } from 'use-file-upload';
import { mobileQueryString } from '../../../utils/responsive';
import parse from 'html-react-parser';
import Linkify from 'react-linkify';

export const MyMessage = React.forwardRef<any, any>(({ className, message = {} }, ref) => {
  const { time, text, bgColor, borderColor } = useMessage(message);
  const isString = typeof text === 'string';

  return (
    <MyMessageStyled
      ref={ref}
      className={className}
      model={{
        type: 'custom',
        direction: 'outgoing',
      }}
      bgcolor={bgColor}
      bordercolor={borderColor}
    >
      <Message.CustomContent>
        {!!time && <div className="time">{time}</div>}
        <div className="content">{isString ? <Linkify>{parse(text)}</Linkify> : text}</div>
      </Message.CustomContent>
    </MyMessageStyled>
  );
});

export const OwnerMessage = React.forwardRef<any, any>(({ className, message = {} }, ref) => {
  const { time, avatar, name, text, borderColor, bgColor } = useMessage(message);
  const isString = typeof text === 'string';

  return (
    <OwnerMessageStyled
      model={{
        sender: name,
        type: 'custom',
        direction: 'incoming',
        position: 'single',
      }}
      className={className}
      ref={ref}
      bordercolor={borderColor}
      bgcolor={bgColor}
    >
      <Message.CustomContent className="message-wrapper">
        <div className="avatar">
          <Avatar src={avatar} name={name} />
        </div>
        <div className="content-wapper">
          <div className="name">
            {name} {!!time && <span className="time">{time}</span>}
          </div>
          <div className="content">{isString ? <Linkify>{parse(text)}</Linkify> : text}</div>
        </div>
      </Message.CustomContent>
    </OwnerMessageStyled>
  );
});

export const ConversationHeaderContent = ({ owner }) => {
  const lastActivityTime = useMemo(() => {
    if (!owner.lastActivityTime) return null;
    return R.is(String, owner.lastActivityTime)
      ? owner.lastActivityTime
      : formatDistanceToNow(owner.lastActivityTime, { addSuffix: true });
  }, [owner]);

  return (
    <div>
      {!!owner.name && (
        <ConversationOwnerNameStyled>
          {owner.name}
          {!!lastActivityTime && <LastActiveTimeStyled>{lastActivityTime}</LastActiveTimeStyled>}
        </ConversationOwnerNameStyled>
      )}

      {!!owner.status && (
        <ConversationOwnerStatusStyled>
          <span>{owner.status}</span>
        </ConversationOwnerStatusStyled>
      )}
    </div>
  );
};

export const KolHeaderContent = ({ kolProfile }) => {
  return (
    <div>
      {!!kolProfile.name && <ConversationOwnerNameStyled>{kolProfile.name}</ConversationOwnerNameStyled>}

      {!!kolProfile.gender && (
        <ConversationOwnerNameStyled>
          <span>{kolProfile.gender}</span>
        </ConversationOwnerNameStyled>
      )}
    </div>
  );
};

const MEDIA_ACCEPTABLE = 'image/gif, image/jpeg, image/jpg, image/png, video/*';
export const MessageInput = React.forwardRef<any, any>((props, ref) => {
  const { placeholder } = props;
  // eslint-disable-next-line no-unused-vars
  const [_, selectFiles] = useFileUpload();
  const [files, setFiles] = useState<any>([]);
  const [msgInputValue, setMsgInputValue] = useState('');
  const inputRef = useRef<any>();

  const handleSend = (val) => {
    const text = val || msgInputValue;
    const fileAttchment = !!files && files.length ? files.map((f) => f.file) : undefined;
    props.onSend(text, fileAttchment).then(() => {
      setTimeout(() => {
        setFiles([]);
      }, 100);
    });
    setMsgInputValue('');
    inputRef.current.focus();
  };

  const handleSelectFile = () => {
    selectFiles({ multiple: true, accept: MEDIA_ACCEPTABLE }, (_files: any) => {
      setFiles((prevState) => [...prevState, ..._files]);
    });
  };

  return (
    <MessageInputStyled>
      {!!files && !!files.length && (
        <div className="attachment-preview">
          {(files || []).map((f, idx) => {
            const isVideo = isVideoChecker(f?.file?.type);
            const url = isVideo ? VideoThumbnailUrl : f?.source;
            return <img src={url} key={idx} alt="attachment preview" />;
          })}
          <button
            onClick={() => {
              setFiles([]);
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}

      <div className="input">
        <LibMessageInput
          ref={inputRef}
          onChange={(msg) => setMsgInputValue(msg)}
          value={msgInputValue}
          sendButton={false}
          attachButton={false}
          onSend={handleSend}
          placeholder={placeholder}
          style={{
            flexGrow: 1,
            borderTop: 0,
            flexShrink: 'initial',
          }}
        />
        <ButtonWrapperStyled>
          <ButtonStyled onClick={handleSelectFile} className="cs-button">
            <AttachmentIcon /> <UploadTitle>Upload media</UploadTitle>
          </ButtonStyled>
        </ButtonWrapperStyled>

        <ButtonWrapperStyled className="send-btn-wrapper">
          <SendButtonStyled
            className="cs-button"
            onClick={() => handleSend(msgInputValue)}
            disabled={(msgInputValue.length === 0 && !files) || (msgInputValue.length === 0 && files && files.length === 0)}
          >
            <span>Send</span>
          </SendButtonStyled>
        </ButtonWrapperStyled>
      </div>
    </MessageInputStyled>
  );
});

function isVideoChecker(type) {
  return (type || '').startsWith('video');
}

export const ChatWrapperStyled = styled.div<any>`
  height: ${(props) => props.height || '500px'};
  & .cs-main-container {
    border: none;
  }
  & .ps__thumb-y {
    background-color: #c1c1c1;
  }
  & .cs-conversation-header {
    padding: 0px 5px 33px 5px;
    background: none;
    border-bottom-color: #eeeeee;
    &__avatar {
    }
  }
  & .chat-header-avatar {
    border-radius: 3px;
    width: 46px;
    height: 46px;
    & img {
      border-radius: 3px;
    }
    &:hover > img {
      filter: brightness(100%);
    }
  }
  & .scrollbar-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  & .cs-message-input__content-editor,
  & .cs-message-input__content-editor-wrapper,
  & .cs-message-input__content-editor-container {
    background-color: unset;
  }
  & .cs-message.cs-message--incoming.cs-message--single:not(:first-child) {
    margin-top: 15px;
  }
  & .cs-overlay::before {
    background-color: rgb(248 248 248 / 38%);
  }

  ${mobileQueryString} {
    padding: 20px;
  }
`;

export const OwnerMessageStyled = styled<any>(Message)`
  .message-wrapper {
    display: flex;
  }
  & .avatar {
  }
  .cs-avatar {
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    border-radius: 5px;
    & img {
      border-radius: 5px;
    }
  }
  & .content-wapper {
    margin-left: 12px;
  }
  & .cs-message__content {
    border-radius: 5px !important;
    border: ${(props) => `1px solid ${props.bordercolor || '#eeeeee'}`};
    background-color: ${(props) => `${props.bgcolor || '#eeeeee'} !important`};
    font: normal normal normal 13px/15px Helvetica Neue;
    letter-spacing: 0px;
    color: #3e3e3e;
  }
  & .name {
    font: normal normal bold 14px/17px Helvetica Neue;
    color: #3e3e3e;
  }
  & .time {
    font: normal normal normal 10px/12px Helvetica Neue;
    color: #949494;
    margin-bottom: 4px;
    margin-left: 8px;
  }
`;

export const MyMessageStyled = styled<any>(Message)`
  & .cs-message__content-wrapper {
  }
  & .cs-message__content {
    border-radius: 5px !important;
    background-color: ${(props) => `${props.bgcolor || 'transparent'}  !important`};
    border: 1px solid ${(props) => `${props.bordercolor || '#eeeeee'}  !important`};
    font: normal normal normal 13px/15px Helvetica Neue;
    letter-spacing: 0px;
    color: #3e3e3e;
  }
  & .time {
    text-align: right;
    font: normal normal normal 10px/12px Helvetica Neue;
    letter-spacing: 0px;
    color: #949494;
    margin-bottom: 4px;
  }
`;

export const MessageInputStyled = styled.div<any>`
  border-top: 1px solid #eeeeee;
  padding-top: 21px;
  & .send-btn-wrapper {
    margin-left: 20px;
  }
  & .input {
    display: flex;
    flex-direction: row;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    & .cs-message-input,
    & .cs-button {
      padding: 0px;
    }
  }
  & .attachment-preview {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      margin-bottom: 5px;
      margin-right: 5px;
      border: 1px solid #d9d9d9;
    }
    & button {
      cursor: pointer;
      border: none;
      background: none;
    }
  }
  & .cs-message-input__content-editor {
    font: normal normal normal 14px/16px Helvetica Neue;
  }
  & .cs-message-input__content-editor[data-placeholder]:empty:before {
    font: normal normal normal 14px/16px Helvetica Neue;
    color: #949494;
  }
  & .cs-message-input {
    padding-top: 5px !important;
  }
`;

export const ButtonStyled = styled.button<any>`
  font: normal normal normal 12px/14px Helvetica Neue;
  color: #3e3e3e;
  display: inline-flex;
  & span {
    margin-left: 7px;
  }
`;

export const ButtonWrapperStyled = styled.div<any>`
  display: inline-flex;
  align-items: center;
`;
export const SendButtonStyled = styled.button<any>`
  background: #5770c6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font: normal normal 600 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 7px 15px !important;
  display: inline-flex;
  align-items: center;
  & span {
    margin-top: 3px;
  }
`;

export const LastActiveTimeStyled = styled.span<any>`
  color: #949494;
  font: normal normal normal 12px/14px Helvetica Neue;
  margin-left: 14px;
`;

export const ConversationOwnerNameStyled = styled.div<any>`
  color: #3e3e3e;
  font: normal normal 500 14px/17px Helvetica Neue;
  margin-bottom: 2px;
`;

export const ConversationOwnerStatusStyled = styled.div<any>`
  & span {
    border-radius: 5px;
    background: rgb(219, 16, 142, 0.1);
    font: normal normal normal 12px/14px Helvetica Neue;
    padding: 2px 5px;
    color: #5770c6;
    text-transform: capitalize;
  }
`;

export const SaveIconStyled = styled<any>((props) => {
  return (
    <div {...props}>
      <SaveIcon stroke="#5770C6" width="47px" height="47px" />
    </div>
  );
})`
  background: rgb(219, 16, 142, 0.1);
  cursor: pointer;
  border-radius: 5px;
  & svg {
    padding: 12px 14px;
  }
`;

export const MessageTemplateStyled = styled.div<any>`
  display: flex;
  flex-direction: column;
  & .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  & .image-group {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    & img {
      vertical-align: middle;
      width: 56px;
      height: 56px;
    }
  }
  & .cover {
    position: absolute;
    font: normal normal 600 14px/17px Helvetica Neue;
    color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  & .btn-wrapper {
    display: flex;
    align-items: flex-end;
  }
  & .row {
    flex-wrap: wrap;
    flex-direction: row;
  }
  & .col {
    flex-direction: column;
  }
`;

export const MessageRequestStyled = styled<any>(MessageTemplateStyled)`
  & .image-group {
    display: inline-flex;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
    & img {
      vertical-align: middle;
      width: 56px;
      height: 56px;
    }
  }
`;

export const MessageButtonStyled = styled.button<any>`
  display: inline-flex;
  background-color: ${(props) => props.bgcolor || '#5770c6'};
  border-radius: 5px;
  font: normal normal 600 12px/18px Helvetica Neue;
  color: ${(props) => props.textcolor || '#ffffff'};
  border: 0px;
  padding: 4px 8px;
  cursor: pointer;
  align-items: center;
  & span {
    margin-top: 3px;
    margin-left: 3px;
  }
  ${(props) =>
    !!props.ml &&
    css`
      margin-left: ${props.ml};
    `}
  ${(props) =>
    !!props.readonly &&
    css`
      pointer-events: none;
      cursor: none;
    `}
`;

const UploadTitle = styled.span<any>`
  ${mobileQueryString} {
    display: none;
  }
`;
