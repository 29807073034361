import React, { useState, useMemo, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { PRIVATE_ROUTE } from '../utils/constants';
import { mobileQueryString } from '../utils/responsive';
import useMobile from '../hooks/useMobile';
import useGuard from '../hooks/useGuard';
import useConvertCurrency from '../hooks/useConvertCurrency';
import { fortmatCurrencyTemplate } from '../utils/formatCurrency';

import TRANSACTION_HISTORY from '../queries/wallet/transactionHistory';

// components
import MainLayout from '../components/layouts/MainLayout/MainLayout';
import MainLayoutMobileSearch from '../components/layouts/MainLayout/MainLayoutMobileSearch';
import Typography from '../components/commons/Typography';
import AsideLeftWallet from '../components/campaigns/AsideLeftWallet';
import Button from '../components/commons/Button';
import Table from '../components/commons/Table';
import Stripe from '../components/stripe/Stripe';
import StripeForm from '../components/popUp/stripe/StripeForm';
import NoInsufficientCreditPopUp from '../components/popUp/topUp/noInsufficientCredit/NoInsufficientCreditPopUp';
import SuccessPopUp from '../components/popUp/topUp/success/SuccessPopUp';
import FailedTopUp from '../components/popUp/topUp/failed/FailedTopUp';
import { useEffect } from 'react';
import { getColumns } from '../components/wallet/data/column';
import MobileWallet from '../components/wallet/MobileWallet';
import { FEATURE_KEY, useFeatureFlag } from '../hooks/useFeatureFlag';
import ClientProfileContext from '../containers/ClientProfileProvider';

function MyWallet() {
  const history = useHistory();
  const isMobile = useMobile();
  const { user, onRefreshWallet, onRefreshUser, onRefreshProfile } = useGuard();
  const { exchangeRate } = useConvertCurrency() as any;
  const [isShowCreditTopUp, setIsShowCreditTopUp] = useState(false);
  const [isShowStripeForm, setIsShowStripeForm] = useState(false);
  const [isSuccessTopUp, setIsSuccessTopUp] = useState(false);
  const [isFailedTopUp, setIsFailedTopUp] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isStripeEnabled, setIsStripeEnabled] = useState(false);
  const { isFeatureFlagEnable, handleRefreshConfig, loading } = useFeatureFlag();
  const { currentProfile } = useContext(ClientProfileContext);

  const [getTransactionHistory, { data: transactionHistory }] = useLazyQuery(TRANSACTION_HISTORY, {
    fetchPolicy: 'no-cache',
    skip: !user.profile.id,
    variables: {
      kol_or_merchant_profile_id: currentProfile?.id,
      profile_type: 'MERCHANT',
    },
  } as any);

  const amountUSD = useMemo(() => {
    return Number((amount / exchangeRate).toFixed(2));
  }, [amount]);

  const walletValue = useMemo(() => {
    return ((user?.profile?.wallet || 0) / 100).toFixed(2);
  }, [user?.profile?.wallet]);

  const walletDisplayValue = useMemo(() => {
    return (((user?.profile?.wallet || 0) / 100) * exchangeRate).toFixed(user?.profile?.default_currency?.fraction_size ?? 0);
  }, [user?.profile?.wallet, user?.profile?.default_currency?.fraction_size, exchangeRate]);

  const columns = useMemo(() => getColumns(user, exchangeRate), [user, exchangeRate]);

  const gotoListCampaign = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const gotoListTeams = () => {
    history.push(PRIVATE_ROUTE.teams);
  };

  const handleOpenStripeForm = useCallback(() => {
    setIsShowCreditTopUp(false);
    setIsShowStripeForm(true);
  }, []);

  const handleCloseStripeForm = useCallback(
    async (status) => {
      if (status) {
        setIsShowStripeForm(false);
        setIsSuccessTopUp(true);
        getTransactionHistory();
      } else {
        setIsShowStripeForm(false);
        setIsFailedTopUp(true);
      }
    },
    [getTransactionHistory],
  );

  const handleTryTopUp = useCallback(() => {
    setIsFailedTopUp(false);
    setIsShowCreditTopUp(true);
  }, []);

  useEffect(() => {
    setIsStripeEnabled(isFeatureFlagEnable(FEATURE_KEY.stripe) === true);
  }, [loading, isFeatureFlagEnable]);

  useEffect(() => {
    getTransactionHistory();
  }, [getTransactionHistory]);

  useEffect(() => {
    onRefreshWallet();
  }, [onRefreshWallet]);

  useEffect(() => {
    if (!user.profile || !user.profile.wallet_id) {
      onRefreshUser();
      onRefreshProfile();
    }
  }, [onRefreshProfile, onRefreshUser, user.profile]);

  const renderHeaderCenter = (
    <MenuItemStyled onClick={gotoListCampaign}>
      <Typography text="Campaigns" color="#5770C6" fontSize="16px / 19px" />
    </MenuItemStyled>
  );

  const renderHeaderTeam = (
    <MenuItemStyled isActive={false} onClick={gotoListTeams}>
      <Typography text="Teams" color="#5770C6" fontSize="16px / 19px" fontWeight="400" />
    </MenuItemStyled>
  );

  const renderAsideRight = (
    <>
      <TopStyled>
        <MobileText>
          <Typography text="Credit Balance" fontSize="16px / 19px" fontWeight="500" color="#5770C6" />
          <Typography
            text={fortmatCurrencyTemplate(
              walletDisplayValue,
              user?.profile?.default_currency?.template,
              user?.profile?.default_currency?.symbol,
              user?.profile?.default_currency?.fraction_size,
            )}
            fontWeight="bold"
          />
        </MobileText>
        <MobileButton>
          {isStripeEnabled && <Button label="TOPUP CREDIT" width="149px" onClick={() => setIsShowCreditTopUp(true)} />}
        </MobileButton>
        <TopupCreditMobile onClick={() => setIsShowCreditTopUp(true)}>Topup Credit</TopupCreditMobile>
        <LineStyled />
      </TopStyled>

      {isMobile ? (
        <MobileWallet data={transactionHistory?.myTransactionHistory ?? []} user={user} exchangeRate={exchangeRate} />
      ) : (
        <Table
          isMiddleRow={true}
          data={transactionHistory?.myTransactionHistory ?? []}
          columns={columns}
          isHiddenPagination
          rowsPerPage={999999}
        />
      )}

      {isShowCreditTopUp && (
        <NoInsufficientCreditPopUp
          wallet={walletDisplayValue}
          walletUSD={walletValue}
          display_currency={user?.profile?.default_currency?.code}
          onClose={() => setIsShowCreditTopUp(false)}
          onSubmit={handleOpenStripeForm}
          amount={amount}
          handleAmount={setAmount}
        />
      )}

      {isShowStripeForm && amount > 0 && (
        <Stripe>
          <StripeForm
            onClose={() => setIsShowStripeForm(false)}
            value={amountUSD}
            user={user}
            handlePublish={handleCloseStripeForm}
            onRefreshWallet={onRefreshWallet}
          />
        </Stripe>
      )}

      {isSuccessTopUp && (
        <SuccessPopUp
          amount={amount}
          amountUSD={amountUSD}
          wallet={Number(walletDisplayValue)}
          walletUSD={Number(walletValue)}
          currency={user?.profile?.default_currency?.code}
          onClose={() => setIsSuccessTopUp(false)}
        />
      )}

      {isFailedTopUp && (
        <FailedTopUp
          wallet={Number(walletDisplayValue)}
          walletUSD={Number(walletValue)}
          currency={user?.profile?.default_currency?.code}
          onClose={() => setIsFailedTopUp(false)}
          handleTopUp={handleTryTopUp}
        />
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <MainLayoutMobileSearch asideLeft={<AsideLeftWallet />} asideRight={renderAsideRight} />
      ) : (
        <MainLayout
          hasButtonNew
          headerCenter={renderHeaderCenter}
          headerTeam={renderHeaderTeam}
          asideLeft={<AsideLeftWallet />}
          asideRight={renderAsideRight}
          isWalletPage={true}
        />
      )}
    </>
  );
}

export default MyWallet;

const TopStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 17px;
`;

const MobileButton = styled.div<any>`
  ${mobileQueryString} {
    display: none;
  }
`;

const MenuItemStyled = styled.div<any>``;

const MobileText = styled.div<any>`
  ${mobileQueryString} {
    & div:first-child span {
      font: normal normal 500 14px/16px Helvetica Neue !important;
      color: #5770c6 !important;
    }

    & div:nth-child(2) span {
      font: normal normal bold 22px/27px Helvetica Neue !important;
      color: #000 !important;
    }
  }
`;

const TopupCreditMobile = styled.div<any>`
  display: none;
  ${mobileQueryString} {
    display: block;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 10px 6px;
    color: #fff;
    background-color: #5770c6;
    border-radius: 5px;
    max-width: 115px;
    max-height: 30px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const LineStyled = styled.div<any>`
  display: none;

  ${mobileQueryString} {
    width: calc(100% + 40px);
    position: absolute;
    top: 120px;
    height: 1px;
    background-color: #eee;
    margin-left: -20px;
    display: block;
  }
`;
