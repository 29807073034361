import styled from 'styled-components';
import useConvertCurrency from '../../hooks/useConvertCurrency';
import { mobileQueryString } from '../../utils/responsive';

import Card from '../commons/Card';
import ContainerScroll from '../commons/ContainerScroll';
const DEFAULT_IMAGE = 'static/images/campaigns/placeholder.png';

const CampaignGrid = ({ data = [], user }) => {
  const { exchangeRate } = useConvertCurrency();

  const getTotalKOls = (card) => {
    const listWorkContract = card?.tranches.map((tranche) => tranche.work_contracts).flat();
    if (!Array.isArray(listWorkContract)) return 0;
    const countWorkItemApproved = listWorkContract.filter((ls) => {
      return ls.status.code === 'KOL_APPLIED';
    }).length;
    return countWorkItemApproved;
  };

  const getKOlAccepted = (card) => {
    const listWorkContract = card?.tranches.map((tranche) => tranche.work_contracts).flat();
    if (!Array.isArray(listWorkContract)) return 0;
    const countWorkItemApproved = listWorkContract.filter((ls) => {
      return ls.status.code === 'CLIENT_APPROVED';
    }).length;
    return countWorkItemApproved;
  };

  const getListPlatform = (rowData) => {
    const listPlatform = rowData.tranches.map((tranche) => {
      return tranche.social_platforms.filter(Boolean).map((s) => s.category_code);
    });
    return Array.from(new Set(listPlatform.flat()));
  };

  return (
    <ContainerScroll>
      <ContainerStyled>
        {data.map((card: any) => (
          <Card
            key={card.id}
            thumbnail={card.image_url || DEFAULT_IMAGE}
            title={card.name}
            status={{ start: new Date(card.start_date), now: new Date(card.end_date), label: card.status }}
            kols={{
              applied: getTotalKOls(card),
              accepted: getKOlAccepted(card),
            }}
            budget={String((card.budget_usd_cents / 100) * exchangeRate) || ''}
            platforms={getListPlatform(card)}
            budget_currency={user?.profile?.default_currency}
            id={card.id}
          />
        ))}
      </ContainerStyled>
    </ContainerScroll>
  );
};

export default CampaignGrid;

const ContainerStyled = styled.div<any>`
  --spacing: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(-1 * var(--spacing));
  margin-bottom: calc(-1 * var(--spacing));

  > * {
    box-sizing: border-box;
    width: 283px;
    height: 403px;
    margin-left: var(--spacing);
    margin-bottom: var(--spacing);
  }

  ${mobileQueryString} {
    margin-left: 0;
    margin-right: 0;

    & > * {
      width: 100%;
      height: auto;
      margin-left: 0;
      padding-bottom: 11px;
      margin-bottom: 10px;
      border-bottom: 1px solid #eeeeee !important;
    }
  }
`;
