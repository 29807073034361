import { useMemo } from 'react';
import { DEFAULT_AVATAR } from './../../../utils/constants';
import { formatDistanceToNow } from 'date-fns';
import * as R from 'ramda';

export function useMessage(message) {
  const time = useMemo(() => {
    if (!message.time) return null;
    return R.is(String, message.time) ? message.time : formatDistanceToNow(message.time, { addSuffix: true });
  }, [message]);

  const user = useMemo(() => {
    return message?.user || {};
  }, [message]);

  const name = useMemo(() => {
    return user?.name || '';
  }, [user]);

  const avatar = useMemo(() => {
    return message?.user?.avatar || DEFAULT_AVATAR;
  }, [message.user]);

  const text = useMemo(() => {
    return message?.text || '';
  }, [message]);

  const borderColor = useMemo(() => {
    return message?.style?.borderColor || '';
  }, [message]);

  const bgColor = useMemo(() => {
    return message?.style?.bgColor || '';
  }, [message]);

  const media = useMemo(() => {
    return message?.media || [];
  }, [message]);

  const thumbnail = useMemo(() => {
    let firstMedia = message?.media?.[0];
    if (!firstMedia) return null;
    firstMedia = { type: firstMedia.type, url: firstMedia.url };
    return firstMedia;
  }, [message]);

  return { time, user, avatar, name, text, borderColor, bgColor, media, thumbnail };
}
