import { gql } from '@apollo/client';
export default gql`
  mutation UpdateClientProfile(
    $id: uuid!
    $company_name: String = ""
    $country_code: String = ""
    $phone_number: String = ""
    $address: String = ""
    $address2: String = ""
    $region: String = ""
    $region2: String = ""
    $postal_code: String = ""
    $display_currency: String = ""
    $logo_file_id: uuid
    $description: String = ""
  ) {
    update_client_profile_by_pk(
      pk_columns: { id: $id }
      _set: {
        company_name: $company_name
        country_code: $country_code
        phone_number: $phone_number
        address: $address
        address2: $address2
        region: $region
        region2: $region2
        postal_code: $postal_code
        display_currency: $display_currency
        logo_file_id: $logo_file_id
        description: $description
      }
    ) {
      id
      company_name
      phone_number
      address
      address2
      country_code
      wallet_id
      display_currency
      country {
        code
        name
        phone_code
      }
      is_approved
      is_active
      region # state
      region2 # city
      default_currency {
        code
        name
        fraction_size
        symbol
        template
      }
      postal_code
      updated_at
      description
      logo_file {
        url
        id
      }
      client_survey {
        additional_info
        avg_budget_per_campaign_usd_cents
        avg_kols_per_campaign
        display_currency
        id
        is_currently_running_campaigns
        number_of_campaigns_annually
        updated_at
      }
    }
  }
`;
