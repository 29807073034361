export const MESSAGE_POSITION = {
  left: 'left',
  right: 'right',
};
export const MESSAGE_COLOR = {
  aprrove: '#5B71C0',
  reject: '#DA5736',
  bgReject: '#FFF0F1',
  transparent: 'transparent',
  default: '#eeeeee',
  white: '#ffffff',
};
