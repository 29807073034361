import { gql } from '@apollo/client';

export const UPDATE_WORK_ITEM_METRIC = gql`
  mutation UpdateWorkItemMetric(
    $spend_usd_cents: bigint!
    $post_on_date: timestamptz
    $reach: bigint!
    $views: bigint!
    $likes: bigint!
    $shares: bigint!
    $ig_post_comments: bigint!
    $ig_post_saves: bigint!
    $id: uuid!
  ) {
    update_work_item_metric_by_pk(
      pk_columns: { id: $id }
      _set: {
        spend_usd_cents: $spend_usd_cents
        post_on_date: $post_on_date
        reach: $reach
        views: $views
        likes: $likes
        shares: $shares
        ig_post_comments: $ig_post_comments
        ig_post_saves: $ig_post_saves
      }
    ) {
      id
    }
  }
`;

export const INSERT_WORK_ITEM_METRIC = gql`
  mutation InsertWorkItemMetric(
    $spend_usd_cents: bigint!
    $post_on_date: timestamptz
    $reach: bigint!
    $views: bigint!
    $likes: bigint!
    $shares: bigint!
    $ig_post_comments: bigint!
    $ig_post_saves: bigint!
    $work_item_id: uuid!
  ) {
    insert_work_item_metric_one(
      object: {
        ig_post_comments: $ig_post_comments
        ig_post_saves: $ig_post_saves
        likes: $likes
        work_item_id: $work_item_id
        views: $views
        spend_usd_cents: $spend_usd_cents
        shares: $shares
        reach: $reach
        post_on_date: $post_on_date
      }
    ) {
      id
    }
  }
`;

export const INSERT_KOL_CAMPAIGN_METRIC = gql`
  mutation InsertKolCampaignMetric($fan_base: bigint!, $campaign_id: uuid!, $kol_profile_id: uuid!, $platform_handle: String!) {
    insert_campaign_kol_metric_one(
      object: {
        campaign_id: $campaign_id
        fan_base: $fan_base
        kol_profile_id: $kol_profile_id
        platform_handle: $platform_handle
      }
    ) {
      id
    }
  }
`;

export const UPDATE_KOL_CAMPAIGN_METRIC = gql`
  mutation UpdateKolCampaignMetric($fan_base: bigint!, $id: uuid!) {
    update_campaign_kol_metric_by_pk(pk_columns: { id: $id }, _set: { fan_base: $fan_base }) {
      id
    }
  }
`;

export const GET_KOL_IG_ID = gql`
  query GetKolIGId($kol_profile_id: uuid!) {
    kol_social_platform(where: { kol_profile_id: { _eq: $kol_profile_id } }) {
      platform_username
    }
  }
`;
