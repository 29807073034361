import { formatValue } from 'react-currency-input-field';

export function formatCurrency(value, prefix, fractionSize) {
  const formattedValue = formatValue({
    value: (Number.isInteger(value) ? value : parseFloat(value).toFixed(fractionSize ?? 2)) + '',
    groupSeparator: ',',
    decimalSeparator: '.',
    prefix: prefix || '$',
  });
  return formattedValue;
}

export function formatCurrencyInput(value: any, fractionSize?: any): any {
  if (typeof value !== 'number' || isNaN(value)) return;
  const formattedValue = formatValue({
    value: (Number.isInteger(value) ? value : parseFloat(value as any).toFixed(fractionSize ?? 2)) + '',
    groupSeparator: ',',
    decimalSeparator: '.',
  });
  return formattedValue;
}

export function fortmatCurrencyTemplate(value, template, symbol, fractionSize) {
  let str = '';
  if (template) {
    str = template.replace('1', '');
    str = str.replace('$', symbol);
  }
  const formattedValue = formatValue({
    value: (Number.isInteger(value) ? value : parseFloat(value).toFixed(fractionSize ?? 2)) + '',
    groupSeparator: ',',
    decimalSeparator: '.',
    prefix: template?.[0] === '$' ? str : '',
    suffix: template?.[template.length - 1] === '$' ? str : '',
  });
  return formattedValue;
}
