import React from 'react';
import styled, { css } from 'styled-components';
// assets
import { ReactComponent as ArrowDownSvg } from '../../assets/svg/arrow-down.svg';
import { mobileQueryString } from '../../utils/responsive';
import LabelStyled from '../commons/Label';
import ContainerStyled from './Container';

const StepperRegister = (props) => {
  const { backToStep } = props;
  return (
    <StepperStyled>
      <ContainerStyled>
        <StepperContainerStyled>
          <ButtonIcon onClick={() => backToStep(props.active)}>
            <ArrowDownSvg />
            <LabelStyled>Back</LabelStyled>
          </ButtonIcon>
          <StepGroupStyled>
            <StepItemStyled {...props} step="1">
              <MarkButtonStyled>1</MarkButtonStyled>
              <LabelStyled>Company Details</LabelStyled>
            </StepItemStyled>
            <StepItemStyled {...props} step="2">
              <MarkButtonStyled>2</MarkButtonStyled>
              <LabelStyled>Survey</LabelStyled>
            </StepItemStyled>
          </StepGroupStyled>
        </StepperContainerStyled>
      </ContainerStyled>
    </StepperStyled>
  );
};

export default StepperRegister;

const StepperStyled = styled.div<any>`
  background-color: rgba(219, 16, 142, 0.1);
  height: 69px;
  width: 100%;
  > div {
    height: 100%;
  }

  ${mobileQueryString} {
    display: none;
  }
`;

const StepperContainerStyled = styled.div<any>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ButtonIcon = styled.button<any>`
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
  position: relative;
  svg {
    transform-origin: center;
    transform: rotate(90deg);
    width: 12px;
    height: 6px;
    stroke-width: 2px;
  }
  label {
    position: absolute;
    top: 50%;
    right: -100%;
    transform: translate(12.46px, -50%);
  }
`;

const StepGroupStyled = styled.div<any>`
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
`;

const MarkButtonStyled = styled.button<any>`
  background-color: #5770c6;
  border-radius: 5px;
  width: 26px;
  height: 26px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;

const StepItemStyled = styled.div<any>`
  position: relative;
  margin-right: 60px;
  display: flex;
  width: fit-content;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
  label {
    margin: 0 0 0 10px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
    flex: 1;
    color: #5770c6;
  }
  opacity: 1;
  ${(props) =>
    props.step > +props.active &&
    css`
      opacity: 0.5;
    `}
`;
