import { gql } from '@apollo/client';

const SEND_MESSAGE_WITH_ATTACHMENTS = gql`
  mutation SendMessageWithAttachments(
    $room_id: uuid = ""
    $plain_text: String = ""
    $attachments: [chat_room_message_attachment_insert_input!]! = {}
  ) {
    insert_chat_room_message_one(object: { room_id: $room_id, plain_text: $plain_text, attachments: { data: $attachments } }) {
      id
      room_id
      participant_id
      created_at
      plain_text
      attachments {
        file_id
        file {
          url
        }
      }
    }
  }
`;

export default SEND_MESSAGE_WITH_ATTACHMENTS;
