import { memo, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { fortmatCurrencyTemplate } from '../../utils/formatCurrency';
import { FORMAT_DATE_WALLET_DEFAULT } from '../../utils/constants';

import { ReactComponent as ArrowDropDownRightSVG } from '../../assets/svg/drop_down_right.svg';
import WalletDetail from '../../pages/WalletDetail';

const MobileWallet = ({ data, user, exchangeRate }) => {
  const [id, setId] = useState<any>(null);

  return (
    <Wrapper>
      {data.length > 0 &&
        data.map((item) => (
          <ItemWrapper key={item.id}>
            <FlexBox onClick={() => setId(item.id)}>
              <DescriptionWrapper>
                <TitleStyled title={item.description}>{item.description}</TitleStyled>
                <DateStyled>{format(new Date(item.timestamp), FORMAT_DATE_WALLET_DEFAULT)}</DateStyled>
              </DescriptionWrapper>
              <CurrencyWrapper>
                <CurrencyStyled>
                  {fortmatCurrencyTemplate(
                    (item.amount_usd_cents / 100) * exchangeRate,
                    user?.profile?.default_currency?.template,
                    user?.profile?.default_currency?.symbol,
                    user?.profile?.default_currency?.fraction_size,
                  )}
                </CurrencyStyled>
                <RightSVG />
              </CurrencyWrapper>
            </FlexBox>
            {id === item.id && <WalletDetail data={item} user={user} onGoBack={() => setId(null)} />}
          </ItemWrapper>
        ))}
    </Wrapper>
  );
};

export default memo(MobileWallet);

const Wrapper = styled.div<any>`
  margin-bottom: 20px;
`;

const ItemWrapper = styled.div<any>`
  padding-bottom: 12px;
  margin-top: 10px;
  border-bottom: 1px solid #eeeeee;
`;

const DescriptionWrapper = styled.div<any>`
  max-width: 70%;
`;

const FlexBox = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CurrencyWrapper = styled.div<any>`
  display: flex;
  align-items: center;
`;

const TitleStyled = styled.div<any>`
  font: normal normal 500 14px/17px Helvetica Neue;
  color: #0b0b0b;
  overflow: hidden;
  height: 51px;
`;

const DateStyled = styled.div<any>`
  font: normal normal 400 14px/16px Helvetica Neue;
  color: #949494;
  margin-top: 6px;
`;

const CurrencyStyled = styled.div<any>`
  font: normal normal bold 14px/17px Helvetica Neue;
  color: #0b0b0b;
  margin-top: 5px;
`;

const RightSVG = styled<any>(ArrowDropDownRightSVG)`
  margin-left: 11px;
  margin-top: 2px;
`;
