import { memo, useRef } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';

import { amountArray } from '../../../../utils/constants';
import { formatCurrency } from '../../../../utils/heplers';
import { formatCurrencyInput } from '../../../../utils/formatCurrency';

import PopUpLayout from '../../popUpLayout/PopUpLayout';
import TextFieldCurrency from '../../../commons/TextFieldCurrency';
import Button from '../../../commons/Button';

import { ReactComponent as CloseSVG } from '../../../../assets/svg/close_black_24dp.svg';

const InsufficientCreditPopup = ({
  walletDisplay,
  walletUSD,
  requireAmount,
  requireAmountUSD,
  display_currency,
  showAdditionalMessage,
  onClose,
  onSubmit,
  handleAmount,
  amount,
}) => {
  const divRef = useRef<any>(null);

  useClickAway(divRef, onClose);

  return (
    <PopUpLayout width="446px">
      <InsufficientCreditPopupWrapper ref={divRef}>
        <Header>
          <div>
            <BlurTitle>INSUFFICIENT CREDIT</BlurTitle>
            <Title>Top-up account</Title>
          </div>
          <CloseSVG onClick={onClose} />
        </Header>
        <ContentWrapper>
          <TopContent>
            <div>
              <BalanceLabel>CURRENT BALANCE</BalanceLabel>
              <BalanceValue>{`${formatCurrencyInput(walletDisplay)} ${display_currency ?? 'USD'}`}</BalanceValue>
              {display_currency !== 'USD' && <BalanceValueUSD>{`${formatCurrencyInput(walletUSD)} ${'USD'}`}</BalanceValueUSD>}
            </div>
            <div>
              <RequiredLabel>REQUIRED</RequiredLabel>
              <RequireValue>{`${formatCurrencyInput(requireAmount)} ${display_currency ?? 'USD'}`}</RequireValue>
              {display_currency !== 'USD' && (
                <RequireValueUSD>{`~${formatCurrencyInput(requireAmountUSD)} ${'USD'}`}</RequireValueUSD>
              )}
            </div>
          </TopContent>
          {showAdditionalMessage && (
            <AdditionalTitle>
              Your balance is insufficient to cover these additional payment requests. Please top-up your account.
            </AdditionalTitle>
          )}
          <AmountWrapper>
            <BalanceLabel>TOP-UP AMOUNT</BalanceLabel>
            <AmountBox>
              <AmoutTextField
                symbol={display_currency ?? 'USD'}
                placeholder="Type Amount"
                onChange={(e) => handleAmount(Number(formatCurrency(e.target.value || 0)))}
                value={amount}
              />
              <PickWrapper>
                {amountArray.length > 0 &&
                  amountArray.map((item) => (
                    <PickItem key={item} onClick={() => handleAmount((prevAmount) => prevAmount + item)}>{`+${item}`}</PickItem>
                  ))}
              </PickWrapper>
            </AmountBox>
          </AmountWrapper>
          <Button label="CHECKOUT" onClick={onSubmit} />
        </ContentWrapper>
      </InsufficientCreditPopupWrapper>
    </PopUpLayout>
  );
};

export default memo(InsufficientCreditPopup);

const InsufficientCreditPopupWrapper = styled.div<any>`
  padding: 28px 0;
`;

const Label = styled.div<any>`
  font-size: 14px;
  line-height: 17px;
  font-family: GilRoy-Bold;
  margin-bottom: 5px;
`;

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 27px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const BlurTitle = styled<any>(Label)`
  color: #949494;
  text-transform: uppercase;
`;

const Title = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  color: #000000;
`;

const ContentWrapper = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 35px 20px 0;
`;

const TopContent = styled.div<any>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  & > div {
    flex-basis: 50%;
    max-width: 50%;
  }
`;

const BalanceLabel = styled<any>(Label)`
  color: #5770c6;
  text-transform: uppercase;
`;

const BalanceValue = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #000000;
`;

const BalanceValueUSD = styled.div<any>`
  font: normal normal bold 14px/20px Helvetica Neue;
  color: #000000;
`;

const RequiredLabel = styled<any>(Label)`
  text-transform: uppercase;
  color: #949494;
`;

const RequireValue = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #949494;
`;

const RequireValueUSD = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #949494;
`;

const AmountWrapper = styled.div<any>`
  margin: 24px 0 30px;
`;

const AmountBox = styled.div<any>`
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
`;

const AmoutTextField = styled<any>(TextFieldCurrency)`
  & > div {
    margin: 0 20px;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
`;

const PickWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 10px;
`;

const PickItem = styled.div<any>`
  font: normal normal 500 18px/23px Helvetica Neue;
  background: rgba(87, 112, 198, 0.1);
  border-radius: 100px;
  color: #5770c6;
  padding: 9px 22px;
  cursor: pointer;
`;

const AdditionalTitle = styled.div<any>`
  font: normal normal bold 16px Inter;
  color: #5770c6;
  font-weight: 700;
  line-height: 132%;
  margin-top: 24px;
`;
