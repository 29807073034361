import React, { useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Avatar, ConversationHeader, MessageList } from '@chatscope/chat-ui-kit-react';
import { format } from 'date-fns';
import * as R from 'ramda';
import { useClickAway } from 'react-use';
import ModalMarkRejected from '../modals/ModalMarkRejected';
import ModalMarkApproved from '../modals/ModalMarkApproved';
import PropTypes from 'prop-types';
import {
  ChatWrapperStyled,
  ConversationHeaderContent,
  KolHeaderContent,
  MessageButtonStyled,
  SaveIconStyled,
} from '../commons/Chat/Components';
import { DEFAULT_AVATAR, FORMAT_DATE_DEFAULT } from '../../utils/constants';

import { ReactComponent as CheckCircleIcon } from '../../assets/svg/Icon-feather-check-circle.svg';
import { ReactComponent as XOctagonIcon } from '../../assets/svg/Icon-feather-x-octagon.svg';
import { ReactComponent as DropDownIcon } from '../../assets/svg/drop_down.svg';
import { mobileQueryString } from '../../utils/responsive';
import { Link } from 'react-router-dom';

export const STATUS = {
  PENDING_SUBMIT: {
    key: 0,
    label: 'Pending',
    value: 'PENDING_SUBMIT',
    color: '#f7d6d6',
  },
  SUBMITTED: {
    key: 1,
    label: 'Submitted',
    value: 'SUBMITTED',
    color: '#1bca73',
  },
  APPROVED: {
    key: 2,
    label: 'Approved',
    value: 'APPROVED',
    color: '#1bca73',
  },
  REJECTED: {
    key: 3,
    label: 'Rejected',
    value: 'REJECTED',
    color: '#da5736',
  },
};
const CampaignDeliverable = (props: any) => {
  const {
    owner = {},
    kolProfile,
    className,
    loading,
    height,
    onApprove,
    onReject,
    onCancelApprove,
    onCancelReject,
    data = [],
  } = props;
  const modalApproveRef = useRef<any>();
  const modalRejectRef = useRef<any>();

  const handleSave = () => {
    props.onSave && props.onSave();
  };

  const handleApprove = (request) => {
    modalApproveRef.current({
      onOk() {
        onApprove && onApprove(request);
      },
      onCancel() {
        onCancelApprove && onCancelApprove(request);
      },
    });
  };

  const handleReject = (request) => {
    modalRejectRef.current({
      onOk() {
        onReject && onReject(request);
      },
      onCancel() {
        onCancelReject && onCancelReject(request);
      },
    });
  };

  const requests = useMemo(() => {
    return data || [];
  }, [data]);

  const kol = useMemo(() => {
    const avatars = kolProfile.kol_file_metadata?.filter((file) => file.type === 'avatar');
    var avatar: string = '';
    if (avatars && avatars.length > 0) {
      avatar = avatars[0].file_metadata?.url;
    }
    return {
      avatar: avatar,
      name: kolProfile.first_name + ' ' + kolProfile.last_name,
      gender: kolProfile.gender?.toLowerCase(),
    };
  }, [kolProfile]);

  return (
    <ChatWrapperStyled className={className} height={height}>
      <ConversationHeaderStyled>
        <Avatar className="chat-header-avatar" src={owner.avatar || DEFAULT_AVATAR} name={owner.name} />
        <ConversationHeader.Content>
          <ConversationHeaderContent owner={owner} />
        </ConversationHeader.Content>
        <ConversationHeader.Actions>
          <SaveIconStyled onClick={handleSave} />
        </ConversationHeader.Actions>
      </ConversationHeaderStyled>
      <ConversationHeaderStyled>
        <Avatar className="chat-header-avatar" src={kol.avatar || DEFAULT_AVATAR} name={kol.name} />
        <ConversationHeader.Content>
          <KolHeaderContent kolProfile={kol} />
        </ConversationHeader.Content>
      </ConversationHeaderStyled>
      <MessageListStyled loading={loading} autoScrollToBottomOnMount={false} autoScrollToBottom={false}>
        {requests.map((r, idx) => (
          <Request
            key={idx}
            is="Message"
            message={r}
            link={r.link}
            onApprove={() => handleApprove(r)}
            onReject={() => handleReject(r)}
          ></Request>
        ))}
      </MessageListStyled>
      <ModalMarkRejected ref={modalRejectRef} />
      <ModalMarkApproved ref={modalApproveRef} />
    </ChatWrapperStyled>
  );
};

CampaignDeliverable.defaultProps = {};
export default CampaignDeliverable;

const Request = (props: any) => {
  const { message, link, onApprove, onReject } = props;
  const time = useMemo(() => {
    return R.is(String, message?.time) ? message?.time : format(message?.time, FORMAT_DATE_DEFAULT);
  }, [message]);

  const text = useMemo(() => {
    return message?.text || '';
  }, [message]);

  const label = useMemo(() => {
    const status = STATUS?.[message?.status];
    switch (message.status) {
      case STATUS.REJECTED.value: {
        return (
          <ButtonStyled readonly textcolor={status?.color} bgcolor="rgb(180, 180, 180, 0.1)">
            <XOctagonIcon stroke={status?.color} width="16px" height="16px" />{' '}
            <span className="label-right">{status?.label}</span>
          </ButtonStyled>
        );
      }
      case STATUS.APPROVED.value: {
        return (
          <ButtonStyled readonly textcolor={status?.color} bgcolor="rgb(180, 180, 180, 0.1)">
            <CheckCircleIcon stroke={status?.color} width="16px" height="16px" />{' '}
            <span className="label-right">{status?.label}</span>
          </ButtonStyled>
        );
      }

      default: {
        return <LabelStyled color={status?.color}>{status?.label}</LabelStyled>;
      }
    }
  }, [message]);

  const isMarkAsButton = useMemo(() => {
    return message?.status !== STATUS.REJECTED.value && message?.status !== STATUS.APPROVED.value;
  }, [message]);

  return (
    <RequestStyled>
      <div className="requirements">
        <div className="due-date">
          Due Date: <span>{time}</span>
        </div>
        <div className="content">{text}</div>
        {link !== undefined && (
          <div className="social-info">
            <div className="social-link-title">Social Post Link: </div>
            <Link className="social-link" to={{ pathname: link }} target="_blank" rel="noopener noreferrer">
              {link}
            </Link>
          </div>
        )}
      </div>
      <div className="action">
        {label}
        {isMarkAsButton && <MarkAsButton onCompeleted={() => onApprove && onApprove()} onReject={() => onReject && onReject()} />}
      </div>
    </RequestStyled>
  );
};

const MarkAsButton = ({ onCompeleted, onReject }) => {
  const ref = useRef<any>();
  const [open, setOpen] = useState(false);

  const handleCompeleted = () => {
    onCompeleted && onCompeleted();
  };

  const handleReject = () => {
    onReject && onReject();
  };

  useClickAway(ref, () => {
    if (!open) return;
    setOpen(false);
  });

  return (
    <MarkAsButtonStyled open={open} ref={ref}>
      <ButtonStyled onClick={() => setOpen(!open)}>
        <span className="label-left">Mark as</span> <DropDownIcon stroke="#5770C6" width="10px" height="4px" />
      </ButtonStyled>
      <div className="dropdown">
        <div onClick={handleCompeleted}>
          <CheckCircleIcon stroke="#3e3e3e" width="16px" height="16px" /> <span className="label">Completed</span>
        </div>
        <div onClick={handleReject}>
          <XOctagonIcon stroke="#3e3e3e" width="16px" height="16px" />
          <span className="label">Reject</span>
        </div>
      </div>
    </MarkAsButtonStyled>
  );
};

const MarkAsButtonStyled = styled.div<any>`
  position: relative;
  & .dropdown {
    position: absolute;
    right: -15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 30px #00000029;
    border-radius: 10px;
    z-index: 1;
    top: calc(100% + 10px);
    padding: 0px 7px;
    display: none;
    ${(props) =>
      !!props.open &&
      css`
        display: block;
      `};
    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 15%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #ffffff transparent;
    }
    & > div {
      display: inline-flex;
      align-items: center;
      width: 206px;
      height: 40px;
      font: normal normal 600 12px/15px Helvetica Neue;
      color: #3e3e3e;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      & span.label {
        margin-left: 15px;
        margin-top: 3px;
      }
    }
    & > div:not(:last-child) {
      border-bottom: 1px solid #eeeeee;
    }
  }
`;

const RequestStyled = styled.div<any>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid #eeeeee;
  padding: 18px 0px;
  & .due-date {
    font: normal normal normal 13px/15px Helvetica Neue;
    color: #3e3e3e;
    margin-bottom: 10px;
    & span {
      font: normal normal bold 13px/16px Helvetica Neue;
      color: #0b0b0b;
      text-transform: uppercase;
    }
  }
  & .content {
    font: normal normal normal 13px/15px Helvetica Neue;
    color: #3e3e3e;
  }
  & .social-info {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;

    & .social-link-title {
      font: normal normal normal 13px/15px Helvetica Neue;
      color: #3e3e3e;
      margin-right: 20px;
    }
    & .social-link {
      font: normal normal normal 13px/15px Helvetica Neue;
      color: #7b73f4;
    }
  }
  & .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 200px;
    padding-right: 13px;
    margin-left: 36px;
  }
  .requirements {
    width: 100%;
  }

  ${mobileQueryString} {
    flex-direction: column;
    & .due-date {
      display: flex;
      align-items: center;
      column-gap: 6px;

      & span {
        margin-top: 5px;
      }
    }
    & .content {
      margin-bottom: 5px;
    }
  }
`;

const ButtonStyled = styled<any>(MessageButtonStyled)`
  background-color: ${(props) => props.bgcolor || 'rgb(219, 16, 142, 0.1)'};
  font: normal normal 600 14px/17px Helvetica Neue;
  color: ${(props) => props.textcolor || '#5770C6'};
  padding: 7px 10px;
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  & .label-right {
    margin-left: 10px;
  }
  & svg {
    margin-left: 10px;
  }
`;

const LabelStyled = styled.div<any>`
  margin-right: 24px;
  font: normal normal 600 14px/17px Helvetica Neue;
  color: ${(props) => props.color || '#1bca73'};
`;

const ConversationHeaderStyled = styled<any>(ConversationHeader)`
  ${mobileQueryString} {
    display: none;
  }
`;

const MessageListStyled = styled<any>(MessageList)`
  ${mobileQueryString} {
    padding: 0 20px;
  }
`;
