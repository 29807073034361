import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/svg/drop_down_avatar.svg';
import { ReactComponent as SelectedIcon } from '../../../assets/svg/ic_selected_merchant.svg';
import useGuard from '../../../hooks/useGuard';

import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { Avatar } from '@mui/material';
import { MerchantProfile } from '../../../types/merchantProfile';

type TeamSelectorProps = {
  onClicked?: () => void;
  merchantProfiles: MerchantProfile[];
  onSelected?: (selectedProfile: MerchantProfile) => void;
  selectedProfile: MerchantProfile | undefined;
};

export const TeamSelector: React.FC<TeamSelectorProps> = ({ onClicked, merchantProfiles, onSelected, selectedProfile }) => {
  const { user } = useGuard();

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(
    containerRef,
    () => {
      if (showDropdownMenu) {
        setShowDropdownMenu(false);
      }
    },
    ['mousedown'],
  );

  const handleClick = () => {
    setShowDropdownMenu(!showDropdownMenu); // Toggle the visibility of the square
    if (onClicked) {
      onClicked(); // Call the onClicked prop if it exists
    }
  };

  const UserItem = ({ user, onSelect }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '200px',
          height: '60px',
          paddingLeft: '10px',
          paddingRight: '10px',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => {
          onSelect(user.id);
        }}
      >
        <Avatar
          alt={user.name}
          src={user.avatar}
          sx={{ width: 35, height: 35, marginLeft: '10px', marginRight: '10px' }}
          variant="rounded"
        />
        <span style={{ marginRight: '10px' }}>{user.name}</span>
        {selectedProfile?.id === user.id && <SelectedIcon style={{ marginLeft: 'auto' }} />}
      </div>
    );
  };

  const UserList = () => {
    const handleSelectMerchant = (userId) => {
      //setSelectedUserId(userId);
      const selectedProfile = merchantProfiles.find((profile) => profile.id === userId);
      if (!selectedProfile || !onSelected) {
        return;
      }
      setShowDropdownMenu(false);

      onSelected(selectedProfile);
    };

    const dividerColor = (id) => {
      const isLastItem = merchantProfiles[merchantProfiles.length - 1].id === id;
      if (isLastItem) {
        return 'white';
      } else {
        return '#DCDCDC';
      }
    };

    return (
      <div
        style={{
          maxHeight: '400px',
          overflowY: 'scroll',
          position: 'absolute',
          backgroundColor: 'white',
          border: '1px solid #ffffff', // Optional: adds a border, adjust color as needed
          borderRadius: '10px',
          marginTop: '10px',
          width: 'fit-content',
          scrollbarWidth: 'none',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        {merchantProfiles.map((user) => (
          <div key={user.id}>
            <UserItem key={user.id} user={user} onSelect={handleSelectMerchant} />
            <div
              style={{ marginLeft: '20px', marginRight: '20px', height: '1px', backgroundColor: `${dividerColor(user.id)}` }}
            ></div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }} ref={containerRef}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginRight: '20px', cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Avatar
          alt={selectedProfile?.name}
          src={selectedProfile?.avatar}
          sx={{ width: 40, height: 40, marginLeft: '10px', marginRight: '10px' }}
          variant="rounded"
        />
        <span style={{ marginRight: '10px', color: '#717171' }}>{selectedProfile?.name}</span>
        <AddIcon style={{ width: '10px', height: '10px' }} />
      </div>
      <PopoverStyled isShowPopover={showDropdownMenu}>
        <UserList />
      </PopoverStyled>
    </div>
  );
};

const StyledUserItem = styled.div`
  border-bottom: 1px solid #ccc;
`;

const PopoverStyled = styled.div<any>`
  display: ${(props) => (props.isShowPopover ? 'block' : 'none')};
`;
