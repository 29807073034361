import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as DropdownRightSVG } from '../../../../../assets/svg/drop_down_right.svg';

const DeliverablePopUp = ({ onClick, length }) => {
  return (
    <div onClick={onClick}>
      <InputStyled>
        <FlexBox>
          <NumberOfDeliverable>{length}</NumberOfDeliverable>
          <DeliverableTitle>Deliverables &amp; Due Dates</DeliverableTitle>
        </FlexBox>
        <DropdownRightSVG />
      </InputStyled>
    </div>
  );
};

DeliverablePopUp.propTypes = {
  onClick: PropTypes.func,
  length: PropTypes.number,
};

export default memo(DeliverablePopUp);

const InputStyled = styled.div<any>`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 18px;
`;

const FlexBox = styled.div<any>`
  display: flex;
`;

const NumberOfDeliverable = styled.div<any>`
  font: normal normal 400 14px/16px Helvetica Neue;
  color: #949494;
  margin-right: 13px;
`;

const DeliverableTitle = styled.div<any>`
  font: normal normal 400 14px/16px Helvetica Neue;
  color: #5770c6;
`;
