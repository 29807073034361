import { memo } from 'react';
import styled from 'styled-components';
import { mobileQueryString } from '../../../utils/responsive';

const PopUpLayOut = ({ children, width }) => {
  return (
    <>
      <PopUpWrapper width={width}>{children}</PopUpWrapper>
      <Overlay />
    </>
  );
};

export default memo(PopUpLayOut);

const PopUpWrapper = styled.div<any>`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px -1px 30px #00000029;
  width: ${(props) => props.width || 'auto'};
  z-index: 10000;

  ${mobileQueryString} {
    width: 100%;
    max-width: ${(props) => props.width || 'none'};
  }
`;

const Overlay = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;
