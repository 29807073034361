import { gql } from '@apollo/client';

const APPROVE_MESSAGE_REQUEST = gql`
  mutation ApproveMessageRequest($approval_request_id: uuid! = "", $approval_status_code: String = "") {
    update_chat_room_message_approval_request_by_pk(
      pk_columns: { id: $approval_request_id }
      _set: { approval_status_code: $approval_status_code }
    ) {
      id
      message_id
      created_at
      updated_at
      approval_status_code
    }
  }
`;

export default APPROVE_MESSAGE_REQUEST;
