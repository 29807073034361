import { gql } from '@apollo/client';

const SEND_PLAIN_TEXT_MESSAGE = gql`
  mutation SendPlainTextMessageMutation($room_id: uuid = "", $plain_text: String = "") {
    insert_chat_room_message_one(object: { room_id: $room_id, plain_text: $plain_text }) {
      id
      room_id
      participant_id
      created_at
      plain_text
    }
  }
`;

export default SEND_PLAIN_TEXT_MESSAGE;
