import { forwardRef, memo } from 'react';
import CampaignAcceptItem from './CampaignAcceptItem';

interface CampaignListOfKolMobileAcceptProps {
  data: any[];
  onGoToDeliverables: (item: any) => void;
  onGoToChat: (item: any) => void;
}

const CampaignListOfKolMobileAccept = forwardRef<HTMLDivElement, CampaignListOfKolMobileAcceptProps>(
  ({ data, onGoToDeliverables, onGoToChat }, ref) => {
    return (
      <div ref={ref}>
        {data.length > 0 &&
          data.map((item) => (
            <CampaignAcceptItem item={item} key={item.id} onGoToDeliverables={onGoToDeliverables} onGoToChat={onGoToChat} />
          ))}
      </div>
    );
  },
);

export default memo(CampaignListOfKolMobileAccept);

// CampaignListOfKolMobileAccept.propTypes = {
//   data: PropTypes.array,
//   onGoToDeliverables: PropTypes.func,
//   onGoToChat: PropTypes.func,
// };
