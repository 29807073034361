export function tierOfFanpage(fan_base: any) {
  if (fan_base >= 1000000) return 'Elite';
  if (fan_base >= 500000) return 'Mega';
  if (fan_base >= 100000) return 'Macro';
  if (fan_base >= 50000) return 'Mid B';
  if (fan_base >= 15000) return 'Mid C';
  if (fan_base >= 5000) return 'Micro';
  if (fan_base >= 1000) return 'Nano';
  return 'KOC';
}
