import { SVGProps } from 'react';

const AddMerchantSvg = ({ color, ...props }: SVGProps<SVGSVGElement>) => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5464_15155)">
      <path
        d="M49.0832 33.5833H33.5832V49.0833H28.4165V33.5833H12.9165V28.4166H28.4165V12.9166H33.5832V28.4166H49.0832V33.5833Z"
        fill="#5770C6"
      />
    </g>
    <defs>
      <clipPath id="clip0_5464_15155">
        <rect width="62" height="62" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AddMerchantSvg;
