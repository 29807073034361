import { memo } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseSVG } from '../../../../assets/svg/close_black_24dp.svg';
import { formatCurrencyInput } from '../../../../utils/formatCurrency';
import Button from '../../../commons/Button';

import PopUpLayout from '../../popUpLayout/PopUpLayout';

const SuccessPopUp = ({ amount, amountUSD, wallet, walletUSD, currency, onClose }) => {
  return (
    <PopUpLayout width="376px">
      <Header>
        <HeaderTitle>Success</HeaderTitle>
        <CloseSVG onClick={onClose} />
      </Header>
      <Content>
        <MoneyTopped>
          {formatCurrencyInput(amount)} {currency}
        </MoneyTopped>
        {currency !== 'USD' && <MoneyToppedSmall>~{formatCurrencyInput(amountUSD)} USD</MoneyToppedSmall>}
        <Describe>has been topped up to your account.</Describe>
        <BalanceLabel>NEW BALANCE</BalanceLabel>
        <BalanceContainer>
          <BalanceValue>
            {formatCurrencyInput(wallet)} {currency}
          </BalanceValue>
          {currency !== 'USD' && <BalanceValueSmall> ~{formatCurrencyInput(walletUSD)} USD</BalanceValueSmall>}
        </BalanceContainer>
        <Button label="CLOSE" onClick={onClose} />
      </Content>
    </PopUpLayout>
  );
};

export default memo(SuccessPopUp);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 11px;
  border-bottom: 1px solid #eee;

  svg {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  color: #000000;
`;

const Content = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 20px 20px 31px;
  margin-top: 8px;
  border-top: 1px solid #eee;
`;

const MoneyTopped = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #949494;
`;

const MoneyToppedSmall = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #949494;
`;

const Describe = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #0b0b0b;
  margin: 8px 0 28px;
`;

const BalanceLabel = styled.div<any>`
  color: #5770c6;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  font-family: Gilroy-Bold;
`;

const BalanceContainer = styled.div<any>`
  margin: 5px 0 40px;
`;

const BalanceValue = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #5770c6;
  margin: 5px 0 0px;
`;

const BalanceValueSmall = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #5770c6;
  margin: 5px 0 0px;
`;
