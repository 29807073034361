import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import UserProfileEdit from '../components/profile/UserProfileEdit';
import MainLayout from '../components/layouts/MainLayout/MainLayout';
import Typography from '../components/commons/Typography';
import AsideLeftContent from '../components/commons/AsideLeftContent';
import Button from '../components/commons/Button';
import { useHistory } from 'react-router-dom';
import useCommonApi from '../hooks/useCommon';
import useGuard from '../hooks/useGuard';
import { useMutation } from '@apollo/client';
import UPDATE_USER from '../queries/onboarding/updateMerchantUser';
import { uploadFileHandler } from '../utils/heplers';
import ContainerScroll from '../components/commons/ContainerScroll';
import { PRIVATE_ROUTE } from '../utils/constants';
import useMobile from '../hooks/useMobile';
import useToastError from '../hooks/useToastError';
import MainLayoutMobile from '../components/layouts/MainLayout/MainLayoutMobile';
import { mobileQueryString } from '../utils/responsive';

function UserProfile(props = {}) {
  const isMobile = useMobile();
  const { listCategory, onRefreshListCategory } = useCommonApi() as any;
  const { user: currentUser, onUpdateUser } = useGuard();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [updateUserProfile] = useMutation(UPDATE_USER);
  const { toastError } = useToastError();

  const buttonFormSubmitRef = useRef<any>();

  useEffect(() => {
    if (Object.keys(listCategory).length === 0) {
      onRefreshListCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (formData) => {
    let uploadAvatar;
    try {
      setIsLoading(true);
      if (formData.avatar && formData.avatar !== currentUser?.picture?.url) {
        uploadAvatar = await uploadFileHandler(formData.avatar);
      }
      delete formData.avatar;
      const requestParams = {
        id: currentUser?.id,
        picture_id: uploadAvatar?.id || currentUser?.picture?.id || '',
        given_name: formData.given_name || currentUser?.given_name,
        family_name: formData.family_name || currentUser?.family_name,
        phone_number: formData.phone_number.phoneNumber || currentUser?.phone_number,
      };

      if (!requestParams.picture_id) {
        delete requestParams.picture_id;
      }
      const result = await updateUserProfile({
        variables: {
          ...requestParams,
        },
      });
      if (result && result?.data?.update_user_by_pk) {
        onUpdateUser(result?.data?.update_user_by_pk);
      }
      setIsLoading(false);
      history.push(PRIVATE_ROUTE.campaigns);
    } catch (error) {
      setIsLoading(false);
      toastError('Something wrongs, please try again');
    }
  };

  const triggerButtonSubmit = () => {
    if (buttonFormSubmitRef.current && !isLoading) {
      buttonFormSubmitRef.current.click();
    }
  };

  const handleDiscard = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const renderAsideLeft = (
    <AsideLeftContent
      asideTitle={<Typography fontSize={isMobile ? '34px/41px' : '28px/34px'} text="Edit Profile" fontWeight="bold" />}
    />
  );

  const mainLayout = () => {
    return (
      <>
        <ProfileHeaderStyled>
          <TabStyled>
            <TabItemStyled active>Profile Information</TabItemStyled>
          </TabStyled>
          <ButtonActionStyled>
            <Button
              disabled={isLoading}
              type="button"
              width="110px"
              variant="secondary"
              label="DISCARD"
              onClick={handleDiscard}
            />
            <Button
              disabled={isLoading}
              label={isLoading ? 'Please wait' : 'SAVE'}
              type="button"
              width="131px"
              variant="primary"
              onClick={triggerButtonSubmit}
            />
          </ButtonActionStyled>
        </ProfileHeaderStyled>
        <ContainerScroll>
          <UserProfileEdit
            onHandleFormSubmit={handleFormSubmit}
            listCategory={listCategory}
            user={currentUser}
            buttonFormSubmitRef={buttonFormSubmitRef}
          />
        </ContainerScroll>
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <MainLayoutMobile
          onCancel={handleDiscard}
          onSave={triggerButtonSubmit}
          asideLeft={renderAsideLeft}
          asideRight={mainLayout()}
        />
      ) : (
        <MainLayout hasButtonNew asideLeft={renderAsideLeft} asideRight={mainLayout()} />
      )}
    </>
  );
}

export default UserProfile;

const ProfileHeaderStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 46px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
  box-sizing: content-box;

  ${mobileQueryString} {
    padding-bottom: 20px;
    height: auto;
  }
`;

const ButtonActionStyled = styled.div<any>`
  display: flex;
  align-items: center;

  ${mobileQueryString} {
    display: none;
  }
`;

const TabStyled = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
  height: 100%;
`;

const TabItemStyled = styled.li<any>`
  position: relative;
  display: block;
  cursor: pointer;
  font: normal normal normal 16px/18px 'Helvetica Neue';
  color: #949494;
  padding-top: 7px;
  &:not(:last-child) {
    margin-right: 31.5px;
  }
  ${(props) =>
    !!props.active &&
    css`
      color: #5770c6;
      font-weight: 500;
      &::after {
        content: '';
        border-radius: 5px 5px 0px 0px;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: -31px;
        left: 0;
        display: block;
        background: #5770c6 0% 0% no-repeat padding-box;
      }
    `};

  ${mobileQueryString} {
    ${(props: any) =>
      !!props.active &&
      css`
        &::after {
          bottom: -20px;
        }
      `}
  }
`;
