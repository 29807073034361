import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput as LibMessageInput,
  Avatar,
  ConversationHeader,
} from '@chatscope/chat-ui-kit-react';
import PropTypes from 'prop-types';
import {
  ChatWrapperStyled,
  SaveIconStyled,
  ConversationHeaderContent,
  MyMessage,
  OwnerMessage,
  MessageInput,
} from './Components';
import { MESSAGE_POSITION, MESSAGE_COLOR } from './constant';
import { MessageApproved, MessageRejected, MessageMedia, MessageRequest } from './Messages';
import { DEFAULT_AVATAR } from '../../../utils/constants';
import { mobileQueryString } from '../../../utils/responsive';

function Chat(props) {
  const { conversation, owner = {}, height, className, loading = false } = props;
  const dataSource = useMemo(() => conversation || [], [conversation]);

  const handleSave = () => {
    props.onSave && props.onSave();
  };

  const sanitizeMessage = (message) => {
    const trimmedMessage = message.trim();
    const decodedMessage = trimmedMessage
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&nbsp;/g, ' ')
      .replace(/&#039;/g, "'");
    return decodedMessage;
  };

  const handleSendMessage = (message, files) => {
    const sanitizedMessage = sanitizeMessage(message);
    return props.onSendMessage && props.onSendMessage(sanitizedMessage.trim(), files);
  };

  return (
    <ChatWrapperStyled height={height} className={className}>
      <MainContainer>
        <ChatContainer>
          <ConversationHeaderStyled>
            <Avatar className="chat-header-avatar" src={owner.avatar || DEFAULT_AVATAR} name={owner.name} />
            <ConversationHeader.Content>
              <ConversationHeaderContent owner={owner} />
            </ConversationHeader.Content>
            <ConversationHeader.Actions>
              <SaveIconStyled onClick={handleSave} />
            </ConversationHeader.Actions>
          </ConversationHeaderStyled>
          <MessageList loading={loading}>
            {dataSource.map((message, idx) => {
              if (message.position === MESSAGE_POSITION.left) {
                return <OwnerMessage key={idx} is={Message} message={message} />;
              }
              return <MyMessage key={idx} is={Message} message={message} />;
            })}
          </MessageList>
          <MessageInput is={LibMessageInput} placeholder="Start typing a message.." onSend={handleSendMessage} />
        </ChatContainer>
      </MainContainer>
    </ChatWrapperStyled>
  );
}

Chat.propTypes = {
  // the owner is information will be display in header of chatbox
  owner: PropTypes.shape({
    idConversation: PropTypes.string,
    name: PropTypes.string,
    avatar: PropTypes.string,
    lastActivityTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    status: PropTypes.string,
  }),
  conversation: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        avatar: PropTypes.string,
      }),
      style: PropTypes.shape({
        borderColor: PropTypes.string, // border color of a message
        bgColor: PropTypes.string, // background color of a message
      }),
    }),
  ),
  height: PropTypes.string,
  loading: PropTypes.bool,

  // function
  onSelectFile: PropTypes.func,
  onSendMessage: PropTypes.func,
  onSave: PropTypes.func,
};

Chat.defaultProps = {};

export default Chat;
export { MESSAGE_COLOR, MESSAGE_POSITION, MessageApproved, MessageRejected, MessageMedia, MessageRequest };

const ConversationHeaderStyled = styled<any>(ConversationHeader)`
  ${mobileQueryString} {
    display: none;
  }
`;
