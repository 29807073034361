import { gql } from '@apollo/client';

export default gql`
  query myTransactionHistory($kol_or_merchant_profile_id: uuid!, $profile_type: ProfileType) {
    myTransactionHistory(kol_or_merchant_profile_id: $kol_or_merchant_profile_id, profile_type: $profile_type) {
      id
      amount_usd_cents
      debit
      credit
      initiated_by
      description
      timestamp
      transaction_type
      external_transaction_id
      balance_usd_cents
    }
  }
`;
