import React, { useEffect, useState } from 'react';
import './ContentPerformance.css'; // Import the CSS file
import InstagramIcon from '../../../../assets/svg/ic_instagram.svg';
import TiktokIcon from '../../../../assets/svg/social-icon-white/tiktok.svg';
import SortIcon from '../../../../assets/svg/ic_sort.svg';
import { format } from 'date-fns';
import { formatNumber } from '../KolPerformance/KolPerformance';
import { MetricData } from '../Overview/CampaignReportOverview';
import { SocialPlatform } from '../../../../types';

export interface TableData {
  platform: string;
  content: string;
  link: string;
  description: string;
  kol: string;
  fanbase: number;
  tier: string;
  postOn: Date | null;
  spend: number;
  reach: number;
  reachRate: number;
  view: number;
  engagement: number;
  engagementRate: number;
  kolProfileId: string;
  comment: number;
  share: number;
  save: number;
  workItemId: string;
  //work_item_metric id
  id: string;
  //campaign_kol_metric id
  campaign_kol_metric_id: string;
  kolAvatar: string;
}

interface ContentPerformancePerformanceProps {
  data: MetricData[];
}

export const roundToTwoDecimalPlaces = (num: number): number => {
  return parseFloat(num.toFixed(2));
};

export const getTierFromFanbase = (fanbase: number): string => {
  if (fanbase >= 1000000) return 'Elite';
  if (fanbase >= 500000) return 'Mega';
  if (fanbase >= 100000) return 'Macro';
  if (fanbase >= 50000) return 'Mid B';
  if (fanbase >= 15000) return 'Mid C';
  if (fanbase >= 5000) return 'Micro';
  if (fanbase >= 1000) return 'Nano';
  return 'KOC';
};

export const ContentPerformance: React.FC<ContentPerformancePerformanceProps> = ({ data }) => {
  const [filteredMetricData, setFilterMetricData] = useState<MetricData[]>(data);

  useEffect(() => {
    setFilterMetricData(data);
  }, [data]);

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const sortFanbase = () => {
    const sortedData = [...filteredMetricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.fanbase - b.fanbase;
      } else {
        return b.fanbase - a.fanbase;
      }
    });

    setFilterMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortPostOn = () => {
    const sortedData = [...filteredMetricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return (a.postOn?.getMilliseconds() ?? 0) - (b.postOn?.getMilliseconds() ?? 0);
      } else {
        return (b.postOn?.getMilliseconds() ?? 0) - (a.postOn?.getMilliseconds() ?? 0);
      }
    });

    setFilterMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortSpend = () => {
    const sortedData = [...filteredMetricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.spend - b.spend;
      } else {
        return b.spend - a.spend;
      }
    });

    setFilterMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortReach = () => {
    const sortedData = [...filteredMetricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.reach - b.reach;
      } else {
        return b.reach - a.reach;
      }
    });

    setFilterMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortView = () => {
    const sortedData = [...filteredMetricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.views - b.views;
      } else {
        return b.views - a.views;
      }
    });

    setFilterMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortEngagement = () => {
    const sortedData = [...filteredMetricData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.engagement - b.engagement;
      } else {
        return b.engagement - a.engagement;
      }
    });

    setFilterMetricData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="noImageSortableField">Platform</th>

              <th className="noImageSortableField">
                Content <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                Link <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                KOL <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortFanbase}>
                Fanbase <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                Tier <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortPostOn}>
                Post On <img src={SortIcon} alt="sort" />
              </th>

              <th className="sortableField" onClick={sortSpend}>
                Spend <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortReach}>
                Reach <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                Reach Rate <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortView}>
                Views <img src={SortIcon} alt="sort" />
              </th>
              <th className="sortableField" onClick={sortEngagement}>
                Engagement <img src={SortIcon} alt="sort" />
              </th>
              <th className="noImageSortableField">
                Engagement Rate <img src={SortIcon} alt="sort" />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredMetricData.map((item, index) => (
              <tr key={index}>
                <td className="platform">
                  {item.platform === SocialPlatform.Instagram ? (
                    <img src={InstagramIcon} alt="Instagram" />
                  ) : (
                    <img src={TiktokIcon} alt="Tiktok" />
                  )}
                </td>
                <td>{item.content}</td>
                <td className="link">
                  <a href={item.contentLink}>{item.description}</a>
                </td>
                <td className="kol">
                  <img src={item.kolAvatar} alt="Instagram" /> {item.kolName}
                </td>
                <td>{formatNumber(item.fanbase)}</td>
                <td>{item.kolTier}</td>
                <td>{item.postOn ? format(new Date(item.postOn), 'do MMM, yy') : '--'}</td>
                <td>{formatNumber(item.spend)} US$</td>
                <td>{formatNumber(item.reach)}</td>
                <td>{item.reachRate} %</td>
                <td>{formatNumber(item.views)}</td>
                <td>{formatNumber(item.engagement)}</td>
                <td>{item.engagementRate} %</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination
                rowsPerPage={10}
                onChangePage={() => { }}
                onChangePerPage={() => { }}
                currentPage={0}
                totalPage={10}
                totalRecord={100}
            /> */}
    </div>
  );
};

export default ContentPerformance;
