import React, { forwardRef, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Checkbox from './Checkbox';
import LabelStyled from './Label';
import ErrorMessageStyled from './ErrorMessage';
import { mobileQueryString } from '../../utils/responsive';

const CheckboxList = forwardRef<any, any>((props, ref) => {
  const { name, onChange, className, label, horizontal, rowsPerCol, isCircle, error } = props;
  const [checkedState, setCheckedState] = useState<any>({});
  const [options, setOptions] = useState<any>([]);
  const [force, setForce] = useState(new Date().getTime());
  const canDispatch = useRef<any>(false);
  const inputRef = useRef<any>();
  const valueRef = useRef<any>([]);

  useEffect(() => {
    const optionMapping = options.reduce((acc, cur) => {
      acc[cur.value] = { ...cur };
      return acc;
    }, {});
    setCheckedState({ ...optionMapping });
  }, [options]);

  useEffect(() => {
    const value = Object.values(checkedState);
    valueRef.current = value;
    if (!canDispatch.current || !value.length) return;
    canDispatch.current = false;
    const evt = new Event('input', { bubbles: true, cancelable: true });
    Object.defineProperty(evt, 'target', {
      writable: false,
      value: {
        value: value,
        name,
      },
    });
    onChange(evt);
  }, [checkedState, name, onChange]);

  const handleChange = (e) => {
    canDispatch.current = true;
    const name = e.target.name;
    const checked = e.target.checked;
    setCheckedState((prev) => ({
      ...prev,
      [name]: { ...prev[name], checked },
    }));
  };

  useEffect(() => {
    if (!inputRef.current) return;
    // Overwrite setter, if react-form-hook set value. we can catch the value from it and do something
    Object.defineProperty(inputRef.current, 'value', {
      set(newValue) {
        setOptions(newValue);
        setForce(new Date().getTime());
      },
      get() {
        return valueRef.current;
      },
    });
  }, []);

  return (
    <WrapperStyled className={className}>
      <LabelStyled htmlFor={name}>{label}</LabelStyled>
      <input
        ref={(el) => {
          (ref as any)(el);
          inputRef.current = el;
        }}
        type="hidden"
      />
      <CheckBoxGroupStyled key={force} horizontal={horizontal} rowsPerCol={rowsPerCol}>
        {options.map((opt, index) => (
          <Checkbox
            isCircle={isCircle}
            key={index}
            label={opt.name ?? capitalizeFirstLetter(opt.value)}
            name={opt.value}
            onChange={handleChange}
            defaultValue={opt.checked}
          />
        ))}
      </CheckBoxGroupStyled>
      {error && <ErrorMessageStyled>{error}</ErrorMessageStyled>}
    </WrapperStyled>
  );
});

function capitalizeFirstLetter(input: string): string {
  if (input.length === 0) return input;
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

CheckboxList.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.array,
  //
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  rowsPerCol: PropTypes.number,
};

CheckboxList.defaultProps = {
  isCircle: true,
  horizontal: false,
  error: '',
  values: [],
  rowsPerCol: 0,
  direction: 'column',
};

export default CheckboxList;

const CheckBoxGroupStyled = styled.div<any>`
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: column;
  --gap: 4px;
  --rowsPerCol: ${(props) => props.rowsPerCol || 0};
  ${(props) =>
    props.rowsPerCol > 0
      ? css`
          display: grid;
          grid-template-columns: repeat(var(--rowsPerCol), 83px);
          grid-gap: 9px 40px;
        `
      : props.horizontal
      ? css`
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px 30px;
          & > div {
            min-width: 27%;
          }
        `
      : css`
          & > * + * {
            margin-top: var(--gap);
          }
        `};

  ${mobileQueryString} {
    display: grid;
    grid-template-columns: repeat(auto-fill, 27%);
  }
`;

const WrapperStyled = styled.div<any>`
  display: inline-flex;
  flex-direction: column;
  & > * + * {
    margin-top: 3px;
  }

  ${mobileQueryString} {
    width: 100%;
  }
`;
