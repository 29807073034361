import { gql } from '@apollo/client';

export const GET_METRICS_IN_CAMPAIGN = gql`
  query MerchantCampaignDetail($id: uuid!) {
    campaign_by_pk(id: $id) {
      tranches {
        id
        work_contracts {
          id
          work_items {
            id
            link
            deliverable_spec {
              content_type_code
              requirement_description
              content_type {
                name
              }
            }
            work_item_metrics {
              spend_usd_cents
              post_on_date
              reach
              views
              likes
              shares
              ig_post_comments
              ig_post_saves
              ig_story_sticker_taps
              ig_story_link_clicks
              ig_story_replies
              work_item_id
              id
            }
            status_code
          }
          created_at
          kol_profile {
            id
            first_name
            last_name
            kol_file_metadata {
              type
              file_metadata {
                url
                id
              }
              updated_at
            }
            social_platforms {
              platform_username
            }
          }
        }
        name
      }
    }
  }
`;

export const GET_CONTENT_TYPE = gql`
  query ContentTypes {
    content_type(where: { is_active: { _eq: true } }) {
      code
      name
    }
  }
`;

export const GET_CAMPAIGN_KOL_METRIC = gql`
  query GetCampaignKolMetric($campaign_id: uuid!) {
    campaign_kol_metric(where: { campaign_id: { _eq: $campaign_id } }) {
      campaign_id
      kol_profile_id
      fan_base
      platform_handle
      id
    }
  }
`;
