import React, { useState, useEffect, useMemo } from 'react';
import MainLayout from '../../components/layouts/MainLayout/MainLayout';
import AsideLeftCampaignDetail from '../../components/campaigns/AsideLeftCampaignDetail';
import CampaignChatComponent from '../../components/campaigns/CampaignChat';
import * as R from 'ramda';
import {
  MESSAGE_POSITION,
  MESSAGE_COLOR,
  MessageApproved,
  MessageRejected,
  MessageRequest,
  MessageMedia,
} from '../../components/commons/Chat';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import Typography from '../../components/commons/Typography';
import { MESSAGE_APPROVAL_REQUEST, PRIVATE_ROUTE, ROUTES } from '../../utils/constants';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import GET_WORK_CONTRACT_MESSAGES from '../../queries/kol/deliverablesDiscussions/getWorkContractMessages';
import useGuard from '../../hooks/useGuard';
import SEND_PLAIN_TEXT_MESSAGE from '../../queries/kol/deliverablesDiscussions/sendPlainTextMessage';
import { uploadFileHandler } from '../../utils/heplers';
import SEND_MESSAGE_WITH_ATTACHMENTS from '../../queries/kol/deliverablesDiscussions/sendMessageWithAttachments';
import APPROVE_MESSAGE_REQUEST from '../../queries/kol/deliverablesDiscussions/approveMessageRequest';
import METCHANT_CAMPAIGN_DETAIL from '../../queries/kol/deliverablesDiscussions/merchantCampaignDetail';
import { useToasts } from 'react-toast-notifications';
import commonMessages from '../../utils/messages';
import useMobile from '../../hooks/useMobile';

import { formatDistanceToNow } from 'date-fns';
import MainLayoutGoBack from '../../components/layouts/MainLayout/MainLayoutGoBack';

const CampaignChat = () => {
  let { id: campaignID, contractID } = useParams() as any;
  const isMobile = useMobile();
  const [messages, setMessages] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const { user } = useGuard();
  const { addToast } = useToasts();

  const { data: workContractMessages, loading: isWorkContractMessagesLoading } = useSubscription(GET_WORK_CONTRACT_MESSAGES, {
    variables: {
      work_contract_id: contractID,
    },
  });

  const { data: mechantCampaignDetailData } = useQuery(METCHANT_CAMPAIGN_DETAIL, {
    variables: {
      id: campaignID,
    },
  });

  const [fetchSendMessage, { loading: isSendMessageLoading }] = useMutation(SEND_PLAIN_TEXT_MESSAGE);
  const [fetchSendMessageWithAttachments, { loading: isSendMessageWithAttachmentsLoading }] =
    useMutation(SEND_MESSAGE_WITH_ATTACHMENTS);
  const [fetchApproveMesssageRequest] = useMutation(APPROVE_MESSAGE_REQUEST);

  const roomId = workContractMessages?.work_contract_by_pk?.chat_room_relationship?.chat_room?.id;
  const kolProfile = workContractMessages?.work_contract_by_pk?.kol_profile;

  // owner of Chatbox, this infomation will be showing in header of chatbox
  const roomOwner = useMemo(() => {
    if (!mechantCampaignDetailData || !mechantCampaignDetailData.campaign_by_pk) return {};

    const campaignDetail = mechantCampaignDetailData.campaign_by_pk;
    return {
      name: campaignDetail?.client_profile?.company_name, // string or nullable
      avatar: campaignDetail?.client_profile?.logo_file?.url, // string or nullable
      lastActivityTime: formatDistanceToNow(new Date(campaignDetail?.created_at)),
      status: campaignDetail?.status.toLowerCase(),
    };
  }, [mechantCampaignDetailData]);

  const campaignProfile = useMemo(() => {
    return {
      name: mechantCampaignDetailData?.campaign_by_pk?.name,
      image_url: mechantCampaignDetailData?.campaign_by_pk?.image_url,
    };
  }, [mechantCampaignDetailData]);

  const handleReject = (message) => {
    const requestID = message?.approval_request?.id;
    fetchApproveMesssageRequest({
      variables: {
        approval_status_code: MESSAGE_APPROVAL_REQUEST.rejected,
        approval_request_id: requestID,
      },
    }).then(() => {});
  };

  const handleApprove = (message) => {
    const requestID = message?.approval_request?.id;
    fetchApproveMesssageRequest({
      variables: {
        approval_status_code: MESSAGE_APPROVAL_REQUEST.approved,
        approval_request_id: requestID,
      },
    }).then(() => {});
  };

  useEffect(() => {
    if (!user) return;
    const _messagesRes = workContractMessages?.work_contract_by_pk?.chat_room_relationship?.chat_room?.messages || [];
    const _messages = sortMessages(_messagesRes).map((message) =>
      MessageMapper({ message, onApprove: handleApprove, onReject: handleReject, user, kolProfile }),
    );
    setMessages(_messages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workContractMessages, user]);

  const gotoListCampaign = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const gotoListTeams = () => {
    history.push(PRIVATE_ROUTE.teams);
  };

  const renderHeaderCenter = (
    <MenuItemStyled onClick={gotoListCampaign}>
      <Typography text="Campaigns" color="#5770C6" fontSize="16px / 19px" />
    </MenuItemStyled>
  );

  const renderHeaderTeam = (
    <MenuItemStyled isActive={false} onClick={gotoListTeams}>
      <Typography text="Teams" color="#5770C6" fontSize="16px / 19px" fontWeight="400" />
    </MenuItemStyled>
  );

  const backToCampaignDetail = () => {
    const path = generatePath(`${ROUTES.campaignsListOfKols}`, { id: campaignID });
    history.push(path);
  };

  const height = '75vh';

  const handleSendMessage = async (msg, files) => {
    if (!!files && files.length) {
      try {
        const fieldUloaded = await Promise.all(files.map((f) => uploadFileHandler(f)));
        await fetchSendMessageWithAttachments({
          variables: {
            room_id: roomId,
            plain_text: msg,
            attachments: fieldUloaded.map((f) => ({
              file_id: f.id,
            })),
          },
        });
      } catch (error) {
        addToast((commonMessages as any)['chat.cannotSendMessages'], {
          appearance: 'error',
          autoDismissTimeout: 3000,
          autoDismiss: true,
        });
      }
      return;
    }

    try {
      await fetchSendMessage({
        variables: {
          room_id: roomId,
          plain_text: msg,
        },
      });
    } catch (error) {
      addToast((commonMessages as any)['chat.cannotSendMessages'], {
        appearance: 'error',
        autoDismissTimeout: 3000,
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      {isMobile ? (
        <MainLayoutGoBack
          asideLeft={
            <AsideLeftCampaignDetail
              type={2}
              data={campaignProfile}
              id={campaignID}
              backToStep={backToCampaignDetail}
              owner={roomOwner}
            />
          }
          asideRight={
            <CampaignChatComponent
              conversation={messages}
              owner={roomOwner}
              height={height}
              isLoading={
                isLoading || isWorkContractMessagesLoading || isSendMessageLoading || isSendMessageWithAttachmentsLoading
              }
              onSendMessage={handleSendMessage}
            />
          }
        />
      ) : (
        <MainLayout
          hasButtonNew
          headerCenter={renderHeaderCenter}
          headerTeam={renderHeaderTeam}
          asideLeft={
            <AsideLeftCampaignDetail type={2} data={campaignProfile} id={campaignID} backToStep={backToCampaignDetail} />
          }
          asideRight={
            <CampaignChatComponent
              conversation={messages}
              owner={roomOwner}
              height={height}
              isLoading={
                isLoading || isWorkContractMessagesLoading || isSendMessageLoading || isSendMessageWithAttachmentsLoading
              }
              onSendMessage={handleSendMessage}
            />
          }
        />
      )}
    </>
  );
};

export default CampaignChat;

const MenuItemStyled = styled.div<any>``;

const MessageMapper = ({ message, onApprove, onReject, user, kolProfile }) => {
  const component = {
    style: {},
    user: {},
  } as any;

  const position = message?.user?.id !== user?.id ? MESSAGE_POSITION.left : MESSAGE_POSITION.right;
  const media = (message.attachments || []).map((attachment) => ({
    url: attachment?.file?.url,
    type: attachment?.file?.content_type,
  }));
  const plainText = message?.plain_text || '';
  const isKolProfile = message?.user?.id === kolProfile?.user?.id;
  const name = isKolProfile ? kolProfile?.first_name : message?.user?.given_name;
  const avatars = kolProfile.kol_file_metadata?.filter((file) => file.type === 'avatar');
  var avatar: string = '';
  if (isKolProfile && avatars && avatars.length > 0) {
    avatar = avatars[0].file_metadata?.url;
  } else {
    avatar = message?.user?.picture?.url;
  }
  component.position = position;
  component.user = {
    name: name || '',
    avatar: avatar || message?.user?.picture?.url || '',
    id: message?.user?.id,
  };
  component.time = new Date(message.created_at);

  if (!!message.approval_request) {
    switch (message.approval_request.approval_status_code) {
      case MESSAGE_APPROVAL_REQUEST.approved:
        component.text = (
          <MessageApproved
            message={{
              media: media,
              text: plainText,
            }}
          />
        );
        component.style.borderColor = MESSAGE_COLOR.aprrove;
        break;
      case MESSAGE_APPROVAL_REQUEST.rejected:
        component.text = (
          <MessageRejected
            message={{
              media: media,
              text: plainText,
            }}
          />
        );
        component.style.borderColor = MESSAGE_COLOR.reject;
        component.style.bgColor = MESSAGE_COLOR.bgReject;
        break;
      case MESSAGE_APPROVAL_REQUEST.pending:
        component.text = (
          <MessageRequest
            message={{
              media: media,
              text: plainText,
            }}
            onApprove={() => onApprove(message)}
            onReject={() => onReject(message)}
          />
        );
        break;
      default:
        component.text = plainText;
        break;
    }
  } else {
    if (media && media.length) {
      component.text = <MessageMedia message={{ media: media, text: plainText }} />;
    } else {
      component.text = plainText;
    }
  }

  return component;
};

function sortMessages(messages) {
  return R.sort((a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  }, messages);
}
