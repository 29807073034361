import { gql } from '@apollo/client';

// @deprecated Use `fetchFxRatesAll` instead
export default gql`
  mutation FetchFxRate($baseCurrency: String!, $targetCurrency: String!) {
    fetchFxRate(object: { baseCurrency: $baseCurrency, targetCurrency: $targetCurrency }) {
      result
    }
  }
`;

/**
 * @deprecated Use `fetchFxRatesAll` instead
 */
export const fetchFxRate = gql`
  mutation FetchFxRate($baseCurrency: String!, $targetCurrency: String!) {
    fetchFxRate(object: { baseCurrency: $baseCurrency, targetCurrency: $targetCurrency }) {
      result
    }
  }
`;

export const fetchFxRatesAll = gql`
  query {
    fetchAllRatesFromUSD {
      rate
      currencyCode
    }
  }
`;
