import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DeliverableItem from './DeliverableItem';
import { ReactComponent as AddSVG } from '../../../../../../assets/svg/control_point_black_24dp.svg';

import ErrorMessageStyled from '../../../../../commons/ErrorMessage';

const DeliverablePopupContent = (props) => {
  const { fields, buttonClick, isShowDeliverable, append, isEmptyDeliverable, remove, typeOptions, name } = props;

  return (
    <Wrapper>
      <Container isShowDeliverable={isShowDeliverable}>
        <Header>
          <CancelStyled onClick={buttonClick}>Cancel</CancelStyled>
          <RequirementNameStyled>{name}</RequirementNameStyled>
          <CancelStyled onClick={buttonClick}>Save</CancelStyled>
        </Header>
        <ContentWrapper>
          {fields.length > 0 &&
            fields.map((field, index) => (
              <DeliverableItem key={field.id} index={index} field={field} remove={remove} typeOptions={typeOptions} />
            ))}
          {isEmptyDeliverable && <CustomErrorMessageStyled>Campaign must be at least 1 deliverable</CustomErrorMessageStyled>}
          <AddMoreFieldStyled>
            <span onClick={append}>Add Deliverables</span>
            <ActionStyled>
              <AddIcon onClick={append} fill="#5770C6" width="17px" height="17px" />
            </ActionStyled>
          </AddMoreFieldStyled>
        </ContentWrapper>
      </Container>
      {isShowDeliverable && <Overlay onClick={buttonClick} />}
    </Wrapper>
  );
};

DeliverablePopupContent.propTypes = {
  fields: PropTypes.array,
  append: PropTypes.func,
  buttonClick: PropTypes.func,
  isShowDeliverable: PropTypes.bool,
  isEmptyDeliverable: PropTypes.bool,
  remove: PropTypes.func,
  typeOptions: PropTypes.array,
  name: PropTypes.string,
};

export default memo(DeliverablePopupContent);

const Wrapper = styled.div<any>`
  width: 100%;
  height: 100%;
`;

const Container = styled.div<any>`
  border-radius: 10px 10px 0 0;
  height: ${(props) => (props.isShowDeliverable ? '80vh' : '0px')};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
  transition: height 0.5s;
`;

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #eeeeee;
`;

const CancelStyled = styled.div<any>`
  font: normal normal 500 12px/15px Helvetica Neue;
  color: #5770c6;
  text-transform: uppercase;
`;

const RequirementNameStyled = styled.div<any>`
  font: normal normal 500 14px/17px Helvetica Neue;
  color: #0b0b0b;
`;

const Overlay = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.65);
`;

const CustomErrorMessageStyled = styled<any>(ErrorMessageStyled)`
  padding: 0 20px;
`;

const AddMoreFieldStyled = styled.div<any>`
  width: 100%;
  color: #5770c6;
  font: normal normal 500 16px/19px Helvetica Neue;
  display: flex;
  justify-content: space-between;
  & > span {
    cursor: pointer;
  }
  margin: 20px 0 100px;
  padding: 0 20px;
`;

const ActionStyled = styled.div<any>`
  width: 180px;
  flex-basis: 81px;
  display: flex;
  justify-content: flex-end;
  margin-left: 0px;
  & span {
    color: #5770c6;
    font: normal normal normal 14px/16px Helvetica Neue;
    cursor: pointer;
  }
  & svg {
    cursor: pointer;
  }
`;

const AddIcon = styled<any>(AddSVG)`
  cursor: pointer;
  display: block;
  margin-right: 19.78px;
  width: 17px;
  height: 17px;
`;

const ContentWrapper = styled.div<any>`
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 50px;
`;
