import { gql } from '@apollo/client';

const GET_TRANCHES_IN_CAMPAIGN = gql`
  query MerchantCampaignDetail($id: uuid!) {
    campaign_by_pk(id: $id) {
      tranches {
        id
        name
      }
    }
  }
`;

export default GET_TRANCHES_IN_CAMPAIGN;
