import { gql } from '@apollo/client';
export default gql`
  mutation CreateClientProfile(
    $company_name: String = ""
    $country_code: String = ""
    $phone_number: String = ""
    $address: String = ""
    $address2: String = ""
    $region: String = ""
    $region2: String = ""
    $postal_code: String = ""
    $display_currency: String = ""
  ) {
    insert_client_profile_one(
      object: {
        company_name: $company_name
        country_code: $country_code
        phone_number: $phone_number
        address: $address
        address2: $address2
        region: $region
        region2: $region2
        postal_code: $postal_code
        display_currency: $display_currency
      }
    ) {
      id
      company_name
      country_code
      phone_number
      address
      wallet_id
      address2
      country {
        code
        name
        phone_code
      }
      region
      region2
      postal_code
      default_currency {
        code
        name
        symbol
        fraction_size
        template
      }
      updated_at
      user_id
    }
  }
`;
