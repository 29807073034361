import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import Typography from '../../components/commons/Typography';
import MainLayout from '../../components/layouts/MainLayout/MainLayout';
import MainLayoutGoBack from '../../components/layouts/MainLayout/MainLayoutGoBack';
import AsideLeftCampaignDetail from '../../components/campaigns/AsideLeftCampaignDetail';
import CampaignListOfKolUI from '../../components/campaigns/CampaignListOfKol/CampaignListOfKol';
import { PRIVATE_ROUTE, ROUTES } from '../../utils/constants';
import qs from 'query-string';
import * as R from 'ramda';
import { formatDistanceToNow } from 'date-fns';

import useCampaign from '../../hooks/useCampaign';
import useWorkContract from '../../hooks/useWorkContract';
import useMobile from '../../hooks/useMobile';

import html2pdf from 'html2pdf.js';

const moderateWorkContractsAction = {
  rejectNormalApplication: 'REJECT_NORMAL_APPLICATION',
  rejectAdditionalPay: 'REJECT_ADDITIONAL_PAY',
  approveNormalApplication: 'APPROVE_NORMAL_APPLICATION',
  approveAdditionPay: 'APPROVE_ADDITIONAL_PAY',
  approveAdditionalPayAndPay: 'APPROVE_ADDITIONAL_PAY_AND_PAY',
  payBulk: 'PAY_BULK',
};

function CampaignListOfKols() {
  let { id } = useParams() as any;
  const { campaignDetail, onGetCampaignById } = useCampaign() as any;
  const isMobile = useMobile();
  const { onApproveWorkContract, onRejectWorkContract, handleModerateWorkContracts } = useWorkContract();
  // const [campaign, setCampaign] = useState<any>();
  const tableRef = useRef<any>();
  const [data, setData] = useState<any>([]);
  const [listWorkContract, setListWorkContract] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    if (!campaignDetail || campaignDetail?.id !== id) {
      onGetCampaignById(id);
      return;
    }
    const listWorkContract = campaignDetail?.tranches.map((tranche) => tranche.work_contracts).flat();
    setListWorkContract(listWorkContract);
    const listContractFilter = handleGetListWorkItem(listWorkContract);
    setData(listContractFilter);
    handleAutomateOpenChat(listWorkContract);
    handleAutomateOpenDeliverable(listWorkContract);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignDetail, id]);

  const owner = useMemo(() => {
    if (!campaignDetail || !campaignDetail?.client_profile) return {};
    return {
      name: campaignDetail?.client_profile?.company_name, // string or nullable
      avatar: campaignDetail?.client_profile?.logo_file?.url, // string or nullable
      lastActivityTime: formatDistanceToNow(new Date(campaignDetail?.created_at)), // instance of Date or string
      status: campaignDetail?.status.toLowerCase(), // string or nullable
    };
  }, [campaignDetail]);

  const gotoListCampaign = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const gotoListTeams = () => {
    history.push(PRIVATE_ROUTE.teams);
  };

  const renderHeaderCenter = (
    <MenuItemStyled onClick={gotoListCampaign}>
      <Typography text="Campaigns" color="#5770C6" fontSize="16px / 19px" fontWeight="500" />
    </MenuItemStyled>
  );

  const renderHeaderTeam = (
    <MenuItemStyled isActive={false} onClick={gotoListTeams}>
      <Typography text="Teams" color="#5770C6" fontSize="16px / 19px" fontWeight="400" />
    </MenuItemStyled>
  );

  const backToCampaign = () => {
    history.push(ROUTES.campaigns);
  };

  const handleChangeTab = (tab) => {
    const newWorkContract = listWorkContract.filter((ls: any) => {
      if (tab === 'CLIENT_APPROVED') {
        return ls.status.code === tab;
      }
      return ls.status.code !== 'CLIENT_APPROVED';
    });
    setData(newWorkContract);
  };

  const handleAccept = async (item) => {
    await onApproveWorkContract(item.id);
    onGetCampaignById(id);
  };

  const handleAcceptItems = async (items) => {
    const promises = items.map((item) => {
      return onApproveWorkContract(item.id);
    });
    await Promise.all(promises);
    onGetCampaignById(id);
  };

  const handleReject = async (item) => {
    await onRejectWorkContract(item.id);
    onGetCampaignById(id);
  };

  const handleRejectPay = async (item) => {
    await handleModerateWorkContracts([item.id], moderateWorkContractsAction.rejectAdditionalPay);
    onGetCampaignById(id);
  };

  const handleApprovePay = async (item) => {
    await handleModerateWorkContracts([item.id], moderateWorkContractsAction.approveAdditionPay);
    onGetCampaignById(id);
  };

  const handleApprovePayAndPay = async (items) => {
    const payItems = items.filter((item) => item.status.code === 'CLIENT_APPROVED_PAY_REQUEST').map((item) => item.id);
    const nonPayItems = items.filter((item) => item.status.code !== 'CLIENT_APPROVED_PAY_REQUEST');
    if (payItems.length > 1) {
      await handleModerateWorkContracts(payItems, moderateWorkContractsAction.payBulk);
    } else if (payItems.length > 0) {
      await handleModerateWorkContracts(payItems, moderateWorkContractsAction.approveAdditionalPayAndPay);
    }
    if (nonPayItems.length > 0) {
      const promises = nonPayItems.map((item) => {
        return onApproveWorkContract(item.id);
      });
      await Promise.all(promises);
    }
    onGetCampaignById(id);
  };

  const handleGotoChat = (item) => {
    const path = generatePath(ROUTES.campaignsChat, { id: id, contractID: item.id });
    history.push(path);
  };

  const handleGotoDeliverables = (item) => {
    const path = generatePath(ROUTES.campaignsDeliverables, { id: id, contractID: item.id });
    history.push(path);
  };

  const handleSearch = (text) => {
    const listContract = handleGetListWorkItem();
    if (!text) {
      setData(listContract);
    }
    const newData = listContract.filter((item: any) => R.toLower(item?.tranche?.name).includes(R.toLower(text)));
    setData(newData);
  };

  const handleDownloadPdf = () => {
    if (!tableRef.current) return;
    const el = tableRef.current;

    const opt = {
      margin: 1,
      filename: 'kolslist.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1.0, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    };

    html2pdf().from(el).set(opt).save();
  };

  const handleAutomateOpenChat = (listWs = listWorkContract) => {
    const query = qs.parse(history.location.search) as any;
    const senderthumbnailid = query.senderthumbnailid;
    if (senderthumbnailid) {
      const contract = listWs.filter((ls: any) => {
        return ls.kol_profile.user.picture.id === senderthumbnailid;
      }) as any;
      if (contract.length > 0) {
        const path = generatePath(ROUTES.campaignsChat, { id: id, contractID: contract[0].id });
        history.push(path);
      }
    }
  };

  const handleAutomateOpenDeliverable = (listWs = listWorkContract) => {
    const query = qs.parse(history.location.search) as any;
    const senderiddeliverable = query.senderiddeliverable;
    if (senderiddeliverable) {
      const contract = listWs.filter((ls: any) => {
        return ls.kol_profile.user.picture.id === senderiddeliverable;
      }) as any;
      if (contract.length > 0) {
        const path = generatePath(ROUTES.campaignsDeliverables, { id: id, contractID: contract[0].id });
        history.push(path);
      }
    }
  };

  const handleGetListWorkItem = (listWs = listWorkContract) => {
    const query = qs.parse(history.location.search) as any;
    const status = query.tab;
    const listContractFilter = listWs.filter((ls: any) => {
      if (status === 'CLIENT_APPROVED') {
        return ls.status.code === status;
      }
      return ls.status.code !== 'CLIENT_APPROVED';
    });
    return listContractFilter;
  };

  return (
    <>
      {isMobile ? (
        <MainLayoutGoBack
          onGoback={() => history.push(ROUTES.campaigns)}
          asideLeft={<AsideLeftCampaignDetail type={1} owner={owner} data={campaignDetail} id={id} backToStep={backToCampaign} />}
          asideRight={
            <CampaignListOfKolUI
              ref={tableRef}
              data={data}
              onTabChange={handleChangeTab}
              onAccept={handleAccept}
              onApprovePay={handleApprovePay}
              onPayWorkContracts={handleApprovePayAndPay}
              onReject={handleReject}
              onRejectPay={handleRejectPay}
              onGotoChat={handleGotoChat}
              onGotoDeliverables={handleGotoDeliverables}
              onDownloadPdf={handleDownloadPdf}
              onSearch={handleSearch}
            />
          }
        />
      ) : (
        <MainLayout
          hasButtonNew
          headerCenter={renderHeaderCenter}
          headerTeam={renderHeaderTeam}
          asideLeft={<AsideLeftCampaignDetail type={1} data={campaignDetail} id={id} backToStep={backToCampaign} />}
          asideRight={
            <CampaignListOfKolUI
              ref={tableRef}
              data={data}
              onTabChange={handleChangeTab}
              onAccept={handleAccept}
              onAcceptItems={handleAcceptItems}
              onApprovePay={handleApprovePay}
              onPayWorkContracts={handleApprovePayAndPay}
              onReject={handleReject}
              onRejectPay={handleRejectPay}
              onGotoChat={handleGotoChat}
              onGotoDeliverables={handleGotoDeliverables}
              onDownloadPdf={handleDownloadPdf}
              onSearch={handleSearch}
            />
          }
        />
      )}
    </>
  );
}

export default CampaignListOfKols;

const MenuItemStyled = styled.div<any>``;
