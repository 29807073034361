import { gql } from '@apollo/client';
export default gql`
  query TrancheDetails($tranch_id: uuid) {
    tranche(where: { id: { _eq: $tranch_id } }) {
      id
      name
      due_date
      cash_usd_cents
      product_value_usd_cents
      campaign {
        id
        name
      }
      work_contracts {
        id
        status_code
        kol_profile {
          first_name
          last_name
          id
        }
      }
    }
  }
`;
