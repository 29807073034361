import React from 'react';
import styled, { css } from 'styled-components';

const Button = (props: any) => {
  const { label = 'Sign In', variant = 'primary', width } = props;
  return (
    <ButtonStyled width={width} variant={variant} {...props}>
      <span className="text">{label}</span>
    </ButtonStyled>
  );
};

export default Button;

const ButtonStyled = styled.button<any>`
  border-radius: 5px;
  opacity: 1;
  border: 0;
  outline: 0;
  color: #fff;
  cursor: pointer;
  width: ${(props) => props.width || '100%'};
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  font: normal normal bold 14px/17px Helvetica Neue;
  letter-spacing: 0px;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${(props) =>
    props.variant === 'primary' &&
    css`
      background: transparent linear-gradient(263deg, #51adf0 0%, #666bfa 49%, #b64dc8 100%) 0% 0% no-repeat padding-box;
      height: 46px;
    `}

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      background-color: transparent;
      color: #949494;
    `}

  ${(props) =>
    props.variant === 'tertiary' &&
    css`
      color: #d9322b;
      height: 46px;
      border: 1px solid rgba(217, 50, 43, 0.4);
      border-radius: 5px;
    `}
  
  ${(props) =>
    props.variant === 'payment' &&
    css`
      background-color: #0a263d;
      height: 46px;
      border-radius: 5px;
    `}

  ${(props) =>
    props.variant === 'simple' &&
    css`
      background-color: transparent;
      color: #7b73f4;
      height: 18px;
      font: normal 14px Helvetica Neue;
    `}

  & .text {
    vertical-align: sub;
    display: inline-flex;
  }
`;
