import { gql } from '@apollo/client';

export default gql`
  mutation InsertMultipleImagesToCampaign($campaignId: uuid!, $fileId: uuid!) {
    insert_campaign_file_metadata(objects: [{ campaign_id: $campaignId, file_metadata_id: $fileId }]) {
      returning {
        file_metadata_id
        campaign_id
      }
    }
  }
`;
