import { gql } from '@apollo/client';

export default gql`
  mutation DeleteAdditionalFiles($trancheId: uuid!) {
    delete_tranche_additional_info_file_metadata(where: { tranche_id: { _eq: $trancheId } }) {
      returning {
        tranche_id
        file_metadata_id
      }
    }
  }
`;
