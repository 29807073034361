import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../../assets/svg/logo_svg.svg';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import SearchPopover from '../SearchPopover';
import { ROUTES } from '../../../utils/constants';
import { mobileQueryString } from '../../../utils/responsive';

const MainLayoutMobileSearch = ({ asideLeft, asideRight }) => {
  return (
    <Wrapper>
      <HeaderContentStyled>
        <SearchPopover />
        <LogoStyled>
          <A to={ROUTES.campaigns}>
            <LogoSVG />
          </A>
        </LogoStyled>
        <HeaderRightStyled>
          <ProfilePopover />
        </HeaderRightStyled>
      </HeaderContentStyled>
      <ContainerStyled mt="40px">
        <MainStyled>
          <AreaLeftStyled>{asideLeft}</AreaLeftStyled>
          <AreaRightStyled>{asideRight}</AreaRightStyled>
        </MainStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

export default memo(MainLayoutMobileSearch);

MainLayoutMobileSearch.propTypes = {
  asideRight: PropTypes.node,
  asideLeft: PropTypes.node,
};

const HeaderContentStyled = styled.div<any>`
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  background-color: #fff;
  z-index: 100;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
`;

const HeaderRightStyled = styled.div<any>`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;

    .a {
      stroke: #5770c6;
    }
  }
`;

const A = styled<any>(Link)`
  cursor: pointer;
  text-decoration: none;
  display: block;
  min-width: 150px;
  height: 22px;
  margin-right: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const LogoStyled = styled.div<any>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 150px;
`;

const Wrapper = styled.div<any>`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
`;

const ContainerStyled = styled.div<any>`
  max-width: 100%;
  width: calc(100% - 170px);
  margin: 0 auto;
  margin-top: ${(props) => props.mt || 0};

  ${mobileQueryString} {
    width: 100%;
    padding: 0 20px;
    margin-top: 15px;
  }
`;

const MainStyled = styled.div<any>`
  display: flex;
  position: relative;

  ${mobileQueryString} {
    flex-direction: column;
  }
`;

const AreaLeftStyled = styled.div<any>`
  width: 100%;
  max-width: 232px;
  flex-shrink: 0;
  margin-right: 68px;
  box-sizing: border-box;
  height: fit-content;

  ${mobileQueryString} {
    max-width: none;
    margin-right: 0;
  }
`;

const AreaRightStyled = styled.div<any>`
  flex-grow: 1;
  margin-right: 50px;
  width: calc(100% - 232px - 68px);

  ${mobileQueryString} {
    width: 100%;
    margin-right: 0;
  }
`;
