import { gql } from '@apollo/client';

export default gql`
  mutation InsertMultipleFilesToTranche($trancheId: uuid!, $fileId: uuid!) {
    insert_tranche_additional_info_file_metadata(objects: [{ file_metadata_id: $fileId, tranche_id: $trancheId }]) {
      returning {
        file_metadata_id
        tranche_id
      }
    }
  }
`;
