import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import useGuard from '../../hooks/useGuard';
import useToastError from '../../hooks/useToastError';
import { PRIVATE_ROUTE, ROUTES } from '../../utils/constants';
import * as Sentry from '@sentry/react';

const Auth0Callback = () => {
  const { logout, getIdTokenClaims, error } = useAuth0();
  const { isAccountActive, isProfileCreated, isLogged, isProfileApproved } = useGuard();
  const history = useHistory();
  const { toastError } = useToastError();

  useEffect(() => {
    if (!isLogged) {
      return;
    }
    if (isProfileApproved && isProfileCreated) {
      return history.push(PRIVATE_ROUTE.campaigns);
    }
    if (!isProfileCreated || !isAccountActive) {
      return history.push(PRIVATE_ROUTE.merchantRegister);
    }
    if (isProfileCreated && !isProfileApproved) {
      return history.push(PRIVATE_ROUTE.pendingReview);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountActive, isLogged, isProfileApproved, isProfileCreated]);

  useEffect(() => {
    async function getClaimUser() {
      try {
        const claimUser = await getIdTokenClaims();
        if (!claimUser?.email || !claimUser?.email_verified) {
          Sentry.captureException(error);
          logout({
            returnTo: `${window.location.origin}${ROUTES.login}?error=email`,
          });
        } else {
          Sentry.captureMessage('Sign-in successful', { level: 'info' });
        }
      } catch (error) {
        Sentry.captureException(error);
        toastError('Something wrongs, please try again');
      }
    }
    getClaimUser();
  }, [error, getIdTokenClaims, logout, toastError]);

  return (
    <div className="auth0-callback">
      <p>Please wait</p>
    </div>
  );
};

export default Auth0Callback;
