/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';

import APPROVE_WORK_CONTRACT from '../queries/workContract/merchantApprove';
import REJECT_WORK_CONTRACT from '../queries/workContract/merchantReject';
import REJECT_PAY_WORK_CONTRACT from '../queries/workContract/merchantRejectPay';
import MODIFY_WORK_CONTRACTS from '../queries/workContract/moderateWorkContracts';
import useToastError from './useToastError';

export default function useCampaign() {
  const [approveWorkContract] = useMutation(APPROVE_WORK_CONTRACT);
  const [rejectWorkContract] = useMutation(REJECT_WORK_CONTRACT);
  const [rejectPayWorkContract] = useMutation(REJECT_PAY_WORK_CONTRACT);
  const [modifyWorkContracts] = useMutation(MODIFY_WORK_CONTRACTS);
  const { toastError } = useToastError();

  const handleApproveWorkContract = async (id) => {
    try {
      await approveWorkContract({
        variables: {
          id,
        },
      });
    } catch (error) {
      toastError('Something wrongs, please try again');
    }
  };

  const handleRejectWorkContract = async (id) => {
    try {
      await rejectWorkContract({
        variables: {
          id,
        },
      });
    } catch (error) {
      toastError('Something wrongs, please try again');
    }
  };

  const handleRejectPayRequestWorkContract = async (id) => {
    try {
      await rejectPayWorkContract({
        variables: {
          id,
        },
      });
    } catch (error) {
      toastError('Something wrongs, please try again');
    }
  };

  const handleModerateWorkContracts = async (ids, actionType) => {
    try {
      await modifyWorkContracts({
        variables: {
          input: {
            workContractIds: ids,
            actionType: actionType,
          },
        },
      });
    } catch (error) {
      toastError('Something wrongs, please try again');
    }
  };

  return {
    onApproveWorkContract: handleApproveWorkContract,
    onRejectWorkContract: handleRejectWorkContract,
    onRejectPayWorkContract: handleRejectPayRequestWorkContract,
    handleModerateWorkContracts: handleModerateWorkContracts,
  };
}
