import { gql } from '@apollo/client';

const GET_NOTIFICATIONS = gql`
  query SearchMyNotifications {
    notification(order_by: { timestamp: desc }) {
      thumbnail {
        url
        id
      }
      id
      unread
      timestamp
      category_code
      recipient_id
      subject_id
      thumbnail_image_file_id
      title
      description
      extended_data
    }
  }
`;

export default GET_NOTIFICATIONS;
