import { gql } from '@apollo/client';

export const UPDATE_MERCHANT_ROLE = gql`
  mutation updateMerchantRole($id: uuid, $role: String) {
    update_client_profile_user_role(where: { id: { _eq: $id } }, _set: { role: $role }) {
      affected_rows
    }
  }
`;

export const DELETE_MERCHANT = gql`
  mutation updateMerchantRole($id: uuid) {
    delete_client_profile_user_role(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
