import styled from 'styled-components';
import { mobileQueryString } from '../../utils/responsive';

const RowStyled = styled.div<any>`
  width: 100%;
  display: flex;
  align-items: ${(props) => props.alignItems || 'stretch'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  padding: 0 15px;

  ${mobileQueryString} {
    padding: 0;
  }
`;

export default RowStyled;
