import { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';
import { ReactComponent as DropdownBottomSVG } from '../../../../../../assets/svg/drop_down_bottom.svg';
import { ReactComponent as DropdownTopSVG } from '../../../../../../assets/svg/drop_down_top.svg';
import { ReactComponent as MinusSVG } from '../../../../../../assets/svg/remove_black_24dp.svg';
import { ReactComponent as PlusSVG } from '../../../../../../assets/svg/add_black_24dp.svg';

import OriginalDatepicker from '../../../../../commons/Datepicker';
import OriginalSelectbox from '../../../../../commons/Selectbox';
import OriginalTextFieldNumber from '../../../../../commons/TextFieldNumber';
import OriginalTextField from '../../../../../commons/TextField';

import { mobileQueryString } from '../../../../../../utils/responsive';
import { FORMAT_DATE_DEFAULT } from '../../../../../../utils/constants';
import { useFormContext } from 'react-hook-form';

const DeliverableItem = ({ field, index, remove, typeOptions }) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const [isShowInformation, setIsShowInformation] = useState(false);

  const fieldName = useMemo(() => `deliverable_specs.${index}`, [index]);
  const num = useMemo(() => index + 1, [index]);

  const quantity = watch(`${fieldName}.quantity`);
  const typeCode = watch(`${fieldName}.content_type_code`);

  const typeSocialName = useMemo(() => {
    const type = typeOptions.find((element) => element.code === typeCode);
    return type.name;
  }, [typeCode, typeOptions]);

  return (
    <div>
      <Section isShowInformation={isShowInformation}>
        <FlexBox>
          <Header onClick={() => setIsShowInformation((prevState) => !prevState)}>
            <LeftHeader>
              <OrderNumber>{num}.</OrderNumber>
              {!isShowInformation && (
                <>
                  <Content>{quantity}</Content>
                  <Content>{typeSocialName}</Content>
                </>
              )}
            </LeftHeader>
            <RightHeader>
              <DateStyled>{format(new Date(field.due_date), FORMAT_DATE_DEFAULT)}</DateStyled>
              <DeleteStyled onClick={() => remove(index)}>Delete</DeleteStyled>
              {isShowInformation ? <DropdownTopSVG /> : <DropdownBottomSVG />}
            </RightHeader>
          </Header>
          <Information isShow={isShowInformation}>
            <GroupItemStyled gap="17px" mb="0px">
              <GroupItemStyled col width="100%" mb="18px" gap="17.46px">
                <DeliverableItemStyled>
                  <GroupItemStyled mb="18px" maxWidth="668px">
                    <TextFieldDeliverables
                      {...register(`${fieldName}.quantity`, {
                        required: {
                          value: true,
                          message: 'Quantity of deliverable is required',
                        },
                        min: {
                          value: 1,
                          message: 'Quantity of deliverable greater than 0',
                        },
                      })}
                      label="No of Deliverables"
                      arrowleft={<MinusSVG />}
                      arrowright={<PlusSVG />}
                      defaultValue={field?.quantity}
                      error={Array.isArray(errors?.deliverable_specs) ? errors?.deliverable_specs[index]?.quantity?.message : ''}
                    />
                    <SelectType
                      options={typeOptions}
                      {...register(`${fieldName}.content_type_code`, {
                        required: {
                          value: true,
                          message: 'Content type is required',
                        },
                      })}
                      error={
                        Array.isArray(errors?.deliverable_specs)
                          ? errors?.deliverable_specs[index]?.content_type_code?.message
                          : ''
                      }
                      defaultValue={field?.content_type_code}
                      canInput
                      label="Type"
                    />
                  </GroupItemStyled>
                </DeliverableItemStyled>
                <GroupItemStyled ml="0px" gap="0px" mb="18px" maxWidth="668px">
                  <Datepicker
                    {...register(`${fieldName}.due_date`, {
                      required: {
                        value: true,
                        message: 'Due date is required',
                      },
                    })}
                    error={Array.isArray(errors?.deliverable_specs) ? errors?.deliverable_specs[index]?.due_date?.message : ''}
                    defaultValue={field?.due_date}
                    label="Due"
                  />
                </GroupItemStyled>
                <GroupItemStyled ml="0px" gap="0px" mb="0px" maxWidth="668px">
                  <TextField
                    {...register(`${fieldName}.requirement_description`, {
                      required: {
                        value: true,
                        message: 'Description is required',
                      },
                    })}
                    name={`${fieldName}.requirement_description`}
                    error={
                      Array.isArray(errors?.deliverable_specs)
                        ? errors?.deliverable_specs[index]?.requirement_description?.message
                        : ''
                    }
                    defaultValue={field?.requirement_description}
                    label="Description"
                  />
                </GroupItemStyled>
              </GroupItemStyled>
            </GroupItemStyled>
          </Information>
        </FlexBox>
      </Section>
    </div>
  );
};

DeliverableItem.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  remove: PropTypes.func,
  typeOptions: PropTypes.array,
};

export default memo(DeliverableItem);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  width: 100%;
`;

const OrderNumber = styled.div<any>`
  font: normal normal normal 14px/17px Helvetica Neue;
`;

const LeftHeader = styled.div<any>`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const RightHeader = styled.div<any>`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

const DeleteStyled = styled.div<any>`
  font: normal normal 400 14px/16px Helvetica Neue;
  color: #5770c6;
`;

const Content = styled.div<any>`
  font: normal normal 600 14px/17px Helvetica Neue;
  color: #0b0b0b;
  margin-top: 5px;
`;

const DateStyled = styled.div<any>`
  color: #949494;
  font: normal normal 400 12px/14px Helvetica Neue;
  margin-right: 13px;
`;

const Section = styled.div<any>`
  ${(props) =>
    props.isShowInformation &&
    css`
      background-color: rgba(238, 238, 238, 0.25);
      border-bottom: 1px solid #eee;
    `}
`;

const FlexBox = styled.div<any>`
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
`;

const TextFieldNumber = styled<any>(OriginalTextFieldNumber)`
  margin-bottom: 0px;
  flex: 1;
`;

const TextFieldDeliverables = styled<any>(TextFieldNumber)`
  max-width: 158px;
  margin-bottom: 0px;
  & .textnumber {
    &__arrow {
      height: 100%;
      top: 0px;
      right: 0px;
      padding: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid #d9d9d9;
      width: 82px;
    }
    &__left,
    &__right {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        width: 20.6px;
        fill: #3e3e3e;
      }
    }

    &__divider {
      min-width: 1px;
    }
  }
  & input[type='number'] {
    width: 100%;
    -moz-appearance: textfield;
  }
  // hidden default arrow of number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Selectbox = styled<any>(OriginalSelectbox)`
  margin-bottom: 0px;
  flex: 1;
`;

const SelectType = styled<any>(Selectbox)`
  max-width: 158px;
`;

const Datepicker = styled<any>(OriginalDatepicker)`
  margin-bottom: 0px;
  flex: 1;
`;

const GroupItemStyled = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  --gap: ${(props) => props.gap || '20px'};
  & > * + * {
    margin-left: var(--gap);
  }
  margin-bottom: ${(props) => props.mb || '20px'};
  ${(props) =>
    !!props.itemWidth &&
    css`
      & > * {
        width: ${props.itemWidth};
      }
    `}
  ${(props) =>
    !!props.col &&
    css`
      flex-direction: column;
    `}
  ${(props) =>
    !!props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    !!props.wrap &&
    css`
      flex-wrap: wrap;
    `}
  ${(props) =>
    !!props.ml &&
    css`
      margin-left: ${props.ml};
    `}
  ${(props) =>
    !!props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
`;

const TextField = styled<any>(OriginalTextField)`
  margin-bottom: 0px;
  flex: 1;
  width: auto;
  & p.label {
    width: 100%;
  }
  & input {
    background-color: #ffffff;
  }
  ${(props) =>
    !!props.width &&
    css`
      flex-basis: ${props.width};
      flex-grow: 0;
    `}

  ${mobileQueryString} {
    flex-basis: 0;
    width: 100%;
    margin-bottom: 10px;

    & input {
      flex-basis: 100%;
    }
  }
`;

const DeliverableItemStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const Information = styled.div<any>`
  max-height: ${(props) => (props.isShow ? '1000px' : '0px')};
  overflow: hidden;
  transition: all 0.5s;
  padding: 0 20px;
  margin: 0 auto;
`;
