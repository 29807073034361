import { memo } from 'react';
import styled from 'styled-components';

import { formatCurrencyInput } from '../../../utils/formatCurrency';

import { ReactComponent as CloseSVG } from '../../../assets/svg/close_black_24dp.svg';

import PopUpLayout from '../popUpLayout/PopUpLayout';
import Button from '../../commons/Button';

const ConfirmMoneyPopUp = ({
  handlePublish,
  onClose,
  requireAmount,
  requireAmountUSD,
  currency,
  isLoadingPublish,
  confirmTitle,
}) => {
  return (
    <PopUpLayout width="376px">
      <Header>
        <HeaderTitle>Confirm</HeaderTitle>
        <CloseSVG onClick={onClose} />
      </Header>
      <Content>
        <MoneyStyled>
          {formatCurrencyInput(requireAmount)} {currency}
        </MoneyStyled>
        {currency !== 'USD' && <MoneyStyledSecondary>~{formatCurrencyInput(requireAmountUSD, 2)} USD </MoneyStyledSecondary>}
        <DescribeStyled>will be deducted from your wallet to cover these additional payment requests.</DescribeStyled>
        <ButtonStyled label={isLoadingPublish ? 'WAITING' : confirmTitle} onClick={handlePublish} />
        <ButtonStyled label="CANCEL" variant="secondary" onClick={onClose} />
      </Content>
    </PopUpLayout>
  );
};

export default memo(ConfirmMoneyPopUp);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 20px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  color: #000000;
`;

const Content = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 40px 20px 20px;
`;

const MoneyStyled = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #000000;
`;

const MoneyStyledSecondary = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #717171;
`;

const DescribeStyled = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #5770c6;
  margin: 8px 0 40px;
`;

const ButtonStyled = styled<any>(Button)`
  height: 46px;
`;
