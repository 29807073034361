import React, { useMemo } from 'react';
import styled from 'styled-components';

import ArrowBottomUrl from '../../assets/svg/drop_down_bottom.svg';
import { ReactComponent as ArrowLeftSVG } from '../../assets/svg/drop_down_left.svg';
import { ReactComponent as ArrowRightSVG } from '../../assets/svg/drop_down_right.svg';

const getStyleIconPagination = (isDisabled) => (isDisabled ? 'rgba(0, 0, 0, 0.26)' : '#3e3e3e');
const rowsPerPageOptions = [10, 25, 50, 100];
const LABEL = {
  itemsPerPage: 'Items per page',
  page: 'Page',
};

const Pagination = ({ totalPage, currentPage, rowsPerPage, totalRecord, onChangePerPage, onChangePage }) => {
  const isDisabledPrev = useMemo(() => {
    return currentPage === 0;
  }, [currentPage]);

  const isDisabledNext = useMemo(() => {
    return currentPage === totalPage - 1;
  }, [currentPage, totalPage]);

  function handleChangePageSize(e) {
    const pageSize = Number(e.target.value);
    if (pageSize) onChangePerPage(pageSize);
  }

  function handlePrevPage() {
    if (isDisabledPrev) return;
    onChangePage(currentPage - 1);
  }

  function handleNextPage() {
    if (isDisabledNext) return;
    onChangePage(currentPage + 1);
  }

  const from = useMemo(() => {
    if (currentPage * rowsPerPage + 1 > totalRecord) {
      return 1;
    }
    return currentPage * rowsPerPage + 1;
  }, [currentPage, rowsPerPage, totalRecord]);

  const to = useMemo(() => {
    if (from + rowsPerPage > totalRecord - 1) {
      return totalRecord;
    }
    return from + rowsPerPage - 1;
  }, [from, rowsPerPage, totalRecord]);

  return (
    <TablePaginationStyled>
      <span className="row-per-page">
        {LABEL.itemsPerPage}
        <select value={rowsPerPage} onChange={handleChangePageSize}>
          {rowsPerPageOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </span>
      <span className="page">
        <strong>
          {from} - {to} of {totalRecord}
        </strong>
      </span>
      <div className="arrow">
        <button className="previous" onClick={handlePrevPage} disabled={isDisabledPrev}>
          <ArrowLeftSVG stroke={getStyleIconPagination(isDisabledPrev)} />
        </button>
        <div className="vertical-line"></div>
        <button className="next" onClick={handleNextPage} disabled={isDisabledNext}>
          <ArrowRightSVG stroke={getStyleIconPagination(isDisabledNext)} />
        </button>
      </div>
    </TablePaginationStyled>
  );
};

export default Pagination;

const TablePaginationStyled = styled.div<any>`
  display: flex;
  padding: 17px 0 32px 16px;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  font: normal normal 600 14px/17px Helvetica Neue;
  & .arrow {
    margin-left: 15px;
    background: #eeeeeed1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    & .vertical-line {
      height: 18px;
      width: 1px;
      background: #d9d9d9 0% 0% no-repeat padding-box;
      border-radius: 1px;
    }
  }

  & button {
    outline: 0;
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    padding: 12px;
    background: none;

    & svg {
      opacity: 1;
    }
  }
  & select {
    margin-left: 7px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 3px;
    background: url(${ArrowBottomUrl}) no-repeat;
    background-position: calc(100% - 10.5px) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 11px !important;
    padding-left: 12px !important;
    min-width: 55px;
    height: 33px;
    font: normal normal normal 12px/14px Helvetica Neue;
    color: #3e3e3e;
  }
  & .row-per-page {
    color: #949494;
    font: normal normal normal 12px/14px Helvetica Neue;
  }
  & .page {
    margin-left: 20px;
    strong {
      color: #949494;
      font: normal normal normal 12px/14px Helvetica Neue;
    }
  }
`;
