import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: '700',
          fontStyle: 'bold',
          letterSpacing: '1px',
          backgroundColor: '#fae3f1',
          lineHeight: '21.12px',
          color: '#5770c6',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#fae3f1',
            boxShadow: 'none',
          },
        },
        text: {
          color: '#717171',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: '44px',
          '& .MuiOutlinedInput-root': {
            height: '44px',
            '& input': {
              height: '100%',
              boxSizing: 'border-box',
              padding: '0 14px', // Adjust padding as necessary
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '132%',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '132%',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '132%',
        },
      },
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    button: {
      textTransform: 'none',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18.48px',
      fontStyle: 'bold',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '30px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '25px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '42.24px',
      fontStyle: 'bold',
    },
    h5: {
      fontSize: '42px',
    },
    h6: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18.48px',
      fontStyle: 'regular',
    },
    subtitle1: {
      fontSize: '16px',
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17.5px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '15px',
    },
  },
});

export default theme;
