import { gql } from '@apollo/client';

const generateInner = (statuss = [], clientProfileId: string) => {
  let query = `
    ALL: campaign_aggregate {
      aggregate {
        count
      }
    }
  `;

  statuss.forEach((s) => {
    const series = `${s}`;
    const source = `${s}`;

    query += `
      ${series}: campaign_aggregate(where: {client_profile_id: {_eq: "${clientProfileId}"}, status: {_eq: "${source}"}}) {
        aggregate {
          count
        }
      }
  `;
  });
  return query;
};

const generateQueryStatus = (statuss = [], clientProfileId: string) => gql`
  query fetchData {
    ${generateInner(statuss, clientProfileId)}
  }
`;

export default generateQueryStatus;
