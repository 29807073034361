import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { useParams, useHistory } from 'react-router-dom';
import Typography from '../../components/commons/Typography';
import { formatDistanceToNow, format } from 'date-fns';
import { PRIVATE_ROUTE, ROUTES } from '../../utils/constants';

import MainLayout from '../../components/layouts/MainLayout/MainLayout';
import MainLayoutGoBack from '../../components/layouts/MainLayout/MainLayoutGoBack';
import AsideLeftCampaignDetail from '../../components/campaigns/AsideLeftCampaignDetail';
import CampaignDetailComponent from '../../components/campaigns/CampaignDetail';
import useCampaign from '../../hooks/useCampaign';
import useMobile from '../../hooks/useMobile';
const TIME_FORMAT = 'yyyy/MM/dd';

function CampaignDetail() {
  const history = useHistory();
  const isMobile = useMobile();
  const [campaign, setCampaign] = useState<any>();
  const {
    campaignDetail,
    onGetCampaignById,
    onUpdateCampaign,
    errorCampaignDetail,
    campaignDetailCalled,
    loadingCampaignDetail,
  } = useCampaign();

  let { id } = useParams() as any;

  useEffect(() => {
    if (!campaignDetail) {
      onGetCampaignById(id);
    }
    setCampaign(campaignDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignDetail]);

  const gotoListCampaign = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const gotoListTeams = () => {
    history.push(PRIVATE_ROUTE.teams);
  };

  useEffect(() => {
    if (campaignDetail) {
      Sentry.captureMessage('Get campaign detaul succesful', { level: 'info' });
    } else if (campaignDetailCalled && !loadingCampaignDetail && errorCampaignDetail) {
      Sentry.captureException(errorCampaignDetail);
    }
  }, [campaignDetail, campaignDetailCalled, errorCampaignDetail, loadingCampaignDetail]);

  const renderHeaderCenter = (
    <MenuItemStyled onClick={gotoListCampaign}>
      <Typography text="Campaigns" color="#5770C6" fontSize="16px / 19px" />
    </MenuItemStyled>
  );

  const renderHeaderTeam = (
    <MenuItemStyled isActive={false} onClick={gotoListTeams}>
      <Typography text="Teams" color="#5770C6" fontSize="16px / 19px" fontWeight="400" />
    </MenuItemStyled>
  );

  const owner = useMemo(() => {
    if (!campaignDetail || !campaignDetail?.client_profile) return {};
    return {
      name: campaignDetail?.client_profile?.company_name, // string or nullable
      avatar: campaignDetail?.client_profile?.logo_file?.url, // string or nullable
      lastActivityTime: formatDistanceToNow(new Date(campaignDetail?.created_at)), // instance of Date or string
      status: campaignDetail?.status.toLowerCase(), // string or nullable
    };
  }, [campaignDetail]);

  const handleSave = (valuesChanged) => {
    const { campaignDetails, campaignPeriod, recruitmentDeadline } = valuesChanged;
    const flipCampaignPeriod = campaignPeriod.map((t) => new Date(t).getTime()).sort();
    onUpdateCampaign({
      id: campaign?.id,
      description: campaignDetails || campaign?.description,
      start_date: format(flipCampaignPeriod[0], TIME_FORMAT) || campaign?.start_date,
      end_date: format(flipCampaignPeriod[1], TIME_FORMAT) || campaign?.end_date,
      recruitment_end_date: format(recruitmentDeadline, TIME_FORMAT) || campaign?.recruitment_end_date,
    });
  };

  const height = '66vh';

  const backToCampaign = () => {
    history.push(ROUTES.campaigns);
  };

  return (
    <>
      {isMobile ? (
        <MainLayoutGoBack
          onGoback={() => history.push(ROUTES.campaigns)}
          asideLeft={<AsideLeftCampaignDetail type={1} data={campaign} owner={owner} backToStep={backToCampaign} />}
          asideRight={
            <CampaignDetailComponent
              className=""
              height={height}
              owner={owner}
              data={campaign}
              onSave={handleSave}
              loading={false}
            />
          }
        />
      ) : (
        <MainLayout
          hasButtonNew
          headerCenter={renderHeaderCenter}
          headerTeam={renderHeaderTeam}
          asideLeft={<AsideLeftCampaignDetail type={1} data={campaign} backToStep={backToCampaign} />}
          asideRight={
            <CampaignDetailComponent
              className=""
              height={height}
              owner={owner}
              data={campaign}
              onSave={handleSave}
              loading={false}
            />
          }
        />
      )}
    </>
  );
}

export default CampaignDetail;

const MenuItemStyled = styled.div<any>``;
