import React from 'react';
import './ActionButtons.css';

interface ActionButtonsProps {
  onSave: () => void;
  onDiscard: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onSave, onDiscard }) => {
  return (
    <div className="button-container">
      <button className="button discard" onClick={onDiscard}>
        Discard
      </button>
      <button className="button save" onClick={onSave}>
        Save
      </button>
    </div>
  );
};

export default ActionButtons;
