import { gql } from '@apollo/client';
export default gql`
  mutation UpdateCampaign(
    $id: uuid!
    $campaign: CampaignFields = {}
    $replace_tranches: [TrancheReplacement!] = { id: "" }
    $remove_tranches: [uuid!] = []
    $add_tranches: [TrancheInsert!] = {}
  ) {
    updateCampaign(
      input: {
        id: $id
        campaign: $campaign
        replace_tranches: $replace_tranches
        remove_tranches: $remove_tranches
        add_tranches: $add_tranches
      }
    ) {
      id
    }
  }
`;
