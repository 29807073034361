import React, { forwardRef, useMemo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTable, usePagination, useSortBy } from 'react-table';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeftSVG } from '../../assets/svg/drop_down_left.svg';
import { ReactComponent as ArrowRightSVG } from '../../assets/svg/drop_down_right.svg';
import ArrowBottomUrl from '../../assets/svg/drop_down_bottom.svg';
import classNames from 'classnames';
import ContainerScroll from '../commons/ContainerScroll';
import { mobileQueryString } from '../../utils/responsive';

const defaultPropGetter = () => ({});
const getStyleIconPagination = (isDisabled) => (isDisabled ? 'rgba(0, 0, 0, 0.26)' : '#3e3e3e');
const CHANGE_PAGE_ACTION = {
  first: 'first',
  last: 'last',
  next: 'next',
  prev: 'prev',
};
const rowsPerPageOptionsDefault = [10, 25, 50, 100];
const LABEL = {
  itemsPerPage: 'Items per page',
  page: 'Page',
};

const Table = forwardRef<any, any>(
  (
    {
      columns,
      data,
      getHeaderProps = defaultPropGetter,
      getColumnProps = defaultPropGetter,
      getRowProps = defaultPropGetter,
      getCellProps = defaultPropGetter,
      isFooter,
      rowsPerPageOptions,
      onPageChange,
      page: currentPage,
      rowsPerPage,
      totalPage,
      onPerPageChange,
      isHiddenPagination,
      totalRecord,
      isSticky,
      stickyTop,
      height,
      statusCampaign,
      containerHeight = '65vh',
      containerMaxHeight = '70vh',
      isMiddleRow,
    },
    ref,
  ) => {
    const [statusCampaignState, setStatusCampaignState] = useState('All');
    const isManually = useMemo(() => {
      return totalPage !== undefined && totalPage !== null;
    }, [totalPage]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      rows,
      footerGroups,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: currentPage,
          pageSize: rowsPerPage,
        },
        defaultCanSort: true,
        // disableSorting: true, // canSort

        ...(isManually
          ? {
              manualPagination: true,
              pageCount: totalPage,
              useControlledState: (state) => {
                return React.useMemo(
                  () => ({
                    ...state,
                    pageIndex: currentPage,
                  }),
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                  [state, currentPage],
                );
              },
            }
          : {}),
      },
      useSortBy,
      usePagination,
    );

    useEffect(() => {
      if (statusCampaign !== statusCampaignState) {
        setStatusCampaignState(statusCampaign);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, statusCampaign]);

    useEffect(() => {
      if (currentPage === pageIndex) return;
      onPageChange(pageIndex);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex]);

    const handleChangePage = (type) => (_) => {
      if (!isManually) {
        switch (type) {
          case CHANGE_PAGE_ACTION.first:
            gotoPage(0);
            break;
          case CHANGE_PAGE_ACTION.last:
            gotoPage(pageCount - 1);
            break;
          case CHANGE_PAGE_ACTION.next:
            nextPage();
            break;
          case CHANGE_PAGE_ACTION.prev:
            previousPage();
            break;
          default:
            break;
        }
        return;
      }
      switch (type) {
        case CHANGE_PAGE_ACTION.first:
          onPageChange(0);
          break;
        case CHANGE_PAGE_ACTION.last:
          onPageChange(pageCount - 1);
          break;
        case CHANGE_PAGE_ACTION.next:
          onPageChange(pageIndex + 1);
          break;
        case CHANGE_PAGE_ACTION.prev:
          onPageChange(pageIndex - 1);
          break;
        default:
          break;
      }
    };

    const handleChangePageSize = (e) => {
      const newPageSize = Number(e.target.value);
      if (isManually) {
        onPerPageChange(newPageSize);
        return;
      }
      setPageSize(newPageSize);
    };

    const perPage = useMemo(() => {
      return isManually ? rowsPerPage : pageSize;
    }, [isManually, rowsPerPage, pageSize]);

    let from = useMemo(() => {
      if (pageIndex * perPage + 1 > totalRecord) {
        return 1;
      }
      return pageIndex * perPage + 1;
    }, [pageIndex, perPage, totalRecord]);

    let to = useMemo(() => {
      if (from + perPage > totalRecord - 1) {
        return totalRecord;
      }
      return from + perPage - 1;
    }, [from, perPage, totalRecord]);

    return (
      <TableContainer>
        <CustomContainerScroll height={containerHeight} maxHeight={containerMaxHeight}>
          <WrapperStyled height={height}>
            <TableStyled {...getTableProps()} ref={ref}>
              <TableHeadStyled stickyTop={stickyTop} sticky={isSticky}>
                {headerGroups.map((headerGroup) => {
                  return (
                    <TableRowStyled {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        const getProps = column.getHeaderProps || defaultPropGetter;
                        return (
                          <TableTHCellStyled
                            {...column.getHeaderProps([
                              {
                                style: column.headerStyle,
                                className: column.headerClassName,
                              },
                              getColumnProps(column),
                              getHeaderProps(column),
                              getProps(column),
                              !!column.isSort ? column.getSortByToggleProps() : {},
                            ])}
                          >
                            {column.render('Header')}
                            <span className="arrow-group">
                              {column.isSort && (
                                <ArrowGroupStyled
                                  {...(column.isSorted ? (column.isSortedDesc ? { top: true } : { bottom: true }) : {})}
                                />
                              )}
                            </span>
                          </TableTHCellStyled>
                        );
                      })}
                    </TableRowStyled>
                  );
                })}
              </TableHeadStyled>
              <TableBodyStyled {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRowStyled {...row.getRowProps(getRowProps(row))}>
                      {row.cells.map((cell) => {
                        const getProps = cell.column.getProps || defaultPropGetter;
                        const style = cell.column.style || {};
                        if (cell.column.minWidth) {
                          Object.assign(style, { minWidth: cell.column.minWidth });
                        }
                        return (
                          <TableCellStyled
                            isMiddleRow={isMiddleRow}
                            {...cell.getCellProps([
                              {
                                className: cell.column.className,
                                style,
                              },
                              getColumnProps(cell.column),
                              getCellProps(cell),
                              getProps(cell),
                            ])}
                          >
                            {cell.render('Cell')}
                          </TableCellStyled>
                        );
                      })}
                    </TableRowStyled>
                  );
                })}
              </TableBodyStyled>
              <TableFooterStyled>
                {isFooter &&
                  footerGroups.map((group) => (
                    <TableRowStyled {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => (
                        <TableCellStyled {...column.getFooterProps()}>{column.render('Footer')}</TableCellStyled>
                      ))}
                    </TableRowStyled>
                  ))}
              </TableFooterStyled>
            </TableStyled>
          </WrapperStyled>
        </CustomContainerScroll>
        {!isHiddenPagination && (
          <TablePaginationStyled>
            <span className="row-per-page">
              {LABEL.itemsPerPage}
              <select value={perPage} onChange={handleChangePageSize}>
                {rowsPerPageOptions.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </span>
            <span className="page">
              <strong>
                {from} - {to} of {totalRecord}
              </strong>
            </span>
            <div className="arrow">
              <button className="previous" onClick={handleChangePage(CHANGE_PAGE_ACTION.prev)} disabled={!canPreviousPage}>
                <ArrowLeftSVG stroke={getStyleIconPagination(!canPreviousPage)} />
              </button>
              <div className="vertical-line"></div>
              <button className="next" onClick={handleChangePage(CHANGE_PAGE_ACTION.next)} disabled={!canNextPage}>
                <ArrowRightSVG stroke={getStyleIconPagination(!canNextPage)} />
              </button>
            </div>
          </TablePaginationStyled>
        )}
      </TableContainer>
    );
  },
);

// Just use props have a comment "Can use", ecause other props else haven't impl yet
// We can ref the docs: https://react-table.tanstack.com/docs/overview
Table.propTypes = {
  data: PropTypes.array, // Can use
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // Renderers
      Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]), // Can use
      Header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]), // Can use
      Footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]), // Can use
      Aggregated: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
      Pivot: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
      PivotValue: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
      Expander: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
      Filter: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

      // All Columns
      sortable: PropTypes.bool,
      resizable: PropTypes.bool,
      filterable: PropTypes.bool,
      show: PropTypes.bool,
      minWidth: PropTypes.string, // can use

      // Cells only
      className: PropTypes.string, // Can use
      style: PropTypes.object, // Can use
      getProps: PropTypes.func, // Can use

      // Pivot only
      aggregate: PropTypes.func,

      // Headers only
      headerClassName: PropTypes.string, // Can use
      headerStyle: PropTypes.object, // Can use
      getHeaderProps: PropTypes.func, // Can use

      // Footers only
      footerClassName: PropTypes.string,
      footerStyle: PropTypes.object,
      getFooterProps: PropTypes.object,
      filterMethod: PropTypes.func,
      filterAll: PropTypes.bool,
      sortMethod: PropTypes.func,
    }),
  ),
  getHeaderProps: PropTypes.func, // Can use
  getColumnProps: PropTypes.func, // Can use
  getRowProps: PropTypes.func, // Can use
  getCellProps: PropTypes.func, // Can use

  // show the footer
  isFooter: PropTypes.bool, // Can use
  isHiddenPagination: PropTypes.bool, // Can use
  onPageChange: PropTypes.func, // Can use
  onPerPageChange: PropTypes.func, // Can use
  page: PropTypes.number, // Can use
  rowsPerPage: PropTypes.number, // Can use
  totalPage: PropTypes.number, // Can use. if we pass totalPage pamras, the table will change to pagination with api mode, default the pagination is local pagination
  rowsPerPageOptions: PropTypes.array, // Can use
  totalRecord: PropTypes.number, // Can use
  isSticky: PropTypes.bool, // can use, default true
  stickyTop: PropTypes.string, // Can use
  height: PropTypes.string,
  isMiddleRow: PropTypes.bool,
};

Table.defaultProps = {
  columns: [],
  data: [],
  isFooter: false,
  rowsPerPageOptions: rowsPerPageOptionsDefault,
  rowsPerPage: 10,
  onPageChange: () => {},
  onPerPageChange: () => {},
  page: 0,
  totalPage: undefined,
  isHiddenPagination: false,
  isSticky: true,
  stickyTop: '0px',
  isMiddleRow: false,
};

export default Table;

const WrapperStyled = styled.div<any>`
  display: block;
  max-width: 100%;
  font: normal normal normal 14px/16px Helvetica Neue;
  color: #3e3e3e;
  ${(props) =>
    !!props.height &&
    css`
      height: height;
    `}
`;

const TableStyled = styled.table`
  border-spacing: 0;
  width: 100%;
  min-width: 650px;
  overflow-x: auto;

  ${mobileQueryString} {
    min-width: auto;
  }
`;

const TableHeadStyled = styled.thead<any>`
  background-color: #ffffff;
  font: normal normal 600 14px/17px Helvetica Neue;
  display: table-header-group;
  & td {
    border-top: 1px solid #eeeeee;
  }

  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: ${(props: any) => props.stickyTop};
    `}

  ${mobileQueryString} {
    font-size: 13px;
    line-height: 16px;
    color: #5770c6;
  }
`;

const TableBodyStyled = styled.tbody`
  background-color: #ffffff;
`;

const TableFooterStyled = styled.tfoot`
  background-color: #ffffff;
`;

const TableRowStyled = styled.tr`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: bottom;
`;

const TableCellStyled = styled.td<any>`
  display: table-cell;
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
  vertical-align: ${(props) => (props.isMiddleRow ? 'middle' : 'inherit')};
  white-space: nowrap;
`;

const TableTHCellStyled = styled.td`
  display: table-cell;
  padding: 20px 0 15px 0;
  font-size: 14px;
  border-bottom: 1px solid #eeeeee;
  vertical-align: inherit;

  &:not(:last-child) {
    padding-right: 20px;
  }

  & .arrow-group {
    margin-left: 4px;
  }

  .arrow {
    padding: 1.2px !important;
  }
`;

const TablePaginationStyled = styled.div<any>`
  display: flex;
  padding: 17px 0 32px 16px;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  font: normal normal 600 14px/17px Helvetica Neue;
  & .arrow {
    margin-left: 15px;
    background: #eeeeeed1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    & .vertical-line {
      height: 18px;
      width: 1px;
      background: #d9d9d9 0% 0% no-repeat padding-box;
      border-radius: 1px;
    }
  }

  & button {
    outline: 0;
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    padding: 12px;
    background: none;

    & svg {
      opacity: 1;
    }
  }
  & select {
    margin-left: 7px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 3px;
    background: url(${ArrowBottomUrl}) no-repeat;
    background-position: calc(100% - 10.5px) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 11px !important;
    padding-left: 12px !important;
    min-width: 55px;
    height: 33px;
    font: normal normal normal 12px/14px Helvetica Neue;
    color: #3e3e3e;
  }
  & .row-per-page {
    color: #949494;
    font: normal normal normal 12px/14px Helvetica Neue;
  }
  & .page {
    margin-left: 20px;
    strong {
      color: #949494;
      font: normal normal normal 12px/14px Helvetica Neue;
    }
  }
`;

const ArrowGroupStyled = styled<any>(({ top = false, bottom = false, ...props }) => {
  return (
    <div {...props}>
      <span className="top">
        <i
          className={classNames('arrow up', {
            active: top,
          })}
        ></i>
      </span>
      <span className="bottom">
        <i
          className={classNames('arrow down', {
            active: bottom,
          })}
        ></i>
      </span>
    </div>
  );
})`
  display: inline-block;
  width: 8px;
  height: 6px;
  position: relative;
  & .top {
    position: absolute;
    top: -4px;
    left: 0px;
  }
  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 1.8px;
    border-color: #727272;
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .active {
    border-color: #3e3e3e;
  }
`;
const TableContainer = styled.div<any>``;
const CustomContainerScroll = styled<any>(ContainerScroll)`
  height: fit-content;
  max-height: ${(props) => props.maxHeight || '70vh'};
`;
