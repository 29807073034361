import { gql } from '@apollo/client';
export default gql`
  mutation UpdateMerchantSurvey(
    $id: uuid! = ""
    $is_currently_running_campaigns: Boolean = false
    $number_of_campaigns_annually: Int = 10
    $avg_kols_per_campaign: Int = 10
    $avg_budget_per_campaign_usd_cents: bigint = ""
    $additional_info: String = ""
  ) {
    update_client_survey_by_pk(
      pk_columns: { id: $id }
      _set: {
        number_of_campaigns_annually: $number_of_campaigns_annually
        is_currently_running_campaigns: $is_currently_running_campaigns
        avg_kols_per_campaign: $avg_kols_per_campaign
        avg_budget_per_campaign_usd_cents: $avg_budget_per_campaign_usd_cents
        additional_info: $additional_info
      }
    ) {
      id
    }
  }
`;
