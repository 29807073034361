import Typography from '../commons/Typography';

import styled from 'styled-components';

import { mobileQueryString } from '../../utils/responsive';

function AsideLeftWallet() {
  return (
    <ContainerStyled>
      <AsideTitleStyled>
        <Typography text="My Wallet" fontWeight="bold" />
      </AsideTitleStyled>
    </ContainerStyled>
  );
}

export default AsideLeftWallet;

const ContainerStyled = styled.div<any>`
  position: sticky;
  left: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
`;

const AsideTitleStyled = styled.div<any>`
  margin-bottom: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mobileQueryString} {
    margin-bottom: 21px;
  }
`;
