import { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { formatCurrencyInput } from '../../../../utils/formatCurrency';

import { ReactComponent as CloseSVG } from '../../../../assets/svg/close_black_24dp.svg';

import PopUpLayout from '../../popUpLayout/PopUpLayout';
import Button from '../../../commons/Button';
import useConvertCurrency from '../../../../hooks/useConvertCurrency';

const ApprovePayRequestPopUp = ({ handlePublish, handleApprove, onClose, currency, workContract }) => {
  const { exchangeRate } = useConvertCurrency() as any;
  const [amountUSD, setAmountUSD] = useState(0);
  const [amount, setAmount] = useState(0);

  const kol_profile = workContract?.kol_profile;
  const name = (kol_profile?.first_name || '') + ' ' + (kol_profile?.last_name || '');
  const amountUSDCent = workContract?.additional_pay_usd_cents;
  const reason = workContract?.additional_pay_reason;

  useEffect(() => {
    if (amountUSDCent === undefined || amountUSDCent === null || Number.isNaN(amountUSDCent)) {
      return;
    }
    setAmountUSD(Number(amountUSDCent / 100));
    setAmount(Number((amountUSDCent / 100) * exchangeRate));
  }, [amountUSDCent, exchangeRate]);

  return (
    <PopUpLayout width="560px">
      <Header>
        <HeaderTitle>Approve Pay Requested</HeaderTitle>
        <CloseSVG onClick={onClose} />
      </Header>
      <Content>
        <DescribeTitleStyled>KOL</DescribeTitleStyled>
        <DescribeNormalStyled>{name}</DescribeNormalStyled>

        <DescribeTitleStyled>AMOUNT</DescribeTitleStyled>
        <DescribeBoldStyled>
          {formatCurrencyInput(amount)} {currency}
        </DescribeBoldStyled>
        {currency !== 'USD' && <MoneyStyledSecondary>~{formatCurrencyInput(amountUSD, 2)} USD </MoneyStyledSecondary>}

        <DescribeTitleStyled2>REASON PROPOSED</DescribeTitleStyled2>
        <DescribeNormalStyled>{reason}</DescribeNormalStyled>
        <ButtonStyled label="ACCEPT & PAY NOW" onClick={handlePublish} />
        <ButtonStyled label="APPROVE PAY REQUEST" variant="secondary" onClick={handleApprove} />
      </Content>
    </PopUpLayout>
  );
};

export default memo(ApprovePayRequestPopUp);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 20px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 32px Inter;
  color: #000000;
`;

const Content = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 24px 16px 16px 24px;
`;

const MoneyStyled = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #000000;
`;

const MoneyStyledSecondary = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #717171;
`;

const DescribeStyled = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #5770c6;
  margin: 8px 0 40px;
`;

const DescribeTitleStyled = styled.div<any>`
  font: normal normal bold 14px/14px Inter;
  color: #717171;
  margin-bottom: 8px;
`;

const DescribeTitleStyled2 = styled.div<any>`
  font: normal normal bold 14px/14px Inter;
  color: #717171;
  margin-bottom: 8px;
  margin-top: 20px;
`;

const DescribeNormalStyled = styled.div<any>`
  font: normal normal regular 14px/14px Inter;
  color: #000000;
  margin-bottom: 24px;
`;

const DescribeBoldStyled = styled.div<any>`
  font: normal normal bold 14px/14px Inter;
  color: #000000;
  margin-bottom: 4px;
`;

const ButtonStyled = styled<any>(Button)`
  height: 46px;
`;
