import { gql } from '@apollo/client';
export default gql`
  query CountryList {
    country_category {
      code
      name
      phone_code
    }
  }
`;
