import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../../../../assets/svg/logo_svg.svg';
import { ROUTES } from '../../../../utils/constants';

function PublicCampaignTerm() {
  return (
    <>
      <HeaderContentStyled>
        <HeaderWrapper>
          <StyledLogoSVG />
        </HeaderWrapper>
      </HeaderContentStyled>
      <ScrollableArea>
        <h1>MERCHANT’S CAMPAIGN TERMS & CONDITIONS</h1>
        <p>
          Please read the terms and conditions (“T&C”) set forth below, as they apply to your use of our services (“Services”) (as
          defined below).
        </p>
        <p>
          Your execution and acceptance of this T&C shall constitute your acceptance of this T&C and agreement to be bound by them
          and any and all other applicable terms herein.
        </p>
        <H2Styled>DESCRIPTION OF SERVICES</H2Styled>
        <p>
          The Services provided by Conte Defee Marketing (M) Sdn Bhd (referred as “we”, “us”, “our” or “the Company”) are (a) the
          App, (b) the services provided through the App and/or by us, particularly the campaign to be posted by you through the
          App, and (c) all information, linked pages, features, data, text, images, photographs, graphics, music, sounds, video
          (including live streams), messages, tags, content, programming, software, application services (including, without
          limitation, any mobile application services) or other materials made available through the App. (“Services”).
        </p>
        <H2Styled>INTERPRETATION</H2Styled>
        <p>
          In this T&C, unless there is something in the subject or context inconsistent with such construction or unless it is
          otherwise expressly provided:
        </p>
        <p>
          (a) words denoting one gender include all other genders and words denoting the singular include the plural and vice
          versa;
        </p>
        <p>
          (b) words denoting persons include corporations, and vice versa and also include their respective estate, personal
          representatives, successors in title or permitted assigns, as the case may be;
        </p>
        <p>
          (c) any reference to a Clause, or party is to the relevant clause or party of or to this T&C and any reference to this
          T&C or any of the provisions hereof includes all amendments and modifications made to this T&C from time to time in
          force;
        </p>
        <p>
          (d) any reference to a statutory provision includes any modification, consolidation or re-enactment thereof for the time
          being in force, and all statutory instruments or orders made pursuant thereto;
        </p>
        <p>
          (e) any reference to “writing” or cognate expressions, includes any communications affected by e-mail, facsimile
          transmission or other comparable means; and
        </p>
        <p>
          (f) if any period of time is specified from a given day or the day of a given act or event, it is to be calculated
          exclusive of that day.
        </p>
        <p>
          The headings in this T&C are inserted merely for convenience of reference and shall be ignored in the interpretation and
          construction of any of the provisions herein contained.
        </p>
        <H2Styled>PACKAGE SELECTION</H2Styled>
        <p>
          Once you have selected and confirmed the package, you hereby agree and accept the provision of the Services on an as is
          where is basis according to the entitlements and/or benefits offered pursuant to the respective packages.
        </p>
        <H2Styled>PAYMENT</H2Styled>
        <p>
          You are responsible to ensure payment for the Services are made on a timely manner. Fifty percent (50%) of the total
          fees (based on the package selected) shall be paid upon selection of the package by you.
        </p>
        <p>
          In the event of late payment, you acknowledge and agree that we are entitled to impose a penalty equivalent to one point
          five percent (1.5%) of the total sum payable for the package selected as late payment administrative charges
          (“Penalty”). The Penalty shall be calculated on a monthly basis from the date following expiry of the stipulated payment
          timeline to the date of the actual payment. Alternatively, we may reject your application and the Services will not be
          provided to you.
        </p>
        <H2Styled>SERVICES</H2Styled>
        <p>
          Subject to the payment of the relevant fees, the Services shall commence from the date of your acceptance of this T&C
          until the completion of the Services and/or termination of the Services.
        </p>
        <p>We reserve the right at all times, where we deem appropriate to refuse to provide the Services to you.</p>
        <p>
          We also reserve the right to access, read, preserve, and disclose any information that you have provided to us as we
          reasonably believe is necessary to:
        </p>
        <p>(a) satisfy any applicable law, regulation, legal process or governmental request;</p>
        <p>(b) enforce the T&C, including investigation of potential violations hereof;</p>
        <p>(c) detect, prevent, or otherwise address fraud, security or technical issues; or</p>
        <p>(d) protect the rights, property or safety of the Company, other customers and the public.</p>
        <H2Styled>TERMINATION</H2Styled>
        <p>
          This T&C will continue to apply until terminated by either you or us. In the event the Services is terminated by you
          without cause, all fees paid you will be forfeited altogether.
        </p>
        <p>
          Notwithstanding anything contained herein, any provision of this T&C expressly or by implication is intended to come
          into or continue in force on or after termination of this T&C shall remain in full force and effect.
        </p>
        <p>
          Nothing in this section shall affect our rights to change, limit or stop the provision of the Services without prior
          notice, as provided in the Variations section herein contained.
        </p>
        <H2Styled>SERVICE INTERRUPTION DUE TO FORCE MAJEURE</H2Styled>
        <p>
          Either party shall be excused from any delay or failure in performance required hereunder if caused by reason of
          occurrence or contingency beyond its reasonable control, including, but not limited to, acts of God, acts of war, fire,
          laws, proclamations, edits, ordinances or regulations, riots, earthquakes, floods, explosions or other acts of nature.
        </p>
        <p>
          The obligations and rights of the party so excused shall be extended on a day-to-day basis for the time period equal to
          the period of such excusable interruption.
        </p>
        <p>
          When such events have abated, the parties’ respective obligations hereunder shall resume. In the event the interruption
          of the excused party’s obligations continues for a period in excess of thirty (30) days, either party shall have the
          right to terminate this T&C upon ten (10) days’ prior written notice to the other party.
        </p>
        <H2Styled>CONFIDENTIALITY</H2Styled>
        <p>
          You will maintain in confidence and will not disclose to third parties without our prior written consent (a) the
          specific terms of this T&C; and (ii) information that is proprietary or confidential to us.
        </p>
        <p>
          The confidentiality provisions of this T&C will not apply to and will exclude information generally available to the
          public, or disclosed to a third party by the owning party without restriction, information rightfully obtained from
          other sources, information independently developed by the receiving party or information previously known to a party
          (and all analyses, compilations, studies or other documents prepared by the parties, their agents, employees or
          professional advisors, which are based on such non-confidential information).
        </p>
        <H2Styled>RIGHTS TO CREATED CONTENT</H2Styled>
        <p>
          You hereby agree and accept that we will retain the right to use any and all content created by us for you, for the
          purpose of (a) providing our portfolio to other clients; or (b) instruction - including, but not limited to,
          presentations, lectures, webinars, and published material in any medium.
        </p>
        <H2Styled>LIABILITY WAIVER</H2Styled>
        <p>
          Please read this section carefully since it limits our liability including but not limited to related companies,
          officers, directors, employees, agents, representatives, partners, and licensors (collectively, the, “Entities of the
          Company”).
        </p>
        <p>
          Nothing in this section is intended to limit any rights you may have which may not be lawfully limited. All information
          is for your general reference only. We do not accept any responsibility whatsoever in respect of such information.
        </p>
        <p>Your access to and use of our services is at your sole risk and is provided “as is”, and, “as available”.</p>
        <p>The Entities of the Company shall not guarantee or assume any responsibility that:</p>
        <p>
          (a) the information presented in our Services is accurate, adequate, current or reliable, or may be used for any purpose
          other than for general reference;
        </p>
        <p>(b) the information presented in our Services is free of defect, error or omission;</p>
        <p>
          (c) messages sent through the internet including in connection with the Services will be free from interception,
          corruption, error, delay or loss; or
        </p>
        <p>(d) use of the Services will achieve any particular result or objective.</p>
        <p>
          Without limiting the generality of the foregoing, in no event will the Entities of the Company be liable to you or any
          other person for any direct, indirect, incidental, special, punitive or consequential loss or damages, including any
          loss of business or profit, goodwill, opportunity or reputation arising out of any use, or inability to use, or reliance
          on the information or the Services.
        </p>
        <p>The limitation of liability contained in this T&C will apply to the fullest extent permitted by applicable laws.</p>
        <H2Styled>INDEMNITY</H2Styled>
        <p>
          You agree to indemnify and hold us harmless from and against any and all claims, losses, expenses, demands or
          liabilities, including solicitor’s fees and costs, incurred by Entities of the Company in connection with any claim by a
          third party (including any intellectual property claim) arising out of:
        </p>
        <p>(a) your use of the Services in violation of this T&C or in violation of any applicable law; and</p>
        <p>(b) any claims brought against us by third parties resulting from you actions or inactions.</p>
        <p>
          You further agree that you will cooperate fully in the defence of any such claims. We reserve the right, at their own
          expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, and you
          shall not in any event settle any such claim or matter without our written consent.
        </p>
        <H2Styled>PARTNERSHIP</H2Styled>
        <p>
          You must not establish or assume a partnership with any third parties in such a way as to suggest any form of
          association, approval or endorsement on our part where none exists.
        </p>
        <H2Styled>SEVERANCE</H2Styled>
        <p>
          The illegality, invalidity or unenforceability of any provision of this T&C under the law of any jurisdiction shall not
          affect its legality, validity or enforceability under the laws of any other jurisdiction nor the legality, validity or
          enforceability of any other provision.
        </p>
        <H2Styled>VARIATIONS</H2Styled>
        <p>
          We reserve the right to amend and/ or revise this T&C at any time by amending the terms without assigning any reason
          thereto or highlighting to you what the changes are.
        </p>
        <p>
          If there is a conflict between two versions of the terms to which you have agreed or been deemed to agree, the more
          recent version shall take precedence unless it is expressly stated otherwise.
        </p>
        <H2Styled>WAIVER</H2Styled>
        <p>
          No failure or delay by a party to exercise any right or remedy provided under this T&C or by law shall constitute a
          waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other
          right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of
          that or any other right or remedy.
        </p>
        <H2Styled>GOVERNING LAW AND JURISDICTION</H2Styled>
        <p>
          This T&C shall be governed by the laws of Malaysia. You agree to submit to the exclusive jurisdiction of the courts of
          Malaysia.
        </p>
        <H2Styled>CONTACT METHOD</H2Styled>
        <p>
          If you have any enquiries in relation to the Services provided, please contact us at our email address hello@kols.asia
          and we will respond to your request promptly or within three (3) working days.
        </p>
        <H2Styled>PREVAILING LANGUAGE</H2Styled>
        <p>
          This T&C is drafted in the English language. If this T&C is translated into any other language, the English language
          version shall prevail.
        </p>
        <p>Any notice given under or in connection with this T&C shall be in the English language.</p>
        <H2Styled>INCONSISTENCY</H2Styled>
        <p>
          In the event of any inconsistency between any term in this T&C and the Merchant’s T&C, the provision under this T&C
          shall prevails.
        </p>
      </ScrollableArea>
    </>
  );
}

const CampaignPreviewHeader = () => {
  return (
    <Wrapper>
      <HeaderContentStyled style={{}}>
        <LinkStyled to={ROUTES.campaigns}>
          <LogoSVG />
        </LinkStyled>
        <div></div>
      </HeaderContentStyled>

      <TitleForm></TitleForm>
    </Wrapper>
  );
};

CampaignPreviewHeader.propTypes = {
  onCancel: PropTypes.func,
};

const HeaderWrapper = styled.div`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  padding-bottom: 20px;
  align-items: left;
  width: 100%;
  padding-left: 40px;
`;

const StyledLogoSVG = styled(LogoSVG)`
  width: 150px;
  height: auto;
`;

const H2Styled = styled.h2`
  padding-top: 20px;
`;

const ScrollableArea = styled.div`
  overflow: auto;
  height: 100%;
  padding: 40px;
  margin-top: 50px;
`;

const LinkStyled = styled<any>(Link)`
  max-width: 150px;

  svg {
    max-width: 100%;
    height: 22px;
  }
`;

const HeaderContentStyled = styled.div<any>`
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const Wrapper = styled.div<any>`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  border-bottom: 1px solid #eeeeee;
`;

const TitleForm = styled.div<any>`
  display: inline-block;
  font: normal normal 500 16px/19px Helvetica Neue;
  color: #5770c6;
  margin: 20px 20px 0 20px;
  padding-bottom: 16px;
  border-bottom: 2px solid #5770c6;
`;

export default PublicCampaignTerm;
