import React, { useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as ArrowforwardSVG } from '../../assets/svg/close_black_24dp.svg';
import SlideShow from '../commons/SlideShow';

const defaultState = {
  images: [],
  isOpen: false,
  onSetOpen: (x: any) => {},
  onSetImages: () => {},
};
export const ImageShowContext = React.createContext(defaultState);

const ImageShowProvider = (props) => {
  const [images, setImages] = useState<any>([]);
  const [isOpen, setOpen] = useState(false);

  const ctx = {
    images,
    onSetImages: setImages,
    isOpen,
    onSetOpen: setOpen,
  };

  return (
    <ImageShowContext.Provider value={ctx as any}>
      {props.children}
      <ImageShow />
    </ImageShowContext.Provider>
  );
};

const ImageShow = () => {
  const ctx = useContext<any>(ImageShowContext);
  const [force, setForce] = useState(Date.now());

  const isOpen = useMemo(() => {
    return ctx.isOpen || false;
  }, [ctx.isOpen]);

  const images = useMemo(() => {
    return ctx.images || [];
  }, [ctx.images]);

  const handleClose = () => {
    setForce(Date.now());
    ctx.onSetOpen(false);
  };

  return (
    <WrapperStyled open={isOpen}>
      <CloseWrapperStyled onClick={handleClose}>
        <ArrowforwardSVG />
      </CloseWrapperStyled>
      <SlideShow
        key={force}
        images={images}
        width="800px"
        imagesWidth="800px"
        imagesHeight="450px"
        imagesHeightMobile="56vw"
        thumbnailsWidth="920px"
        thumbnailsHeight="12vw"
        indicators
        fixedImagesHeight
      />
    </WrapperStyled>
  );
};

const WrapperStyled = styled.div<any>`
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 9999;
  ${(props) =>
    !!props.open &&
    css`
      display: flex;
    `}
  & .ss-indicators {
    top: 11px;
    & span {
      background: #858585 !important;
      border: 1px solid #858585 !important;
    }
    & span.ss-active {
      background: #fdfdfd !important;
      border: 1px solid #fdfdfd !important;
    }
  }
`;

const CloseWrapperStyled = styled.div<any>`
  display: inline-flex;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 68px;
  right: 85px;
  cursor: pointer;
`;

export default ImageShowProvider;
