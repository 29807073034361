import { memo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../../assets/svg/logo_svg.svg';
import { ReactComponent as BackSVG } from '../../../assets/svg/back.svg';
import ProfilePopover from '../ProfilePopover/ProfilePopover';
import { ROUTES } from '../../../utils/constants';
import { mobileQueryString } from '../../../utils/responsive';

const MainLayoutMobileGoBack = (props: any) => {
  const { asideLeft, asideRight, onGoback } = props;
  return (
    <Wrapper>
      <HeaderContentStyled>
        <div onClick={onGoback}>
          <BackSVG />
        </div>
        <LogoStyled>
          <A to={ROUTES.campaigns}>
            <LogoSVG />
          </A>
        </LogoStyled>
        <HeaderRightStyled>
          <ProfilePopover />
        </HeaderRightStyled>
      </HeaderContentStyled>
      <ContainerStyled mt="40px">
        <MainStyled>
          <AreaLeftStyled>{asideLeft}</AreaLeftStyled>
          <AreaRightStyled>{asideRight}</AreaRightStyled>
        </MainStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

export default memo(MainLayoutMobileGoBack);

// MainLayoutMobileGoBack.propTypes = {
//   asideRight: PropTypes.node,
//   asideLeft: PropTypes.node,
// };

const Wrapper = styled.div<any>`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
`;

const HeaderContentStyled = styled.div<any>`
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const HeaderRightStyled = styled.div<any>`
  display: flex;

  svg {
    cursor: pointer;

    .a {
      stroke: #5770c6;
    }
  }
`;

const A = styled<any>(Link)`
  cursor: pointer;
  text-decoration: none;
  display: block;
  min-width: 150px;
  height: 22px;
  margin-right: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const LogoStyled = styled.div<any>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 150px;
`;

const ContainerStyled = styled.div<any>`
  max-width: 100%;
  width: calc(100% - 170px);
  margin: 0 auto;
  margin-top: ${(props) => props.mt || 0};

  ${mobileQueryString} {
    width: 100%;
  }
`;

const MainStyled = styled.div<any>`
  display: flex;

  ${mobileQueryString} {
    flex-direction: column;
  }
`;

const AreaLeftStyled = styled.div<any>`
  width: 100%;
  max-width: 232px;
  flex-shrink: 0;
  margin-right: 68px;
  box-sizing: border-box;
  height: fit-content;

  ${mobileQueryString} {
    max-width: none;
    margin-right: 0;
    padding: 0 20px;
  }
`;

const AreaRightStyled = styled.div<any>`
  flex-grow: 1;
  margin-right: 50px;
  width: calc(100% - 232px - 68px);

  ${mobileQueryString} {
    width: 100%;
    margin-right: 0;
  }
`;
