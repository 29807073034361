import { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import ErrorMessageStyled from '../../components/commons/ErrorMessage';
import SurveyForm from '../../components/authentication/forms/SurveyForm';
import HeaderAuth from '../../components/authentication/HeaderAuth';
import StepCompanyDetail from '../../components/authentication/forms/StepCompanyDetail';

import UPDATE_USER from '../../queries/onboarding/updateMerchantUser';
import CREATE_PROFILE_USER from '../../queries/onboarding/createMerchantProfile';
import UPDATE_PROFILE_USER from '../../queries/onboarding/updateMerchantProfile';
import CREATE_SURVEY from '../../queries/onboarding/createMerchantSurvey';
import INVITE_MEMBER from '../../queries/teams/inviteMember';
import useGuard from '../../hooks/useGuard';
import useToastError from '../../hooks/useToastError';
import { PRIVATE_ROUTE, ROUTES } from '../../utils/constants';
import useConvertCurrency from '../../hooks/useConvertCurrency';
import ClientProfileContext from '../ClientProfileProvider';
import CookieUtil, { CookieKeys } from '../../utils/cookies';

const REGISTER_STEPS = {
  CompanyDetails: 2,
  Survey: 3,
};

const ClientRegister = () => {
  const history = useHistory();
  const [registerStep, setRegisterStep] = useState(REGISTER_STEPS.CompanyDetails);
  const [updateUser, { data: updateUserData }] = useMutation(UPDATE_USER);
  const [createProfile] = useMutation(CREATE_PROFILE_USER);
  const [updateProfile] = useMutation(UPDATE_PROFILE_USER);
  const [createSurvey, { data: createSurveyData }] = useMutation(CREATE_SURVEY);
  const [inviteMember] = useMutation(INVITE_MEMBER);
  const [user, setUser] = useState<any>({});
  const [listProfile, setListProfile] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyResult, setCompanyResult] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { exchangeRate } = useConvertCurrency();
  const { toastError } = useToastError();
  const { saveCurrentProfile, refetch } = useContext(ClientProfileContext);

  const {
    onRefreshProfile,
    user: currentUser,
    onUpdateUser,
    onUpdateProfile,
    isAccountActive,
    isCreateSurvey,
    isCreateCompanyDetail,
  } = useGuard();

  useEffect(() => {
    const newUserUpdate = updateUserData?.update_user_by_pk;
    if (!newUserUpdate) return;

    onUpdateUser(newUserUpdate);
  }, [onUpdateUser, updateUserData]);

  useEffect(() => {
    if (!createSurveyData || !companyResult) return;
    onRefreshProfile();
    if (!currentUser?.profile) return;
    history.push(ROUTES.campaigns);
  }, [createSurveyData, companyResult, currentUser, onRefreshProfile, history]);

  useEffect(() => {
    if (!!currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  async function submitCompanyDetailsForm(data) {
    const requestParams = {
      company_name: data.companyName,
      country_code: data.country,
      phone_number: data.phone,
      address: data.address,
      address2: data.address2,
      region: data.state,
      region2: data.city,
      postal_code: data.postalCode,
      display_currency: data.display_currency,
    };

    try {
      setErrorMessage('');
      setIsSubmitting(true);
      let newCompanyDetail;

      if (companyResult && companyResult.id) {
        const result = await updateProfile({
          variables: {
            id: companyResult.id,
            ...requestParams,
          },
        });
        newCompanyDetail = result.data.update_client_profile_by_pk;
      } else {
        const result = await createProfile({
          variables: {
            ...requestParams,
          },
        });
        newCompanyDetail = result.data.insert_client_profile_one;
      }
      onUpdateProfile(newCompanyDetail);
      setCompanyResult(newCompanyDetail);
      setListProfile([...listProfile, newCompanyDetail]);
      setRegisterStep(REGISTER_STEPS.Survey);
      setIsSubmitting(false);
    } catch (error) {
      handleErrorMessage(error);
      toastError('Something wrongs, please try again');
    }
  }

  async function submitSurveyForm(data) {
    if (!companyResult?.id) return;

    const requestParams = {
      client_profile_id: companyResult?.id,
      display_currency: companyResult?.display_currency || companyResult?.default_currency?.code,
      is_currently_running_campaigns: Boolean(+data.is_currently_running_campaigns),
      number_of_campaigns_annually: parseInt(data.number_of_campaigns_annually),
      avg_kols_per_campaign: parseInt(data.avg_kols_per_campaign),
      avg_budget_per_campaign_usd_cents: Math.floor((Number(data.avg_budget_per_campaign_usd_cents) * 100) / exchangeRate),
      additional_info: data.additional_info,
    };
    try {
      setErrorMessage('');
      setIsSubmitting(true);
      const result = await createSurvey({
        variables: {
          ...requestParams,
        },
      });
      if (!result?.data?.insert_client_survey_one) return;

      refetch();
      CookieUtil.set(CookieKeys.PROFILE_ID, companyResult?.id);
    } catch (error) {
      handleErrorMessage(error);
      toastError('Something wrongs, please try again');
    }
    setIsSubmitting(false);
  }

  function backToStep(nameStep) {
    if (parseInt(nameStep) <= 1) {
      saveCurrentProfile(undefined);
      history.push(PRIVATE_ROUTE.selectMerchant);
      return;
    }
    setRegisterStep(parseInt(nameStep));
  }

  function handleErrorMessage(error) {
    const message = error?.graphQLErrors[0] && error?.graphQLErrors[0]?.message;
    setErrorMessage(message);
    setIsSubmitting(false);
  }

  if (!currentUser) {
    return (
      <div className="auth0-callback">
        <p>Please wait</p>
      </div>
    );
  }

  function renderRegisterPage(STEP) {
    switch (STEP) {
      case REGISTER_STEPS.CompanyDetails: {
        return (
          <StepCompanyDetail
            submitCompanyDetailsForm={submitCompanyDetailsForm}
            backToStep={backToStep}
            isSubmitting={isSubmitting}
            profileData={companyResult || {}}
          />
        );
      }
      case REGISTER_STEPS.Survey: {
        return (
          <SurveyForm
            submitSurveyForm={submitSurveyForm}
            backToStep={backToStep}
            isSubmitting={isSubmitting}
            profileData={companyResult || {}}
            exchangeRate={exchangeRate}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <>
      <HeaderAuth />
      {renderRegisterPage(registerStep)}
      {errorMessage && <CustomErrorMessageStyled>{errorMessage}</CustomErrorMessageStyled>}
    </>
  );
};

export default ClientRegister;

const CustomErrorMessageStyled = styled<any>(ErrorMessageStyled)`
  max-width: 100%;
  width: 700px;
  margin: 19px auto 0;
`;
