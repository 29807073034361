import styled from 'styled-components';

import { ReactComponent as FacebookSVG } from '../../assets/svg/facebook.svg';
import { ReactComponent as FacebookBlackSVG } from '../../assets/svg/social-icon-white/facebook.svg';
import { ReactComponent as InstagramSVG } from '../../assets/svg/social-icon-link/instagram.svg';
import { ReactComponent as InstagramBlackSVG } from '../../assets/svg/social-icon-white/instagram.svg';
import { ReactComponent as TwitterSVG } from '../../assets/svg/twitter.svg';
import { ReactComponent as TwitterBlackSVG } from '../../assets/svg/social-icon-white/twitter.svg';
import { ReactComponent as YoutubeSVG } from '../../assets/svg/youtube-play.svg';
import { ReactComponent as YoutubeBlackSVG } from '../../assets/svg/social-icon-white/youtube.svg';
import { ReactComponent as TiktokSVG } from '../../assets/svg/tiktok.svg';
import { ReactComponent as TiktokBlackSVG } from '../../assets/svg/social-icon-white/tiktok.svg';
import { ReactComponent as L8SVG } from '../../assets/svg/social-icon-white/l8.svg';
import { ReactComponent as XHSSVG } from '../../assets/svg/social-icon-white/XHS.svg';
import { Link } from 'react-router-dom';

const CampaignIconSocial = (props: any): any => {
  const { platforms = [] } = props;
  return (
    <WrapperStyled>
      {platforms.map((platform: any, idx) => {
        const username = platform.platform_username || '';
        const userid = platform.platform_user_id || '';
        const code = platform.code || platform.category_code || platform.social_platform_category.code;
        switch (code) {
          case 'FACEBOOK':
            const fblink = `https://www.facebook.com/` + username;
            return username === '' || username === undefined ? (
              <FacebookBlackSVG key={idx} />
            ) : (
              <Link key={idx} className="social-link" to={{ pathname: fblink }} target="_blank" rel="noopener noreferrer">
                <FacebookSVG key={idx} />
              </Link>
            );
          case 'INSTAGRAM':
            const instaLink = `https://www.instagram.com/` + username;
            return username === '' || username === undefined ? (
              <InstagramBlackSVG key={idx} />
            ) : (
              <Link key={idx} className="social-link" to={{ pathname: instaLink }} target="_blank" rel="noopener noreferrer">
                <InstagramSVG key={idx} />
              </Link>
            );
          case 'TWITTER':
            const twitterLink = `https://www.instagram.com/` + username;
            return username === '' || username === undefined ? (
              <TwitterBlackSVG key={idx} />
            ) : (
              <Link key={idx} className="social-link" to={{ pathname: twitterLink }} target="_blank" rel="noopener noreferrer">
                <TwitterSVG key={idx} />
              </Link>
            );
          case 'YOUTUBE':
            const youtubeLink = `https://www.youtube.com/@` + username;
            return username === '' || username === undefined ? (
              <YoutubeBlackSVG key={idx} />
            ) : (
              <Link key={idx} className="social-link" to={{ pathname: youtubeLink }} target="_blank" rel="noopener noreferrer">
                <YoutubeSVG key={idx} />
              </Link>
            );
          case 'TIKTOK':
            const tiktokLink = `https://www.tiktok.com/` + username;
            return username === '' || username === undefined ? (
              <TiktokBlackSVG key={idx} />
            ) : (
              <Link key={idx} className="social-link" to={{ pathname: tiktokLink }} target="_blank" rel="noopener noreferrer">
                <TiktokSVG key={idx} />
              </Link>
            );
          case 'LEMON8':
            const lemon8Link = `https://www.lemon8.app.com/` + username;
            return username === '' || username === undefined ? (
              <L8SVG key={idx} />
            ) : (
              <Link key={idx} className="social-link" to={{ pathname: lemon8Link }} target="_blank" rel="noopener noreferrer">
                <L8SVG key={idx} />
              </Link>
            );
          case 'XIAOHONGSHU':
            const xiaohongshuLink = `https://www.xiaohongshu.com/user/profile/` + userid;
            return userid === '' || userid === undefined ? (
              <XHSSVG key={idx} />
            ) : (
              <Link
                key={idx}
                className="social-link"
                to={{ pathname: xiaohongshuLink }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <XHSSVG key={idx} />
              </Link>
            );
          default:
            return null;
        }
      })}
    </WrapperStyled>
  );
};

export default CampaignIconSocial;

const WrapperStyled = styled.div<any>`
  svg {
    width: 20px;
    height: 20px;
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`;
