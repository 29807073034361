import { gql } from '@apollo/client';

export default gql`
  mutation inviteEmailToMerchantProfile($email: String!, $client_profile_id: uuid!, $role: ClientProfileUserRole!) {
    inviteEmailToMerchantProfile(email: $email, client_profile_id: $client_profile_id, role: $role) {
      id
      email
      unregistered_email
      role
      client_profile_id
    }
  }
`;
