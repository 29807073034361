import flatten from 'flat';

const messages: any = {
  campaigns: {
    product: 'Product',
    qytOfKols: 'Kols (qty of kols)',
    totalRewards: 'Total Rewards',
    cash: 'Total Cash',
    Reward: 'Reward',
    pik: 'Total PIK',
    recruitmentDeadline: 'Recruitment Deadline',
    campaignPeriod: 'Campaign Period',
    campaignDetails: 'Campaign Details',
    kolsRequirements: 'Kols Requirements',
    kolAffinity: 'KOL Affinity',
    minFollowers: 'Min Followers',
    maxFollowers: 'Max Followers',
    dueDate: 'Due Date',
    tokenPerKoLs: 'KOL Compensation',
    pikValue: 'PIK VALUE',
    pikDescription: 'PIK Description',
    noOfKoLs: 'No. of KOLs',
    platforms: 'Platforms',
    ageGroup: 'Age Group',
    gender: 'Gender',
    deliverables: 'Deliverables',
    otherRequirements: 'Other Requirements',
    remunerationPerKol: 'KOL Compensation',
    totalBudget: 'Total Budget',
    campagnperiod: 'Campagn Period',
    noOfDeliverable: 'No. of deliverables',
    type: 'Type',
    deliverablesDescription: 'Deliverables description',
    languages: 'Languages',
  },
  chat: {
    cannotSendMessages: 'Cannot send messages',
  },
};

export default flatten(messages) as any;
