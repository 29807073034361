import { gql } from '@apollo/client';

const GET_WORK_CONTRACT_MESSAGES = gql`
  subscription GetWorkContractMessages($work_contract_id: uuid! = "") {
    work_contract_by_pk(id: $work_contract_id) {
      id
      kol_profile {
        first_name
        last_name
        kol_file_metadata {
          type
          file_metadata {
            url
            id
          }
          updated_at
        }
        user {
          id
          given_name
          picture {
            url
          }
        }
      }
      chat_room_relationship {
        chat_room {
          id
          description
          messages(order_by: { created_at: desc }) {
            created_at
            id
            participant_id
            plain_text
            user {
              id
              given_name
              picture {
                url
              }
            }
            attachments {
              file {
                id
                url
                content_type
                size_bytes
              }
            }
            approval_request {
              id
              created_at
              approval_status_code
              updated_at
            }
          }
        }
      }
    }
  }
`;

export default GET_WORK_CONTRACT_MESSAGES;
