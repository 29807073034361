/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import useToastError from './useToastError';

import GET_LIST_CAMPAIGN from '../queries/campaignCreation/listCampaign';
import VIEW_CAMPAIGN_DETAIL from '../queries/campaignCreation/viewCampaignDetail';
import UPDATE_CAMPAIGN from '../queries/campaignCreation/updateCampaign';
import APPROVE_WORK_ITEM from '../queries/workItem/approveWorkItem';
import REJECT_WORK_ITEM from '../queries/workItem/rejectWorkItem';
import { FEATURE_KEY, useFeatureFlag } from './useFeatureFlag';
import useGuard from '../hooks/useGuard';
import ClientProfileContext from '../containers/ClientProfileProvider';

export default function useCampaign(clientProfileId?: string) {
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [campaignId, setCampaignId] = useState<any>();
  const [draftCampaignId, setDraftCampaignId] = useState<any>();
  const [campaignDetail, setCampaignDetail] = useState<any>();
  const [listCampaign, setListCampaign] = useState<any>([]);
  const { toastError } = useToastError();
  const [isAdditionalEnable, setIsAdditionalEnable] = useState(false);
  const { isFeatureFlagEnable, loading, isFeatureFlagEnableAsync } = useFeatureFlag();
  const { currentProfile } = useContext(ClientProfileContext);

  const { user } = useGuard();
  let clientProfileIdToGet = '';
  if (clientProfileId) {
    clientProfileIdToGet = clientProfileId;
  } else {
    clientProfileIdToGet = currentProfile?.id ?? '';
  }

  const [getListCampaign, { data: listCampaignData, loading: campaignLoading, error: campaignError, called: campaignCalled }] =
    useLazyQuery<any>(GET_LIST_CAMPAIGN, {
      variables: {
        limit,
        offset,
        client_profile_id: clientProfileIdToGet,
      },
    });

  const [
    fetchCampaignById,
    { data: campaignDetailData, loading: loadingCampaignDetail, error: errorCampaignDetail, call: campaignDetailCalled },
  ] = useLazyQuery(VIEW_CAMPAIGN_DETAIL) as any;

  const [approveWorkItem] = useMutation(APPROVE_WORK_ITEM);

  const [rejectWorkItem] = useMutation(REJECT_WORK_ITEM);

  useEffect(() => {
    setIsAdditionalEnable(isFeatureFlagEnable(FEATURE_KEY.additionalRemuneration) === true);
  }, [loading, isFeatureFlagEnable]);

  const handleGetCampaignById = async (id) => {
    setCampaignId(id);
    const isAdditionalEnable = await isFeatureFlagEnableAsync(FEATURE_KEY.additionalRemuneration);
    fetchCampaignById({
      variables: {
        id,
        additionalEnable: isAdditionalEnable,
      },
    });
  };

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (loadingCampaignDetail) return;
    if (campaignDetailData && campaignDetailData.campaign_by_pk) {
      setCampaignDetail(campaignDetailData.campaign_by_pk);
    }
  }, [campaignDetail, loadingCampaignDetail, campaignDetailData]);

  useEffect(() => {
    if (campaignLoading || campaignError) return;
    if (listCampaignData && Array.isArray(listCampaignData.campaign)) {
      setListCampaign([...listCampaignData.campaign]);
    }
  }, [listCampaignData, campaignLoading, campaignError]);

  const handleUpdateCampaign = async (dataRequest) => {
    try {
      const result = await updateCampaign({
        variables: {
          ...dataRequest,
        },
      });
      if (result && result.data && result.data.update_campaign) {
        handleGetCampaignById(campaignId);
        Sentry.captureMessage('Update campaign successful', { level: 'info' });
      }
    } catch (error) {
      Sentry.captureException(error);
      toastError('Something wrongs, please try again');
    }
  };

  const handleApproveWorkItem = async (workItemId) => {
    try {
      await approveWorkItem({
        variables: {
          workItemId,
        },
      });
      handleGetCampaignById(campaignId);
    } catch (error) {
      toastError('Something wrongs, please try again');
    }
  };

  const handleRejectWorkItem = async (workItemId) => {
    try {
      await rejectWorkItem({
        variables: {
          workItemId,
        },
      });
      handleGetCampaignById(campaignId);
    } catch (error) {
      toastError('Something wrongs, please try again');
    }
  };

  const handleClearCampaignSelected = () => {
    setCampaignDetail(null);
    setDraftCampaignId(null);
    setCampaignId(null);
  };

  return {
    listCampaign,
    campaignDetail,
    errorCampaignDetail,
    campaignDetailCalled,
    loadingCampaignDetail,
    draftCampaignId,
    campaignError,
    campaignLoading,
    campaignCalled,
    onGetListCampaign: getListCampaign,
    onChangeLimit: setLimit,
    onSetOffset: setOffset,
    onGetCampaignById: handleGetCampaignById,
    onUpdateCampaign: handleUpdateCampaign,
    onSetDraftCampaignId: setDraftCampaignId,
    onApproveWorkItem: handleApproveWorkItem,
    onRejectWorkItem: handleRejectWorkItem,
    onSetCampaignDetail: setCampaignDetail,
    onClearCampaignSelected: handleClearCampaignSelected,
  };
}
