import { gql } from '@apollo/client';

const MARK_NOTIFICATION_READ = gql`
  mutation MarkNotificationsRead($ids: [uuid!]) {
    update_notification(where: { id: { _in: $ids } }, _set: { unread: false }) {
      affected_rows
    }
  }
`;

export default MARK_NOTIFICATION_READ;
