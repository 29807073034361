import React, { useState, useEffect, useRef, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useClickAway } from 'react-use';

import LabelStyled from './Label';
import Tag from './Tag';
import ErrorMessageStyled from './ErrorMessage';

const TextFieldTag = forwardRef<any, any>((props, ref) => {
  const { renderOption } = props;
  // States
  const [tags, setTags] = useState<any>([]);
  const [subtitle, setSubTitle] = useState('');
  const [listItemCustom, setListItemCustom] = useState<any>([]);
  const [isShowListItem, setIsShowListItem] = useState(false);

  // Refs
  const inputRef = useRef<any>(null);
  const canDispatch = useRef<any>(false);
  const listItemRef = useRef<any>(null);
  // set default value
  useEffect(() => {
    const defaultValue = props.values;
    if (defaultValue && defaultValue.length) {
      setTags(defaultValue);
      canDispatch.current = true;
    }
  }, [props.values]);

  useEffect(() => {
    if (!canDispatch.current) return;
    canDispatch.current = false;
    const evt = new Event('input', { bubbles: true, cancelable: true });
    Object.defineProperty(evt, 'target', {
      writable: false,
      value: {
        value: listItemCustom.filter((l) => l.checked),
        name: props.name,
      },
    });
    props.onChange(evt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, props.name]);

  // overwrite setter
  useEffect(() => {
    if (!inputRef.current) return;
    // Overwrite setter, if react-form-hook set value. we can catch the value from it and do something
    Object.defineProperty(inputRef.current, 'value', {
      set(newValue) {
        if (Array.isArray(newValue)) {
          setTags(newValue.filter((e) => e.checked));
          setListItemCustom(newValue);
        } else {
          setTags([]);
        }
      },
    });
  }, []);

  function onChangeSubtitle(e) {
    const { value } = e.target;
    setSubTitle(value);
  }

  function handleAddTag(e) {
    if (e.keyCode === 27 || searchListItem.length === 0) {
      setSubTitle('');
      setIsShowListItem(false);
      e.target.blur();
    }
    if (e.keyCode !== 13) return;
    canDispatch.current = true;
    if (searchListItem.length > 0) {
      handleItemSelected(searchListItem[0]);
      setTags((prevState) => [...prevState, searchListItem[0]]);
      setSubTitle('');
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function onClose(tagItem) {
    canDispatch.current = true;
    setTags((prevState) => {
      const newTags = [...prevState];
      const tagIndex = newTags.findIndex((tag) => tag.value === tagItem.value);
      const newArray = listItemCustom.map((ls) => {
        if (ls.value === tagItem.value) {
          return {
            ...ls,
            checked: false,
          };
        }
        return ls;
      });
      setListItemCustom(newArray);
      newTags.splice(tagIndex, 1);
      return newTags;
    });
  }

  const searchListItem = useMemo(() => {
    return listItemCustom
      .filter((ls) => !ls.checked)
      .filter((ls) => {
        if (!subtitle) return true;
        return renderOption(ls).toLowerCase().includes(subtitle.toLowerCase());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtitle, isShowListItem, tags.length]);

  const onSelectItem = (item) => {
    canDispatch.current = true;
    handleItemSelected(item);
    setSubTitle('');
    setTags((prevState) => [...prevState, item]);
  };

  const handleItemSelected = (item) => {
    const idx = listItemCustom.findIndex((ls) => ls.value === item.value);
    const newArray = [
      ...listItemCustom.slice(0, idx),
      {
        ...listItemCustom[idx],
        checked: true,
      },
      ...listItemCustom.slice(idx + 1),
    ];
    setListItemCustom(newArray);
  };

  useClickAway(listItemRef, () => {
    setIsShowListItem(false);
  });

  return (
    <WrapperStyled fullwidth={props.fullWidth} className={props.className}>
      <LabelStyled htmlFor={props.name}>{props.label}</LabelStyled>
      <input
        type="hidden"
        ref={(el) => {
          (ref as any)(el);
          inputRef.current = el;
        }}
        name={props.name}
      />
      <ContentStyled error={props.error} $isVisible={isShowListItem} ref={listItemRef}>
        <LeadStyled>
          {tags.map((tag, index) => (
            <Tag key={`${tag?.value}-${index}`} text={renderOption(tag)} onClose={() => onClose(tag)} />
          ))}
          <InputStyled
            type="text"
            value={subtitle}
            onChange={onChangeSubtitle}
            onFocus={() => setIsShowListItem(true)}
            onKeyDown={handleAddTag}
          />
        </LeadStyled>
        <ListItemSelectBoxStyled $isVisible={isShowListItem}>
          {searchListItem.map((ls, idx) => (
            <ItemSelectBoxStyled key={idx} onClick={() => onSelectItem(ls)}>
              <TextItemStyled>{renderOption(ls)}</TextItemStyled>
            </ItemSelectBoxStyled>
          ))}
          {!searchListItem || (searchListItem.length === 0 && <EmptyMessageStyled>No item</EmptyMessageStyled>)}
        </ListItemSelectBoxStyled>
      </ContentStyled>
      {props.error && <ErrorMessageStyled>{props.error}</ErrorMessageStyled>}
    </WrapperStyled>
  );
});

export default TextFieldTag;

TextFieldTag.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  renderOption: PropTypes.func,
};
TextFieldTag.defaultProps = {
  label: 'KOL Affinity',
  name: 'KOL Affinity',
  fullWidth: false,
  renderOption: (item) => item.name,
};

const WrapperStyled = styled.div<any>`
  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}
`;

const ContentStyled = styled.div<any>`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  padding: 10px 12px 0px 12px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  min-height: 46px;
  position: relative;
  ${(props) =>
    props.error &&
    css`
      border-color: #f44336;
    `}
  ${(props) =>
    props.$isVisible &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const LeadStyled = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const InputStyled = styled.input<any>`
  border: 0;
  outline: 0;
  flex-grow: 1;
  margin-bottom: 8px;
`;

const ListItemSelectBoxStyled = styled.div<any>`
  display: none;
  flex-wrap: wrap;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  margin-top: 1px;
  box-shadow: -1px 1px 2px rgb(67 70 74 / 0%), -2px 2px 5px rgb(67 86 100 / 12%);
  width: calc(100% + 2px);
  box-sizing: border-box;
  border-top: none;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  top: 100%;
  left: -1px;
  max-height: 300px;
  overflow-y: scroll;
  ${(props) =>
    props.$isVisible &&
    css`
      display: flex;
      border: 1px solid #d9d9d9;
      border-top: none;
    `}
`;

const ItemSelectBoxStyled = styled.div<any>`
  padding: 0px 20px;
  width: 100%;
  &:not(:first-child) {
    border-top: 1px solid #d9d9d9;
  }
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:hover::after {
    display: block;
  }
`;
const TextItemStyled = styled.span<any>`
  display: block;
  transition: all 0.1s;
  color: #3e3e3e;
  font-size: 14px;
  font-family: 'Helvetica Neue';
  font-weight: 500;
  line-height: 17px;
  padding: 14px 0;
`;
const EmptyMessageStyled = styled.p<any>`
  margin: 0;
  padding: 10px 12px;
  font-family: 'Helvetica Neue';
  line-height: 17px;
  font-size: 14px;
`;
