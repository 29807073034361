import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, generatePath } from 'react-router-dom';
import { onUserVar } from '../cache/action';
import Button from '../components/commons/Button';
import { ROUTES } from '../utils/constants';

function Dashboard(props) {
  const { logout } = useAuth0();
  const history = useHistory();
  const handleLogout = (e) => {
    e.preventDefault();
    logout({
      returnTo: `${window.location.origin}${ROUTES.login}`,
    });
    onUserVar({
      isReady: true,
    });
  };
  return (
    <>
      <Button onClick={handleLogout} variant="primary" label="Logout"></Button>
      <hr />
      <Button
        onClick={() => {
          history.push(ROUTES.campaigns);
        }}
        label=" Go to campaigns page"
      />
      <hr />
      <Button
        onClick={() => {
          const path = generatePath(ROUTES.campaignsDetails, { id: 'xxxx' });
          history.push(path + '?mode=0');
        }}
        label=" Go to campaigns detail page"
      />
    </>
  );
}

export default Dashboard;
