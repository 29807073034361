import React, { useEffect, useState, useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { mobileQueryString } from '../../utils/responsive';
import { PRIVATE_ROUTE } from '../../utils/constants';

import MainLayout from '../../components/layouts/MainLayout/MainLayout';
import AsideLeftStatistic from '../../components/teams/AsideLeftStatistic';
import CommonTypography from '../../components/commons/Typography';
import FooterMobileCampaign from '../../components/footerMobileCampaign';
import useGuard from '../../hooks/useGuard';
import {
  Stack,
  TextField,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from '@mui/material';

import INVITE_MEMBER from '../../queries/teams/inviteMember';
import LIST_MEMBER from '../../queries/teams/listMember';
import useToastError from '../../hooks/useToastError';
import useToastSuccess from '../../hooks/useToastSuccess';
import PopUpLayout from '../../components/popUp/popUpLayout/PopUpLayout';
import { ReactComponent as CloseSVG } from '../../assets/svg/close_black_24dp.svg';
import { useApolloClient } from '@apollo/client';
import { UPDATE_MERCHANT_ROLE, DELETE_MERCHANT } from '../../queries/teams/roles';
import ClientProfileContext from '../../containers/ClientProfileProvider';

function Teams() {
  const history = useHistory();
  const { user } = useGuard();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [inviteMember] = useMutation(INVITE_MEMBER);
  const { toastError } = useToastError();
  const { toastSuccess } = useToastSuccess();
  const client = useApolloClient();
  const { currentProfile } = useContext(ClientProfileContext);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleAddMember = async () => {
    if (email === undefined || validateEmail(email) === false) {
      setEmailError('Invalid email');
      return;
    }
    const existMember = members.find((member) => member.email === email || member.unregistered_email === email);
    if (existMember !== undefined) {
      setEmailError('Member already exists');
      return;
    }
    setEmailError('');
    await inviteMember({
      variables: {
        client_profile_id: currentProfile?.id,
        email: email,
        role: 'Member',
        grant_by_user_id: user.id,
      },
    })
      .then((_res) => {
        toastSuccess(`Member ${email} added successfully`);
        setEmail('');
        refetch();
      })
      .catch((_err) => {
        toastError('Something wrongs, please try again');
      });
  };

  const { data, refetch } = useQuery(LIST_MEMBER, {
    variables: {
      client_profile_id: currentProfile?.id,
    },
  });
  useEffect(() => {
    setMembers(data?.client_profile_user_role ?? []);
  }, [data]);
  const [members, setMembers] = useState<{ id: any; role: any; email: any; unregistered_email: any; isAccepted: boolean }[]>([]);
  const isOnlyOneOwner = useMemo(() => {
    return members.filter((member) => member.role === 'Owner').length <= 1;
  }, [members]);
  const isAdmin = useMemo(() => {
    return members.find((member) => member.email === user.email)?.role === 'Owner';
  }, [members, user]);

  const isGrid = false;

  const gotoListCampaign = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const gotoListTeam = () => {
    history.push(PRIVATE_ROUTE.teams);
  };

  const renderHeaderCenter = (
    <MenuItemStyled isActive={false} onClick={gotoListCampaign}>
      <CommonTypography text="Campaigns" color="#5770C6" fontSize="16px / 19px" fontWeight="400" />
    </MenuItemStyled>
  );

  const renderHeaderTeam = (
    <MenuItemStyled isActive={true} onClick={gotoListTeam}>
      <CommonTypography text="Teams" color="#5770C6" fontSize="16px / 19px" fontWeight="600" />
    </MenuItemStyled>
  );

  const renderAsideRight = (
    <>
      <TopStyled isModeGrid={isGrid}>
        <ToggleStyled>
          {currentProfile?.isAdmin() && (
            <Stack direction={'row'} spacing={'16px'} height={'44px'}>
              <TextField
                variant="outlined"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                sx={{ height: '44px', width: '264px' }}
                error={emailError !== ''}
                helperText={emailError}
              />
              <Button variant="contained" onClick={handleAddMember} sx={{ width: '146px', height: '44px' }}>
                Add Member
              </Button>
            </Stack>
          )}
        </ToggleStyled>
      </TopStyled>

      <ListStyled>
        <TableContainer sx={{ maxHeight: 'calc(100vh - 312px)', border: 'none', boxShadow: 'none' }} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ height: '46px' }}>
                <TableCell sx={{ maxWidth: '120px' }}>Name</TableCell>
                <TableCell sx={{ maxWidth: '100px' }}>Role</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow key={member.id} sx={{ minHeight: '50px' }}>
                  <TableCell sx={{ maxWidth: '120px' }}>
                    {member.isAccepted ? (
                      <Typography sx={{ height: '40px', marginTop: '24px' }} variant="h6">
                        {member.email ?? member.unregistered_email}
                      </Typography>
                    ) : (
                      <Typography sx={{ height: '40px', marginTop: '24px', color: '#717171', fontStyle: 'italic' }} variant="h6">
                        {member.unregistered_email ?? member.email}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell sx={{ maxWidth: '100px' }}>
                    {(!isOnlyOneOwner || member.role !== 'Owner') && member.isAccepted && isAdmin && (
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, height: '50px', paddingTop: '12px' }}>
                        <Select
                          value={member.role}
                          sx={{ border: 'none' }}
                          disableUnderline
                          onChange={(expectedRole) => {
                            setShowChangeRoleDialog(true);
                            setChangeRoleData({
                              email: member.email,
                              role: expectedRole.target.value as string,
                              id: member.id,
                              unregistered_email: member.unregistered_email,
                            });
                          }}
                        >
                          <MenuItem key={'Owner'} value={'Owner'} sx={{ height: '50px' }}>
                            Owner
                          </MenuItem>
                          <MenuItem key={'Member'} value={'Member'} sx={{ height: '50px' }}>
                            Member
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {((isOnlyOneOwner && member.role === 'Owner') || !isAdmin) && member.isAccepted && (
                      <Typography sx={{ height: '40px', marginTop: '24px' }} variant="h6">
                        {member.role}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {(!isOnlyOneOwner || member.role !== 'Owner') && isAdmin && (
                      <Button
                        variant="text"
                        color="error"
                        sx={{ flexGrow: 1 }}
                        onClick={() => {
                          setShowDeleteMerchantDialog(true);
                          setDeleteMerchantData({
                            email: member.email,
                            role: member.role,
                            id: member.id,
                            unregistered_email: member.unregistered_email,
                          });
                        }}
                      >
                        REMOVE
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ListStyled>
    </>
  );

  const getDisplayedEmail = (data: any) => {
    if (data.email) {
      return data.email;
    } else if (data.unregistered_email) {
      return data.unregistered_email;
    } else {
      return '';
    }
  };

  //Update roles
  const [showChangeRoleDialog, setShowChangeRoleDialog] = useState(false);
  const [changeRoleData, setChangeRoleData] = useState<
    { email: string; role: string; id: any; unregistered_email: string } | undefined
  >(undefined);
  const updateMerchantRole = () => {
    client
      .mutate({
        mutation: UPDATE_MERCHANT_ROLE,
        variables: {
          id: changeRoleData?.id,
          role: changeRoleData?.role,
        },
      })
      .then((result) => {
        toastSuccess('Role updated successfully');
        refetch();
      })
      .catch((error) => {
        console.error('updateMerchantRole_Error:', error);
        toastError('Something wrongs, please try again');
      });
  };

  function ConfirmChangeRoleDialog() {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1001,
        }}
      >
        <PopUpLayout width={`${window.innerWidth / 3}px`}>
          {' '}
          <Header>
            <HeaderTitle>Confirm</HeaderTitle>
            <CloseSVG
              onClick={() => {
                setShowChangeRoleDialog(false);
              }}
            />
          </Header>
          <Content>
            {changeRoleData && (
              <p style={{ color: '#5770C6', fontWeight: 'bold' }}>
                Are you sure you want to change role of {changeRoleData.role} to {getDisplayedEmail(changeRoleData)}?
              </p>
            )}

            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
                borderRadius: '10px',
                background: 'linear-gradient(to right, #B54EC9, #7D62EC, #628FEE, #57AEF1)',
                color: 'white',
                fontWeight: 'bold',
                height: '46px',
              }}
              onClick={() => {
                setShowChangeRoleDialog(false);
                updateMerchantRole();
              }}
            >
              CONFIRM
            </div>
            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10px',
                borderRadius: '10px',
                backgroundColor: 'white',
                color: '#717171',
                fontWeight: 'bold',
                height: '46px',
              }}
              onClick={() => {
                setShowChangeRoleDialog(false);
              }}
            >
              CANCEL
            </div>
          </Content>
        </PopUpLayout>
      </div>
    );
  }

  //Remove members
  const [showDeleteMerchantDialog, setShowDeleteMerchantDialog] = useState(false);
  const [deleteMerchantData, setDeleteMerchantData] = useState<
    { email: string; role: string; id: any; unregistered_email: string } | undefined
  >(undefined);
  const deleteMerchant = () => {
    client
      .mutate({
        mutation: DELETE_MERCHANT,
        variables: {
          id: deleteMerchantData?.id,
        },
      })
      .then((result) => {
        toastSuccess('Member removed successfully');
        refetch();
      })
      .catch((error) => {
        console.error('deleteMerchant_Error:', error);
        toastError('Something wrongs, please try again');
      });
  };

  function ConfirmDeleteMechantDialog() {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1001,
        }}
      >
        <PopUpLayout width={`${window.innerWidth / 3}px`}>
          {' '}
          <Header>
            <HeaderTitle>Confirm</HeaderTitle>
            <CloseSVG
              onClick={() => {
                setShowDeleteMerchantDialog(false);
              }}
            />
          </Header>
          <Content>
            {deleteMerchantData && (
              <p style={{ color: '#5770C6', fontWeight: 'bold' }}>
                Are you sure you want to remove {getDisplayedEmail(deleteMerchantData)} from this merchant?
              </p>
            )}

            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
                borderRadius: '10px',
                background: 'linear-gradient(to right, #B54EC9, #7D62EC, #628FEE, #57AEF1)',
                color: 'white',
                fontWeight: 'bold',
                height: '46px',
              }}
              onClick={() => {
                setShowDeleteMerchantDialog(false);
                deleteMerchant();
              }}
            >
              CONFIRM
            </div>
            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10px',
                borderRadius: '10px',
                backgroundColor: 'white',
                color: '#717171',
                fontWeight: 'bold',
                height: '46px',
              }}
              onClick={() => {
                setShowDeleteMerchantDialog(false);
              }}
            >
              CANCEL
            </div>
          </Content>
        </PopUpLayout>
      </div>
    );
  }

  return (
    <>
      <MainLayout
        hasButtonNew
        headerCenter={renderHeaderCenter}
        headerTeam={renderHeaderTeam}
        asideLeft={<AsideLeftStatistic menu={[]} total={members.length} />}
        asideRight={renderAsideRight}
      />
      <FooterMobileCampaign />
      {showChangeRoleDialog && <ConfirmChangeRoleDialog />}
      {showDeleteMerchantDialog && <ConfirmDeleteMechantDialog />}
    </>
  );
}

export default Teams;

const MenuItemStyled = styled.div<any>`
  ${(props) =>
    props.isActive &&
    css`
      > div {
        font-weight: bold;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #5770c6 0% 0% no-repeat padding-box;
        border-radius: 5px 5px 0px 0px;
        opacity: 1;
      }
    `}
`;

const TopStyled = styled.div<any>`
  text-align: right;
  padding-bottom: 32px;

  ${(props) =>
    props.isModeGrid &&
    css`
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
    `}

  ${mobileQueryString} {
    display: none;
  }
`;

const ToggleStyled = styled.div<any>`
  display: inline-block;
`;

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 20px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const ListStyled = styled.div<any>`
  table thead tr td {
  }

  ${mobileQueryString} {
    display: none;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  color: #000000;
`;

const Content = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 0px 20px 20px;
`;
