import React from 'react';
import { ReactComponent as ArrowBackUrl } from '../../../../assets/svg/arrow_back_ios_black_24dp.svg';
import { ReactComponent as ArrowforwardUrl } from '../../../../assets/svg/arrow_forward_ios_black_24dp.svg';

const Arrows = (props) => {
  const { onLeftClick, onRightClick } = props;
  return (
    <div className="ss-arrows">
      <span className="ss-arrow-left" onClick={onLeftClick}>
        <ArrowBackUrl fill="#fff" />
      </span>
      <span className="ss-arrow-right" onClick={onRightClick}>
        <ArrowforwardUrl fill="#fff" />
      </span>
    </div>
  );
};

export default Arrows;
