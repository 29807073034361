import { REACT_APP_WEBHOOKS_BASE_URL } from '../configs';

export function toCommas(value: any) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const UPLOAD_FILE_URL = REACT_APP_WEBHOOKS_BASE_URL + '/files';
const DEFAULT_COMMA_CURRENCY = ',';
export async function uploadFileHandler(file: any) {
  const formData = new FormData();
  formData.append('file', file);

  return await fetch(UPLOAD_FILE_URL, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json());
}

export const formatCurrency = (value: string | number) => {
  if (!(typeof value === 'string' || typeof value === 'number')) return 0;
  if (typeof value === 'number') {
    return value;
  }
  let newValue = parseInt(value);
  if (value.includes(DEFAULT_COMMA_CURRENCY)) {
    newValue = parseInt(value.split(DEFAULT_COMMA_CURRENCY).join(''));
    return !!newValue ? newValue : 0;
  }
  return newValue;
};

export function isHTML(str: string): boolean {
  const htmlPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlPattern.test(str);
}
