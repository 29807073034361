import { gql } from '@apollo/client';
export default gql`
  mutation UpdateCampaign(
    $id: uuid!
    $start_date: timestamptz!
    $end_date: timestamptz!
    $description: String!
    $recruitment_end_date: timestamptz!
  ) {
    update_campaign(
      where: { id: { _eq: $id } }
      _set: {
        start_date: $start_date
        end_date: $end_date
        description: $description
        recruitment_end_date: $recruitment_end_date
      }
    ) {
      returning {
        id
      }
    }
  }
`;
