import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../../assets/svg/logo_svg.svg';
import ContainerStyled from './Container';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';
import { mobileQueryString } from '../../utils/responsive';

const HeaderAuth = () => {
  return (
    <Header>
      <ContainerStyled>
        <LogoContainer>
          <A to={ROUTES.campaigns}>
            <LogoSVG />
          </A>
        </LogoContainer>
        <MenuLink>
          <MenuItem>
            <A to="/">FAQ</A>
          </MenuItem>
          <MenuItem>
            <A to="/">About Us</A>
          </MenuItem>
        </MenuLink>
      </ContainerStyled>
    </Header>
  );
};

export default HeaderAuth;

const Header = styled.header`
  width: 100%;
  height: 80px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 29px 0;
    box-sizing: border-box;
  }

  ${mobileQueryString} {
    display: none;
  }
`;

const LogoContainer = styled.div<any>`
  width: 150px;
  height: 22px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const A = styled<any>(Link)`
  cursor: pointer;
  text-decoration: none;
  display: block;
`;

const MenuLink = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  > li:last-child a {
    margin-right: 0;
  }
`;
const MenuItem = styled.li<any>`
  > a {
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: #323953;
    font: normal normal normal 18px/23px Helvetica Neue;
    opacity: 0.6;
    margin-right: 40px;
  }
`;
