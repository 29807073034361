/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { onListCategoryValue } from '../cache/action';

import GET_CURRENT_LIST_CATEGORY_VALUE from '../queries/local/getListCategoryValue';
import GET_LIST_CATEGORY_VALUE from '../queries/campaignCreation/categoryValues';
import { CommonData } from '../types';

export default function useCommon() {
  const { data: listCategoryLocal } = useQuery<{ listCategoryValue: CommonData }>(GET_CURRENT_LIST_CATEGORY_VALUE, {
    fetchPolicy: 'cache-only',
  });
  const [getListCategoryValue, { data: listCategoryValue, loading: listCategoryLoading, error: listCategoryError }] =
    useLazyQuery<CommonData>(GET_LIST_CATEGORY_VALUE);

  useEffect(() => {
    if (listCategoryLoading || listCategoryError) return;
    if (listCategoryValue) {
      onListCategoryValue(listCategoryValue);
    }
  }, [listCategoryLoading, listCategoryError, listCategoryValue]);

  return {
    listCategory: (listCategoryLocal?.listCategoryValue || {}) as CommonData,
    onRefreshListCategory: getListCategoryValue,
  };
}
