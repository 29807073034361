import { memo } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { REACT_APP_STRIPE_PUBLIC_KEY } from '../../configs';

const Stripe = ({ children }) => {
  const promiseStripe = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY as any);

  return <Elements stripe={promiseStripe}>{children}</Elements>;
};

export default memo(Stripe);
