import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../../../../assets/svg/logo_svg.svg';
import { ROUTES } from '../../../../utils/constants';

const CreateCampaignDetailHeader = ({ onNext, onCancel }) => {
  return (
    <Wrapper>
      <HeaderContentStyled>
        <CancelStyled onClick={onCancel}>Cancel</CancelStyled>
        <LinkStyled to={ROUTES.campaigns}>
          <LogoSVG />
        </LinkStyled>
        <NextStyled onClick={onNext}>Next</NextStyled>
      </HeaderContentStyled>
      <ContainerStyled mt="40px">
        <MainStyled>
          <Header>New Campaign</Header>
        </MainStyled>
      </ContainerStyled>
      <TitleForm>1.Campaign Details</TitleForm>
    </Wrapper>
  );
};

export default memo(CreateCampaignDetailHeader);

CreateCampaignDetailHeader.propTypes = {
  onNext: PropTypes.func,
  onCancel: PropTypes.func,
};

const TextStyled = styled.div<any>`
  color: #5770c6;
  text-transform: uppercase;
`;

const CancelStyled = styled<any>(TextStyled)`
  font: normal normal 400 12px/14px Helvetica Neue;
`;

const NextStyled = styled<any>(TextStyled)`
  font: normal normal 500 12px/15px Helvetica Neue;
`;

const LinkStyled = styled<any>(Link)`
  max-width: 150px;

  svg {
    max-width: 100%;
    height: 22px;
  }
`;

const HeaderContentStyled = styled.div<any>`
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const Wrapper = styled.div<any>`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
`;

const ContainerStyled = styled.div<any>`
  max-width: 100%;
  margin: 71px auto 0;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid #eeeeee;
`;

const MainStyled = styled.div<any>`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const Header = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  margin: 15px 0 23px 0;
  color: #000;
`;

const TitleForm = styled.div<any>`
  font: normal normal 500 16px/19px Helvetica Neue;
  color: #5770c6;
  margin: 20px;
`;
