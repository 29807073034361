import { gql } from '@apollo/client';

export default gql`
  query ListMember($client_profile_id: uuid) {
    client_profile_user_role(where: { client_profile_id: { _eq: $client_profile_id } }) {
      email
      id
      role
      isAccepted
      unregistered_email
      client_profile {
        id
        user {
          picture {
            url
          }
        }
      }
    }
  }
`;

export const LIST_MECHANT_INVITED_ME = gql`
  query ListMerchantInvitedMe($email: String) {
    client_profile_user_role(where: { email: { _eq: $email } }) {
      email
      id
      client_profile_id
      role
      isAccepted
      client_profile {
        id
        company_name
        logo_file {
          url
        }
      }
    }
  }
`;
