import React, { useEffect, useMemo, useState, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Button from '../../commons/Button';
import CampaignIconSocial from '../CampaignIconSocial';
import { useHistory, generatePath } from 'react-router-dom';

import useCommonApi from '../../../hooks/useCommon';
import { mobileQueryString } from '../../../utils/responsive';
import CampaignReportOverview, { MetricData, fetchMetricData } from '../CampaignReport/Overview/CampaignReportOverview';
import ContentMetricsArea from './ContentMetricField/ContentMetricsArea';
import DeliverableArea from './ContentMetricField/DeliverableArea';
import { getTierFromFanbase } from '../CampaignReport/ContentPerformance/ContentPerformance';
import { useApolloClient } from '@apollo/client';
import ActionButtons from './ActionButtons/ActionButtons';
import {
  UPDATE_WORK_ITEM_METRIC,
  UPDATE_KOL_CAMPAIGN_METRIC,
  INSERT_WORK_ITEM_METRIC,
  INSERT_KOL_CAMPAIGN_METRIC,
} from './queries/queries';
import { ROUTES } from '../../../utils/constants';

export const CAMPAIGN_REPORT_TAB = {
  overview: 'OVERVIEW',
  content: 'CONTENT_PERFORMANCE',
  kol: 'KOL_PERFORMANCE',
};

interface ContentMetricsUIProps {
  campaignId: string;
  kolProfileId: string;
}

const ContentMetricsUI = forwardRef<any, ContentMetricsUIProps>((props, ref) => {
  const history = useHistory();
  const { listCategory, onRefreshListCategory } = useCommonApi();
  var [metricData, setMetricData] = useState<MetricData[]>([]);

  const client = useApolloClient();

  const backToCampaign = () => {
    var path = generatePath(ROUTES.campaignsReport, { id: props.campaignId });
    history.push(path);
  };

  const updateMetrics = async (metricData: MetricData[]) => {
    for (const data of metricData) {
      //const timestamp =  data.postOn.toISOString().replace('T', ' ').substring(0, 19);
      const timestamp = data.postOn?.toISOString() ?? new Date().toISOString();
      if (data.workItemMetricId === undefined) {
        await client.mutate({
          mutation: INSERT_WORK_ITEM_METRIC,
          variables: {
            spend_usd_cents: data.spend,
            post_on_date: timestamp,
            reach: data.reach,
            views: data.views,
            likes: data.engagement,
            shares: data.shares,
            ig_post_comments: data.ig_post_comments,
            ig_post_saves: data.ig_post_saves,
            work_item_id: data.workItemId,
          },
        });
      } else {
        await client.mutate({
          mutation: UPDATE_WORK_ITEM_METRIC,
          variables: {
            spend_usd_cents: data.spend,
            post_on_date: timestamp,
            reach: data.reach,
            views: data.views,
            likes: data.engagement,
            shares: data.shares,
            ig_post_comments: data.ig_post_comments,
            ig_post_saves: data.ig_post_saves,
            id: data.workItemMetricId,
          },
        });
      }
    }
    const firstMetric = metricData[0];
    if (firstMetric.campaign_kol_metric_id === undefined) {
      await client.mutate({
        mutation: INSERT_KOL_CAMPAIGN_METRIC,
        variables: {
          fan_base: firstMetric.fanbase,
          campaign_id: firstMetric.campaignId,
          kol_profile_id: firstMetric.kolProfileId,
          platform_handle: 'INSTAGRAM',
        },
      });
    } else {
      await client.mutate({
        mutation: UPDATE_KOL_CAMPAIGN_METRIC,
        variables: {
          fan_base: firstMetric.fanbase,
          id: firstMetric.campaign_kol_metric_id,
        },
      });
    }
    refreshData();
    displayUpdatedMessage();
  };

  const displayUpdatedMessage = () => {
    window.alert('Data updated!');
  };

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    fetchMetricData(client, props.campaignId).then((data) => {
      const dataByKolProfileId = data.filter((item) => item.kolProfileId === props.kolProfileId);
      setMetricData(dataByKolProfileId);
    });
  };

  const updateFanbase = (kolProfiles: MetricData[], kolProfileId: string, fanbase: number): MetricData[] => {
    return kolProfiles.map((kolProfile) => {
      if (kolProfile.kolProfileId === kolProfileId) {
        return { ...kolProfile, fanbase, tier: getTierFromFanbase(fanbase), kolName: kolProfile.kolName };
      }
      return kolProfile;
    });
  };

  useEffect(() => {
    if (Object.keys(listCategory).length === 0) {
      onRefreshListCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (metricData.length === 0) {
    return <div></div>;
  }

  const DeliverableAreas = Array.from({ length: metricData.length }, (_, index) => (
    <DeliverableArea
      data={metricData[index]}
      key={index}
      onDataChange={(data) => {
        const newMetricData = metricData.map((item) => {
          if (item.workItemId === metricData[index].workItemId) {
            return data;
          }
          return item;
        });
        setMetricData(newMetricData);
      }}
    />
  ));

  return (
    <WrapperStyled>
      <div className="scrollView" style={{ overflowY: 'scroll', height: '100vh' }}>
        <ActionButtons
          onSave={() => {
            updateMetrics(metricData);
          }}
          onDiscard={() => {
            backToCampaign();
          }}
        />
        <ContentMetricsArea
          title="KOL Details"
          kol={metricData[0].instagramId ?? ''}
          fanbase={metricData[0].fanbase}
          tier={metricData[0].kolTier}
          onFanbaseChange={(kolProfileId: string, fanbase: number) => {
            const newMetricData = updateFanbase(metricData, kolProfileId, fanbase);

            setMetricData(newMetricData);
          }}
          kolProfileId={metricData[0].kolProfileId}
        />
        {DeliverableAreas}
      </div>
    </WrapperStyled>
  );
});

export default ContentMetricsUI;

const WrapperStyled = styled.div<any>`
  & .tools {
    display: inline-flex;
    & button {
      margin-left: 20px;
    }
  }

  ${mobileQueryString} {
    padding: 0 20px;

    & .tools {
      display: none;
    }
  }
`;

const LOKHeaderStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const LOKHeaderTabStyled = styled.div<any>`
  ${mobileQueryString} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid #eeeeee;
  }
`;

const LOKHeaderButtonStyled = styled.button<any>`
  font: normal normal normal 16px/18px Helvetica Neue;
  color: #949494;
  display: inline-flex;
  padding: 10px 20px 50px 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      color: #5770c6;
      border-bottom: 2px solid #5770c6;
      font-weight: 500;
    `}

  ${mobileQueryString} {
    padding: 0 0 20px 0;
    margin-right: 25px;
  }
`;

const LOKHeaderSearchStyled = styled.div<any>`
  height: 46px;
  width: 292px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  --padding-right: 22px;
  padding: 10px var(--padding-right);
  box-sizing: border-box;
  border-radius: 5px;
  & input {
    width: calc(100% - var(--padding-right));
    border: none;
    font: normal normal normal 14px/16px Helvetica Neue;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #949494;
    }
  }
  & .icon {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ButtonActionStyled = styled<any>(Button)`
  width: 87px;
  height: 38px;
  ${(props) =>
    props.variant === 'secondary' &&
    css`
      & {
        background-color: transparent;
        color: #949494;
      }
      &:hover {
        background-color: #949494;
        color: #fff;
      }
    `}
  ${(props) =>
    props.variant === 'primary' &&
    css`
      & {
        background: transparent;
        color: #5770c6;
      }
      &:hover {
        background: transparent linear-gradient(263deg, #51adf0 0%, #666bfa 49%, #b64dc8 100%) 0% 0% no-repeat padding-box;
        color: #fff;
      }
    `}
    ${(props) =>
    !!props.disabled &&
    css`
      &:hover {
        background-color: transparent;
        color: #949494;
      }
    `}
`;

const ActionCellStyled = styled.div<any>`
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const KolCard = (props: any) => {
  const {
    name = '',
    gender = '',
    avatar = '',
    platform = [],
    isAccepted = false,
    onGoToChat = () => {},
    onGoToDeliverables = () => {},
  } = props;
  return (
    <KolCardStyled>
      <div className="left">
        <img src={avatar} alt="" />
      </div>
      <div className="right">
        <div className="name">{name}</div>
        {isAccepted && (
          <div className="action-navigation">
            <div onClick={onGoToDeliverables}>Deliverables</div>
            <div className="chat-label" onClick={onGoToChat}>
              Chat
            </div>
          </div>
        )}
        <div className="bottom">
          <div className="gender">{gender}</div>
          <CampaignIconSocial platforms={platform} />
        </div>
      </div>
    </KolCardStyled>
  );
};

const KolCardStyled = styled.div<any>`
  display: flex;
  flex-direction: row;
  & .left {
    img {
      border-radius: 5px;
      width: 43px;
      height: 43px;
    }
  }
  & .right {
    margin-left: 28px;
  }
  & .name {
    color: #0b0b0b;
    text-align: left;
    font: normal normal bold 16px/19px Helvetica Neue;
  }
  & .action-navigation {
    display: flex;
    margin-bottom: 7px;
    color: #5770c6;
    font: normal normal bold 12px/15px Helvetica Neue;
    & .chat-label {
      margin-left: 13px;
    }
    & > div {
      cursor: pointer;
    }
  }
  & .bottom {
    display: flex;
    & .gender {
      font: normal normal normal 13px/15px Helvetica Neue;
      color: #949494;
      text-transform: capitalize;
      margin-right: 18px;
    }
  }
`;

const StatusStyled = styled.div<any>`
  & .status {
    font: normal normal normal 14px/16px Helvetica Neue;
    margin-bottom: 6px;
  }
  & .step {
    color: #949494;
    font: normal normal normal 14px/16px Helvetica Neue;
  }
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

const DownloadButtonStyled = styled.button<any>`
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  width: 160px;
  height: 46px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #5770c6;
  cursor: pointer;
  & span {
    margin-left: 12px;
  }
`;

const ButtonMobileWrapper = styled.div<any>`
  display: none;

  ${mobileQueryString} {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    column-gap: 10px;
  }
`;

const ButtonMobile = styled.div<any>`
  height: 30px;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  font-size: 12px;
  color: #5770c6;

  & svg {
    width: 13px;
    height: 13px;
    fill: #5770c6;
  }
`;
