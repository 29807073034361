import React, { useState, useEffect, useMemo, useCallback } from 'react';
import MainLayout from '../../components/layouts/MainLayout/MainLayout';
import AsideLeftCampaignDetail from '../../components/campaigns/AsideLeftCampaignDetail';
import CampaignDeliverableComponent from '../../components/campaigns/CampaignDeliverable';
import { formatDistanceToNow, format } from 'date-fns';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import Typography from '../../components/commons/Typography';
import { FORMAT_DATE_DEFAULT, PRIVATE_ROUTE, ROUTES } from '../../utils/constants';
import useCampaign from '../../hooks/useCampaign';
import useMobile from '../../hooks/useMobile';

import MainLayoutGoBack from '../../components/layouts/MainLayout/MainLayoutGoBack';

const CampaignDeliverables = () => {
  let { id, contractID } = useParams() as any;
  const isMobile = useMobile();
  const [isLoading, setLoading] = useState(true);
  const { campaignDetail, onGetCampaignById, onApproveWorkItem, onRejectWorkItem } = useCampaign();
  const [listWorkItem, setListWorkItem] = useState<any>([]);
  const [kolProfile, setKolProfile] = useState([]);

  const history = useHistory();

  // owner of Chatbox, this infomation will be showing in header of chatbox
  const owner = useMemo(() => {
    if (!campaignDetail || !campaignDetail?.client_profile) return {};
    return {
      name: campaignDetail?.client_profile?.company_name, // string or nullable
      avatar: campaignDetail?.client_profile?.logo_file?.url, // string or nullable
      lastActivityTime: formatDistanceToNow(new Date(campaignDetail?.created_at)), // instance of Date or string
      status: campaignDetail?.status.toLowerCase(), // string or nullable
    };
  }, [campaignDetail]);

  useEffect(() => {
    if (!contractID) return;

    if (!campaignDetail || campaignDetail?.id !== id) {
      onGetCampaignById(id);
      return;
    }
    const listContracts = campaignDetail?.tranches
      .map((tranche) => tranche.work_contracts)
      .flat()
      ?.filter((contract) => contract.status.code === 'CLIENT_APPROVED')
      ?.filter((contract) => contract.id === contractID);

    const listWorkItem = listContracts?.map((contract) => contract?.work_items).flat();

    setListWorkItem(listWorkItem);

    if (listContracts && listContracts.length > 0) {
      const kolProfile = listContracts[0].kol_profile;
      setKolProfile(kolProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignDetail, contractID, id]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const listWorkItemMapping = useMemo(() => {
    if (!Array.isArray(listWorkItem)) return [];
    return listWorkItem.map((item: any) => ({
      ...item,
      time: format(new Date(item?.deliverable_spec?.due_date), FORMAT_DATE_DEFAULT),
      status: item?.status?.code,
      text: item?.deliverable_spec?.requirement_description,
    }));
  }, [listWorkItem]);

  const handleApprove = async (request) => {
    if (!request?.id) return;
    await onApproveWorkItem(request.id);
  };

  const handleReject = async (request) => {
    if (!request?.id) return;
    await onRejectWorkItem(request.id);
  };

  const handleCancelApprove = (request) => {
    console.log('cancel approve', request);
  };

  const handleCancelReject = (request) => {
    console.log('cancel reject', request);
  };

  const handleSave = (payload) => {
    console.log('save', payload);
  };

  const gotoListCampaign = () => {
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const gotoListTeams = () => {
    history.push(PRIVATE_ROUTE.teams);
  };

  const renderHeaderCenter = (
    <MenuItemStyled onClick={gotoListCampaign}>
      <Typography text="Campaigns" color="#5770C6" fontSize="16px / 19px" />
    </MenuItemStyled>
  );

  const renderHeaderTeam = (
    <MenuItemStyled isActive={false} onClick={gotoListTeams}>
      <Typography text="Teams" color="#5770C6" fontSize="16px / 19px" fontWeight="400" />
    </MenuItemStyled>
  );

  const backToCampaignDetail = () => {
    const path = generatePath(`${ROUTES.campaignsListOfKols}?tab=KOL_APPLIED`, { id: id });
    history.push(path);
  };

  const height = '66vh';

  const handleGoBack = useCallback(() => {
    const path = generatePath(ROUTES.campaignsListOfKols, { id });
    history.push(path);
  }, [history, id]);

  return (
    <>
      {isMobile ? (
        <MainLayoutGoBack
          onGoback={handleGoBack}
          asideLeft={
            <AsideLeftCampaignDetail type={2} data={campaignDetail} id={id} backToStep={backToCampaignDetail} owner={owner} />
          }
          asideRight={
            <CampaignDeliverableComponent
              height={height}
              owner={owner}
              kolProfile={kolProfile}
              loading={isLoading}
              data={listWorkItemMapping}
              onSave={() => handleSave('payload')}
              onApprove={handleApprove}
              onReject={handleReject}
              onCancelApprove={handleCancelApprove}
              onCancelReject={handleCancelReject}
            />
          }
        />
      ) : (
        <MainLayout
          hasButtonNew
          headerCenter={renderHeaderCenter}
          headerTeam={renderHeaderTeam}
          asideLeft={<AsideLeftCampaignDetail type={2} data={campaignDetail} id={id} backToStep={backToCampaignDetail} />}
          asideRight={
            <CampaignDeliverableComponent
              height={height}
              owner={owner}
              kolProfile={kolProfile}
              loading={isLoading}
              data={listWorkItemMapping}
              onSave={() => handleSave('payload')}
              onApprove={handleApprove}
              onReject={handleReject}
              onCancelApprove={handleCancelApprove}
              onCancelReject={handleCancelReject}
            />
          }
        />
      )}
    </>
  );
};

export default CampaignDeliverables;

const MenuItemStyled = styled.div<any>``;
