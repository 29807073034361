import { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

const useToastSuccess = () => {
  const { addToast } = useToasts();

  const toastSuccess = useCallback(
    (message) => {
      addToast(message, { appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000 });
    },
    [addToast],
  );

  return {
    toastSuccess,
  };
};

export default useToastSuccess;
