import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function IconButton({ icon, bgColor, ...props }) {
  return (
    <ButtonStyled bgColor={bgColor} {...props}>
      {icon}
    </ButtonStyled>
  );
}

export default IconButton;

IconButton.propTypes = {
  bgColor: PropTypes.string,
  icon: PropTypes.node,
};

IconButton.defaultProps = {
  bgColor: 'rgba(219, 16, 142, .1)',
};

const ButtonStyled = styled.button<any>`
  width: 32px;
  height 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  border-radius: 5px;
  background-color: ${(props) => props.bgColor || 'rgba(219, 16, 142, .1)'};

  svg {
    width: 17px;    
  }
`;
