import { jsPDF } from 'jspdf';

export default function useExport() {
  const handleExportPdf = (dom, name, options = {}) => {
    var doc = new jsPDF('p', 'pt', 'a4');
    doc.html(dom, {
      callback: function (doc) {
        if (!!name) {
          doc.save(name);
          return;
        }
        doc.save();
      },
      ...options,
    });
  };

  return {
    onExportPdf: handleExportPdf,
  };
}
