import React, { forwardRef, useMemo, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import RadioInput from '../../commons/RadioInput';
import TextFieldNumber from '../../commons/TextFieldNumber';
import TextFieldCurrency from '../../commons/TextFieldCurrency';
import TextField from '../../commons/TextField';
import StepperRegister from '../StepperRegister';
import StepperRegisterMobile from '../StepperRegisterMobile';
import Button from '../../commons/Button';
import { mobileQueryString } from '../../../utils/responsive';
import { PRIVATE_ROUTE } from '../../../utils/constants';
import { formatCurrencyInput } from '../../../utils/formatCurrency';
import { formatCurrency } from '../../../utils/heplers';
import ClientProfileContext from '../../../containers/ClientProfileProvider';

const SurveyForm = forwardRef<any, any>((props, ref) => {
  const { pathname } = useLocation();
  const { submitSurveyForm, backToStep, isSubmitting, profileData = {}, isUpdate, buttonFormSubmitRef, exchangeRate } = props;
  const { register, handleSubmit, formState, reset, setValue } = useForm({
    defaultValues: profileData,
  });
  const { errors } = formState;
  const { currentProfile } = useContext(ClientProfileContext);
  const isAdmin = !isUpdate || (currentProfile !== undefined && currentProfile.isAdmin());

  function onSubmit(values) {
    const avg_budget_per_campaign_usd_cents = formatCurrency(values.avg_budget_per_campaign_usd_cents);
    const newValue = {
      ...values,
      avg_budget_per_campaign_usd_cents,
    };
    submitSurveyForm(newValue);
  }

  const influencerMarketingCampaigns = useMemo(
    () => ({
      options: [
        {
          name: 'Yes',
          value: '1',
        },
        {
          name: 'No',
          value: '0',
        },
      ],
      label: 'Have you or are currently running influencer marketing campaigns?',
      name: 'is_currently_running_campaigns',
      defaultValue: profileData?.client_survey?.is_currently_running_campaigns ? '1' : '0',
    }),
    [profileData?.client_survey?.is_currently_running_campaigns],
  );

  const campaignAnnually = useMemo(
    () => ({
      label: 'How many campaign annually',
      defaultValue: 0,
      name: 'number_of_campaigns_annually',
    }),
    [],
  );

  const kolsPerCampaign = useMemo(
    () => ({
      label: 'How many kols per campaign on average',
      defaultValue: 0,
      name: 'avg_kols_per_campaign',
    }),
    [],
  );

  const budget = useMemo(
    () => ({
      label: 'How much budget per campaign on average',
      defaultValue: 0,
      symbol: profileData?.default_currency?.symbol || '$',
      name: 'avg_budget_per_campaign_usd_cents',
    }),
    [profileData?.default_currency?.symbol],
  );

  const additionalInfo = useMemo(
    () => ({
      label: 'Additional info',
      placeholder: 'How can we help?',
      type: 'text',
      name: 'additional_info',
    }),
    [],
  );

  const setValueForm = useCallback(() => {
    if (!profileData) return;
    reset(profileData);
  }, [profileData, reset]);

  useEffect(() => {
    if (isUpdate) {
      setValueForm();
      setTimeout(() => {
        setValue(
          budget.name,
          formatCurrencyInput(
            (profileData?.client_survey?.avg_budget_per_campaign_usd_cents / 100) * exchangeRate,
            profileData?.default_currency?.fraction_size,
          ),
        );
        setValue(influencerMarketingCampaigns.name, profileData?.client_survey?.is_currently_running_campaigns ? '1' : '0');
        setValue(campaignAnnually.name, profileData?.client_survey?.number_of_campaigns_annually || 0);
        setValue(kolsPerCampaign.name, profileData?.client_survey?.avg_kols_per_campaign || 0);
        setValue(additionalInfo.name, profileData?.client_survey?.additional_info || '');
      }, 100);
    }
  }, [
    profileData,
    isUpdate,
    exchangeRate,
    setValueForm,
    setValue,
    budget.name,
    influencerMarketingCampaigns.name,
    campaignAnnually.name,
    kolsPerCampaign.name,
    additionalInfo.name,
  ]);

  return (
    <>
      {!isUpdate && <StepperRegister active="2" backToStep={backToStep}></StepperRegister>}
      {(pathname === PRIVATE_ROUTE.merchantRegister || pathname === PRIVATE_ROUTE.clientRegister) && (
        <StepperRegisterMobile title="Survey" order="2/2" active="2" backToStep={backToStep} />
      )}
      <Form onSubmit={handleSubmit(onSubmit)} mt="26px" isUpdate={isUpdate}>
        <WrapperStyled>
          <SurveyFormStyled>
            <GroupItemStyled mb="20px">
              <RadioInput
                disabled={!isAdmin}
                gap="40px"
                {...influencerMarketingCampaigns}
                {...register(influencerMarketingCampaigns.name)}
                error={errors[influencerMarketingCampaigns.name]?.message}
              />
            </GroupItemStyled>
            <GroupItemStyled>
              {isAdmin ? (
                <TextFieldNumber
                  {...campaignAnnually}
                  {...register(campaignAnnually.name)}
                  error={errors[campaignAnnually.name]?.message}
                />
              ) : (
                <TextField {...campaignAnnually} {...register(campaignAnnually.name)} disabled />
              )}
              {isAdmin ? (
                <TextFieldNumber
                  {...kolsPerCampaign}
                  {...register(kolsPerCampaign.name)}
                  error={errors[kolsPerCampaign.name]?.message}
                />
              ) : (
                <TextField {...kolsPerCampaign} {...register(kolsPerCampaign.name)} disabled />
              )}
            </GroupItemStyled>
            <GroupItemStyled itemWidth="calc(50% - 15px)">
              {isAdmin ? (
                <TextFieldCurrency {...budget} {...register(budget.name)} error={errors[budget.name]?.message} />
              ) : (
                <TextField {...budget} {...register(budget.name)} disabled />
              )}
            </GroupItemStyled>
            <GroupItemStyled>
              {isAdmin ? (
                <TextField {...additionalInfo} {...register(additionalInfo.name)} error={errors[additionalInfo.name]?.message} />
              ) : (
                <TextField {...additionalInfo} {...register(additionalInfo.name)} disabled />
              )}
            </GroupItemStyled>
            {!isUpdate && (
              <ButtonStyled disabled={isSubmitting} label={isSubmitting ? 'Please wait' : 'Done'} width="211px" type="submit" />
            )}
          </SurveyFormStyled>
        </WrapperStyled>
        {isUpdate && (
          <>
            <HideButtonStyled ref={buttonFormSubmitRef} type="submit"></HideButtonStyled>
          </>
        )}
      </Form>
    </>
  );
});

SurveyForm.propTypes = {
  submitSurveyForm: PropTypes.func,
  backToStep: PropTypes.func,
  isUpdate: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

SurveyForm.defaultProps = {
  isUpdate: false,
  isSubmitting: false,
};

export default SurveyForm;

const WrapperStyled = styled.div<any>`
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;
`;

const SurveyFormStyled = styled.div<any>`
  width: 100%;

  ${mobileQueryString} {
    padding: 20px 0 0;
  }
`;

const GroupItemStyled = styled.div<any>`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  margin-bottom: ${(props) => props.mb || '1px'};
  ${(props) =>
    !!props.itemWidth &&
    css`
      & > * {
        width: ${props.itemWidth};
      }
    `}

  ${mobileQueryString} {
    flex-direction: column;
    column-gap: 0;
    & > * {
      width: 100%;
    }
  }
`;

const ButtonStyled = styled<any>(Button)`
  display: block;
  margin: 0 auto;
`;

const Form = styled.form<any>`
  max-width: 100%;
  width: 700px;
  margin: 60px auto 0;
  ${(props) =>
    !!props.mt &&
    css`
      margin-top: ${props.mt};
    `}
  ${(props) =>
    !!props.isUpdate &&
    css`
      margin-left: 0;
    `}

  ${mobileQueryString} {
    margin-top: 0;
    margin-bottom: 40px;
  }
`;

const HideButtonStyled = styled.button<any>`
  display: none;
`;
