import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// assets
import { ReactComponent as ChevronLefIcon } from '../../assets/svg/arrow_back_ios_black_24dp.svg';

// components
import IconButton from '../../components/commons/IconButton';
import { mobileQueryString } from '../../utils/responsive';

function AsideLeftContent({ hasIconBack, asideTitle, total, children, onClick }) {
  return (
    <ContainerStyled>
      {hasIconBack && <IconButtonStyled icon={<ChevronLefIcon />} onClick={onClick} />}
      {asideTitle && (
        <AsideTitleStyled>
          {asideTitle}
          {total >= 0 && <NumberStyled>{total}</NumberStyled>}
        </AsideTitleStyled>
      )}
      {children}
    </ContainerStyled>
  );
}

export default AsideLeftContent;

AsideLeftContent.propTypes = {
  hasIconBack: PropTypes.bool,
  asideTitle: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
  total: PropTypes.number,
};

// IconButton.defaultProps = {
//   hasIconBack: false,
// };

const ContainerStyled = styled.div<any>`
  position: sticky;
  left: 0;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
`;

const IconButtonStyled = styled<any>(IconButton)`
  margin-bottom: 30px;

  ${mobileQueryString} {
    display: none;
  }
`;

const AsideTitleStyled = styled.div<any>`
  margin-bottom: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mobileQueryString} {
    margin-bottom: 21px;
  }
`;

const NumberStyled = styled.div<any>`
  font: normal normal normal 28px/34px Helvetica Neue;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: -8px;

  ${mobileQueryString} {
    display: none;
  }
`;
