import React, { useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import GridStyled from '../../../styled/GridStyled';

// assets
import { mobileQueryString } from '../../../../utils/responsive';
import KOLEngagedView from './CampaignReportKOLEngagedView';
import ContentCreatedView from './CampaignReportContentCreatedView';
import CampaignPerformanceView from './CampaignReportCampaignPerformanceView';
import PerformanceEngagementView from './CampaignReportPerformanceEngagementView';
import TopPerformanceContentView from './CampaignReportTopContentView';
import TopKOLsContentView from './CampaignReportTopKOLsView';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { GET_METRICS_IN_CAMPAIGN, GET_CAMPAIGN_KOL_METRIC } from './queries/queries';
import { tierOfFanpage } from '../utils/TierRole';
import { roundToTwoDecimalPlaces } from '../ContentPerformance/ContentPerformance';
import { ALL_TRANCHES_ID } from '../CampaignReportUI';
import { SocialPlatform } from '../../../../types';

export interface MetricData {
  trancheId: string;
  kolProfileId: string;
  kolAvatar: string;
  kolTier: string;
  kolName: string;
  fanbase: number;

  contentLink: string;
  contentType: string;

  reach: number;
  reachRate: number;
  engagement: number;
  engagementRate: number;
  views: number;
  shares: number;
  ig_post_comments: number;
  ig_post_saves: number;

  content: string;
  spend: number;
  postOn: Date | null;
  description: string;

  workItemId: string;
  workItemMetricId: string;
  campaign_kol_metric_id: string;
  campaignId: string;
  instagramId: string | null;
  platform: SocialPlatform;
}

export const fetchMetricData = async (client: ApolloClient<object>, campaignId: string): Promise<MetricData[]> => {
  try {
    var metricsData = Array<MetricData>();
    const response = await client.query({ query: GET_METRICS_IN_CAMPAIGN, variables: { id: campaignId } });
    const kolsCampaignMetric = await client.query({ query: GET_CAMPAIGN_KOL_METRIC, variables: { campaign_id: campaignId } });
    const tranchesData = response?.data?.campaign_by_pk?.tranches;
    const tranches = tranchesData !== undefined ? tranchesData : [];
    const kolsMetricData = kolsCampaignMetric?.data?.campaign_kol_metric;
    const kolMetrics = kolsMetricData !== undefined ? kolsMetricData : [];

    tranches.forEach((tranche) => {
      const workContracts = tranche.work_contracts;
      workContracts.forEach((workContract) => {
        const workItemsData = workContract.work_items != undefined ? workContract.work_items : [];
        const workItems = workItemsData.filter((workItem) => workItem.link != undefined);
        const kolProfile = workContract.kol_profile;
        const avatars = kolProfile.kol_file_metadata?.filter((file) => file.type === 'avatar');
        var kolAvatar: string = '';
        if (avatars && avatars.length > 0) {
          kolAvatar = avatars[avatars.length - 1].file_metadata?.url;
        }
        if (kolAvatar == undefined || kolAvatar!.length == 0) {
          kolAvatar = '/static/images/campaigns/user.png';
        }
        const kolProfileMetric = kolMetrics.find((metric) => metric.kol_profile_id === kolProfile?.id);
        const kolName = kolProfile.first_name + ' ' + kolProfile.last_name;
        const fanBase = kolProfileMetric?.fan_base || 0;
        const tier = tierOfFanpage(fanBase);
        const socialPlatform =
          kolProfile.social_platforms.length > 0
            ? kolProfile.social_platforms.find((platform) => platform.platform_username !== null)
            : undefined;
        const instagramId = socialPlatform?.platform_username || '';

        workItems.forEach((workItem) => {
          const workItemMetric =
            workItem.work_item_metrics != undefined && workItem.work_item_metrics.length > 0
              ? workItem.work_item_metrics[0]
              : undefined;
          const likes = workItemMetric?.likes || 0;
          const shares = workItemMetric?.shares || 0;
          const ig_post_comments = workItemMetric?.ig_post_comments || 0;
          const ig_post_saves = workItemMetric?.ig_post_saves || 0;
          const ig_story_sticker_taps = workItemMetric?.ig_story_sticker_taps || 0;
          const ig_story_link_clicks = workItemMetric?.ig_story_link_clicks || 0;
          const ig_story_replies = workItemMetric?.ig_story_replies || 0;
          const engagement =
            likes + shares + ig_post_comments + ig_post_saves + ig_story_sticker_taps + ig_story_link_clicks + ig_story_replies;
          const reach = workItemMetric?.reach || 0;
          const reachRate = fanBase > 0 ? roundToTwoDecimalPlaces((reach / fanBase) * 100) : 0;
          const engagementRate = fanBase > 0 ? roundToTwoDecimalPlaces((engagement / fanBase) * 100) : 0;
          const spend = workItemMetric?.spend_usd_cents || 0;
          const postOn = workItemMetric?.post_on_date;
          var postOnDate = postOn ? new Date(postOn) : null;
          const description = workItem?.deliverable_spec?.requirement_description;
          const contentType = workItem.deliverable_spec?.content_type?.name;
          const platform =
            typeof contentType === 'string' && contentType.toLowerCase().startsWith('ig')
              ? SocialPlatform.Instagram
              : SocialPlatform.Tiktok;
          const metricData = {
            trancheId: tranche.id,
            kolProfileId: kolProfile.id,
            kolAvatar: kolAvatar,
            kolTier: tier,
            kolName: kolName,
            contentLink: workItem.link,
            contentType: contentType,
            reach: workItemMetric?.reach || 0,
            reachRate: reachRate,
            engagement: engagement,
            engagementRate: engagementRate,
            views: workItemMetric?.views || 0,
            shares: shares,
            ig_post_comments: ig_post_comments,
            ig_post_saves: ig_post_saves,
            content: '1',
            fanbase: fanBase,
            spend: spend,
            postOn: postOnDate,
            description: description,

            workItemId: workItem?.id,
            workItemMetricId: workItemMetric?.id,
            campaign_kol_metric_id: kolProfileMetric?.id,
            campaignId: campaignId,
            instagramId: instagramId,
            platform: platform,
          };
          metricsData.push(metricData);
        });
      });
    });
    return metricsData;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const CampaignReportOverview = ({ campaignId, data, symbol, selectTranche }) => {
  const [totalKOLs, setTotalKOLs] = useState(0);
  const [totalContent, setTotalContent] = useState(0);
  const [totalReach, setTotalReach] = useState(0);
  const [totalReachRate, setTotalReachRate] = useState('0%');
  const [totalEngagement, setTotalEngagement] = useState(0);
  const [totalEngagementRate, setTotalEngagementRate] = useState('0%');
  const [aer, setAer] = useState('0%');
  const [kolsEngagementLabel, setKolsEngagementLabel] = useState<any[]>([]);
  const [kolsEngagementValue, setKolsEngagementValue] = useState<any[]>([]);
  const [contentLabel, setContentLabel] = useState<any[]>([]);
  const [contentValue, setContentValue] = useState<any[]>([]);
  const [filteredMetricData, setFilterMetricData] = useState<MetricData[]>([]);
  const client = useApolloClient();
  const [highestViews, setHighestViews] = useState('');
  const [highestReach, setHighestReach] = useState('');
  const [highestEngagement, setHighestEngagement] = useState('');
  const [topKols, setTopKols] = useState<any[]>([]);

  useEffect(() => {
    fetchMetricData(client, campaignId).then((data) => {
      if (selectTranche === ALL_TRANCHES_ID) {
        setFilterMetricData(data);
      } else {
        const filterData = data.filter((e) => e.trancheId == selectTranche);
        setFilterMetricData(filterData);
      }
    });
  }, [selectTranche]);

  useEffect(() => {
    if (!filteredMetricData || filteredMetricData.length === 0) {
      return;
    }
    var totalReach: number = 0;
    var totalReachRate: number = 0;
    var totalEngagement: number = 0;
    var totalEngagementRate: number = 0;
    var kolEngageData: any = {};
    var kolJoinData: any = {};
    var totalKOL: number = 0;
    var totalContent: number = 0;
    var contentData: any = {};
    var highestView: any = null;
    var highestReach: any = null;
    var highestEngagement: any = null;
    filteredMetricData.forEach((data) => {
      totalReach += data.reach;
      totalReachRate += data.reachRate;
      totalEngagement += data.engagement;
      totalEngagementRate += data.engagementRate;
      if (kolEngageData.hasOwnProperty(data.kolProfileId)) {
        kolEngageData[data.kolProfileId].totalEngagementRate =
          kolEngageData[data.kolProfileId].totalEngagementRate + data.engagementRate;
      } else {
        kolEngageData[data.kolProfileId] = {
          tier: data.kolTier,
          totalEngagementRate: data.engagementRate,
          avatar: data.kolAvatar,
          kolName: data.kolName,
        };
        if (kolJoinData.hasOwnProperty(data.kolTier)) {
          kolJoinData[data.kolTier] = kolJoinData[data.kolTier] + 1;
        } else {
          kolJoinData[data.kolTier] = 1;
        }
        totalKOL += 1;
      }

      if (contentData.hasOwnProperty(data.contentType)) {
        contentData[data.contentType] = contentData[data.contentType] + 1;
      } else {
        contentData[data.contentType] = 1;
      }
      totalContent += 1;

      if (highestView == null || highestView.views < data.views) {
        highestView = data;
      }
      if (highestReach == null || highestReach.reach < data.reach) {
        highestReach = data;
      }
      if (highestEngagement == null || highestEngagement.engagement < data.engagement) {
        highestEngagement = data;
      }
    });
    const kolEngageLabel = Object.getOwnPropertyNames(kolJoinData);
    const kolEngageValue = kolEngageLabel.map((key) => kolJoinData[key]);
    const contentDataLabel = Object.getOwnPropertyNames(contentData);
    const contentDataValue = contentDataLabel.map((key) => contentData[key]);
    const kolListIds = Object.getOwnPropertyNames(kolEngageData);

    let cerTotal: number = 0;
    const kolLists = kolListIds
      .map((id) => {
        const aerData = roundToTwoDecimalPlaces((kolEngageData[id].totalEngagementRate * 100) / totalEngagementRate);
        cerTotal += aerData;
        const aer = aerData + '% AER';
        return {
          id: id,
          avatar: kolEngageData[id].avatar,
          totalEngagementRate: kolEngageData[id].totalEngagementRate,
          aer: aer,
          kolName: kolEngageData[id].kolName,
        };
      })
      .sort((a, b) => (a.totalEngagementRate > b.totalEngagementRate ? -1 : 1))
      .slice(0, 3);

    cerTotal = roundToTwoDecimalPlaces(cerTotal / kolListIds.length);
    totalEngagementRate = roundToTwoDecimalPlaces(totalEngagementRate);

    setTotalReach(totalReach);
    setTotalReachRate(totalReachRate + '%');
    setTotalEngagement(totalEngagement);
    setTotalEngagementRate(totalEngagementRate + '%');
    setAer(cerTotal + '%');
    setTotalKOLs(totalKOL);
    setKolsEngagementLabel(kolEngageLabel);
    setKolsEngagementValue(kolEngageValue);
    setTotalContent(totalContent);
    setContentLabel(contentDataLabel);
    setContentValue(contentDataValue);
    setHighestViews(String(highestView?.contentLink));
    setHighestReach(String(highestReach?.contentLink));
    setHighestEngagement(String(highestEngagement?.contentLink));
    setTopKols(kolLists);
  }, [filteredMetricData]);

  const listImage = useMemo(() => {
    if (!data || !Array.isArray(data?.images)) return [];
    return data?.images.map((tranche) => tranche.file_metadata).flat();
  }, [data]);

  return (
    <>
      <CampaignContainer>
        <GridStyled isRow={true}>
          <KOLEngagedView totalKOLs={totalKOLs} labels={kolsEngagementLabel} values={kolsEngagementValue} />
          <ContentCreatedView totalContent={totalContent} contentDataLabel={contentLabel} contentDataValue={contentValue} />
        </GridStyled>
        <Spacer />
        <GridStyled isRow={true}>
          <CampaignPerformanceView totalReach={totalReach} totalReachRate={totalReachRate} />
          <PerformanceEngagementView totalEngagement={totalEngagement} totalEngagementRate={totalEngagementRate} aer={aer} />
        </GridStyled>
        <Spacer />
        <GridStyled isRow={true}>
          <TopPerformanceContentView
            highestViewLink={highestViews}
            highestEngagementLink={highestEngagement}
            highestReachLink={highestReach}
          />
          <TopKOLsContentView kolLists={topKols} />
        </GridStyled>
      </CampaignContainer>
    </>
  );
};

export default CampaignReportOverview;

const CampaignContainer = styled.div<any>`
  ${(props) =>
    !!props.pt &&
    css`
      padding-top: ${props.pt || 0};
    `};
  ${(props) =>
    !!props.maxWidth &&
    css`
      max-width: ${props.maxWidth || '700px'};
    `};

  ${mobileQueryString} {
    padding: 20px 0 0;
  }
  margin: 24px;
`;

const Spacer = styled.div<any>`
  width: 100%;
  heigh: 24px;
  display: inline-block;
`;
