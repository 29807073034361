import React, { useState } from 'react';
import './ContentMetricsField.css'; // Import the CSS file

interface ContentMetricsFieldProps {
  title: string;
  content: string;
  enableEdit?: boolean;
  type: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, type: string) => void;
  iconPath: string | null;
  onIconClick?: () => void;
  contentType: string;
}

const ContentMetricsField: React.FC<ContentMetricsFieldProps> = ({
  title,
  content,
  enableEdit,
  type,
  handleInputChange,
  iconPath,
  onIconClick,
  contentType,
}) => {
  return (
    <div className="contentMetricsField">
      <div className="title">{title}</div>
      <MetricInputField
        enable={enableEdit ?? true}
        content={content}
        iconPath={iconPath}
        handleInputChange={(e) => handleInputChange(e, type)}
        onIconClick={onIconClick}
        contentType={contentType}
        type={type}
      />
    </div>
  );
};

interface InputFieldProps {
  enable: boolean;
  content: string;
  type: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, type: string) => void;
  iconPath: string | null;
  onIconClick?: () => void;
  contentType: string;
}

const MetricInputField: React.FC<InputFieldProps> = ({
  enable,
  content,
  type,
  handleInputChange,
  iconPath,
  onIconClick,
  contentType,
}) => {
  if (iconPath && onIconClick) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',
          marginTop: '15px',
          width: 'calc(100vw / 5)',
          border: '1px solid #D9D9D9',
          height: '40px',
        }}
      >
        <input
          type={contentType}
          defaultValue={content}
          disabled={true}
          value={content}
          onChange={(e) => handleInputChange(e, type)}
          style={{
            backgroundColor: 'white',
            border: '0px solid #D9D9D9',
            color: 'black',
            paddingBottom: '15px',
            paddingTop: '15px',
            height: '30px',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '5px',
            width: 'calc(100vw / 5 - 40px)',
          }}
        />
        <img src={iconPath} alt="icon" style={{ width: '30px', height: '30px', paddingLeft: '10px' }} onClick={onIconClick} />
      </div>
    );
  }

  if (iconPath && !onIconClick) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',
          marginTop: '15px',
          width: 'calc(100vw / 5)',
          border: '1px solid #D9D9D9',
          height: '40px',
        }}
      >
        <input
          type={contentType}
          defaultValue={content}
          disabled={false}
          value={content}
          onChange={(e) => handleInputChange(e, type)}
          style={{
            backgroundColor: 'white',
            border: '0px solid #D9D9D9',
            color: 'black',
            paddingBottom: '15px',
            paddingTop: '15px',
            height: '30px',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '5px',
            width: 'calc(100vw / 5 - 40px)',
          }}
        />
        {iconPath && <img src={iconPath} alt="icon" style={{ width: '30px', height: '30px' }} />}
      </div>
    );
  }

  if (enable) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type={contentType}
          defaultValue={content}
          disabled={false}
          onChange={(e) => {
            handleInputChange(e, type);
          }}
          style={{
            backgroundColor: 'white',
            border: '1px solid #D9D9D9',
            marginTop: '15px',
            color: 'black',
            height: '40px',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '5px',
            width: 'calc(100vw / 5)',
          }}
        />
        {iconPath && <img src={iconPath} alt="icon" style={{ width: '30px', height: '30px' }} onClick={onIconClick} />}
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type={contentType}
          value={content}
          disabled={true}
          style={{
            backgroundColor: '#D9D9D9',
            border: '1px solid #D9D9D9',
            marginTop: '15px',
            color: 'black',
            height: '40px',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '5px',
            width: 'calc(100vw / 5)',
          }}
        />
        {iconPath && <img src={iconPath} alt="icon" style={{ width: '30px', height: '30px' }} />}
      </div>
    );
  }
};

export default ContentMetricsField;
