import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';
import { useEffect, useState } from 'react';
import { REACT_APP_FRONTEND_URL } from '../configs';

export const FEATURE_KEY = {
  stripe: 'stripe',
  additionalRemuneration: 'additional_remuneration',
};

const environmentName = () => {
  const url = REACT_APP_FRONTEND_URL;
  if (url === undefined) {
    return '';
  }
  if (url.includes('sit')) {
    return 'sit';
  }
  if (url.includes('uat')) {
    return 'uat';
  }
  return 'production';
};

export const useFeatureFlag = () => {
  const [featureFlags, setFeatureFlags] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const isDev = process.env.NODE_ENV === 'development';

  const firebaseConfig = {
    apiKey: 'AIzaSyBpAORx1yFYNHQQCC-aJFuLY6_FldPRI6Q',
    authDomain: 'kols-24f90.firebaseapp.com',
    projectId: 'kols-24f90',
    storageBucket: 'kols-24f90.appspot.com',
    messagingSenderId: '35789267872',
    appId: '1:35789267872:web:1ec5f0cdfff812c634bac5',
    measurementId: 'G-VX0X1D80M8',
  };

  const firebaseConfigLive = {
    apiKey: 'AIzaSyDeMuGOPOAn8tnRUCeUAVrFhmdKZZrbUCs',
    authDomain: 'kols-asia-prod.firebaseapp.com',
    projectId: 'kols-asia-prod',
    storageBucket: 'kols-asia-prod.appspot.com',
    messagingSenderId: '925992791168',
    appId: '1:925992791168:web:13e06339790610d2e50a65',
    measurementId: 'G-G2YBCJGQRL',
  };
  const app = isDev ? initializeApp(firebaseConfig) : initializeApp(firebaseConfigLive);
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 1000;

  useEffect(() => {
    fetchAndActivate(remoteConfig).then(() => {
      setLoading(false);
    });
  }, []);

  const handleRefreshConfig = async () => {
    await fetchAndActivate(remoteConfig);
  };

  const isFeatureFlagEnableAsync = async (key: string) => {
    await fetchAndActivate(remoteConfig);
    const flagKey = key + '_' + environmentName();
    return getValue(remoteConfig, flagKey).asBoolean() === true;
  };

  if (loading || remoteConfig === undefined) {
    return {
      loading: loading,
      featureFlags: featureFlags,
      isFeatureFlagEnableAsync: isFeatureFlagEnableAsync,
      handleRefreshConfig: () => {},
      isFeatureFlagEnable: () => false,
    };
  }

  return {
    loading: loading,
    featureFlags: featureFlags,
    handleRefreshConfig: handleRefreshConfig,
    remoteConfig: remoteConfig,
    isFeatureFlagEnableAsync: isFeatureFlagEnableAsync,
    isFeatureFlagEnable(key: string): boolean {
      const flagKey = key + '_' + environmentName();
      return getValue(remoteConfig, flagKey).asBoolean() === true;
    },
  };
};
