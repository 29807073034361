import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import Button from './Button';

function Modal(props: any) {
  const { isOpen, width, isPassiveCancel, children, closeModal } = props;
  const [container] = useState(document.createElement('div'));

  const renderModal = useMemo(() => {
    return (
      <WrapperStyled>
        <BackdropStyled onClick={closeModal} />
        <ContentStyled width={width}>
          <ChildrenStyled>{children}</ChildrenStyled>
          {isPassiveCancel && (
            <PassiveStyled>
              <Button variant="secondary" label="Cancel" onClick={closeModal} />
            </PassiveStyled>
          )}
        </ContentStyled>
      </WrapperStyled>
    );
  }, [children, closeModal, isPassiveCancel, width]);

  useEffect(() => {
    if (!isOpen) return;
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container, isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(renderModal, container);
}

export default Modal;

// Modal.propType = {
//   isOpen: PropTypes.bool,
//   width: PropTypes.string,
//   isPassiveCancel: PropTypes.bool,
//   children: PropTypes.children,
//   closeModal: PropTypes.func,
// };

Modal.defaultProps = {
  isOpen: false,
  width: '376px',
  isPassiveCancel: false,
};

const WrapperStyled = styled.div<any>`
  position: fixed;
  z-index: 1300;
  inset: 0;
`;

const BackdropStyled = styled.div<any>`
  position: fixed;
  inset: 0px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.65;
`;

const ContentStyled = styled.div<any>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  width: ${(props) => props.width || '376px'};

  opacity: 1;
  overflow-y: auto;
`;

const ChildrenStyled = styled.div<any>`
  padding: 25px 20px 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 30px #00000029;
  height: auto;
  max-height: 90%;
  border-radius: 10px;

  button + button {
    margin-top: 18px;
  }
`;

const PassiveStyled = styled.div<any>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 30px #00000029;
  border-radius: 10px;
  height: 53px;
  display: flex;
  algin-items: center;
  margin-top: 30px;
`;
