import React from 'react';
import PendingRegisterForm from '../components/authentication/forms/PendingRegister';

export function PendingRegister(props = {}) {
  return <PendingRegisterForm type={'pendingReview'} />;
}

export function UnAvailableAccount(props = {}) {
  return <PendingRegisterForm type={'unAvailable'} />;
}

export function TokenExpired(props = {}) {
  return <PendingRegisterForm type={'tokenExpired'} />;
}

export default PendingRegister;
