import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import TextField from '../../commons/TextField';
import Button from '../../commons/Button';
import RadioInput from '../../commons/RadioInput';
import FormGroupStyled from '../../commons/FormGroup';
import TextFieldPhone from '../../commons/TextFieldPhone';
// styled
import GridStyled from '../../styled/GridStyled';
import RowStyled from '../../styled/RowStyled';

const RegisterForm = (props) => {
  const { submitGetStartedForm, isSubmitting, user = {} } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const radioProps = {
    name: 'typeCustomer',
    defaultValue: '0',
    label: 'I am a',
    options: [
      {
        name: 'Brand',
        value: '0',
      },
      {
        name: 'Agency',
        value: '1',
      },
    ],
    gap: '40px',
  };

  function onSubmit(values) {
    const {
      firstName,
      lastName,
      phone: { phoneCode, phoneNumber },
      email,
      typeCustomer,
    } = values;
    submitGetStartedForm({ firstName, lastName, phoneCode, phoneNumber, email, typeCustomer });
  }

  function setDefaultValueForm() {
    setValue('firstName', user?.given_name || '');
    setValue('lastName', user?.family_name || '');
    setValue('email', user?.email || '');
  }

  useEffect(() => {
    setDefaultValueForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <FormContainerStyled>
        <TitleHeader>Get Started</TitleHeader>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <CustomGridStyled>
            <CustomRowStyled>
              <TextField
                {...register('firstName', {
                  required: {
                    value: true,
                    message: 'First name is required',
                  },
                })}
                label="First Name"
                name="firstName"
                error={errors?.firstName?.message}
              />
            </CustomRowStyled>
            <CustomRowStyled>
              <TextField
                {...register('lastName', { required: { value: true, message: 'Last name is required' } })}
                label="Last Name"
                name="lastName"
                error={errors?.lastName?.message}
              />
            </CustomRowStyled>
          </CustomGridStyled>
          <TextFieldPhone {...register('phone')} placeholder="Enter your phone number" />
          <TextField
            {...register('email', {
              required: {
                value: true,
                message: 'Email is required',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Email is invalid',
              },
            })}
            label="Email Address"
            name="email"
            placeholder="Enter your email address"
            error={errors?.email?.message}
            readonly
            disabled
          />
          {false && (
            <FormGroupStyled>
              <RadioInput {...register(radioProps.name)} {...radioProps} />
            </FormGroupStyled>
          )}
          <Button disabled={isSubmitting} label={isSubmitting ? 'Please wait' : 'Continue'} type="submit" />
        </FormStyled>
      </FormContainerStyled>
    </div>
  );
};

export default RegisterForm;

const TitleHeader = styled.h2`
  color: #5770c6;
  font: normal normal bold 34px/41px Helvetica Neue;
  text-transform: capitalize;
  margin: 55px 0 63px;
`;

const FormContainerStyled = styled.div<any>`
  width: 335px;
  margin: 0 auto;
  @media (max-width: 350px) {
    width: 96%;
  }
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CustomGridStyled = styled<any>(GridStyled)`
  margin: 0 -5px;
  width: calc(100% + 10px);
`;
const CustomRowStyled = styled<any>(RowStyled)`
  padding: 0 5px;
`;
