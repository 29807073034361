import { useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

// assets
import { ReactComponent as SearchSVG } from '../../assets/svg/search.svg';
import { ReactComponent as CloseSVG } from '../../assets/svg/close_black_24dp.svg';
import { mobileQueryString } from '../../utils/responsive';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Stack, debounce } from '@mui/material';
import SEARCH_CAMPAIGN from '../../queries/campaignCreation/searchCampaign';
import { useQuery } from '@apollo/client';
import ClientProfileContext from '../../containers/ClientProfileProvider';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';

function SearchPopover() {
  const [isShowInput, setIsShowInput] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const { currentProfile } = useContext(ClientProfileContext);
  const { data } = useQuery(SEARCH_CAMPAIGN, {
    variables: {
      name: searchKey,
      client_profile_id: currentProfile?.id,
    },
  });
  const campaigns = data?.searchCampaigns || [];
  const history = useHistory();

  function handleShowInputField() {
    setIsShowInput(true);
  }

  function handleHideInputField() {
    setSearchKey('');
    setIsShowInput(false);
  }

  const handleSearchInput = (event) => {
    const query = event.target.value;
    debouncedSearch(query);
  };

  const debouncedSearch = useMemo(() => debounce((value) => setSearchKey(value), 300), []);

  return (
    <ContainerStyled>
      {isShowInput && (
        <Stack
          direction={'column'}
          spacing={'24px'}
          justifyContent={'justifyContent'}
          sx={{
            boxSizing: 'border-box',
            position: 'absolute',
            top: '-12px',
            right: '-21px',
            padding: '0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '362px',
            height: isShowInput ? '300px' : '80px',
            zIndex: isShowInput ? 1 : -1,
            opacity: isShowInput ? 1 : 0,
            overflow: 'hidden',
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <FieldStyled isShowInput={isShowInput}>
            <input type="text" placeholder="Start typing..." onChange={handleSearchInput} />
            <CloseSVG onClick={handleHideInputField} />
          </FieldStyled>
          {campaigns.length > 0 && (
            <div
              style={{
                width: '100%',
                height: '300px',
                overflow: 'auto',
                top: '32px',
                borderRadius: '4px',
                border: '1px solid #d9d9d9',
                backgroundColor: 'white',
              }}
            >
              <List
                style={{ width: '100%', top: '32px' }}
                sx={{
                  backgroundColor: 'white',
                }}
              >
                {campaigns.map((result, index) => (
                  <ListItem
                    key={result.id}
                    onClick={() => {
                      const path = generatePath(ROUTES.campaignsDetails, { id: result.id });
                      history.push(path + '?mode=0');
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ListItemAvatar>
                      <Avatar src={result.image_url} alt={result.name} variant="rounded" />
                    </ListItemAvatar>
                    <ListItemText primary={result.name} />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
        </Stack>
      )}

      <SearchSVG fill="#5770C6" onClick={handleShowInputField} />
    </ContainerStyled>
  );
}

export default SearchPopover;

const ContainerStyled = styled.div<any>`
  position: relative;
`;

const FieldStyled = styled.div<any>`
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 46px;
  z-index: -1;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  input {
    outline: 0;
    border: 0;
    color: #949494;
    flex-grow: 1;
    font: normal normal normal 14px/16px Helvetica Neue;
  }

  svg {
    height: 19px;
    fill: #0b0b0b;
    flex-shrink: 0;
  }

  ${(props) =>
    props.isShowInput &&
    css`
      z-index: 1;
      opacity: 1;
      width: 362px;
    `}

  ${mobileQueryString} {
    right: auto;
    width: 335px;
  }
`;
