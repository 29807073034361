import { memo, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NotificationPopover from '../../components/layouts/NotificationPopover';
import { ROUTES } from '../../utils/constants';

import CampaignPng from '../../assets/png/campaign-1.png';
import CampaignPngActive from '../../assets/png/campaign-active.png';
import { ReactComponent as AddSVG } from '../../assets/svg/add.svg';
import { mobileQueryString } from '../../utils/responsive';

const FooterMobileCampaigns = () => {
  const { pathname } = useLocation();
  const [isShowNotify, setIsShowNotify] = useState(false);

  const onClick = useCallback(() => {
    setIsShowNotify(false);
  }, []);

  return (
    <FooterWrapper>
      <Link to={ROUTES.campaigns} onClick={onClick}>
        <img src={pathname === ROUTES.campaigns && !isShowNotify ? CampaignPngActive : CampaignPng} alt="Campaign" />
      </Link>
      <Link to={ROUTES.campaignsCreate} onClick={onClick}>
        <AddButton>
          <AddSVG />
        </AddButton>
      </Link>
      <NotificationPopover notificationInMobile onClick={() => setIsShowNotify(!isShowNotify)} />
    </FooterWrapper>
  );
};

export default memo(FooterMobileCampaigns);

const FooterWrapper = styled.div<any>`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 -1px 2px #00000029;

  ${mobileQueryString} {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;

    & > * {
      flex-basis: 25%;
      text-align: center;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }
`;

const AddButton = styled.div<any>`
  width: 56px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 11px;
`;
