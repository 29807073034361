import React from 'react';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as FacebookSVG } from '../../assets/svg/facebook.svg';
import { ReactComponent as InstagramSVG } from '../../assets/svg/instagram.svg';
import { ReactComponent as TwitterSVG } from '../../assets/svg/twitter.svg';
import { ReactComponent as youtubeSVG } from '../../assets/svg/youtube-play.svg';
import { ReactComponent as TikTokSVG } from '../../assets/svg/tiktok.svg';
import { ReactComponent as XSVG } from '../../assets/svg/icon-x.svg';
import { ReactComponent as LemonSVG } from '../../assets/svg/icon-l8.svg';
import { ReactComponent as XHSSVG } from '../../assets/svg/icon-XHS.svg';
import { mobileQueryString } from '../../utils/responsive';

const icons = {
  FACEBOOK: FacebookSVG,
  INSTAGRAM: InstagramSVG,
  TWITTER: XSVG,
  YOUTUBE: youtubeSVG,
  TIKTOK: TikTokSVG,
  LEMON8: LemonSVG,
  XIAOHONGSHU: XHSSVG,
};
const PlatformStyled = styled<any>(({ className, alignItems, iconColor, ...props }) => {
  const platforms = useMemo(() => {
    return props.platforms.map((key) => {
      return icons[key] || null;
    });
  }, [props.platforms]);
  return (
    <div className={className}>
      {platforms.map(
        (Logo, idx) =>
          Logo && (
            <LogoStyled iconColor={iconColor} key={idx}>
              <Logo />
            </LogoStyled>
          ),
      )}
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;

  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
`;

export default PlatformStyled;

const LogoStyled = styled.div<any>`
  width: 11px;
  height: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 11px;
    height: 11px;
    fill: ${(props) => props.iconColor};
  }

  &:not(:first-child) {
    margin-left: 5px;
  }

  ${mobileQueryString} {
    &:not(:first-child) {
      margin-bottom: 5px;
    }
  }
`;
