import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import AsideLeftContent from '../commons/AsideLeftContent';
import { ROUTES } from '../../utils/constants';
import { useLocation, useParams, Link, generatePath } from 'react-router-dom';
import { mobileQueryString } from '../../utils/responsive';

const LABEL = {
  campaignDetails: 'Campaign Details',
  listOfKols: 'List of Kols',
  deliverables: 'Deliverables',
  chat: 'Chat',
  report: 'Report',
};

function AsideLeftCampaignDetail(props: any) {
  const { data = {}, type = 1, backToStep, owner } = props;
  const { pathname } = useLocation();
  const params = useParams() as any;

  const menu = useMemo(() => {
    const showShouldReport = data.status === 'COMPLETED' || data.status === 'RUNNING';
    var labels = [
      {
        label: LABEL.campaignDetails,
        path: ROUTES.campaignsDetails,
        type: 1,
      },
      {
        label: LABEL.listOfKols,
        path: ROUTES.campaignsListOfKols,
        type: 1,
      },
      {
        label: LABEL.deliverables,
        path: ROUTES.campaignsDeliverables,
        type: 2,
      },
      {
        label: LABEL.chat,
        path: ROUTES.campaignsChat,
        type: 2,
      },
    ];
    if (showShouldReport) {
      labels.push({
        label: LABEL.report,
        path: ROUTES.campaignsReport,
        type: 1,
      });
    }

    return labels
      .filter((f) => f.type === type)
      .map((item) => {
        const path = generatePath(item.path, { id: params.id, contractID: params.contractID });
        return {
          ...item,
          path,
        };
      });
  }, [type, params, data]);

  if (!data || Object.keys(data).length === 0) {
    return <></>;
  }

  return (
    <AsideLeftContent
      onClick={backToStep}
      hasIconBack
      asideTitle={<Title thumbnail={data?.image_url} title={data?.name} owner={owner} />}
    >
      <ContentStyled>
        <ul>
          {menu.map((item, idx) => (
            <ListStyled key={idx} active={item.path === pathname}>
              <ItemStyled to={item.path}>{item.label}</ItemStyled>
            </ListStyled>
          ))}
        </ul>
      </ContentStyled>
    </AsideLeftContent>
  );
}

export default AsideLeftCampaignDetail;

const Title = ({ thumbnail = '', title = '', owner }) => {
  return (
    <TitleStyled>
      <img src={thumbnail} alt="" />
      <TittleWrapperStyled>
        <div>{title}</div>
        <FlexBox>
          <Manufacturer>{owner?.name}</Manufacturer>
          <TimeStyled>{owner?.lastActivityTime} ago</TimeStyled>
        </FlexBox>
        <StatusStyled>{owner?.status}</StatusStyled>
      </TittleWrapperStyled>
    </TitleStyled>
  );
};

const TitleStyled = styled.div<any>`
  display: flex;
  & img {
    width: 59px;
    height: 59px;
    background-color: #cdc7c7;
    border-radius: 5px;
    overflow: hidden;
  }
  & div {
    margin-left: 15px;
    font: normal normal bold 22px/27px Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 3px;
  }

  ${mobileQueryString} {
    & div {
      font-size: 16px;
      line-height: 19px;
      flex: none;
    }
  }
`;

const ContentStyled = styled.div<any>`
  ul {
    margin: 0;
    padding: 0;
  }

  ${mobileQueryString} {
    ul {
      display: grid;
      justify-content: start;
      grid-auto-flow: column;
      column-gap: 20px;
      border-bottom: 1px solid #eeeeee;
    }
  }
`;

const ListStyled = styled.div<any>`
  list-style: none;
  display: flex;
  align-items: center;
  padding: 13px 0px;
  ${(props) =>
    props.active &&
    css`
      & > a {
        border-left: 2px solid #5770c6;
        color: #5770c6;
      }
    `}

  ${mobileQueryString} {
    padding-bottom: 16px;

    ${(props) =>
      props.active &&
      css`
        border-bottom: 2px solid #5770c6;

        & > a {
          border-left: none;
        }
      `}
  }
`;

const ItemStyled = styled<any>(Link)`
  display: inline-flex;
  text-align: left;
  font: normal normal 500 16px/19px Helvetica Neue;
  letter-spacing: 0px;
  color: #949494;
  opacity: 1;
  padding: 0px 11px;
  cursor: pointer;
  text-decoration: none;

  ${mobileQueryString} {
    font-size: 14px;
    line-height: 16px;
    padding: 0;
  }
`;

const FlexBox = styled.div<any>`
  display: none;

  ${mobileQueryString} {
    display: flex;
    align-items: center;
    margin-top: 0 !important;
  }
`;

const Manufacturer = styled.div<any>`
  font: normal normal 500 14px/17px Helvetica Neue !important;
  color: #3e3e3e !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
`;

const TimeStyled = styled.div<any>`
  font: normal normal 400 12px/14px Helvetica Neue !important;
  color: #949494 !important;
`;

const TittleWrapperStyled = styled.div<any>`
  margin: 0 !important;
`;

const StatusStyled = styled.span<any>`
  display: none;
  text-transform: capitalize;
  color: #5770c6;
  font: normal normal 400 12px/14px Helvetica Neue;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  padding: 2px 5px;
  margin: 4px 0 0 15px;

  ${mobileQueryString} {
    display: inline-block;
  }
`;
