import { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

const useToastNotification = () => {
  const { addToast } = useToasts();

  const toastNotification = useCallback(
    (notification) => {
      addToast(notification.title, { appearance: 'info', autoDismiss: true, autoDismissTimeout: 3000, id: notification.id });
    },
    [addToast],
  );

  return {
    toastNotification,
  };
};

export default useToastNotification;
