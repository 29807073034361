import { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { PRIVATE_ROUTE } from '../../../../utils/constants';

import PopupMobile from '../../../commons/PopupMobile/PopupMobile';

// assets
import { ReactComponent as LogoutSVG } from '../../../../assets/svg/logout_svg.svg';
import { ReactComponent as UserSettingSVG } from '../../../../assets/svg/manage_accounts.svg';
import { ReactComponent as WalletSVG } from '../../../../assets/svg/ios-wallet-svg.svg';

// components
import Typography from '../../../commons/Typography';

const PopoverContentMobile = ({ handleClickOverlay, handleLogout }) => {
  const history = useHistory();

  return (
    <PopupMobile handleClickOverlay={handleClickOverlay}>
      <ItemStyled onClick={() => history.push(PRIVATE_ROUTE.wallet)}>
        <WalletSVGStyled>
          <WalletSVG />
        </WalletSVGStyled>
        <TypographyStyled hasBorderBottom>
          <Typography text="E-Wallet" color="#3E3E3E" fontSize="12px/15px" />
        </TypographyStyled>
      </ItemStyled>

      <ItemStyled onClick={() => history.push(PRIVATE_ROUTE.userProfile + '?mode=1')}>
        <UserSVGStyled>
          <UserSettingSVG />
        </UserSVGStyled>
        <TypographyStyled hasBorderBottom>
          <Typography text="Edit User Profile" color="#3E3E3E" fontSize="12px/15px" />
        </TypographyStyled>
      </ItemStyled>

      <ItemStyled onClick={() => history.push(PRIVATE_ROUTE.companyProfile + '?mode=1')}>
        <UserSVGStyled>
          <UserSettingSVG />
        </UserSVGStyled>
        <TypographyStyled hasBorderBottom>
          <Typography text="Edit Company Profile" color="#3E3E3E" fontSize="12px/15px" />
        </TypographyStyled>
      </ItemStyled>
      <ItemStyled onClick={handleLogout}>
        <UserSVGStyled>
          <LogoutSVG />
        </UserSVGStyled>
        <TypographyStyled>
          <Typography text="Log Out" color="#3E3E3E" fontSize="12px/15px" />
        </TypographyStyled>
      </ItemStyled>
    </PopupMobile>
  );
};

export default memo(PopoverContentMobile);

PopoverContentMobile.propTypes = {
  handleClickOverlay: PropTypes.func,
  handleLogout: PropTypes.func,
};

const ItemStyled = styled.li<any>`
  list-style: none;
  display: flex;
  align-items: stretch;
  height: 54px;
  padding: 0px 10px 0 22px;
  cursor: pointer;
`;

const UserSVGStyled = styled.div<any>`
  margin-right: 18px;
  align-self: center;
  svg {
    height: 20px;
  }
`;

const WalletSVGStyled = styled.div<any>`
  margin-right: 18px;
  align-self: center;
  svg {
    width: 20px;
    height: 15px;
  }
`;

const TypographyStyled = styled.div<any>`
  flex-grow: 1;

  div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.hasBorderBottom &&
    css`
      border-bottom: 1px solid #eee;
    `}
`;
