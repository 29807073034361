import { memo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { ReactComponent as LogoSVG } from '../assets/svg/logo_svg.svg';
import { ReactComponent as BackSVG } from '../assets/svg/back.svg';

import { ROUTES, FORMAT_DATE_WALLET_DEFAULT } from '../utils/constants';
import { fortmatCurrencyTemplate } from '../utils/formatCurrency';

const WalletDetail = ({ data, user, onGoBack }) => {
  return (
    <Wrapper>
      <HeaderContentStyled>
        <div onClick={onGoBack}>
          <BackSVG />
        </div>
        <LogoStyled>
          <A to={ROUTES.campaigns}>
            <LogoSVG />
          </A>
        </LogoStyled>
        <div></div>
      </HeaderContentStyled>
      <ContainerStyled mt="15px">
        <MainStyled>
          <HeaderStyled>Transaction Details</HeaderStyled>
          <RowWrapper>
            <TitleStyled>Date</TitleStyled>
            <DescriptionStyled>{format(new Date(data.timestamp), FORMAT_DATE_WALLET_DEFAULT)}</DescriptionStyled>
          </RowWrapper>
          <RowWrapper>
            <TitleStyled>Description</TitleStyled>
            <DescriptionStyled>{data.description}</DescriptionStyled>
          </RowWrapper>
          <RowWrapper>
            <TitleStyled>Transaction ID</TitleStyled>
            <DescriptionStyled>{data.id}</DescriptionStyled>
          </RowWrapper>
          <FlexBox>
            <RowWrapper>
              <TitleStyled>Amount (USD)</TitleStyled>
              <CurrencyStyled>
                {fortmatCurrencyTemplate(
                  data.amount_usd_cents,
                  user?.profile?.default_currency?.template,
                  user?.profile?.default_currency?.symbol,
                  user?.profile?.default_currency?.fraction_size,
                )}
              </CurrencyStyled>
            </RowWrapper>
            <RowWrapper m="0 0 20px 35px">
              <TitleStyled>Balance (USD)</TitleStyled>
              <CurrencyStyled>
                {fortmatCurrencyTemplate(
                  data.balance_usd_cents,
                  user?.profile?.default_currency?.template,
                  user?.profile?.default_currency?.symbol,
                  user?.profile?.default_currency?.fraction_size,
                )}
              </CurrencyStyled>
            </RowWrapper>
          </FlexBox>
        </MainStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

export default memo(WalletDetail);

const Wrapper = styled.div<any>`
  width: calc(100% + 40px);
  max-width: 1450px;
  margin-left: -20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
`;

const HeaderContentStyled = styled.div<any>`
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const A = styled<any>(Link)`
  cursor: pointer;
  text-decoration: none;
  display: block;
  min-width: 150px;
  height: 22px;
  margin-right: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const LogoStyled = styled.div<any>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 150px;
`;

const ContainerStyled = styled.div<any>`
  max-width: 100%;
  margin: 0 auto;
  margin-top: ${(props) => props.mt || 0};
  width: 100%;
`;

const MainStyled = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const HeaderStyled = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue !important;
  color: #0b0b0b;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
`;

const TitleStyled = styled.div<any>`
  font: normal normal 400 13px/15px Helvetica Neue !important;
  color: #949494;
  margin-bottom: 2px;
`;

const RowWrapper = styled.div<any>`
  margin-bottom: 20px;
  margin: ${(props) => props.m};
`;

const DescriptionStyled = styled.div<any>`
  font: normal normal 400 14px/16px Helvetica Neue !important;
  color: #0b0b0b;
`;

const CurrencyStyled = styled.div<any>`
  font: normal normal bold 14px/17px Helvetica Neue !important;
  color: #0b0b0b;
`;

const FlexBox = styled.div<any>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
`;
