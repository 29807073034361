import React from 'react';
import styled from 'styled-components';

const TopKOLsContentView = (props: any) => {
  const { kolLists } = props;
  return (
    <>
      <Container>
        <Header>
          <Title>Top Performing KOLs (By AER)</Title>
        </Header>
        <Line />
        <Content>
          {kolLists.map((kol) => {
            return (
              <Element key={kol.id}>
                <Image src={kol.avatar} />
                <SmallTitle>{kol.kolName}</SmallTitle>
                <SmallTitle>{kol.aer}</SmallTitle>
              </Element>
            );
          })}
        </Content>
      </Container>
    </>
  );
};

export default TopKOLsContentView;

const Container = styled.div<any>`
  width: 100%;
  overflow: hidden;
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  border-color: red;
  outline: 1px #d9d9d9 solid;
  > svg {
    width: 55px;
    height: 55px;
  }
`;
const Content = styled.div<any>`
  margin: 24px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  gap: 24px;
`;

const Header = styled.div<any>`
  margin: auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 48px;
  width: 100%;
  padding: 16px;
`;

const Title = styled.div<any>`
  color: #000000;
  font: normal normal bold 20px/24px 'Inter';
  margin: 0 0;
`;

const Line = styled.div<any>`
  margin: auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
  padding: 0px;
`;

const Element = styled.div<any>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SmallTitle = styled.div<any>`
  color: #79747e;
  font: normal normal regular 14px 'Inter';
  margin: 4px 0 8px 0;
`;

const Value = styled.div<any>`
  color: #000000;
  font: normal normal bold 36px 'Inter';
  margin: 0 0;
`;

const Image = styled.img`
  max-width: 100%;
  width: 64px;
  height: 64px;
  object-fit: cover;
  padding-right: 0px;
  margin-bottom: 0px;
  display: block;
  border-radius: 50%;
`;
