export const STATUS = {
  PENDING_SUBMIT: {
    label: 'Pending',
    value: 'PENDING_SUBMIT',
  },
  SUBMITTED: {
    label: 'Submitted',
    value: 'SUBMITTED',
  },
  APPROVED: {
    label: 'Approved',
    value: 'APPROVED',
  },
  CLIENT_APPROVED_PAY_REQUEST: {
    label: 'CLIENT_APPROVED_PAY_REQUEST',
    value: 'CLIENT_APPROVED_PAY_REQUEST',
  },
  KOL_APPLIED: {
    value: 'KOL_APPLIED',
  },
  REJECTED: {
    label: 'Rejected',
    value: 'REJECTED',
  },
  onGoing: {
    label: 'Ongoing',
    value: 'onGoing',
    color: '#5770C6',
  },
  completed: {
    label: 'Completed',
    value: 'completed',
    color: '#00B700',
  },
  clientReject: {
    value: 'CLIENT_REJECTED',
  },
  clientRejectPay: {
    value: 'CLIENT_REJECTED_PAY_REQUEST',
  },
};
