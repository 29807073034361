import { useCallback, useContext, useEffect, useRef } from 'react';
import { useMessage } from './hooks';
import { ImageShowContext } from './../ImageShowProvider';
import { MessageTemplateStyled, MessageButtonStyled, MessageRequestStyled } from './Components';
import { MESSAGE_COLOR } from './constant';
import styled from 'styled-components';

import { ReactComponent as CheckCircleIcon } from '../../../assets/svg/Icon-feather-check-circle.svg';
import { ReactComponent as XOctagonIcon } from '../../../assets/svg/Icon-feather-x-octagon.svg';

// If u want to use this component, pls make sure use ImageShowProvider before
export function MessageApproved({ message = {} }) {
  const { onSetImages, onSetOpen } = useContext<any>(ImageShowContext);
  const { text, media, className, thumbnail } = useMessage(message) as any;

  const handleOpenSlide = () => {
    if (!media.length) return;
    onSetImages(media);
    setTimeout(() => {
      onSetOpen(true);
    }, 100);
  };

  return (
    <MessageTemplateStyled className={className}>
      <div className="content">{text}</div>
      <div className="footer">
        <div className="image-group" onClick={handleOpenSlide}>
          {!!thumbnail && <img src={thumbnail.url} alt="" />}
          {!!media.length && <div className="cover">+{media.length}</div>}
        </div>
        <div className="btn-wrapper">
          <MessageButtonStyled bgcolor={MESSAGE_COLOR.aprrove} readonly>
            <span>Approved</span>
          </MessageButtonStyled>
        </div>
      </div>
    </MessageTemplateStyled>
  );
}

// If u want to use this component, pls make sure use ImageShowProvider before
export function MessageRejected({ message = {} }) {
  const { onSetImages, onSetOpen } = useContext<any>(ImageShowContext);
  const { text, media, className, thumbnail } = useMessage(message) as any;

  const handleOpenSlide = () => {
    if (!media.length) return;
    onSetImages(media);
    setTimeout(() => {
      onSetOpen(true);
    }, 100);
  };

  return (
    <MessageTemplateStyled className={className}>
      <div className="content">{text}</div>
      <div className="footer">
        <div className="image-group" onClick={handleOpenSlide}>
          {!!thumbnail && <img src={thumbnail.url} alt="" />}
          {!!media.length && <div className="cover">+{media.length}</div>}
        </div>
        <div className="btn-wrapper">
          <MessageButtonStyled bgcolor={MESSAGE_COLOR.reject} readonly>
            <span>Rejected</span>
          </MessageButtonStyled>
        </div>
      </div>
    </MessageTemplateStyled>
  );
}

// If u want to use this component, pls make sure use ImageShowProvider before
export function MessageMedia({ message = {} }) {
  const { onSetImages, onSetOpen } = useContext<any>(ImageShowContext);
  const { text, media, thumbnail, className } = useMessage(message) as any;
  const canvasRef = useRef<any>();
  const videoRef = useRef<any>();

  const handleOpenSlide = () => {
    if (!media.length) return;
    onSetImages(media);
    setTimeout(() => {
      onSetOpen(true);
    }, 100);
  };

  const getThumbnail = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      canvasRef.current?.getContext('2d').drawImage(videoElement, 0, 0, 56, 56);
    }
    requestAnimationFrame(getThumbnail);
  }, []);

  useEffect(() => {
    getThumbnail();
  }, [getThumbnail]);
  return (
    <MessageTemplateStyled className={className}>
      <div className="content">{text}</div>
      <div className="footer">
        <div className="image-group" onClick={handleOpenSlide}>
          {!!thumbnail && thumbnail.type.startsWith('image') ? (
            <img src={thumbnail.url} alt="" />
          ) : (
            <>
              <canvas ref={canvasRef} width="56" height="56" />
              <VideoStyled src={thumbnail.url.includes('https://') ? thumbnail.url : `https://${thumbnail.url}`} ref={videoRef} />
            </>
          )}
          {!!media.length && <div className="cover">+{media.length}</div>}
        </div>
      </div>
    </MessageTemplateStyled>
  );
}

// If u want to use this component, pls make sure use ImageShowProvider before
export function MessageRequest({ onReject, message = {}, onApprove }) {
  const { onSetImages, onSetOpen } = useContext<any>(ImageShowContext);
  const { text, media, className, thumbnail } = useMessage(message) as any;

  const handleOpenSlide = () => {
    if (!media.length) return;
    onSetImages(media);
    setTimeout(() => {
      onSetOpen(true);
    }, 100);
  };

  const handleReject = () => {
    onReject && onReject();
  };

  const handleApprove = () => {
    onApprove && onApprove();
  };

  return (
    <MessageRequestStyled className={className}>
      <div className="content">{text}</div>
      <div className="footer col">
        <div>
          <div className="image-group" onClick={handleOpenSlide}>
            {!!thumbnail && <img src={thumbnail.url} alt="" />}
            {!!media.length && <div className="cover">+{media.length}</div>}
          </div>
        </div>
        <div className="btn-wrapper">
          <MessageButtonStyled bgcolor={MESSAGE_COLOR.aprrove} onClick={handleApprove}>
            <CheckCircleIcon stroke={MESSAGE_COLOR.white} width="16px" height="16px" /> <span>Approve</span>
          </MessageButtonStyled>
          <MessageButtonStyled bgcolor={MESSAGE_COLOR.reject} ml="10px" onClick={handleReject}>
            <XOctagonIcon stroke={MESSAGE_COLOR.white} width="16px" height="16px" /> <span>Reject</span>
          </MessageButtonStyled>
        </div>
      </div>
    </MessageRequestStyled>
  );
}

const VideoStyled = styled.video`
  width: 0;
  height: 0;
`;
