import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';

// components
import Typography from '../../commons/Typography';
import { formatCurrencyInput } from '../../../utils/formatCurrency';
import { FORMAT_DATE_WALLET_DEFAULT } from '../../../utils/constants';

export const getColumns = (user, exchangeRate) => [
  {
    Header: 'Date',
    accessor: (data) => data.timestamp,
    Cell: ({ cell }) => {
      return (
        <CellStyled>
          <Typography text={format(new Date(cell.value), FORMAT_DATE_WALLET_DEFAULT)} color="#3E3E3E" fontSize="14px/16px" />
        </CellStyled>
      );
    },
  },
  {
    Header: `Amount (${user?.profile?.display_currency})`,
    accessor: (data) => data,
    Cell: ({ cell }) => {
      const myWalletId = user?.profile?.wallet_id;

      const calculation = useMemo(() => {
        return cell?.value?.credit === myWalletId ? '+' : '-';
      }, [cell?.value?.transaction_type?.code]);

      return (
        <CellStyled>
          <Typography
            text={`${calculation} ${formatCurrencyInput(
              (cell?.value?.amount_usd_cents / 100) * exchangeRate,
              user?.profile?.default_currency?.fraction_size ?? 0,
            )}`}
            color="#3E3E3E"
            fontSize="14px/16px"
          />
        </CellStyled>
      );
    },
  },
  {
    Header: `Balance (${user?.profile?.display_currency})`,
    accessor: (data) => data.balance_usd_cents,
    Cell: ({ cell }) => {
      return (
        <CellStyled>
          <Typography
            text={formatCurrencyInput((cell.value / 100) * exchangeRate, user?.profile?.default_currency?.fraction_size ?? 0)}
            color="#3E3E3E"
            fontSize="14px/16px"
          />
        </CellStyled>
      );
    },
  },
  {
    Header: 'Description',
    accessor: (data) => data.description,
    Cell: ({ cell }) => {
      return (
        <CellStyled className="description">
          <ComponentStyled color="#3E3E3E" fontSize="14px/16px">
            <span>{cell.value}</span>
          </ComponentStyled>
        </CellStyled>
      );
    },
  },
  {
    Header: 'Transaction ID',
    accessor: (data) => data.id,
    Cell: ({ cell }) => {
      return (
        <CellStyled className="description">
          <ComponentStyled color="#3E3E3E" fontSize="14px/16px">
            <span>{cell.value}</span>
          </ComponentStyled>
        </CellStyled>
      );
    },
  },
];

const CellStyled = styled.div<any>`
  margin-top: 16px;
  margin-bottom: 16px;
  padding-right: 20px;
  display: flex;
  align-items: center;

  &.description {
    max-width: 250px;
    height: 52px;
  }
`;

const ComponentStyled = styled.div<any>`
  span {
    padding-top: ${(props) => props.pdTop || '4px'};
    color: ${(props) => props.color};
    font: normal normal 400 ${(props) => props.fontSize} Helvetica Neue;
    overflow: hidden;
    display: block;
    white-space: normal;
    max-height: 52px;
  }
`;
