import { gql } from '@apollo/client';
export default gql`
  mutation ApproveWorkContract($id: uuid! = "") {
    update_work_contract_by_pk(pk_columns: { id: $id }, _set: { status_code: "CLIENT_REJECTED_PAY_REQUEST" }) {
      id
      tranche_id
      kol_profile_id
      status_code
      created_at
      updated_at
    }
  }
`;
